import React, { FC } from "react";

export interface CustomerDetailsPropsTypes {
  code: string;
  title: string;
  name: string;
  email: string;
  tel: string;
  quoteId?: string | number | null;
}

const CustomerDetails: FC<CustomerDetailsPropsTypes> = ({
  code,
  title,
  name,
  email,
  tel,
  quoteId,
}) => {
  const isQuoteIdValid: boolean = !!quoteId && quoteId !== 0 && quoteId !== "0";

  return (
    <>
      <div className="cart-header-code cart-header-text">{code ?? ""}</div>
      {isQuoteIdValid && (
        <div className="cart-header-code cart-header-text">
          {`Quote number: ${quoteId}`}
        </div>
      )}
      <div className="cart-header-title">{title ?? ""}</div>
      <div className="cart-header-name cart-header-text">{name ?? ""}</div>
      <div className="cart-header-email cart-header-text">{email ?? ""}</div>
      <div className="cart-header-tel cart-header-text">{tel ?? ""}</div>
    </>
  );
};

export default CustomerDetails;
