import React, { FC } from "react";

interface ButtonTakeMeBackPropsTypes {
  onClick: () => void;
}

const ButtonTakeMeBack: FC<ButtonTakeMeBackPropsTypes> = ({ onClick }) => {
  return (
    <div className="white-btn underline-hover-btn" onClick={onClick}>
      <div className="btn-icon">
        <div className="icon symmetry-icon-arrow-left" />
      </div>
      <div className="btn-text">Back</div>
    </div>
  );
};

export default ButtonTakeMeBack;
