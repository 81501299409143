/* eslint-disable no-unused-vars */
import React, { FC } from "react";

interface Props {
  title: string;
  onClick: (title: string) => void;
}

const ForkliftsToQuoteButton: FC<Props> = ({ title, onClick }) => {
  return (
    <div
      className="white-btn underline-hover-btn"
      onClick={() => onClick(title)}
      data-testid={`button-${title}`}
    >
      <div className="btn-icon">
        <div className="icon symmetry-icon-add" />
      </div>
      <div className="btn-text">{title}</div>
    </div>
  );
};

export default ForkliftsToQuoteButton;
