import React, { FC, useContext, useState } from "react";

import ButtonClose from "../Buttons/ButtonClose";
import ForkliftsToQuoteButton from "../Buttons/ForkliftsToQuoteButton";
import { SelectedPopupTopTypes } from "../../types/SelectedBatteryPopup";
import ButtonTakeMeBack from "../Buttons/ButtonTakeMeBack";
import {
  BatteryTypesNew,
  ChargerTypes,
  ReplacementBatteriesTypes,
} from "../../types/basicProductsTypes";
import {
  getRightBannerTopValuesForBatteries,
  getBannerRightTableValuesForBatteries,
  getBatteryReplacements,
} from "../../helpers/parsers";
import { BannerRightTableTypes } from "../../types/bannersTypes";
import { CoreFieldsArraysTypes } from "../../pages/Battery/SearchBatteryPage";
import SelectBatteryBanner from "../Banners/BatteryJourneyBanners/SelectBatteryBanner";
import SelectChargerBanner from "../Banners/BatteryJourneyBanners/SelectChargerBanner";
import { useQuoteUpdate } from "../../helpers/storage";
import { storageVariables } from "../../lib/storageVariables";
import AlertPopup from "./AlertPopup";
import { ModalContext } from "../../context/ModalContext";
import {
  alertAddBatteryBeforeCharger,
  alertSelectCustomerBeforeBattery,
} from "../../settings/confirmAlertMessages";

interface PropsTypes {
  selectedBattery: BatteryTypesNew | null;
  coreFieldsArrays: CoreFieldsArraysTypes;
  closePopup: () => void;
}

const SelectedBatteryJourneyPopup: FC<PropsTypes> = ({
  selectedBattery,
  coreFieldsArrays,
  closePopup,
}) => {
  const { openAlert, showAlert, alertMsg } = useContext(ModalContext);

  const { addBatteryToQuoteByBatteryFlow } = useQuoteUpdate();

  const [showAddCharger, setShowAddCharger] = useState(false);

  const [quantity, setQuantity] = useState<number>(1);

  const [batteryAddedToQuote, setBatteryAddedToQuote] = useState(false);

  const onIncrement = () => {
    setQuantity(quantity + 1);
  };

  const onDecrement = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const availableChargers: ChargerTypes[] | null =
    selectedBattery?.chargers ?? null;

  const batteryTableValues: BannerRightTableTypes[] =
    getBannerRightTableValuesForBatteries(
      coreFieldsArrays.batteryCoreFieldsArray,
      selectedBattery
    );

  const batteryPopupTopValues: SelectedPopupTopTypes =
    getRightBannerTopValuesForBatteries(selectedBattery);

  const batteryReplacementsArray: ReplacementBatteriesTypes[] =
    getBatteryReplacements(selectedBattery);

  const handleAddBatteryToQuote = () => {
    if (!selectedBattery) return;

    const selectedCustomerStr = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );
    const currentQuoteStr = localStorage.getItem(
      storageVariables.CURRENT_QUOTE
    );

    if (!selectedCustomerStr || !currentQuoteStr) {
      openAlert(alertSelectCustomerBeforeBattery);
      return;
    }

    setBatteryAddedToQuote(
      addBatteryToQuoteByBatteryFlow(selectedBattery, quantity)
    );
  };

  const handleShowAddCharger = () => {
    if (batteryAddedToQuote) {
      setShowAddCharger(true);
      setQuantity(1);
    } else {
      openAlert(alertAddBatteryBeforeCharger);
    }
  };
  const css = `
  body {
      overflow-y: hidden;
  }
`;
  return (
    <div className="popup-outer open">
      {showAlert && alertMsg && alertMsg === alertAddBatteryBeforeCharger && (
        <AlertPopup title={alertMsg} />
      )}
      {showAlert &&
        alertMsg &&
        alertMsg === alertSelectCustomerBeforeBattery && (
          <AlertPopup title={alertMsg} />
        )}
      <style>{css}</style>
      <div className="popup-bg" onClick={closePopup} />
      <div className="popup-inner mobile-popup">
        <div className="popup-box">
          {!showAddCharger ? (
            <SelectBatteryBanner
              batteryPopupTopValues={batteryPopupTopValues}
              batteryPopupTableValues={batteryTableValues}
              addItemToQuote={handleAddBatteryToQuote}
              replacementsArray={batteryReplacementsArray}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          ) : (
            <SelectChargerBanner
              availableChargers={availableChargers}
              chargerCoreFieldsArray={coreFieldsArrays.chargerCoreFieldsArray}
            />
          )}

          <div className="popup-poupup-btn row box-grid-container">
            <div className="col-md-6 col-left-align mobile-center my-2">
              {selectedBattery?.chargers &&
                selectedBattery?.chargers?.length > 0 && (
                  <>
                    {showAddCharger ? (
                      <ButtonTakeMeBack
                        onClick={() => setShowAddCharger(false)}
                      />
                    ) : (
                      <ForkliftsToQuoteButton
                        onClick={handleShowAddCharger}
                        title="Charger to quote"
                      />
                    )}
                  </>
                )}
            </div>

            <div className="col-md-6 col-right-align mobile-center my-2">
              <ButtonClose onClick={closePopup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedBatteryJourneyPopup;
