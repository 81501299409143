import React, { FC, useEffect, useState } from "react";
import ButtonSave from "../../../Buttons/ButtonSave";
import CustomerDetails, { CustomerDetailsPropsTypes } from "./CustomerDetails";
import { FetchedCompanyTypes } from "../../../../types/companies";
import { storageVariables } from "../../../../lib/storageVariables";

interface HeaderPropsTypes {
  code: string;
  title: string;
  name: string;
  email: string;
  tel: string;
}

const initialHeaderValues: HeaderPropsTypes = {
  code: "",
  title: "",
  name: "",
  email: "",
  tel: "",
};

interface CurrentQuoteHeaderPropsTypes {
  quoteId: number | undefined;
  handleSave: () => void;
  handlePrintPDF?: () => void;
}
const saveTooltip = (
  <div className="tooltip-container tooltiptext">
    Make sure you do not want to do any changes before clicking Save
  </div>
);
const CurrentQuoteHeader: FC<CurrentQuoteHeaderPropsTypes> = ({
  quoteId,
  handleSave,
  handlePrintPDF,
}) => {
  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetailsPropsTypes>(initialHeaderValues);

  useEffect(() => {
    const selectedCompany = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );
    if (selectedCompany) {
      const parsedCompany: FetchedCompanyTypes = JSON.parse(selectedCompany);
      const {
        sapId,
        companyName,
        address,
        city,
        countryId,
        activePersonId,
        contactPersons,
      } = parsedCompany;

      // find the active person from the contact persons array
      const activePerson = contactPersons.find(
        (person) => person.contactPersonId === activePersonId
      );

      const activePersonName = !activePerson
        ? ""
        : `${activePerson.firstName} ${activePerson.lastName} - ${activePerson.title}`;

      setCustomerDetails({
        code: sapId ?? "",
        title: `${companyName} - ${address}, ${city}, ${
          countryId.countryName || ""
        }`,
        name: activePersonName,
        email: activePerson?.email || "",
        tel: activePerson?.phoneNumber || "",
        quoteId: quoteId ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="row box-grid-container cart-header-container">
      <div className="col-md-4 col-left-align">
        <CustomerDetails {...customerDetails} />
      </div>

      <div className="col-md-6 col-right-align">
        <div className="generate_pdf" onClick={handlePrintPDF}>
          <div
            className="white-btn underline-hover-btn"
            data-html2canvas-ignore="true"
          >
            <div className="btn-text me-2">Make PDF</div>
            <div className="btn-icon">
              <div className="icon symmetry-icon-bill" />
            </div>
          </div>
        </div>
        <ButtonSave onClick={handleSave} hasTooltip={saveTooltip} />
      </div>
    </div>
  );
};

export default CurrentQuoteHeader;
