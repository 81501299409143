/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";
import ButtonShowHide from "../Buttons/ButtonShowHide";

interface BatterySearchHeaderPropsTypes {
  measurementEU: boolean;
  displaySearchForm: boolean;
  toggleDisplaySearchForm: () => void;
}

const BatterySearchHeader: FC<BatterySearchHeaderPropsTypes> = ({
  measurementEU,
  displaySearchForm,

  toggleDisplaySearchForm,
}) => {
  return (
    <div className="">
      <div className="row box-grid-container">
        <div className="col-md-6 col-left-align mb-4 mobile-center">
          <div className="title-form">
            <div className="title-form-text">
              Let&apos;s find a battery &nbsp;{" "}
            </div>
            <ButtonShowHide
              displaySearchForm={displaySearchForm}
              onClick={toggleDisplaySearchForm}
            />
          </div>
        </div>

        <div className="col-md-6 col-right-align mb-2 mobile-center">
          <div className="custom-toggle-outer">
            <div className="custom-toggle-inner px-3 py-1 cursor-auto">
              Current Measurement System: &nbsp;
              <div className="custom-toggle custom-toggle-right zona-bold">
                {measurementEU ? "EUR" : "USA"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatterySearchHeader;
