import React, { FC } from "react";

interface ButtonPreviousPropsTypes {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

const ButtonPrevious: FC<ButtonPreviousPropsTypes> = ({
  title,
  onClick,
  disabled,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div
      className={`${
        disabled ? "disable" : "white"
      }-btn corner-btn underline-hover-btn`}
      onClick={handleClick}
    >
      <div className="btn-icon">
        <div className="icon symmetry-icon-arrow-left" />
      </div>
      <div className="btn-text">{title}</div>
    </div>
  );
};

export default ButtonPrevious;
