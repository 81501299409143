/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";

interface ButtonRemoveAllPropsTypes {
  onClick: () => void;
}

const ButtonRemoveAll: FC<ButtonRemoveAllPropsTypes> = ({ onClick }) => {
  return (
    <div
      className="cart-item-btn col-sm-1"
      onClick={onClick}
      role="button"
      data-html2canvas-ignore
    >
      <div className="cart-item-btn-remove-all">
        <span>Remove all</span>
        <div data-icon="$" className="icon" />
      </div>
    </div>
  );
};

export default ButtonRemoveAll;
