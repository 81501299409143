import React, { FC } from "react";
import DividerGrey from "../../../Dividers/DividerGrey";
import { CurrentQuoteTypes } from "../../../../types/currentQuoteTree";
import ExistingQuoteFooterDetails from "./ExistingQuoteFooterDetails";

interface ExistingQuoteFooterPropsTypes {
  repNotes: string;
  currentQuote: CurrentQuoteTypes | null;
}

const ExistingQuoteFooter: FC<ExistingQuoteFooterPropsTypes> = ({
  repNotes,
  currentQuote,
}) => {
  return (
    <>
      <DividerGrey />
      <ExistingQuoteFooterDetails
        repNotes={repNotes}
        currentQuote={currentQuote}
      />
    </>
  );
};

export default ExistingQuoteFooter;
