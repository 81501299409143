import React, { FC, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../../hooks/UseFormatPrice";
import {
  currentDecimalSeparator,
  currentGroupSeparator,
} from "../../../../../helpers/currencyFormat";
import {
  getFloatFromString,
  getStringFromFloat,
} from "../../../../../helpers/parsers";
import { getCurrency } from "../../../../../helpers/storage";

interface PropsTypes {
  totalPrice: number;
  index: number;
  manualAddedPrice: number | null;
  toggleSelectedFinalPrice?: () => void;
  changeSelectedFinalPrice: (_value: number) => void;
}

const FinalPriceWithToggle: FC<PropsTypes> = ({
  totalPrice,
  index,
  manualAddedPrice,
  toggleSelectedFinalPrice,
  changeSelectedFinalPrice,
}) => {
  const { getFormattedPriceFromObj } = useFormatPrice();
  const currency = getCurrency();

  const zeroValue = `0${currentDecimalSeparator}00`;

  const manualAddedPriceObj: FormattedMoneyTypes = formatMoney({
    amount: totalPrice,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  const [currentValue, setValue] = React.useState<string>(zeroValue);

  const handleValueChange = (value: string) => {
    setValue(value);

    changeSelectedFinalPrice(getFloatFromString(value));
  };

  const currencyStyle = currency === "EUR" ? "euro-format" : "usd-format";

  useEffect(() => {
    setValue(
      manualAddedPrice !== null
        ? getStringFromFloat(manualAddedPrice)
        : zeroValue
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manualAddedPrice !== null) {
      setValue(getStringFromFloat(manualAddedPrice));
    }
  }, [manualAddedPrice]);

  return (
    <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-final-price">
      {toggleSelectedFinalPrice && (
        <div className="cart-item-top">
          <div className="cart-item-final-price-label">
            <div>FINAL PRICE</div>
            <label
              className="switch"
              htmlFor={`checkedInput${index}`}
              data-html2canvas-ignore
            >
              <input
                id={`checkedInput${index}`}
                type="checkbox"
                checked={manualAddedPrice !== null}
                onChange={toggleSelectedFinalPrice}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      )}

      <div className="cart-item-bottom">
        {manualAddedPrice !== null ? (
          <>
            <div
              className={`cart-item-bottom ${currencyStyle} cart-item-discount-number `}
            >
              <CurrencyInput
                allowNegativeValue={false}
                decimalsLimit={2}
                decimalScale={2}
                disableAbbreviations
                value={currentValue}
                decimalSeparator={currentDecimalSeparator}
                groupSeparator={currentGroupSeparator}
                onValueChange={(value) => handleValueChange(value || zeroValue)}
              />
            </div>
          </>
        ) : (
          <div className="cart-item-final-price">
            {getFormattedPriceFromObj(manualAddedPriceObj)}
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalPriceWithToggle;
