/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useContext, useEffect, useState } from "react";
import {
  FetchedCompanyTypes,
  FetchedContactPersonsTypes,
} from "../../types/companies";

import useDebounce from "../../hooks/useDebounce";

import {
  getCompanyDebounceTimeMs,
  getCompanyMinimumCharacters,
} from "../../settings/debounceLimits";
import { CartContext } from "../../context/CartContext";

import useAuthApiCall from "../../hooks/useAuthApiCall";
import ConfirmPopup from "../Popups/ConfirmPopup";
import { confirmDeleteCompany } from "../../settings/confirmAlertMessages";
import { ModalContext } from "../../context/ModalContext";
// import { parseFetchedCompanies } from "../../helpers/parsers";

interface SelectInputPropsTypes {
  selectedOption: number;
  setSelectedCompany: (_company: FetchedCompanyTypes | null) => void;
  setContactPersons: (_contactPersons: FetchedContactPersonsTypes[]) => void;
  setActivePersonId: React.Dispatch<React.SetStateAction<number | null>>;
  setShowPopupEditCompany: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPopupAddContacts: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteCompany: () => void;
}

const SelectCustomerInputAsync: FC<SelectInputPropsTypes> = ({
  selectedOption,
  setSelectedCompany,
  setContactPersons,
  setActivePersonId,
  setShowPopupEditCompany,
  setShowPopupAddContacts,
  onDeleteCompany,
}) => {
  const { setApiError } = useContext(CartContext);

  const { showConfirm, openConfirm, confirmMsg } = useContext(ModalContext);

  const [isInputOpen, setIsInputOpen] = useState(false);

  const [fetchedCompanies, setFetchedCompanies] = React.useState<
    FetchedCompanyTypes[]
  >([]);

  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState("");
  const debouncedFilterValue = useDebounce<string>(
    filterValue,
    getCompanyDebounceTimeMs
  );

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  async function fetchCustomers(valueStr: string) {
    setApiError(null);
    if (selectedValue === filterValue) return;

    let searchValue = "COMPANY_NAME";
    if (selectedOption === 2) searchValue = "SAP_ID";
    const dataStr = JSON.stringify({
      search: searchValue,
      value: valueStr,
    });

    handleAuthApiCall({
      method: "post",
      url: "/v1/companies/search",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataStr,
      isSearchRequest: true,
    });
  }

  useEffect(() => {
    if (!apiData) {
      setFetchedCompanies([]);
      return;
    }

    const { companies } = apiData;

    if (!companies) {
      setFetchedCompanies([]);
    } else {
      setFetchedCompanies(companies);
    }
  }, [apiData]);

  const handleSelectCompany = (selectedCompany: FetchedCompanyTypes) => {
    const selectedPersons: FetchedContactPersonsTypes[] =
      selectedCompany.contactPersons;

    if (selectedPersons.length > 0)
      setActivePersonId(selectedPersons[0].contactPersonId);
    else setActivePersonId(null);
    setFilterValue(selectedCompany.companyName);
    setSelectedValue(selectedCompany.companyName);
    setSelectedCompany(selectedCompany);
    setContactPersons(selectedPersons);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSelectedValue("");
    setFilterValue(input);
    setSelectedCompany(null);
    setContactPersons([]);
    if (input.length > 2) setIsInputOpen(true);
    else setIsInputOpen(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputOpen(false);
    }, 200);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > getCompanyMinimumCharacters - 1)
      setIsInputOpen(true);
  };

  let placeholder = "Filter by client's company name here";
  if (selectedOption === 2) placeholder = "Filter by client's SAP ID here";

  useEffect(() => {
    setFilterValue("");
    setSelectedValue("");
    setSelectedCompany(null);
    setContactPersons([]);
    setFetchedCompanies([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (filterValue.length > 2) fetchCustomers(filterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValue]);

  const handleDelete = () => {
    if (!selectedValue) return;

    openConfirm(confirmDeleteCompany);
  };

  return (
    <>
      <div className=" form-dropdown-list" onBlur={handleBlur}>
        <div className="list-results-item p-0">
          <input
            type="text"
            placeholder={placeholder}
            value={selectedValue || filterValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
          />
          {selectedValue && (
            <div className="list-results-item-choices popup-company">
              <div className="list-results-item-add">
                <div
                  className="icon-add"
                  onClick={() => setShowPopupAddContacts(true)}
                  role="button"
                >
                  <div className="btn-text">+</div>
                </div>
              </div>

              <div className="list-results-item-edit">
                <div
                  className="icon-edit"
                  onClick={() => setShowPopupEditCompany(true)}
                  role="button"
                >
                  <div className="icon symmetry-icon-edit" />
                </div>
              </div>
              <div className="list-results-item-delete">
                <div
                  className="icon-delete"
                  onClick={handleDelete}
                  role="button"
                >
                  <div className="icon symmetry-icon-bin" />
                </div>
              </div>
            </div>
          )}
        </div>

        {isInputOpen && (
          <div
            className="form-dropdown-list-container"
            style={{ display: "block" }}
          >
            {/* <ul>
            {parsedCompanies.map((option) => (
              <li key={option} onClick={() => handleSelectCompany(option)}>
                {option}
              </li>
            ))}
          </ul> */}
            <ul>
              {fetchedCompanies.length > 0 &&
                fetchedCompanies.map((option: FetchedCompanyTypes) => (
                  <li
                    key={option.companyId}
                    onClick={() => handleSelectCompany(option)}
                  >
                    {option.companyName}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      {showConfirm && confirmMsg && confirmMsg === confirmDeleteCompany && (
        <ConfirmPopup title={confirmMsg} onConfirm={onDeleteCompany} />
      )}
    </>
  );
};

export default SelectCustomerInputAsync;
