/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC } from "react";
import ExistingQuantityInput from "../ExistingQuoteItemComponents/ExistingQuantityInput";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../../hooks/UseFormatPrice";
import ExistingDescription from "../ExistingQuoteItemComponents/ExistingDescription";
import InitialPrice from "../ExistingQuoteItemComponents/ExistingInitialPrice";
import ExistingDiscountPriceWithToggle from "../ExistingQuoteItemComponents/ExistingDiscountPriceWithToggle";
import FinalPriceWithToggle from "../ExistingQuoteItemComponents/ExistingFinalPriceWithToggle";
import { QuoteItemChargerTypes } from "../../../../../types/currentQuoteTree";

interface ExistingQuoteItemChargerInBatteryPropsTypes {
  currency: string;
  quoteItem: QuoteItemChargerTypes;
  finalPriceEnabled: boolean;
}

const ExistingQuoteItemChargerInBattery: FC<
  ExistingQuoteItemChargerInBatteryPropsTypes
> = ({ currency, quoteItem, finalPriceEnabled }) => {
  const { getFormattedPriceFromObj } = useFormatPrice();

  const {
    name,
    imageUrl,
    itemPrice,
    totalPrice,
    quantity,
    manualAddedPrice,
    discountType,
    discountPercentage,
    discountPrice,
    discountIncrease,
  } = quoteItem;

  const initialPriceObj: FormattedMoneyTypes = formatMoney({
    amount: itemPrice,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  return (
    <>
      <div
        className="cart-item-row cart-item-level-3"
        style={{ display: "block" }}
      >
        <div className="cart-item-row-inner row">
          <ExistingDescription productTitle={name} imageUrl={imageUrl} />

          <InitialPrice
            formattedPrice={getFormattedPriceFromObj(initialPriceObj)}
          />

          <ExistingQuantityInput quantity={quantity} />

          <div
            className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-discount"
            data-html2canvas-ignore
          >
            {!finalPriceEnabled && (
              <ExistingDiscountPriceWithToggle
                discountIncrease={discountIncrease}
                discountType={discountType}
                discountPercentage={discountPercentage}
                discountPrice={discountPrice}
              />
            )}
          </div>

          <FinalPriceWithToggle
            totalPrice={totalPrice}
            manualAddedPrice={manualAddedPrice}
          />
        </div>
      </div>
    </>
  );
};

export default ExistingQuoteItemChargerInBattery;
//
