import React, { FC } from "react";
import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import ButtonChooseJourney from "../../components/Buttons/ButtonChooseJourney";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import { getCompanyName } from "../../helpers/functions";

const SelectJourneyPage: FC = () => {
  const { navigateTo } = useNavigation();

  const companyTitle: string = getCompanyName();

  return (
    <MainPageWrapper title="Select Journey" testid="not-found-page">
      <TopBanner startFromBeginning />

      <div className="row box-grid-container">
        <div className="col-md-6">
          <div className="banner-left-outer">
            <div className="banner-left-inner">
              <div className="banner-title">
                {`How can we help ${companyTitle} today?`}
              </div>
              <div className="choose-journey-container">
                <ButtonChooseJourney
                  title="Forklift"
                  onClick={() =>
                    navigateTo(availableRoutes.START_JOURNEY_BY_FORKLIFT)
                  }
                />
                <ButtonChooseJourney
                  title="Battery"
                  onClick={() =>
                    navigateTo(availableRoutes.START_JOURNEY_BY_BATTERY)
                  }
                />
                <ButtonChooseJourney
                  title="Charger"
                  onClick={() =>
                    navigateTo(availableRoutes.START_JOURNEY_BY_CHARGER)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainPageWrapper>
  );
};

export default SelectJourneyPage;
