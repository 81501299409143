import React, { FC } from "react";

import {
  ChargerTypes,
  CoreFieldsTypes,
} from "../../../types/basicProductsTypes";
import { getBannerRightTableValuesForChargers } from "../../../helpers/parsers";
import { BannerRightTableTypes } from "../../../types/bannersTypes";
import ChargerItem from "./ChargerPopup/ChargerItem";
import { useQuoteUpdate } from "../../../helpers/storage";

interface ProsTypes {
  availableChargers: ChargerTypes[] | null;
  chargerCoreFieldsArray: CoreFieldsTypes[] | [];
}

const SelectChargerBanner: FC<ProsTypes> = ({
  availableChargers,
  chargerCoreFieldsArray,
}) => {
  const { addChargerToQuoteByBatteryFlow } = useQuoteUpdate();

  const [selectedCharger, setSelectedCharger] =
    React.useState<ChargerTypes | null>(
      availableChargers ? availableChargers[0] : null
    );

  const [quantity, setQuantity] = React.useState<number>(1);

  const rightBannerTableValues: BannerRightTableTypes[] =
    getBannerRightTableValuesForChargers(
      chargerCoreFieldsArray,
      selectedCharger
    );

  const handleSelectCharger = (chargerId: number) => {
    const chosenCharger: ChargerTypes | undefined = availableChargers?.find(
      (charger) => charger.chargerId === chargerId
    );

    setSelectedCharger(chosenCharger ?? null);
    setQuantity(1);
  };

  const handleAddChargerToQuote = () => {
    if (selectedCharger) {
      addChargerToQuoteByBatteryFlow(selectedCharger, quantity);
    }
  };

  const onIncrement = () => {
    setQuantity(quantity + 1);
  };
  const onDecrement = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div className="">
      <React.Fragment>
        {availableChargers &&
          availableChargers.length > 0 &&
          availableChargers.map((charger: ChargerTypes) => {
            const isSelected = charger.chargerId === selectedCharger?.chargerId;

            return (
              <ChargerItem
                charger={charger}
                quantity={quantity}
                isSelected={isSelected}
                key={charger.chargerId}
                chargerId={charger.chargerId}
                chargerPopupTableValues={rightBannerTableValues}
                onIncrement={onIncrement}
                onDecrement={onDecrement}
                onClick={handleSelectCharger}
                addItemToQuote={handleAddChargerToQuote}
                setQuantity={setQuantity}
              />
            );
          })}
      </React.Fragment>
    </div>
  );
};

export default SelectChargerBanner;
