import React, { FC } from "react";
import Select from "react-select";
import { ReactSelectOptionsTypes } from "../../types/inputs";

interface PropsTypes {
  placeholder: string;
  defaultValue?: ReactSelectOptionsTypes | null;
  availableOptions: ReactSelectOptionsTypes[];
  selectedOption?: ReactSelectOptionsTypes | null;
  onChange: (_option: ReactSelectOptionsTypes | null) => void;
}

const ReactSelectInput: FC<PropsTypes> = ({
  availableOptions,
  defaultValue,
  placeholder,
  selectedOption,
  onChange,
}) => {
  // Custom filtering logic
  const filterOption = (option: any, rawInput: any) => {
    const inputValue = rawInput.toLowerCase();
    const optionValue = option.label.toLowerCase();
    return optionValue.includes(inputValue);
  };

  return (
    <Select
      isClearable
      value={selectedOption ?? null}
      placeholder={placeholder}
      defaultValue={defaultValue ?? null}
      options={availableOptions}
      onChange={onChange}
      filterOption={filterOption}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

export default ReactSelectInput;
