import React from "react";
import { useNavigation } from "../../hooks/useNavigation";

const TakeMeBackButton = () => {
  const { goBack } = useNavigation();

  const handleGoBack = () => {
    goBack();
  };
  return (
    <div className="white-btn underline-hover-btn" onClick={handleGoBack}>
      <div className="btn-icon">
        <div className="icon symmetry-icon-arrow-left" />
      </div>
      <div className="btn-text">Back</div>
    </div>
  );
};

export default TakeMeBackButton;
