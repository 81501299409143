import React, { FC } from "react";

interface ButtonProductAddToQuotePropsTypes {
  title?: string;
  onClick?: () => void;
}

const ButtonProductAddToQuote: FC<ButtonProductAddToQuotePropsTypes> = ({
  onClick,
  title = "Add to Quote",
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div className="product-details-add-to-quote" onClick={handleClick}>
      <div className="blue-btn corner-btn">
        <div className="btn-icon">
          <div className="icon symmetry-icon-add" />
        </div>
        <div className="btn-text">{title}</div>
      </div>
    </div>
  );
};

export default ButtonProductAddToQuote;
