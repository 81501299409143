import React, { FC } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import useAuth from "../../hooks/useAuth";

const UserNavLinks: FC = () => {
  const { handleLogout, getAgentName } = useAuth();

  const agentName: string = getAgentName() || "";

  return (
    <div className="navbar-user" data-testid="user-nav-link">
      <div className="navbar-user-container">
        <AuthenticatedTemplate>
          <div className="navbar-user-left">
            <div className="navbar-user-welcome-text">Welcome</div>
            <div className="navbar-user-welcome-name">{agentName}</div>
          </div>
          <div className="navbar-user-right">
            <div className="navbar-user-welcome-icon corner-btn">
              <div className="icon symmetry-icon-user-locked-yes" />
            </div>
          </div>
          <div className="navbar-user-on-hover">
            <div
              className="logout-user-btn corner-btn"
              onClick={() => handleLogout()}
            >
              <div>Logout</div>
              <div className="icon symmetry-icon-user-locked-no" />
            </div>
          </div>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <div className="navbar-user-left">
            <div className="no-loggedin-user-empty" />
          </div>
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
};

export default UserNavLinks;
