import React, { FC } from "react";
import ExistingQuoteItemHeader from "./ExistingQuoteItemLevels/ExistingQuoteItemHeader";

import {
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../../../../types/currentQuoteTree";
import ExistingQuoteItemBattery from "./ExistingQuoteItemLevels/ExistingQuoteItemBattery";
import ExistingQuoteItemCharger from "./ExistingQuoteItemLevels/ExistingQuoteItemCharger";

export interface ChangeItemQuantityTypes {
  itemType: "battery" | "charger";
  updateType: "increase" | "decrease";
}

interface ExistingQuoteItemBlockPropsTypes {
  quoteItem:
    | QuoteItemForkliftTypes
    | QuoteItemBatteryTypes
    | QuoteItemChargerTypes;
}

const ExistingQuoteItemBlock: FC<ExistingQuoteItemBlockPropsTypes> = ({
  quoteItem,
}) => {
  let initiallyEnabled = false;
  if (quoteItem.itemType === "forklift") {
    initiallyEnabled = quoteItem.battery?.manualAddedPrice !== null;
  } else if (quoteItem.itemType === "battery") {
    initiallyEnabled = quoteItem.manualAddedPrice !== null;
  } else if (quoteItem.itemType === "charger") {
    initiallyEnabled = quoteItem.manualAddedPrice !== null;
  }

  const [showQuoteItems, setShowQuoteItems] = React.useState(true);

  const toggleShowQuoteItems = () => {
    setShowQuoteItems(!showQuoteItems);
  };

  if (quoteItem.itemType === "forklift" || quoteItem.itemType === "battery") {
    const battery =
      quoteItem.itemType === "forklift" ? quoteItem?.battery : quoteItem;

    if (!battery) return <></>;

    return (
      <div className="cart-item-outer">
        <div className="cart-item-inner">
          <ExistingQuoteItemHeader
            quoteItem={quoteItem}
            toggleShowQuoteItems={toggleShowQuoteItems}
          />
          {showQuoteItems && battery && (
            <ExistingQuoteItemBattery
              quoteItem={battery}
              finalPriceEnabled={initiallyEnabled}
            />
          )}
        </div>
      </div>
    );
  }

  if (quoteItem.itemType === "charger") {
    return (
      <div className="cart-item-outer">
        <div className="cart-item-inner">
          <ExistingQuoteItemHeader
            quoteItem={quoteItem}
            toggleShowQuoteItems={toggleShowQuoteItems}
          />
          {showQuoteItems && (
            <ExistingQuoteItemCharger
              quoteItem={quoteItem}
              finalPriceEnabled={initiallyEnabled}
            />
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default ExistingQuoteItemBlock;
