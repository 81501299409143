/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, FC, ReactNode, useMemo, useState } from "react";
import {
  AvailableCompanyTypes,
  AvailableCountryTypes,
} from "../types/companies";

interface AppContextTypes {
  fetchDataApiError: string | null;
  fetchDataLoading: boolean;
  AvailableCompanies: AvailableCompanyTypes[];
  AvailableCountries: AvailableCountryTypes[];
  newCompanyId: number | null;

  setFetchDataLoading: (_loading: boolean) => void;
  setFetchDataApiError: (_error: string | null) => void;
  setAvailableCompanies: (_companies: AvailableCompanyTypes[]) => void;
  setAvailableCountries: (_countries: AvailableCountryTypes[]) => void;
  setNewCompanyId: (_id: number | null) => void;
}

const FetchedDataContext = createContext<AppContextTypes>({
  fetchDataApiError: null,
  fetchDataLoading: false,
  AvailableCompanies: [],
  AvailableCountries: [],
  newCompanyId: null,

  setFetchDataLoading: (_loading: boolean) => {},
  setFetchDataApiError: (_error: string | null) => {},
  setAvailableCompanies: (_companies: AvailableCompanyTypes[]) => {},
  setAvailableCountries: (_countries: AvailableCountryTypes[]) => {},
  setNewCompanyId: (_id: number | null) => {},
});

interface FetchedDataProviderProps {
  children: ReactNode;
}

const FetchedDataProvider: FC<FetchedDataProviderProps> = ({ children }) => {
  const [fetchDataApiError, setFetchDataApiError] = useState<string | null>(
    null
  );
  const [fetchDataLoading, setFetchDataLoading] = useState<boolean>(false);

  const [AvailableCompanies, setAvailableCompanies] = useState<
    AvailableCompanyTypes[]
  >([]);
  const [AvailableCountries, setAvailableCountries] = useState<
    AvailableCountryTypes[]
  >([]);

  const [newCompanyId, setNewCompanyId] = useState<number | null>(null);

  const providerValue = useMemo(() => {
    return {
      fetchDataLoading,
      fetchDataApiError,
      AvailableCompanies,
      AvailableCountries,
      newCompanyId,

      setFetchDataApiError,
      setFetchDataLoading,
      setAvailableCompanies,
      setAvailableCountries,
      setNewCompanyId,
    };
  }, [
    fetchDataLoading,
    fetchDataApiError,
    AvailableCompanies,
    AvailableCountries,
    newCompanyId,

    setAvailableCompanies,
    setAvailableCountries,
    setFetchDataApiError,
    setFetchDataLoading,
    setNewCompanyId,
  ]);

  return (
    <FetchedDataContext.Provider value={providerValue}>
      {children}
    </FetchedDataContext.Provider>
  );
};

export { FetchedDataContext, FetchedDataProvider };
