import React, { FC } from "react";

interface InitialPricePropsTypes {
  formattedPrice: React.ReactNode | string;
}

const InitialPrice: FC<InitialPricePropsTypes> = ({ formattedPrice }) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-price">
      <div className="cart-item-top">
        <div className="cart-item-price-label">PRICE</div>
      </div>
      <div className="cart-item-bottom">
        <div className="cart-item-price">{formattedPrice}</div>
      </div>
    </div>
  );
};

export default InitialPrice;
