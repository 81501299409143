interface MeasurementUnits {
  weight: string;
  length: string;
}

interface AvailableContinentsUnits {
  EU: MeasurementUnits;
  USA: MeasurementUnits;
}

export const measurementUnits: AvailableContinentsUnits = {
  EU: {
    weight: "kg",
    length: "mm",
  },
  USA: {
    weight: "pounds",
    length: "in",
  },
};

export const chemistryFindChargersAvailableOptions: string[] = [
  "Lithium-ion",
  "Lead-Acid",
];
export const chemistryAvailableOptions: string[] = [
  "Nickel Metal Hydride",
  "Lithium Iron Phosphate",
  "Lithium Titanate Oxide",
  "Lithium Manganese Oxide",
  "Lithium Nickel Manganese Cobalt Oxide",
  "Lithium Nickel Cobalt Aluminum Oxide",
  "Lithium Cobalt Oxide",
];

export const chemistryOptions: string[] = [
  "Lithium-ion",
  "Lead-acid",
  "Nickel-metal hydride",
];

export const batteryTypeOptions: string[] = [
  "High-capacity",
  "Low-capacity",
  "Medium-capacity",
];

export const batteryColorsOptions: string[] = ["Blue", "Green"];

export const batteryDinOptions: string[] = ["Yes", "No"];
