import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import useAuthApiCall from "./useAuthApiCall";
import { storageVariables } from "../lib/storageVariables";
import { CurrentRegionSettingsTypes } from "../types/agent";
import { getCurrencyByRegion } from "../helpers/currencyFormat";

const useRegion = () => {
  const { accounts } = useMsal();
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const currentUserName: string = accounts[0].name ?? "";

  const getRegion = () => {
    const config = {
      url: "/v1/user/",
      method: "get",
    };
    handleAuthApiCall(config);
  };

  const handleGetRegion = (): void => {
    const currentRegionStr: string | null = localStorage.getItem(
      storageVariables.CURRENCY
    );

    if (!currentRegionStr) {
      getRegion();
      return;
    }

    const currentRegionSettings: CurrentRegionSettingsTypes =
      JSON.parse(currentRegionStr);

    if (
      !currentRegionSettings?.officeLocation ||
      !currentRegionSettings?.currency
    ) {
      getRegion();
      return;
    }

    if (currentRegionSettings?.userName !== currentUserName) getRegion();
  };

  useEffect(() => {
    if (apiData) {
      localStorage.removeItem(storageVariables.CURRENCY);

      const currentRegionSettings: CurrentRegionSettingsTypes = {
        officeLocation: apiData?.officeLocation ?? null,
        userName: currentUserName,
        currency: getCurrencyByRegion(apiData?.officeLocation ?? null),
      };

      localStorage.setItem(
        storageVariables.CURRENCY,
        JSON.stringify(currentRegionSettings)
      );
    }
  }, [apiData]);

  return { handleGetRegion };
};
export default useRegion;
