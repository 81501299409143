import React, { FC } from "react";
import { DiscountTypesEnum } from "../../../../../config/discountTypes";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { getCurrency } from "../../../../../helpers/storage";

interface PropsTypes {
  discountType: DiscountTypesEnum;
  discountIncrease: boolean;
  discountPercentage: number | null;
  discountPrice: number;
}

const ExistingDiscountPriceWithToggle: FC<PropsTypes> = ({
  discountType,
  discountIncrease,
  discountPercentage,
  discountPrice,
}) => {
  const currency = getCurrency();

  const discountIsPercentage = discountType === DiscountTypesEnum.PERCENTAGE;

  const discountValuePercentageObj: FormattedMoneyTypes = formatMoney({
    amount: discountPercentage || 0,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  const discountPriceObj: FormattedMoneyTypes = formatMoney({
    amount: discountPrice || 0,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  // const currencyStyle = currency === "EUR" ? "euro-format" : "usd-format";

  return (
    <>
      <div className="cart-item-top center-align">
        <div className="cart-item-quantity-label">
          {!discountIncrease ? "DISCOUNT" : "INCREASE"}
        </div>
      </div>

      <div className="cart-item-price">
        {!discountIsPercentage ? (
          <div className="cart-item-bottom center-align">
            {`${discountPriceObj.currencySymbol}${discountPriceObj.money}`}
            <span className="existing-discount">
              {discountPriceObj.decimalPart}
            </span>
          </div>
        ) : (
          <div className="cart-item-bottom center-align">
            {`${discountValuePercentageObj.money}`}
            <span className="existing-discount">
              {discountValuePercentageObj.decimalPart}
            </span>
            &#37;
          </div>
        )}
      </div>
    </>
  );
};

export default ExistingDiscountPriceWithToggle;
