import React, { FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FetchedContactPersonsTypes } from "../../types/companies.d";
import TextInput from "../Inputs/TextInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import {
  emailValidationRegex,
  phoneCountryCodeValidationRegex,
} from "../../config/validationRegex";
// import { getCurrency } from "../../helpers/storage";

export interface ClientDataEditTypes {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  phoneCountryCode: string;
  companyId?: number;
}

export interface EditContactPopupTypes {
  selectedClient: FetchedContactPersonsTypes;

  onClick: (_data: ClientDataEditTypes) => void;
  closePopup: () => void;
}

const validationSchemaEdit = Yup.object().shape({
  firstName: Yup.string()
    .required("This is a required field")
    .max(40, "First Name can be at most 40 characters"),
  lastName: Yup.string()
    .required("This is a required field")
    .max(40, "Last Name can be at most 40 characters"),
  email: Yup.string()
    .required("This is a required field")
    .matches(emailValidationRegex, "Please enter a valid email")
    .max(100, "Email can be at most 100 characters"),
  phoneCountryCode: Yup.string()
    .required("This is a required field")
    .matches(
      phoneCountryCodeValidationRegex,
      "Please enter a valid phone country code"
    ),
  phoneNumber: Yup.string()
    .required("This is a required field")
    .matches(/^[0-9-]*$/, "Please enter a valid phone number")
    .max(20, "Phone Number can be at most 20 characters"),
  title: Yup.string()
    .required("This is a required field")
    .max(40, "Job Title can be at most 40 characters"),
});

// const validationSchemaEditUSA = Yup.object().shape({
//   firstName: Yup.string()
//     .required("This is a required field")
//     .max(40, "First Name can be at most 40 characters"),
//   lastName: Yup.string()
//     .required("This is a required field")
//     .max(40, "Last Name can be at most 40 characters"),
//   email: Yup.string()
//     .required("This is a required field")
//     .matches(emailValidationRegex, "Please enter a valid email")
//     .max(100, "Email can be at most 100 characters"),
//   phoneCountryCode: Yup.string()
//     .nullable()
//     .matches(
//       phoneCountryCodeValidationRegex,
//       "Please enter a valid phone country code"
//     ),
//   phoneNumber: Yup.string()
//     .nullable()
//     .matches(/^[0-9-]*$/, "Please enter a valid phone number")
//     .max(20, "Phone Number can be at most 20 characters"),
//   title: Yup.string()
//     .nullable()
//     .max(40, "Job Title can be at most 40 characters"),
// });

// const currency: string = getCurrency();

const EditContactPopup: FC<EditContactPopupTypes> = ({
  selectedClient,
  onClick,
  closePopup,
}) => {
  const formikEditClient = useFormik({
    initialValues: {
      firstName: selectedClient?.firstName || "",
      lastName: selectedClient?.lastName || "",
      email: selectedClient?.email || "",
      phoneCountryCode: selectedClient?.phoneCountryCode || "",
      phoneNumber: selectedClient?.phoneNumber || "",
      title: selectedClient?.title || "",
    },
    validateOnBlur: true,
    validationSchema: validationSchemaEdit,
    // validationSchema:
    //   currency === "EUR" ? validationSchemaEdit : validationSchemaEditUSA,
    onSubmit: () => {
      formikEditClient.resetForm();
    },
  });

  const updateFormikValue = (name: string, value: string) => {
    formikEditClient.setFieldValue(name, value);
  };

  const inputData = {
    firstName: {
      name: "firstName",
      placeholder: "First Name",
      value: formikEditClient.values.firstName,
      onChange: (value: string) => updateFormikValue("firstName", value),
      errorMessage: formikEditClient.errors.firstName ?? null,
    },
    lastName: {
      name: "lastName",
      placeholder: "Last Name",
      value: formikEditClient.values.lastName,
      onChange: (value: string) => updateFormikValue("lastName", value),
      errorMessage: formikEditClient.errors.lastName ?? null,
    },
    email: {
      name: "email",
      placeholder: "Email",
      value: formikEditClient.values.email,
      onChange: (value: string) => updateFormikValue("email", value),
      errorMessage: formikEditClient.errors.email ?? null,
    },
    phoneCountryCode: {
      name: "phoneCountryCode",
      placeholder: "Phone Country Code",
      value: formikEditClient.values.phoneCountryCode,
      onChange: (value: string) => updateFormikValue("phoneCountryCode", value),
      errorMessage: formikEditClient.errors.phoneCountryCode ?? null,
    },
    phoneNumber: {
      name: "phoneNumber",
      placeholder: "Phone Number",
      value: formikEditClient.values.phoneNumber,
      onChange: (value: string) => updateFormikValue("phoneNumber", value),
      errorMessage: formikEditClient.errors.phoneNumber ?? null,
    },
    title: {
      name: "title",
      placeholder: "Job Title",
      value: formikEditClient.values.title,
      onChange: (value: string) => updateFormikValue("title", value),
      errorMessage: formikEditClient.errors.title ?? null,
    },
  };

  const errors = Object.keys(formikEditClient.errors).length > 0;

  const handleOnClick = () => {
    if (!errors) {
      formikEditClient.handleSubmit();
      // pass data to onClick to call service to update contact
      onClick(formikEditClient.values);
    }
  };

  return (
    <div className="popup-outer open">
      <div className="popup-bg" onClick={closePopup} />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="banner-title">
            Let&apos;s update this contact&apos;s info
          </div>
          <div className="row box-grid-container">
            <TextInput {...inputData.firstName} />
            <TextInput {...inputData.lastName} />
            <TextInput {...inputData.email} />
            <TextInput {...inputData.phoneCountryCode} />
            <TextInput {...inputData.phoneNumber} />
            <TextInput {...inputData.title} />
          </div>
          <div className="row box-grid-container">
            <div className="col-md-12 col-right-align">
              <ButtonSubmit onClick={handleOnClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContactPopup;
