import React, { FC } from "react";

interface ButtonGreenSearchPropsTypes {
  onClick: () => void;

  disabled?: boolean;
}

const ButtonGreenSearch: FC<ButtonGreenSearchPropsTypes> = ({
  onClick,
  disabled,
}) => {
  return (
    <div className="col-form-item">
      <div className="">
        <div
          className={`${disabled ? "disable" : "green"}-btn corner-btn`}
          onClick={onClick}
        >
          <div className="btn-text">Search</div>
          <div className="btn-icon">
            <div className="icon symmetry-icon-search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonGreenSearch;
