import React, { FC, useEffect, useState } from "react";

interface PropsTypes {
  name: string;
  value: string | number | null;
  availableOptions: string[] | any;
  arrowClick: () => void;
  eraseField: () => void;
  updateForkliftDetails: (_name: string, _value: string) => void;
  handleInputChange: (_key: string, _value: string) => void;
}

const InputSelectOption: FC<PropsTypes> = ({
  name,
  value,
  availableOptions,
  arrowClick,
  eraseField,
  updateForkliftDetails,
  handleInputChange,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [filteredAvailableOptions, setFilteredAvailableOptions] = useState<
    any[]
  >([]);
  const isValueInAvailableOptions = filteredAvailableOptions?.includes(
    value ?? ""
  );
  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };
  const hideOptions = () => {
    setShowOptions(false);
  };
  const filterOptions = (val: string) => {
    if (val !== "") {
      setFilteredAvailableOptions(
        availableOptions.filter((option: string) =>
          option.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
  };
  let prenameText = "";
  if (
    name === "Height" ||
    name === "Length" ||
    name === "Width" ||
    name === "Capacity" ||
    name === "Weight"
  ) {
    prenameText = "Battery ";
  }
  const handleBlur = () => {
    setTimeout(() => {
      setShowOptions(false);
    }, 200);
  };
  const handleFocus = () => {
    arrowClick();
    setShowOptions(true);
  };
  useEffect(() => {
    setFilteredAvailableOptions(availableOptions);
  }, [availableOptions]);
  return (
    <div className="banner-input-col with-dropdown with-right-button forklift-container">
      <div
        className={`col-form-item form-dropdown-list disabled-input ${
          availableOptions?.length > 0 ? "open" : ""
        }  ${value ? "is-populated" : ""}`}
      >
        <input
          type="text"
          placeholder={prenameText + name}
          onChange={(e) => {
            handleInputChange(name, e.target.value);
            if (e.target.value !== "") filterOptions(e.target.value);
          }}
          value={value ?? ""}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className="cursor-pointer"
        />
        {!value && !showOptions && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus
          <div
            className="icon symmetry-icon-arrow-up pointer-events-none "
            role="button"
          />
        )}

        {!value && showOptions && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus
          <div
            className="icon symmetry-icon-arrow-down look-up"
            onClick={() => {
              toggleShowOptions();
            }}
            role="button"
          />
        )}
        {value && (
          <div className="right-input">
            <div
              className="transparent-btn underline-hover-btn"
              onClick={eraseField}
            >
              <div className="btn-icon">
                <div className="icon symmetry-icon-refresh" />
              </div>
              <div className="btn-text">
                Change {prenameText}
                {name}
              </div>
            </div>
          </div>
        )}

        {showOptions &&
          filteredAvailableOptions?.length > 0 &&
          !isValueInAvailableOptions && (
            <div className="form-dropdown-list-container list-forklift-options">
              <ul>
                {filteredAvailableOptions.map(
                  (option: string, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        hideOptions();
                        updateForkliftDetails(name, option);
                      }}
                    >
                      {option}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
      </div>
    </div>
  );
};

export default InputSelectOption;
