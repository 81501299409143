import React, { FC } from "react";

interface ButtonSubmitPropsTypes {
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonSubmit: FC<ButtonSubmitPropsTypes> = ({
  disabled = false,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`green-btn corner-btn ${disabled ? " disable-btn" : ""}`}
    >
      <div className="btn-text">Submit</div>
      <div className="btn-icon">
        <div data-icon="" className="icon" />
      </div>
    </div>
  );
};

export default ButtonSubmit;
