import React, { FC, useState } from "react";
import { ReplacementBatteriesTypes } from "../../types/basicProductsTypes";

export interface ProductReplacesProps {
  replacementsArray: ReplacementBatteriesTypes[];
}

const ProductReplacesInfoButton: FC<ProductReplacesProps> = ({
  replacementsArray,
}) => {
  const [opened, setOpened] = useState<boolean>(false);

  const toggleOpened = () => {
    setOpened((currentValue) => !currentValue);
  };

  return (
    <div className="product-details-top-right-container">
      <div
        className={`product-details-top-right ${opened ? "opened" : ""}`}
        onClick={toggleOpened}
        style={{ cursor: "pointer" }}
      >
        <div className="product-details-top-right-text">Replaced by &nbsp;</div>
        <div className="product-details-top-right-icon">
          <div className="icon symmetry-icon-info" />
        </div>
        <div className="product-details-top-right-hidden-icon">
          <div className="icon symmetry-icon-cancel" />
        </div>
        <div className="product-details-top-right-hidden-text">
          {replacementsArray.map(({ id, title }) => (
            <div key={id}>{title}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductReplacesInfoButton;
