import { storageVariables } from "../lib/storageVariables";
import { initialQuote, quoteItemInitialValues } from "../lib/quoteItemInitial";
import { BatteryTypesNew, ChargerTypes } from "../types/basicProductsTypes";
import { FetchedCompanyTypes } from "../types/companies.d";
import { QuoteItemTypes, QuoteTypes } from "../types/quote";

const updateQuote = (quote: QuoteTypes) => {
  localStorage.setItem(storageVariables.CURRENT_QUOTE, JSON.stringify(quote));
};

export const getCurrentQuote = (): QuoteTypes | null => {
  return localStorage.getItem(storageVariables.CURRENT_QUOTE)
    ? JSON.parse(localStorage.getItem(storageVariables.CURRENT_QUOTE) || "")
    : null;
};

export const createNewQuote = (selectedCompany: FetchedCompanyTypes) => {
  const newQuote: QuoteTypes = initialQuote;

  newQuote.companyId = selectedCompany.companyTypeId.companyTypeId;
  newQuote.country = selectedCompany.countryId.countryName;

  updateQuote(newQuote);
};

export function quoteExists(): boolean {
  const currentQuote: string | null | undefined = JSON.stringify(
    localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
  );
  if (currentQuote) return true;
  return false;
}

export const deleteCurrentQuote = () => {
  localStorage.removeItem(storageVariables.CURRENT_QUOTE);
};

export const calculateQuoteTotalPrice = (quote: QuoteTypes | null): number => {
  let totalPrice = 0;

  const currentQuoteItems = quote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return 0;

  currentQuoteItems.forEach((quoteItem) => {
    if (
      quoteItem.batteryPrice > 0 &&
      quoteItem.batteryQuantity > 0 &&
      quoteItem.batteryId > 0
    )
      totalPrice +=
        quoteItem.batteryPrice * quoteItem.batteryQuantity -
        quoteItem.batteryDiscount;

    if (
      quoteItem.chargerPrice > 0 &&
      quoteItem.chargerQuantity > 0 &&
      quoteItem.chargerId > 0
    )
      totalPrice +=
        quoteItem.chargerPrice * quoteItem.chargerQuantity -
        quoteItem.chargerDiscount;
  });

  return totalPrice;
};

const calcQuoteItemTotalPrice = (quoteItem: QuoteItemTypes): number => {
  let totalPrice = 0;

  if (
    quoteItem.batteryPrice > 0 &&
    quoteItem.batteryQuantity > 0 &&
    quoteItem.batteryId > 0
  )
    totalPrice += quoteItem.batteryPrice * quoteItem.batteryQuantity;

  if (
    quoteItem.chargerPrice > 0 &&
    quoteItem.chargerQuantity > 0 &&
    quoteItem.chargerId > 0
  )
    totalPrice += quoteItem.chargerPrice * quoteItem.chargerQuantity;

  return totalPrice;
};

export const updateQuoteItemPriceByIndex = ({
  index,
  price,
  type,
}: {
  index: number;
  price: number;
  type: string;
}): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return;

  const currentQuoteItems = currentQuote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return;

  const quoteItemToUpdate: QuoteItemTypes | null =
    currentQuote.quoteItems[index];

  if (!quoteItemToUpdate) return;

  if (type === "battery") quoteItemToUpdate.batteryPrice = price;
  if (type === "charger") quoteItemToUpdate.chargerPrice = price;

  quoteItemToUpdate.totalPrice = calcQuoteItemTotalPrice(quoteItemToUpdate);

  currentQuote.quoteItems[index] = quoteItemToUpdate;

  updateQuote(currentQuote);
};

export const addBatteryToQuote = ({
  battery,
  forkliftId = 0,
  forkliftName = "",
}: {
  battery: BatteryTypesNew;
  forkliftId?: number;
  forkliftName?: string;
}): boolean => {
  if (!battery) return false;

  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return false;

  const newQuoteItem: QuoteItemTypes = { ...quoteItemInitialValues };

  newQuoteItem.batteryQuantity = 1;
  newQuoteItem.batteryName = battery?.batteryInfo;
  newQuoteItem.batteryId = battery?.batteryId;
  newQuoteItem.batteryPrice = battery?.price?.price || 0;
  newQuoteItem.batteryTotalPrice = battery?.price?.price || 0;

  if (forkliftId && forkliftId !== 0) {
    newQuoteItem.forkliftId = forkliftId;
    newQuoteItem.forkliftName = forkliftName;
  }

  currentQuote.quoteItems.push(newQuoteItem);

  updateQuote(currentQuote);

  return true;
};

export const addChargerToQuoteByBattery = ({
  charger,
}: {
  charger: ChargerTypes;
}): void => {
  if (!charger) return;
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (
    !currentQuote ||
    !currentQuote?.quoteItems ||
    currentQuote?.quoteItems?.length === 0
  ) {
    return;
  }

  const lastQuoteItem =
    currentQuote.quoteItems[currentQuote.quoteItems.length - 1];

  lastQuoteItem.chargerId = charger.chargerId;
  lastQuoteItem.chargerName = charger.model;
  lastQuoteItem.chargerPrice = charger?.price?.price || 0;
  lastQuoteItem.chargerTotalPrice = charger?.price?.price || 0;
  lastQuoteItem.chargerQuantity = 1;

  updateQuote(currentQuote);
};

export const addChargerToQuote = ({
  charger,
  forkliftId,
}: {
  charger: ChargerTypes;
  forkliftId?: number;
}): void => {
  if (!charger || charger.chargerId === 0) return;
  if (!quoteExists()) return;

  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (
    !currentQuote ||
    !currentQuote?.quoteItems ||
    currentQuote?.quoteItems?.length === 0
  )
    return;

  const lastQuoteItem =
    currentQuote.quoteItems[currentQuote.quoteItems.length - 1];

  lastQuoteItem.chargerId = charger.chargerId;
  lastQuoteItem.chargerName = charger.model;
  lastQuoteItem.chargerPrice = charger?.price?.price || 0;
  lastQuoteItem.chargerTotalPrice = charger?.price?.price || 0;
  lastQuoteItem.chargerQuantity = 1;

  if (forkliftId && forkliftId !== 0) {
    lastQuoteItem.forkliftId = forkliftId;
  }

  currentQuote.quoteItems.push(lastQuoteItem);

  updateQuote(currentQuote);
};

export interface UpdateQuoteItemQuantityTypes {
  index: number;
  quantity: number;
  itemType: "battery" | "charger";
  updateType: "increase" | "decrease";
}

interface UpdateQuoteItemDiscountTypes {
  price: number;
  quantity: number;
  discount: number;
  discountType: "money" | "percentage";
  updateType: "increase" | "decrease";
}

const getTotalPriceAfterDiscount = ({
  price,
  quantity,
  discount,
  discountType,
  updateType,
}: UpdateQuoteItemDiscountTypes): number => {
  let totalPrice = price * quantity;

  if (discount === 0) return totalPrice;

  if (discountType === "money") {
    if (updateType === "increase") {
      totalPrice += discount;
    }
    if (updateType === "decrease") {
      totalPrice -= discount;
    }
  }

  if (discountType === "percentage") {
    if (updateType === "increase") {
      totalPrice += (totalPrice * discount) / 100;
    }
    if (updateType === "decrease") {
      totalPrice -= (totalPrice * discount) / 100;
    }
  }

  return totalPrice;
};

export const updateQuoteItemQuantity = ({
  index,
  quantity,
  itemType,
  updateType,
}: UpdateQuoteItemQuantityTypes): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return;

  const currentQuoteItems = currentQuote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return;

  const quoteItemToUpdate: QuoteItemTypes | null =
    currentQuote.quoteItems[index];

  if (!quoteItemToUpdate) return;

  if (itemType === "battery") {
    if (updateType === "increase") {
      quoteItemToUpdate.batteryQuantity += quantity;
    } else if (
      updateType === "decrease" &&
      quoteItemToUpdate.batteryQuantity - quantity > 0
    ) {
      quoteItemToUpdate.batteryQuantity -= quantity;
    }

    quoteItemToUpdate.batteryTotalPrice = getTotalPriceAfterDiscount({
      price: quoteItemToUpdate.batteryPrice,
      quantity: quoteItemToUpdate.batteryQuantity,
      discount: quoteItemToUpdate.batteryDiscount,
      discountType: quoteItemToUpdate.discountType,
      updateType,
    });
  } else if (itemType === "charger") {
    if (updateType === "increase") {
      quoteItemToUpdate.chargerQuantity += quantity;
    } else if (
      updateType === "decrease" &&
      quoteItemToUpdate.chargerQuantity - quantity > 0
    ) {
      quoteItemToUpdate.chargerQuantity -= quantity;
    }

    quoteItemToUpdate.chargerTotalPrice = getTotalPriceAfterDiscount({
      price: quoteItemToUpdate.chargerPrice,
      quantity: quoteItemToUpdate.chargerQuantity,
      discount: quoteItemToUpdate.chargerDiscount,
      discountType: quoteItemToUpdate.discountType,
      updateType,
    });
  }

  quoteItemToUpdate.totalPrice = calcQuoteItemTotalPrice(quoteItemToUpdate);

  currentQuote.quoteItems[index] = quoteItemToUpdate;

  updateQuote(currentQuote);
};

export const removeQuoteItemByIndex = (index: number): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return;

  const currentQuoteItems = currentQuote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return;

  currentQuote.quoteItems.splice(index, 1);

  updateQuote(currentQuote);
};

export const removeQuoteItemLevelByIndex = ({
  index,
  levelType,
}: {
  index: number;
  levelType: string;
}): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return;

  const currentQuoteItems = currentQuote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return;

  if (levelType === "battery") {
    currentQuote.quoteItems[index].batteryQuantity = 0;
    currentQuote.quoteItems[index].batteryTotalPrice = 0;
    currentQuote.quoteItems[index].batteryPrice = 0;
    currentQuote.quoteItems[index].batteryId = 0;
    currentQuote.quoteItems[index].batteryDiscount = 0;
    currentQuote.quoteItems[index].batteryName = "";
  }
  if (levelType === "charger") {
    currentQuote.quoteItems[index].chargerQuantity = 0;
    currentQuote.quoteItems[index].chargerTotalPrice = 0;
    currentQuote.quoteItems[index].chargerPrice = 0;
    currentQuote.quoteItems[index].chargerId = 0;
    currentQuote.quoteItems[index].chargerDiscount = 0;
    currentQuote.quoteItems[index].chargerName = "";
  }

  currentQuote.quoteItems[index].totalPrice = calcQuoteItemTotalPrice(
    currentQuote.quoteItems[index]
  );

  updateQuote(currentQuote);
};

export const deleteProductFromQuoteItemByIndex = ({
  index,
  productType,
}: {
  index: number;
  productType: string;
}): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();

  if (!currentQuote) return;

  const currentQuoteItems = currentQuote?.quoteItems || [];

  if (currentQuoteItems.length === 0) return;

  const quoteItemToUpdate: QuoteItemTypes | null =
    currentQuote.quoteItems[index];

  if (!quoteItemToUpdate) return;

  if (productType === "battery") {
    quoteItemToUpdate.batteryId = 0;
    quoteItemToUpdate.forkliftId = 0;
    quoteItemToUpdate.forkliftName = "";
    quoteItemToUpdate.batteryName = "";
    quoteItemToUpdate.batteryPrice = 0;
    quoteItemToUpdate.batteryDiscount = 0;
    quoteItemToUpdate.batteryQuantity = 0;
    quoteItemToUpdate.batteryTotalPrice = 0;
  }

  if (productType === "charger") {
    quoteItemToUpdate.chargerId = 0;
    quoteItemToUpdate.chargerName = "";
    quoteItemToUpdate.chargerPrice = 0;
    quoteItemToUpdate.chargerDiscount = 0;
    quoteItemToUpdate.chargerQuantity = 0;
    quoteItemToUpdate.chargerTotalPrice = 0;
  }

  quoteItemToUpdate.totalPrice = calcQuoteItemTotalPrice(quoteItemToUpdate);

  updateQuote(currentQuote);
};

export const updateQuoteItemDiscount = ({
  index,
  discount,
  discountType,
  itemType,
}: {
  index: number;
  discount: number;
  discountType: "money" | "percentage";
  itemType: "battery" | "charger";
}): void => {
  const currentQuote: QuoteTypes | null = getCurrentQuote();
  if (!currentQuote) return;
  const currentQuoteItems = currentQuote?.quoteItems || [];
  if (currentQuoteItems.length === 0) return;

  const quoteItemToUpdate: QuoteItemTypes | null =
    currentQuote.quoteItems[index];

  if (!quoteItemToUpdate) return;

  if (itemType === "battery") {
    // check if discount is more than the total price depending on the discount type
    if (discountType === "money") {
      if (discount <= quoteItemToUpdate.batteryTotalPrice) {
        quoteItemToUpdate.batteryDiscount = discount;
        quoteItemToUpdate.batteryTotalPrice =
          quoteItemToUpdate.batteryPrice * quoteItemToUpdate.batteryQuantity -
          quoteItemToUpdate.batteryDiscount;
      }
    } else if (discountType === "percentage") {
      if (discount <= 100) {
        quoteItemToUpdate.batteryDiscount =
          quoteItemToUpdate.batteryTotalPrice * (discount / 100);
        quoteItemToUpdate.batteryTotalPrice =
          quoteItemToUpdate.batteryPrice * quoteItemToUpdate.batteryQuantity -
          quoteItemToUpdate.batteryDiscount;
      }
    }
  } else if (itemType === "charger") {
    // check if discount is more than the total price depending on the discount type
    if (discountType === "money") {
      if (discount <= quoteItemToUpdate.chargerTotalPrice) {
        quoteItemToUpdate.chargerDiscount = discount;
        quoteItemToUpdate.chargerTotalPrice =
          quoteItemToUpdate.chargerPrice * quoteItemToUpdate.chargerQuantity -
          quoteItemToUpdate.chargerDiscount;
      }
    } else if (discountType === "percentage") {
      if (discount <= 100) {
        quoteItemToUpdate.chargerDiscount =
          quoteItemToUpdate.chargerTotalPrice * (discount / 100);
        quoteItemToUpdate.chargerTotalPrice =
          quoteItemToUpdate.chargerPrice * quoteItemToUpdate.chargerQuantity -
          quoteItemToUpdate.chargerDiscount;
      }
    }
  }

  quoteItemToUpdate.totalPrice = calcQuoteItemTotalPrice(quoteItemToUpdate);

  currentQuote.quoteItems[index] = quoteItemToUpdate;

  updateQuote(currentQuote);
};

export const quoteItemContainsCharger = (quoteItem: QuoteItemTypes) => {
  return quoteItem?.chargerId !== 0;
};

export const getJourneyTypeByQuoteItem = (
  quoteItem: QuoteItemTypes
): string | null => {
  if (quoteItem?.forkliftId === 0) {
    if (quoteItem?.batteryId !== 0) return "Battery flow";
    if (quoteItem?.chargerId !== 0) return "Charger flow";
  }
  if (quoteItem?.batteryId !== 0) return "Forklift flow";

  return null;
};

interface QuoteItemLevel1Props {
  title: string;
  imageUrl?: string | null;
}

const getLevel1Props = (quoteItem: QuoteItemTypes): QuoteItemLevel1Props => {
  const journeyType = getJourneyTypeByQuoteItem(quoteItem);

  if (journeyType === "Battery flow") {
    return {
      title: quoteItem?.batteryName || "",
      imageUrl: null,
    };
  }

  if (journeyType === "Charger flow") {
    return {
      title: quoteItem?.chargerName || "",
      imageUrl: null,
    };
  }

  if (journeyType === "Forklift flow") {
    return {
      title: quoteItem.forkliftName,
    };
  }

  return {
    title: "",
  };
};

interface QuoteItemLevel2BatteryProps {
  batteryName: string;
  batteryPrice: number;
  batteryQuantity: number;
  batteryDiscount: number;
  batteryTotalPrice: number;
  itemType: string;
}
interface QuoteItemLevelChargerProps {
  chargerName: string;
  chargerPrice: number;
  chargerQuantity: number;
  chargerDiscount: number;
  chargerTotalPrice: number;
  itemType?: string;
}

const getLevel2Props = (
  quoteItem: QuoteItemTypes
): QuoteItemLevel2BatteryProps | QuoteItemLevelChargerProps => {
  const journeyType = getJourneyTypeByQuoteItem(quoteItem);

  if (journeyType === "Battery flow" || journeyType === "Forklift flow") {
    return {
      batteryName: quoteItem.batteryName || "",
      batteryPrice: quoteItem.batteryPrice,
      batteryQuantity: quoteItem.batteryQuantity,
      batteryDiscount: quoteItem.batteryDiscount,
      batteryTotalPrice: quoteItem.batteryTotalPrice,
      itemType: "battery",
    };
  }

  if (journeyType === "Charger flow") {
    return {
      chargerName: quoteItem.chargerName || "",
      chargerPrice: quoteItem.chargerPrice,
      chargerQuantity: quoteItem.chargerQuantity,
      chargerDiscount: quoteItem.chargerDiscount,
      chargerTotalPrice: quoteItem.chargerTotalPrice,
      itemType: "charger",
    };
  }

  return {
    batteryName: "",
    batteryPrice: 0,
    batteryQuantity: 0,
    batteryDiscount: 0,
    batteryTotalPrice: 0,
    itemType: "",
  };
};

const getLevel3Props = (
  quoteItem: QuoteItemTypes
): QuoteItemLevelChargerProps | null => {
  const journeyType: string | null = getJourneyTypeByQuoteItem(quoteItem);

  if (
    journeyType === "Charger flow" ||
    (journeyType === "Forklift flow" && quoteItem.chargerId !== 0)
  ) {
    return {
      chargerName: quoteItem.chargerName || "",
      chargerPrice: quoteItem.chargerPrice,
      chargerQuantity: quoteItem.chargerQuantity,
      chargerDiscount: quoteItem.chargerDiscount,
      chargerTotalPrice: quoteItem.chargerTotalPrice,
      itemType: "charger",
    };
  }

  return null;
};

export interface AllLevelPropsTypes {
  level1Props: QuoteItemLevel1Props;
  level2Props: QuoteItemLevel2BatteryProps | QuoteItemLevelChargerProps;
  level3Props: QuoteItemLevelChargerProps | null;
}

export const getAllLevelPropsByQuoteItem = (
  quoteItem: QuoteItemTypes
): AllLevelPropsTypes => {
  return {
    level1Props: getLevel1Props(quoteItem),
    level2Props: getLevel2Props(quoteItem),
    level3Props: getLevel3Props(quoteItem),
  };
};
