import { CoreFieldsTypes } from "../../types/basicProductsTypes";

// TODO:FIX drawingNumber & color & model fields in BatteryTypes
// when api call integrates

export const batteries = [
  {
    batteryId: 2,
    sapId: 12345,
    chemistry: "Lithium-ion",
    batteryType: "High-capacity",
    batteryTypeId: 1,
    length: 60,
    height: 80,
    width: 40,
    nominalSuggestedWeight: 600,
    minimumWeight: 500,
    voltage: 48,
    capacity: 1000,
    din: true,
    manufacturer: "ABC Battery Co.",
    batteryInfo: "This is a lithium-ion battery",
    pbCapacity: 50,
    leadAcidBatteryTrayCode: "LA123",
    minWeight: 450,
    liMaxCapacity: 2000,
    replacementPercentage: 80,
    typeModuleFinalChoice: "Type A",
    moduleWeight: 30,
    sumModuleWeight: 90,
    trayWeight: 10,
    ballastWeight: 20,
    trayThickness: 5,
    status: "Active",
    numberOfShells: 4,
    dateCreated: "2022-01-01",
    dateUpdated: "2022-02-15",
    // drawingNumber: 98765,
    drawingNumber: null,
    // color: "Blue",
    color: null,
    imageUrl:
      "https://www.datocms-assets.com/64711/1654525530-lionforce1.png?auto=format&dpr=1.5&w=240",
    price: { priceId: 6, region: "EU", price: 50 },
    chargers: [
      {
        chargerId: 3,
        batteryType: "High-capacity",
        batteryVoltage: 4.0,
        batteryCapacity: 3.0,
        countryNetwork: 5,
        chargerPhaseType: "Double phase",
        chargerFrequency: "60 Hz",
        chargingCurve: "Constant current",
        chargingTime: 3.0,
        chargerConnectors: 1,
        accessories: "USB cable",
        sapCode: 12345,
        model: "ABC-123",
        mainsVoltage: "240V",
        width: 10,
        height: 15,
        depth: 20,
        weight: 5,
        acInputCurrent: 1,
        mainsPlugTypeSuggestion: "Type B",
        chargingProfile: "Ultra Fast charging",
        imageUrl:
          "https://www.datocms-assets.com/64711/1654525530-lionforce1.png?auto=format&dpr=1.5&w=240",
        price: { priceId: 7, region: "EU", price: 20 },
      },
      {
        chargerId: 4,
        batteryType: "Low-capacity",
        batteryVoltage: 4.0,
        batteryCapacity: 2.0,
        countryNetwork: 6,
        chargerPhaseType: "Single-phase",
        chargerFrequency: "50 Hz",
        chargingCurve: "Constant current",
        chargingTime: 2.0,
        chargerConnectors: 2,
        accessories: "USB cable",
        sapCode: 12345,
        model: "456-ABC",
        mainsVoltage: "240V",
        width: 10,
        height: 17,
        depth: 20,
        weight: 5,
        acInputCurrent: 2,
        mainsPlugTypeSuggestion: "Type C",
        chargingProfile: "Normal Fast charging",
        imageUrl:
          "https://www.datocms-assets.com/64711/1654525530-lionforce1.png?auto=format&dpr=1.5&w=240",
        price: { priceId: 9, region: "EU", price: 80 },
      },
      {
        chargerId: 7,
        batteryType: "Low-capacity",
        batteryVoltage: 4.0,
        batteryCapacity: 2.0,
        countryNetwork: 6,
        chargerPhaseType: "Single-phase",
        chargerFrequency: "50 Hz",
        chargingCurve: "Constant current",
        chargingTime: 2.0,
        chargerConnectors: 2,
        accessories: "USB cable",
        sapCode: 12345,
        model: "456-ABC",
        mainsVoltage: "240V",
        width: 10,
        height: 17,
        depth: 20,
        weight: 5,
        acInputCurrent: 2,
        mainsPlugTypeSuggestion: "Type C",
        chargingProfile: "Normal Fast charging",
        imageUrl:
          "https://www.datocms-assets.com/64711/1654525530-lionforce1.png?auto=format&dpr=1.5&w=240",
        price: { priceId: 9, region: "EU", price: 80 },
      },
      {
        chargerId: 8,
        batteryType: "Low-capacity",
        batteryVoltage: 4.0,
        batteryCapacity: 2.0,
        countryNetwork: 6,
        chargerPhaseType: "Single-phase",
        chargerFrequency: "50 Hz",
        chargingCurve: "Constant current",
        chargingTime: 2.0,
        chargerConnectors: 2,
        accessories: "USB cable",
        sapCode: 12345,
        model: "456-ABC",
        mainsVoltage: "240V",
        width: 10,
        height: 17,
        depth: 20,
        weight: 5,
        acInputCurrent: 2,
        mainsPlugTypeSuggestion: "Type C",
        chargingProfile: "Normal Fast charging",
        imageUrl:
          "https://www.datocms-assets.com/64711/1654525530-lionforce1.png?auto=format&dpr=1.5&w=240",
        price: { priceId: 9, region: "EU", price: 80 },
      },
    ],
    benefits: [
      {
        batteryBenefitId: 3,
        batteryTypeId: 2,
        batteryBenefitTitle: "Battery Benefit Title 3",
        batteryBenefitDescription: "Battery Benefit Description 3",
      },
    ],
    model: "1",
    replacementBatteries: null,
  },
];

export const batteryCoreFieldsArrayMockData: CoreFieldsTypes[] = [
  { key: "batteryId", label: "ID of battery" },
  { key: "sapId", label: "SAP ID" },
  { key: "chemistry", label: "CHEMISTRY" },
  { key: "batteryType", label: "BATTERY TYPE" },
  { key: "batteryTypeId", label: "BATTERY TYPE ID" },
  { key: "length", label: "LENGTH" },
  { key: "height", label: "HEIGHT" },
  { key: "width", label: "WIDTH" },
  { key: "nominalSuggestedWeight", label: "NOMINAL SUGGESTED WEIGHT" },
  { key: "minimumWeight", label: "MINIMUM WEIGHT" },
  { key: "voltage", label: "VOLTAGE" },
  { key: "capacity", label: "CAPACITY" },
  { key: "din", label: "DIN" },
];

export const chargerCoreFieldsArrayMockData: CoreFieldsTypes[] = [
  { key: "chargerId", label: "ID of charger" },
  { key: "batteryType", label: "BATTERY TYPE" },
  { key: "batteryVoltage", label: "BATTERY VOLTAGE" },
  { key: "countryNetwork", label: "COUNTRY NETWORK" },
  { key: "chargerPhaseType", label: "CHARGER PHASE TYPE" },
  { key: "chargerFrequency", label: "CHARGER FREQUENCY" },
  { key: "chargingCurve", label: "CHARGING CURVE" },
  { key: "chargingTime", label: "CHARGING TIME" },
  { key: "chargerConnectors", label: "CHARGER CONNECTORS" },
  { key: "accessories", label: "ACCESSORIES" },
];
