import React, { FC, useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import ChargerSearchHeader from "../../components/Headers/ChargerSearchHeader";
import { CartContext } from "../../context/CartContext";
import {
  ChargersDataType,
  ChargersOptionsDataType,
  inputDataOneType,
  inputDataThreeType,
  inputDataTwoType,
} from "../../types/chargers";
import StepperFindCharger from "../../components/Charger/StepperFindCharger";
import StepOne from "../../components/Charger/StepOne";
import StepTwo from "../../components/Charger/StepTwo";
import StepThree from "../../components/Charger/StepThree";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import { storageVariables } from "../../lib/storageVariables";
import { getCurrency } from "../../helpers/storage";
import { regexEUR, regexUSD } from "../../config/currencyRegex";

const SearchChargerPage: FC = () => {
  const { apiError, setApiError, setSelectedCharger } = useContext(CartContext);
  const [formikErrors, setFormikErrors] = useState<boolean>(false);
  const [noChargersResults, setNoChargersResults] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<1 | 2 | 3>(1);
  const [dataChargers, setDataChargers] = useState<ChargersOptionsDataType>({
    batteryChemistryArray: [],
    batteryTypeArray: [],
    // batteryCapacityArray: [],
    batteryVoltageArray: [],
    countryArray: [],
    mainsVoltageArray: [],
    chargerPhaseTypeArray: [],
    chargerFrequencyArray: [],
    chargingCurveArray: [],
    chargingProfileArray: [],
  });

  const { setChargersFoundData } = useContext(CartContext);

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const { navigateTo } = useNavigation();

  const currency = getCurrency();

  const validRegex = currency === "EUR" ? regexEUR : regexUSD;

  const getDecimalFromString = (value: string): number => {
    if (currency === "EUR") return Number(value.replace(",", "."));
    return Number(value);
  };

  const validationSchemaStepOne = Yup.object().shape({
    chemistry: Yup.string()
      .nullable()
      .oneOf(dataChargers?.batteryChemistryArray, "Select one of the options"),
    type: Yup.string()
      .nullable()
      .oneOf(dataChargers?.batteryTypeArray, "Select one of the options"),
    capacity: Yup.string()
      .nullable()
      .matches(validRegex, "Please enter only digits or valid decimal format."),
    voltage: Yup.string()
      .nullable()
      .oneOf(dataChargers?.batteryVoltageArray, "Select one of the options"),
    country: Yup.string()
      .nullable()
      .oneOf(dataChargers?.countryArray, "Select one of the options"),
    mainsVoltage: Yup.string()
      .nullable()
      .oneOf(dataChargers?.mainsVoltageArray, "Select one of the options"),
    chargercurrent: Yup.string().matches(
      validRegex,
      "Please enter only digits or valid decimal format."
    ),
  });

  const formikOne = useFormik({
    initialValues: {
      chemistry:
        dataChargers?.batteryChemistryArray?.length > 0
          ? dataChargers.batteryChemistryArray[0]
          : null,
      type:
        dataChargers.batteryTypeArray?.length > 0
          ? dataChargers.batteryTypeArray[0]
          : null,
      // capacity: dataChargers.batteryCapacityArray[0],
      capacity: "",
      voltage:
        dataChargers.batteryVoltageArray?.length > 0
          ? dataChargers.batteryVoltageArray[0]
          : null,
      country:
        dataChargers.countryArray?.length > 0
          ? dataChargers.countryArray[0]
          : null,
      mainsVoltage:
        dataChargers.mainsVoltageArray?.length > 0
          ? dataChargers.mainsVoltageArray[0]
          : null,
      chargercurrent: "",
    },
    validationSchema: validationSchemaStepOne,
    onSubmit: () => {
      setFormikErrors(false);
      if (Object.keys(formikOne.errors)?.length > 0) {
        setFormikErrors(true);
      }
      console.log(formikErrors);
    },
  });

  const validationSchemaStepTwo = Yup.object().shape({
    phasetype: Yup.string()
      .nullable()
      .oneOf(dataChargers?.chargerPhaseTypeArray, "Select one of the options"),
    frequency: Yup.string()
      .nullable()
      .oneOf(dataChargers?.chargerFrequencyArray, "Select one of the options"),
  });

  const formikTwo = useFormik({
    initialValues: {
      phasetype:
        dataChargers.chargerPhaseTypeArray?.length > 0
          ? dataChargers.chargerPhaseTypeArray[0]
          : null,
      frequency:
        dataChargers.chargerFrequencyArray?.length > 0
          ? dataChargers.chargerFrequencyArray[0]
          : null,
    },
    validationSchema: validationSchemaStepTwo,

    onSubmit: () => {
      setFormikErrors(false);
      if (Object.keys(formikTwo.errors)?.length > 0) {
        setFormikErrors(true);
      }
    },
  });

  const validationSchemaStepThree = Yup.object().shape({
    curve: Yup.string()
      .nullable()
      .oneOf(dataChargers?.chargingCurveArray, "Select one of the options"),
    timeprofile: Yup.string()
      .nullable()
      .oneOf(dataChargers?.chargingProfileArray, "Select one of the options"),
  });

  const formikThree = useFormik({
    initialValues: {
      curve:
        dataChargers.chargingCurveArray?.length > 0
          ? dataChargers.chargingCurveArray[0]
          : null,
      timeprofile:
        dataChargers.chargingProfileArray?.length > 0
          ? dataChargers.chargingProfileArray[0]
          : null,
    },
    validationSchema: validationSchemaStepThree,

    onSubmit: () => {
      setFormikErrors(false);
      if (Object.keys(formikThree.errors)?.length > 0) {
        setFormikErrors(true);
      }
    },
  });

  const errorFormOne = Object.keys(formikOne.errors)?.length > 0;
  const errorFormTwo = Object.keys(formikTwo.errors)?.length > 0;
  const errorFormThree = Object.keys(formikThree.errors)?.length > 0;
  const errors = errorFormOne || errorFormTwo || errorFormThree;

  function resetFormik() {
    formikOne.setFieldValue("type", "");
    formikOne.setFieldValue("capacity", "");
    formikOne.setFieldValue("voltage", "");
    formikOne.setFieldValue("country", "");
    formikOne.setFieldValue("mainsVoltage", "");
    formikOne.setFieldValue("chargercurrent", "");
    formikTwo.setFieldValue("phasetype", "");
    formikTwo.setFieldValue("frequency", "");
    formikThree.setFieldValue("curve", "");
    formikThree.setFieldValue("timeprofile", "");
  }

  const updateFormikValueOne = (name: string, value: string) => {
    setNoChargersResults(false);
    if (
      name === "chemistry" &&
      formikOne.values.chemistry !== null &&
      formikOne.values.chemistry !== value
    ) {
      resetFormik();
    }
    if (
      name === "country" &&
      dataChargers.countryArray.indexOf(formikOne.values.country || "") !== -1
    ) {
      formikOne.setFieldValue("mainsVoltage", "");
    }
    setFormikErrors(false);
    formikOne.setFieldValue(name, value);
    if (errors) {
      setFormikErrors(true);
    }
  };

  const updateFormikValueTwo = (name: string, value: string) => {
    setFormikErrors(false);
    formikTwo.setFieldValue(name, value);
    if (errors) {
      setFormikErrors(true);
    }
  };

  const updateFormikValueThree = (name: string, value: string) => {
    setFormikErrors(false);
    formikThree.setFieldValue(name, value);
    if (errors) {
      setFormikErrors(true);
    }
  };

  const inputDataOne: inputDataOneType = {
    chemistry: {
      name: "chemistry",
      placeholder: "Battery Chemistry",
      value: formikOne.values.chemistry,
      onChange: (value: string) => updateFormikValueOne("chemistry", value),
      errorMessage: formikOne.errors.chemistry ?? null,
    },
    type: {
      name: "type",
      placeholder: "Battery Type",
      value: formikOne.values.type,
      onChange: (value: string) => updateFormikValueOne("type", value),
      errorMessage: formikOne.errors.type ?? null,
    },
    capacity: {
      name: "capacity",
      placeholder: "Battery Capacity",
      value: formikOne.values.capacity,
      onChange: (value: string) => updateFormikValueOne("capacity", value),
      errorMessage: formikOne.errors.capacity ?? null,
    },
    voltage: {
      name: "voltage",
      placeholder: "Voltage",
      value: formikOne.values.voltage,
      onChange: (value: string) => updateFormikValueOne("voltage", value),
      errorMessage: formikOne.errors.voltage ?? null,
    },
    country: {
      name: "country",
      placeholder: "Country",
      value: formikOne.values.country,
      onChange: (value: string) => updateFormikValueOne("country", value),
      errorMessage: formikOne.errors.country ?? null,
    },
    mainsVoltage: {
      name: "mainsVoltage",
      placeholder: "Mains Voltage",
      value: formikOne.values.mainsVoltage,
      onChange: (value: string) => updateFormikValueOne("mainsVoltage", value),
      errorMessage: formikOne.errors.mainsVoltage ?? null,
    },
    chargercurrent: {
      name: "chargercurrent",
      placeholder: "Charger Current",
      value: formikOne.values.chargercurrent,
      onChange: (value: string) =>
        updateFormikValueOne("chargercurrent", value),
      errorMessage: formikOne.errors.chargercurrent ?? null,
    },
  };

  const inputDataTwo: inputDataTwoType = {
    phasetype: {
      name: "phasetype",
      placeholder: "Charger Phase Type",
      value: formikTwo.values.phasetype,
      onChange: (value: string) => updateFormikValueTwo("phasetype", value),
      errorMessage: formikTwo.errors.phasetype ?? null,
    },
    frequency: {
      name: "frequency",
      placeholder: "Charger Frequency",
      value: formikTwo.values.frequency,
      onChange: (value: string) => updateFormikValueTwo("frequency", value),
      errorMessage: formikTwo.errors.frequency ?? null,
    },
  };

  const inputDataThree: inputDataThreeType = {
    curve: {
      name: "curve",
      placeholder: "Charging Curve",
      value: formikThree.values.curve,
      onChange: (value: string) => updateFormikValueThree("curve", value),
      errorMessage: formikThree.errors.curve ?? null,
    },
    timeprofile: {
      name: "timeprofile",
      placeholder: "Charging Time/Profile",
      value: formikThree.values.timeprofile,
      onChange: (value: string) => updateFormikValueThree("timeprofile", value),
      errorMessage: formikThree.errors.timeprofile ?? null,
    },
  };

  const handleSubmit = async () => {
    if (errors) {
      setFormikErrors(true);
    } else {
      const chargersData = {
        batteryChemistry:
          formikOne.values?.chemistry !== ""
            ? formikOne.values?.chemistry
            : null,
        batteryType:
          formikOne.values?.type !== "" ? formikOne.values?.type : null,
        batteryCapacity:
          formikOne.values.capacity !== ""
            ? getDecimalFromString(formikOne.values.capacity)
            : null,
        batteryVoltage: formikOne.values?.voltage
          ? getDecimalFromString(formikOne.values?.voltage)
          : null,
        country:
          formikOne.values?.country !== "" ? formikOne.values?.country : null,
        mainsVoltage:
          formikOne.values?.mainsVoltage !== ""
            ? formikOne.values?.mainsVoltage
            : null,
        nominalChargeCurrent:
          formikOne.values?.chargercurrent !== ""
            ? getDecimalFromString(formikOne.values?.chargercurrent)
            : null,
        chargerPhaseType:
          formikTwo.values?.phasetype !== ""
            ? formikTwo.values?.phasetype
            : null,
        chargerFrequency:
          formikTwo.values?.frequency !== ""
            ? formikTwo.values?.frequency
            : null,
        chargingCurve:
          formikThree.values?.curve !== "" ? formikThree.values?.curve : null,
        chargingProfile:
          formikThree.values?.timeprofile !== ""
            ? formikThree.values?.timeprofile
            : null,
        showChargers: true,
        orderBy: "BATTERY_CHEMISTRY",
        orderDirection: "ASC",
        page: 0,
        pageSize: 200,
      };

      handleAuthApiCall({
        method: "post",
        data: chargersData,
        url: `/v1/chargers/search`,
        headers: {
          "Content-Type": "application/json",
        },
        isSearchRequest: true,
      });
    }
  };

  const changeStep = (element: 1 | 2 | 3) => {
    const newStep = element;

    setActiveStep(newStep);
  };

  const fetchChargersData = async () => {
    if (apiError) setApiError(null);

    const chargersData: ChargersDataType = {
      batteryChemistry:
        formikOne.values?.chemistry !== "" ? formikOne.values?.chemistry : null,
      batteryType:
        formikOne.values?.type !== "" ? formikOne.values?.type : null,
      batteryCapacity:
        formikOne.values.capacity !== ""
          ? getDecimalFromString(formikOne.values.capacity)
          : null,
      batteryVoltage: formikOne.values?.voltage
        ? getDecimalFromString(formikOne.values?.voltage)
        : null,
      country:
        formikOne.values?.country !== "" ? formikOne.values?.country : null,
      mainsVoltage:
        formikOne.values?.mainsVoltage !== ""
          ? formikOne.values?.mainsVoltage
          : null,

      nominalChargeCurrent:
        formikOne.values?.chargercurrent !== ""
          ? getDecimalFromString(formikOne.values?.chargercurrent)
          : null,
      chargerPhaseType:
        formikTwo.values?.phasetype !== "" ? formikTwo.values?.phasetype : null,
      chargerFrequency:
        formikTwo.values?.frequency !== "" ? formikTwo.values?.frequency : null,
      chargingCurve:
        formikThree.values?.curve !== "" ? formikThree.values?.curve : null,
      chargingProfile:
        formikThree.values?.timeprofile !== ""
          ? formikThree.values?.timeprofile
          : null,
      showChargers: false,
      orderBy: "BATTERY_CHEMISTRY",
      orderDirection: "ASC",
      page: 0,
      pageSize: 200,
    };

    handleAuthApiCall({
      method: "post",
      data: chargersData,
      url: "/v1/chargers/search",
      headers: {
        "Content-Type": "application/json",
      },
      isSearchRequest: true,
    });
  };

  const convertNumberArrayToStringArray = (array: number[]) => {
    const stringArray: string[] = [];

    if (!array || array.length === 0) return stringArray;

    array.forEach((element) => {
      stringArray.push(element.toString());
    });

    return stringArray;
  };

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (
      apiData?.chargerCoreFieldsArray &&
      apiData?.chargerCoreFieldsArray?.length > 0
    ) {
      localStorage.setItem(
        storageVariables.CORE_FIELDS_ARRAY_CHARGER,
        JSON.stringify(apiData.chargerCoreFieldsArray)
      );
    }

    if (apiData?.chargers && apiData.chargers?.length > 0) {
      setChargersFoundData(apiData.chargers);
      setSelectedCharger(apiData.chargers[0]);
      navigateTo(availableRoutes.CHARGERS_FOUND);
    } else if (apiData?.chargers && apiData.chargers?.length === 0) {
      setNoChargersResults(true);
    } else {
      setDataChargers(
        (prev) =>
          ({
            ...prev,
            batteryChemistryArray: apiData?.batteryChemistryArray.length
              ? apiData?.batteryChemistryArray
              : dataChargers.batteryChemistryArray,
            batteryTypeArray: apiData?.batteryTypeArray.length
              ? apiData?.batteryTypeArray
              : dataChargers.batteryTypeArray,
            // batteryCapacityArray: apiData?.batteryCapacityArray ?? [],
            batteryVoltageArray: convertNumberArrayToStringArray(
              apiData?.batteryVoltageArray.length
                ? apiData?.batteryVoltageArray
                : dataChargers.batteryVoltageArray
            ),
            countryArray: apiData?.countryArray ?? [],
            mainsVoltageArray: apiData?.mainsVoltageArray ?? [],
            chargerPhaseTypeArray: apiData?.chargerPhaseTypeArray ?? [],
            chargerFrequencyArray: apiData?.chargerFrequencyArray ?? [],
            chargingCurveArray: apiData?.chargingCurveArray ?? [],
            chargingProfileArray: apiData?.chargingProfileArray ?? [],
          } as ChargersOptionsDataType)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  useEffect(() => {
    fetchChargersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formikOne.initialValues !== formikOne.values) {
      fetchChargersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikOne.values, formikTwo.values, formikThree.values]);

  return (
    <MainPageWrapper title="Charger Search" testid="search-charger-page">
      <TopBanner goBack startFromBeginning />
      <ChargerSearchHeader />
      {/* {errors && formikErrors && (
        <div className="header-form-error">
          Please take another look something is not quite right
        </div>
      )} */}
      <StepperFindCharger
        valueChemistry={formikOne.values?.chemistry}
        activeNumberStep={activeStep}
      />
      <div className="row box-grid-container steps-container">
        {activeStep === 1 && (
          <StepOne
            isLithiumText={formikOne.values?.chemistry}
            dataChargersStep={dataChargers}
            inputDataStep={inputDataOne}
            activeStep={activeStep}
            changeStep={changeStep}
            handleSubmit={handleSubmit}
            errorsExist={errorFormOne}
          />
        )}
        {activeStep === 2 && (
          <StepTwo
            isLithiumText={formikOne.values?.chemistry}
            dataChargersStep={dataChargers}
            inputDataStep={inputDataTwo}
            activeStep={activeStep}
            changeStep={changeStep}
            errorsExist={errorFormTwo}
            dependencyCountryFromOne={formikOne.values?.country}
          />
        )}
        {activeStep === 3 && (
          <StepThree
            isLithiumText={formikOne.values?.chemistry}
            dataChargersStep={dataChargers}
            inputDataStep={inputDataThree}
            activeStep={activeStep}
            changeStep={changeStep}
            handleSubmit={handleSubmit}
            errorsExist={errorFormThree}
          />
        )}
      </div>
      {noChargersResults && (
        <div className="no-results-container">No chargers found</div>
      )}
    </MainPageWrapper>
  );
};

export default SearchChargerPage;
