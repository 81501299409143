import { storageVariables } from "../lib/storageVariables";
import { CurrentQuoteTypes } from "../types/currentQuoteTree";
import { FetchedForkliftFleetTypesNew } from "../types/forkliftFleetTypes";

// set forkliftId from localStorage
export const setForkliftIdToLocalStorage = (forkliftId: string) => {
  localStorage.setItem("forkliftId", forkliftId);
};

// return forkliftId from localStorage
export const getForkliftIdFromLocalStorage = () => {
  return localStorage.getItem("forkliftId");
};

export function fullNavigateToUrl(url: string) {
  window.location.href = url;
}

export const getCurrentDate = (): string => {
  const localOptions = Intl.DateTimeFormat().resolvedOptions();
  // const localDate = new Date().toLocaleString(localOptions.locale, {
  //   timeZone: localOptions.timeZone,
  // });

  const standardLocale = "en-DE";
  const localDate = new Date().toLocaleString(standardLocale, {
    timeZone: localOptions.timeZone,
  });

  return localDate;
};

export const getCompanyName = (): string => {
  const selectedCompanyString = localStorage.getItem(
    storageVariables.SELECTED_COMPANY
  );

  const selectedCompany = selectedCompanyString
    ? JSON.parse(selectedCompanyString)
    : null;

  return selectedCompany?.companyName || "";
};

export const getForkliftFullName = (
  forklift: FetchedForkliftFleetTypesNew
): string => {
  return `${forklift.manufacturer}, ${forklift.model}`;
};
// export const getForkliftFullNameNew = (
//   forklift: FetchedForkliftFleetTypesNew
// ): string => {
//   return `${forklift.manufacturer}, ${forklift.model}`;
// };
interface InitialValuesTypes {
  [key: string]: any;
}

export const checkIfObjectsHasEqualValues = (
  obj1: InitialValuesTypes,
  obj2: InitialValuesTypes
): boolean => {
  const obj1Values = Object.values(obj1);
  const obj2Values = Object.values(obj2);

  for (let i = 0; i < obj1Values.length; i += 1) {
    if (obj1Values[i] !== obj2Values[i]) return false;
  }

  return true;
};

export const checkIfDiscountIsGreaterThanPrice = ({
  totalPrice,
}: {
  totalPrice: number;
}): string | null => {
  if (totalPrice < 0) return "Final price is less than 0";

  return null;
};

export const currentQuoteHasNegativeFinalPrices = (
  currentQuote: CurrentQuoteTypes
): boolean => {
  let result = false;
  // eslint-disable-next-line consistent-return
  currentQuote.quoteItems.forEach((quoteItem) => {
    if (quoteItem.itemType === "forklift") {
      if (quoteItem?.battery && quoteItem?.battery?.totalPrice < 0)
        result = true;
      if (
        quoteItem?.battery?.charger &&
        quoteItem?.battery?.charger?.totalPrice < 0
      )
        result = true;
    }
    if (quoteItem.itemType === "battery") {
      if (quoteItem?.totalPrice < 0) result = true;
      if (quoteItem?.charger && quoteItem?.charger?.totalPrice < 0)
        result = true;
    }
    if (quoteItem.itemType === "charger") {
      if (quoteItem?.totalPrice < 0) result = true;
    }
  });

  return result;
};
