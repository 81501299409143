import React, { FC } from "react";

interface ButtonAddPropsTypes {
  title: string;
  onClick?: () => void;
}

const ButtonAdd: FC<ButtonAddPropsTypes> = ({ onClick, title }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div
      className="white-btn underline-hover-btn"
      onClick={handleClick}
      data-html2canvas-ignore
    >
      <div className="btn-icon">
        <div className="icon symmetry-icon-add" />
      </div>
      <div className="btn-text">{title}</div>
    </div>
  );
};

export default ButtonAdd;
