import React, { FC, useEffect, useState } from "react";

import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import InputSelectOption from "../../components/Inputs/InputSelectOption";
import ButtonNext from "../../components/Buttons/ButtonNext";
import { storageVariables } from "../../lib/storageVariables";
import { FetchedCompanyTypes } from "../../types/companies";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import useAuthApiCall from "../../hooks/useAuthApiCall";

interface ForkliftDetails {
  manufacturer: string | null;
  model: string | null;
}

interface AvailableOptions {
  manufacturer: string[];
  model: string[];
}

const AddNewForklictToExistingFleet: FC = () => {
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const { fullNavigateToUrl } = useNavigation();

  const initialForkliftDetails: ForkliftDetails = {
    manufacturer: null,
    model: null,
  };
  const initialAvailableOptions: AvailableOptions = {
    manufacturer: [],
    model: [],
  };

  const [availableOptions, setAvailableOptions] = useState<AvailableOptions>(
    initialAvailableOptions
  );

  const [selectedCompany, setSelectedCompany] =
    useState<FetchedCompanyTypes | null>(null);

  const [forkliftObj, setForkliftObj] = useState<ForkliftDetails>(
    initialForkliftDetails
  );

  const [apiReason, setApiReason] = useState<string>("");
  const [flagForService, setFlagForService] = useState<boolean>(true);
  const [name, setName] = useState<string>("manufacturers");
  const [notesValue, setNotesValue] = useState<string>("");

  const updateForkliftDetails = (key: string, value: string) => {
    setFlagForService(true);
    setForkliftObj((prev) => ({ ...prev, [key.toLowerCase()]: value }));
  };

  const eraseField = (key: string) => {
    setFlagForService(true);
    if (key === "manufacturer") {
      setForkliftObj(initialForkliftDetails);
      setAvailableOptions(initialAvailableOptions);
    } else if (key === "model") {
      setForkliftObj((prev) => ({
        ...prev,
        model: null,
      }));
      setAvailableOptions((prev) => ({
        ...prev,
        model: [],
      }));
    }
  };

  const handleArrowClick = (tempName: string) => {
    setApiReason("fetchData");

    setName(tempName);

    const dataForklift = {
      manufacturer: forkliftObj.manufacturer,
      model: forkliftObj.model,
    };
    if (flagForService) {
      handleAuthApiCall({
        method: "post",
        data: dataForklift,
        url: "/v1/forklifts/search",
        headers: {
          "Content-Type": "application/json",
        },
        isSearchRequest: true,
      });
    }
  };

  const handlePopulatedClick = () => {
    const { manufacturers, models } = apiData;

    if (name === "manufacturers") {
      setAvailableOptions({
        ...initialAvailableOptions,
        manufacturer: manufacturers || [],
      });
    } else if (name === "models") {
      setAvailableOptions({
        ...availableOptions,
        model: models || [],
      });
    }
  };

  // TODO: add logic to fetch data from API and update available options
  const handleInputChange = async (key: string, value: any) => {
    let flag = true;
    if (key.toLocaleLowerCase() === "manufacturer") {
      flag = availableOptions.manufacturer.includes(value);
    } else if (key.toLocaleLowerCase() === "model") {
      flag = availableOptions.model.includes(value);
    }

    if (value !== "") setFlagForService(flag);
    setForkliftObj((prev) => ({ ...prev, [key.toLocaleLowerCase()]: value }));
  };

  const submitNewForkliftToFleet = async () => {
    if (!forkliftObj?.model) return;

    // if (!selectedCompany || !selectedCompany?.companyId) return;

    setApiReason("submitData");
    const forkliftDataToAdd = {
      manufacturer: forkliftObj?.manufacturer,
      model: forkliftObj?.model,
      notes: notesValue,
    };
    handleAuthApiCall({
      method: "post",
      data: forkliftDataToAdd,
      url: `/v2/fleets/companies/${selectedCompany?.companyId}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  useEffect(() => {
    const selectedCompanyStr: string | null = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );

    if (selectedCompanyStr) {
      setSelectedCompany(JSON.parse(selectedCompanyStr));
    }
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiReason === "fetchData") {
      handlePopulatedClick();
    } else if (apiReason === "submitData") {
      localStorage.setItem(storageVariables.NEW_FORKLIFT_ADDED, "true");

      fullNavigateToUrl(availableRoutes.START_JOURNEY_BY_FORKLIFT);
    }
    setApiReason("");
    // if (apiData !== null && apiData.forkliftId && forkliftObj.voltage) {
    if (apiData !== null && apiData.forkliftId) {
      setForkliftObj((prev) => ({
        ...prev,
        forkliftId: apiData.forkliftId,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  return (
    <MainPageWrapper
      title="Add new Forklift to existing Fleet"
      testid="home-page"
    >
      <TopBanner goBack startFromBeginning />
      <div className="row box-grid-container row-with-notes">
        <div className="col-md-6">
          <div className="banner-left-outer">
            <div className="banner-left-inner">
              <div className="banner-title">
                Add a new forklift to {selectedCompany?.companyName ?? ""}{" "}
                existing Fleet
              </div>

              <InputSelectOption
                name="Manufacturer"
                value={forkliftObj.manufacturer}
                availableOptions={availableOptions.manufacturer}
                arrowClick={() => handleArrowClick("manufacturers")}
                handleInputChange={handleInputChange}
                eraseField={() => eraseField("manufacturer")}
                updateForkliftDetails={updateForkliftDetails}
              />

              {forkliftObj.manufacturer &&
                availableOptions.manufacturer.includes(
                  forkliftObj.manufacturer
                ) && (
                  <InputSelectOption
                    name="Model"
                    value={forkliftObj.model}
                    availableOptions={availableOptions.model}
                    arrowClick={() => handleArrowClick("models")}
                    handleInputChange={handleInputChange}
                    eraseField={() => eraseField("model")}
                    updateForkliftDetails={updateForkliftDetails}
                  />
                )}

              {forkliftObj.model && (
                <div className="row box-grid-container">
                  <div className="col-md-12 col-right-align">
                    <ButtonNext onClick={submitNewForkliftToFleet} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 notes-outer">
          <div className="notes-inner">
            <div className="d-flex box-grid-container justify-content-between ">
              <span className="textarea-notes">NOTES</span>
            </div>
            <textarea
              id="notes"
              name="notes"
              className="textarea-notes"
              placeholder="Notes for forklift"
              onChange={(e) => setNotesValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    </MainPageWrapper>
  );
};

export default AddNewForklictToExistingFleet;
