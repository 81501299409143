import React, { FC } from "react";
import { useNavigation } from "../../../hooks/useNavigation";
import { availableRoutes } from "../../../lib/routes";
import ButtonSkipClientFilter from "../../Buttons/ButtonSkipClientFilter";
import StartFromBeginButton from "../../Buttons/StartFromBeginButton";

interface Props {
  clickGoBack?: () => void;
  startFromBeginning?: boolean;
  skipFilter?: boolean;
}

const TopBannerStepperBack: FC<Props> = ({
  clickGoBack,
  startFromBeginning = false,
  skipFilter = false,
}) => {
  const { navigateTo } = useNavigation();

  return (
    <div className="topbanner-wrapper">
      <div className="row box-grid-container topbanner-container">
        <div className="col-md-6 col-left-align">
          {clickGoBack && (
            <div
              className="white-btn underline-hover-btn"
              onClick={clickGoBack}
            >
              <div className="btn-icon">
                <div className="icon symmetry-icon-arrow-left" />
              </div>
              <div className="btn-text">Back</div>
            </div>
          )}
        </div>
        <div className="col-md-6 col-right-align">
          {startFromBeginning && <StartFromBeginButton />}
          {skipFilter && (
            <ButtonSkipClientFilter
              onClick={() => navigateTo(availableRoutes.CHOOSE_JOURNEY)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBannerStepperBack;
