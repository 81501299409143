/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useContext } from "react";
import ButtonRemoveAll from "../../../../Buttons/ButtonRemoveAll";
import { useQuoteUpdate } from "../../../../../helpers/storage";
import { confirmDeleteQuoteItem } from "../../../../../settings/confirmAlertMessages";
import ImageWithFallback from "../../../../Media/ImageWithFallback";
import { ModalContext } from "../../../../../context/ModalContext";

import ConfirmPopupOpen from "../../../../Popups/ConfirmPopupOpen";

interface QuoteItemHeaderPropsTypes {
  title: string;
  index: number;
  imageUrl?: string;
  toggleShowQuoteItems: () => void;
}

const QuoteItemHeader: FC<QuoteItemHeaderPropsTypes> = ({
  title,
  index,
  imageUrl,

  toggleShowQuoteItems,
}) => {
  const { showConfirm, openConfirm, confirmMsg } = useContext(ModalContext);
  const { deleteQuoteBlockByIndex } = useQuoteUpdate();

  const [displayConfirm, setDisplayConfirm] = React.useState<boolean>(false);

  const handleRemoveAll = () => {
    setDisplayConfirm(true);
    openConfirm(confirmDeleteQuoteItem);
  };

  const completeDelete = () => {
    deleteQuoteBlockByIndex(index);
    setDisplayConfirm(false);
  };

  const handleCancelConfirm = () => {
    setDisplayConfirm(false);
  };

  return (
    <div>
      <div className="cart-item-row cart-item-level-1">
        <div className="cart-item-row-inner row">
          <div
            className="cart-item-title col-sm-11"
            role="button"
            onClick={toggleShowQuoteItems}
          >
            <div className="icon symmetry-icon-arrow-up" />
            <div className="cart-item-image">
              {imageUrl && (
                <ImageWithFallback type="forklift" imageUrl={imageUrl} />
              )}
            </div>
            <div className="cart-item-text">{title}</div>
          </div>
          <ButtonRemoveAll onClick={handleRemoveAll} />
        </div>
      </div>

      {/* {showConfirm && confirmMsg && confirmMsg === confirmDeleteQuoteItem && (
        <ConfirmPopup
          title={confirmMsg}
          onConfirm={() => deleteQuoteBlockByIndex(index)}
        />
      )} */}

      {showConfirm &&
        displayConfirm &&
        confirmMsg &&
        confirmMsg === confirmDeleteQuoteItem && (
          <ConfirmPopupOpen
            title={confirmMsg}
            onConfirm={completeDelete}
            open={displayConfirm}
            onCancel={handleCancelConfirm}
          />
        )}
    </div>
  );
};

export default QuoteItemHeader;
