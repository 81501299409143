import React, { FC } from "react";

interface StepsPropsTypes {
  valueChemistry: string | null;
  activeNumberStep: number;
}
const StepperFindCharger: FC<StepsPropsTypes> = ({
  valueChemistry,
  activeNumberStep,
}) => {
  const steps = [
    {
      step: 1,
      stepTitle: "Standard Information",
    },
    {
      step: 2,
      stepTitle: "Model Variation",
    },
    {
      step: 3,
      stepTitle: "Unique Model",
    },
  ];
  return (
    <div className="step-outer">
      <div className="steps-inner stepper">
        {valueChemistry !== "Lithium-ion" &&
          steps.map(({ step, stepTitle }) => {
            return (
              <div
                className={`step my-2   ${
                  activeNumberStep === step && "active"
                } ${activeNumberStep > step && "done"}`}
                key={step}
              >
                <div className="step-number">{step}</div>
                <div className="step-text">{stepTitle}</div>
              </div>
            );
          })}
        {valueChemistry === "Lithium-ion" && (
          <div className="step">
            <div className="step-text">Standard Information</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepperFindCharger;
