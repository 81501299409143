import React, { FC } from "react";

export interface TextInputTypes {
  value?: string;
  name: string;
  placeholder?: string;
  errorMessage: string | null;
  onChange: (_value: string) => void;
}

const TextInput: FC<TextInputTypes> = ({
  value,
  name,
  placeholder,
  errorMessage,
  onChange,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <div className="col-md-6">
      <div className="popup-input">
        <input
          name={name}
          value={value}
          type="text"
          onChange={handleInputChange}
          placeholder={placeholder ?? ""}
        />
      </div>
      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </div>
  );
};

export default TextInput;
