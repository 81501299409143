/* eslint-disable no-unused-vars */
import React, { FC } from "react";

interface AllCompatiblesButtonPropsTypes {
  title: string;
  onClick: (title: string) => void;
}

const AllCompatiblesButton: FC<AllCompatiblesButtonPropsTypes> = ({
  title,
  onClick,
}) => {
  return (
    <div
      className="white-btn underline-hover-btn"
      data-testid={`all-compatibles-button-${title}`}
      onClick={() => onClick(title)}
    >
      <div className="btn-icon">
        <div className="icon symmetry-icon-grid" />
      </div>
      <div className="btn-text">{title}</div>
    </div>
  );
};

export default AllCompatiblesButton;
