import React, { FC, useState } from "react";
import QuoteItemHeader from "./QuoteItemLevels/QuoteItemHeader";

import {
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../../../../types/currentQuoteTree";
import { fallbackTruckImg } from "../../../../assets/mockData/fallbackImages";
import QuoteItemBattery from "./QuoteItemLevels/QuoteItemBattery";
import { useQuoteUpdate } from "../../../../helpers/storage";
import QuoteItemCharger from "./QuoteItemLevels/QuoteItemCharger";

export interface ChangeItemQuantityTypes {
  itemType: "battery" | "charger";
  updateType: "increase" | "decrease";
}

interface CurrentQuoteItemBlockPropsTypes {
  index: number;
  quoteItem:
    | QuoteItemForkliftTypes
    | QuoteItemBatteryTypes
    | QuoteItemChargerTypes;
}

const CurrentQuoteItemBlock: FC<CurrentQuoteItemBlockPropsTypes> = ({
  index,
  quoteItem,
}) => {
  const { toggleManualAddedPriceForBattery, toggleManualAddedPriceForCharger } =
    useQuoteUpdate();

  let initiallyEnabled = false;
  if (quoteItem.itemType === "forklift") {
    initiallyEnabled = quoteItem.battery?.manualAddedPrice !== null;
  } else if (quoteItem.itemType === "battery") {
    initiallyEnabled = quoteItem.manualAddedPrice !== null;
  } else if (quoteItem.itemType === "charger") {
    initiallyEnabled = quoteItem.manualAddedPrice !== null;
  }

  const [finalPriceEnabled, setFinalPriceEnabled] =
    useState<boolean>(initiallyEnabled);
  const [showQuoteItems, setShowQuoteItems] = React.useState(true);

  const toggleShowQuoteItems = () => {
    setShowQuoteItems(!showQuoteItems);
  };

  const toggleFinalPriceEnabled = () => {
    setFinalPriceEnabled(!finalPriceEnabled);
    toggleManualAddedPriceForBattery(index);
  };

  const toggleChargerFinalPriceEnabled = () => {
    setFinalPriceEnabled(!finalPriceEnabled);
    toggleManualAddedPriceForCharger(index);
  };

  if (quoteItem.itemType === "forklift" || quoteItem.itemType === "battery") {
    const battery =
      quoteItem.itemType === "forklift" ? quoteItem?.battery : quoteItem;

    if (!battery) return <></>;

    const title =
      quoteItem.itemType === "forklift"
        ? `${quoteItem.manufacturer}, ${quoteItem.model}`
        : "Battery Flow";
    const imageUrl =
      quoteItem.itemType === "forklift" ? fallbackTruckImg : undefined;

    return (
      <div className="cart-item-outer">
        <div className="cart-item-inner">
          <QuoteItemHeader
            index={index}
            title={title}
            imageUrl={imageUrl}
            toggleShowQuoteItems={toggleShowQuoteItems}
          />
          {showQuoteItems && battery && (
            <QuoteItemBattery
              index={index}
              quoteItem={battery}
              finalPriceEnabled={finalPriceEnabled}
              toggleFinalPriceEnabled={toggleFinalPriceEnabled}
            />
          )}
        </div>
      </div>
    );
  }

  if (quoteItem.itemType === "charger") {
    return (
      <div className="cart-item-outer">
        <div className="cart-item-inner">
          <QuoteItemHeader
            index={index}
            title="Charger Flow"
            toggleShowQuoteItems={toggleShowQuoteItems}
          />
          {showQuoteItems && (
            <QuoteItemCharger
              index={index}
              quoteItem={quoteItem}
              finalPriceEnabled={finalPriceEnabled}
              toggleFinalPriceEnabled={toggleChargerFinalPriceEnabled}
            />
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default CurrentQuoteItemBlock;
