import React, { FC } from "react";

interface ButtonNextPropsTypes {
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonClose: FC<ButtonNextPropsTypes> = ({
  disabled = false,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`${disabled ? "disable" : "yellow"}-btn corner-btn`}
    >
      <div className="btn-text">Close</div>
      <div className="btn-icon">
        <div className="icon symmetry-icon-cancel" />
      </div>
    </div>
  );
};

export default ButtonClose;
