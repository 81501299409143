import { getCurrency } from "../helpers/storage";

export const useFormatDate = () => {
  const currency = getCurrency();

  const formatDateByCurrency = (date: string): string => {
    if (currency === "USA") {
      const [day, month, yearWithTime] = date.split("/");
      return `${month}/${day}/${yearWithTime}`;
    }

    return date;
  };

  return { formatDateByCurrency };
};
