import React, { FC } from "react";

interface ClientContactItemTypesProps {
  firstName: string;
  email: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  title: string;
  onClick: () => void;
  hasTooltip?: React.ReactNode;
}

const ClientContactItem: FC<ClientContactItemTypesProps> = ({
  firstName,
  email,
  lastName,
  phoneCountryCode,
  phoneNumber,
  title,
  onClick,
  hasTooltip,
}) => {
  const clientFullName = firstName.concat(" ", lastName);
  const phoneFullNumber = phoneCountryCode.concat(" ", phoneNumber);
  return (
    <div className="clients-table-item row box-grid-container">
      <div className="clients-table-col col-md-2 clients-name">
        {clientFullName}
      </div>

      <div className="clients-table-col col-md-2 clients-role">{title}</div>

      <div className="clients-table-col col-md-4 clients-email">{email}</div>

      <div className="clients-table-col col-md-3 clients-tel">
        {phoneFullNumber}
      </div>

      <div
        onClick={onClick}
        className={`clients-table-col col-md-1 clients-icon tooltip-delete ${
          hasTooltip !== undefined ? "tooltip_outer" : ""
        }`}
      >
        <div className="icon symmetry-icon-redo corner-btn-left" />
        {hasTooltip !== undefined && hasTooltip}
      </div>
    </div>
  );
};

export default ClientContactItem;
