import React, { FC, useContext, useEffect, useState } from "react";
import ButtonFinalizeQuote from "../../../../../Buttons/ButtonFinalizeQuote";
import { CartContext } from "../../../../../../context/CartContext";
import { useFormatPrice } from "../../../../../../hooks/UseFormatPrice";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../../helpers/formatMoney";
import CurrentQuotePopupItem from "./CurrentQuotePopupItem";
import {
  ItemsDataTypes,
  getQuotePopupItemsValues,
} from "../../../../../../helpers/parsers";
import { getCurrency } from "../../../../../../helpers/storage";

const CurrentQuotePopup: FC = () => {
  const [itemsData, setItemsData] = useState<ItemsDataTypes[]>([]);

  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();
  const { currentQuote } = useContext(CartContext);

  const [quoteTotal, setQuoteTotal] = useState<number>(0);

  const quoteTotalObj: FormattedMoneyTypes = formatMoney({
    amount: quoteTotal,
    country: currency,
    separateDecimals: false,
    showMinusSign: true,
  });

  useEffect(() => {
    setItemsData(getQuotePopupItemsValues(currentQuote));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItemsData(getQuotePopupItemsValues(currentQuote));
    setQuoteTotal(currentQuote?.totalPriceExclVat || 0);
  }, [currentQuote]);

  return (
    <div className="current-quote-outer">
      <div className="current-quote-inner">
        {itemsData.map((item, index) => (
          <CurrentQuotePopupItem {...item} key={index} />
        ))}

        <div className="quote-divider" />
        <div className="current-quote-item quote-total">
          <div className="current-quote-left">
            <div className="current-quote-total-text">Quote total</div>
          </div>
          <div className="current-quote-right">
            {getFormattedPriceFromObj(quoteTotalObj)}
          </div>
        </div>
        <ButtonFinalizeQuote />
      </div>
    </div>
  );
};

export default CurrentQuotePopup;
