import React, { FC } from "react";
import TopBanner from "../components/Banners/TopBanner";
import MainPageWrapper from "../components/Containers/MainPageWrapper";

interface EmptyPagePropsTypes {
  children?: React.ReactNode;
  testId?: string;
}

const EmptyPage: FC<EmptyPagePropsTypes> = ({ testId, children }) => {
  return (
    <MainPageWrapper testid={`empty-page-${testId ?? ""}`}>
      <TopBanner goBack startFromBeginning />
      {children}
    </MainPageWrapper>
  );
};

export default EmptyPage;
