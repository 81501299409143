import React, { FC, useEffect } from "react";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import ButtonAdd from "../../components/Buttons/ButtonAdd";
import { availableRoutes } from "../../lib/routes";
import { useNavigation } from "../../hooks/useNavigation";
import useRegion from "../../hooks/useRegion";

const HomePage: FC = () => {
  const { navigateTo } = useNavigation();

  const { handleGetRegion } = useRegion();
  useEffect(() => {
    handleGetRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainPageWrapper title="Home" testid="not-found-page">
      <div className="select-customer-btn-outer">
        <ButtonAdd
          onClick={() => navigateTo(availableRoutes.SELECT_CUSTOMER)}
          title="Select a customer"
        />
      </div>
    </MainPageWrapper>
  );
};

export default HomePage;
