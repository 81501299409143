import React, { FC } from "react";
import { useNavigation } from "../../../hooks/useNavigation";
import { availableRoutes } from "../../../lib/routes";
import ButtonSkipClientFilter from "../../Buttons/ButtonSkipClientFilter";
import StartFromBeginButton from "../../Buttons/StartFromBeginButton";
import TakeMeBackButton from "../../Buttons/TakeMeBackButton";

interface Props {
  goBack?: boolean;
  startFromBeginning?: boolean;
  skipFilter?: boolean;
}

const TopBanner: FC<Props> = ({
  goBack = false,
  startFromBeginning = false,
  skipFilter = false,
}) => {
  const { navigateTo } = useNavigation();

  return (
    <div className="topbanner-wrapper" data-html2canvas-ignore>
      <div className="row box-grid-container topbanner-container">
        <div className="col-md-6 col-left-align">
          {goBack && <TakeMeBackButton />}
        </div>
        <div className="col-md-6 col-right-align">
          {startFromBeginning && <StartFromBeginButton />}
          {skipFilter && (
            <ButtonSkipClientFilter
              onClick={() => navigateTo(availableRoutes.CHOOSE_JOURNEY)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
