import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { availableRoutes } from "../../lib/routes";
import CurrentQuotePopup from "../Quote/CurrentQuotePageItems/CurrentQuoteItem/QuoteItemComponents/CurrentQuotePopup/CurrentQuotePopup";

import { CartContext } from "../../context/CartContext";
import {
  ItemsDataTypes,
  getQuotePopupItemsValues,
} from "../../helpers/parsers";

const NavbarLinks: FC = () => {
  const { currentQuote } = useContext(CartContext);

  const [quoteHasItems, setQuoteHasItems] = useState<boolean>(false);

  const quoteItems: ItemsDataTypes[] = getQuotePopupItemsValues(currentQuote);

  useEffect(() => {
    if (!currentQuote || currentQuote.quoteItems.length === 0)
      setQuoteHasItems(false);
    else setQuoteHasItems(true);
  }, [currentQuote]);

  return (
    <div className="navbar-links">
      <div className="navbar-nav">
        <Link to={availableRoutes.ALL_QUOTES} data-testid="all-quotes-link">
          <div className="nav-link underline-hover active">
            <div className="icon symmetry-icon-inbox-items" />
            <div className="nav-link-text">All Quotes</div>
          </div>
        </Link>

        {quoteHasItems ? (
          <Link to={availableRoutes.CURRENT_QUOTE}>
            <div
              data-testid="current-quote-link"
              className="nav-link current-quote-link active underline-hover"
            >
              <div className="icon symmetry-icon-inbox-query" />
              <div className="nav-link-text">
                Current Quote
                {currentQuote !== null &&
                  currentQuote.quoteItems.length > 0 && (
                    <div className="quote-items-length">
                      {quoteItems.length}
                    </div>
                  )}
              </div>
              <div className="quote-items-length counter-mobile">
                {quoteItems.length}
              </div>
              <CurrentQuotePopup />
            </div>
          </Link>
        ) : (
          <div data-testid="current-quote-link" className="nav-link">
            <div className="icon symmetry-icon-inbox-query" />
            <div className="nav-link-text">Current Quote</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarLinks;
