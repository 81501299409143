import React, { useContext } from "react";
import { useNavigation } from "../../hooks/useNavigation";
import { storageVariables } from "../../lib/storageVariables";
import { ModalContext } from "../../context/ModalContext";
import ConfirmPopup from "../Popups/ConfirmPopup";
import { confirmResetMsg } from "../../settings/confirmAlertMessages";

const StartFromBeginButton = () => {
  const { showConfirm, openConfirm, confirmMsg } = useContext(ModalContext);
  const { fullNavigateToUrl } = useNavigation();

  const handleReset = () => {
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.NEW_FORKLIFT_ADDED);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_COMPANY);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);

    fullNavigateToUrl("/");
  };

  return (
    <>
      {showConfirm && confirmMsg && confirmMsg === confirmResetMsg && (
        <ConfirmPopup title={confirmMsg} onConfirm={handleReset} />
      )}
      <div
        className="white-btn underline-hover-btn"
        onClick={() => openConfirm(confirmResetMsg)}
      >
        <div className="btn-icon">
          <div className="icon symmetry-icon-refresh" />
        </div>
        <div className="btn-text">Reset</div>
      </div>
    </>
  );
};

export default StartFromBeginButton;
