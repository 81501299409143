import React, { FC } from "react";
import { Link } from "react-router-dom";

import logo from "../../images/sunlight-logo.svg";
import logoHover from "../../images/sunlight-logo-hover.svg";
import { availableRoutes } from "../../lib/routes";

const SunlightLogo: FC = () => {
  return (
    <Link to={availableRoutes.HOME} data-testid="logo">
      <div className="navbar-brand">
        <div className="logo-header">
          <img
            title="Sunlight logo"
            alt="Sunlight logo"
            src={logo}
            className="logo-header-basic"
          />
          <img
            alt="Sunlight logo"
            title="Sunlight logo"
            src={logoHover}
            className="logo-header-hover"
          />
        </div>
      </div>
    </Link>
  );
};

export default SunlightLogo;
