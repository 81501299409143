/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from "react";
import { CurrentQuoteTypes } from "../../../../types/currentQuoteTree";
import formatMoney from "../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../../helpers/storage";
import { vatText } from "../../../../helpers/currencyFormat";

interface FooterDetailsPropTypes {
  repNotes: string;

  currentQuote: CurrentQuoteTypes | null;
}
const ExistingQuoteFooterDetails: FC<FooterDetailsPropTypes> = ({
  repNotes,
  currentQuote,
}) => {
  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const vat = currentQuote?.vat ?? 0;
  const totalPriceExclVat = currentQuote?.totalPriceExclVat ?? 0;
  const totalPriceInclVat = currentQuote?.totalPriceInclVat ?? 0;

  const totalPriceExclVatObj = formatMoney({
    amount: totalPriceExclVat || 0,
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });
  const totalPriceInclVatObj = formatMoney({
    amount: totalPriceInclVat.toString(),
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });

  return (
    <div className="row box-grid-container cart-bottom-container">
      <div className="col-md-7 notes-outer">
        <div className="notes-inner">
          <label htmlFor={currentQuote?.dateCreated || "currentQuote"}>
            SALES REP. NOTES
          </label>
          <div
            id={currentQuote?.dateCreated || "currentQuote"}
            className="cart-notes-text"
          >
            {repNotes}
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="brief-cart-inner">
          <div className="brief-row brief-cart-price">
            <span className="brief-left">{`TOTAL EXCL. ${vatText}`}</span>

            <span className="brief-right cart-item-price-span">
              {getFormattedPriceFromObj(totalPriceExclVatObj)}
            </span>
          </div>
          <div className="brief-row brief-cart-vat">
            <span className="brief-left">{vatText}</span>
            <span className="brief-right vat">{vat}%</span>
          </div>
          <div className="brief-cart-divider" />
          <div className="brief-row brief-cart-total-price">
            <span className="brief-left" />

            <span className="brief-right cart-item-price-span">
              {getFormattedPriceFromObj(totalPriceInclVatObj)}
            </span>
          </div>
          <div className="brief-cart-divider" />
        </div>
      </div>
    </div>
  );
};

export default ExistingQuoteFooterDetails;
