import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import App from "./App";
import { CartProvider } from "./context/CartContext";

// import "./css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Initializers
import "./css/reset.css";
import "./css/fonts.css";
import "./css/style.css";
import "./css/symmetry.css";

// Components CSS
import "./css/bottom_banner.css";
import "./css/cart.css";
import "./css/choose_journey.css";
import "./css/form.css";
import "./css/header.css";
import "./css/main_banner.css";
import "./css/popup.css";
import "./css/product_details.css";
import "./css/top_banner.css";
import "./css/steps_form.css";
import "./css/current_quote.css";
import "./css/add_client.css";
import "./css/datatables.css";
import "./css/popup_selected_battery.css";
import "./css/react_select.css";
import "./css/datepicker.css";
import { FetchedDataProvider } from "./context/FetchedDataContext";
import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <FetchedDataProvider>
            <CartProvider>
              <App />
            </CartProvider>
          </FetchedDataProvider>
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  </MsalProvider>
);
