export const alertHasChargerMessage =
  "Please delete the charger before deleting the battery.";

export const alertHasAccessoriesMessage =
  "Please delete the accessories before deleting the charger.";

export const confirmDeleteQuoteItem =
  "This action will delete this quote item. Are you sure you want to continue?";

export const confirmDeleteBatteryMessage =
  "Are you sure you want to delete this battery?";

export const confirmDeleteChargerMessage =
  "Are you sure you want to delete this charger?";

export const confirmDeleteAccessoryMessage =
  "Are you sure you want to delete this accessory?";

export const confirmDeleteQuoteMessage =
  "If you continue you will delete the current quote. Are you sure you want continue?";
export const confirmEditQuoteMessage =
  "This action will delete the current quote. Are you sure you want continue?";

export const confirmLogoutMessage =
  "If you continue you will be logged out and lose any possible unsaved quote data. Are you sure you want continue?";

export const alertSelectCustomerBeforeBattery =
  "Please select a customer before add a battery to quote.";

export const alertAddBatteryBeforeCharger =
  "Please add battery to quote before select a charger.";

export const alertAddItemsBeforePdf =
  "You have to add at least one item to the quote before create the PDF!";

export const alertAddItemsBeforeSave =
  "You have to add at least one item to the quote before save!";

export const alertQuoteIsReadyForEdit = "Quote is ready for editing!";

export const alertQuoteDuplicated = "Quote duplicated successfully!";

export const alertFinalPricesZero =
  "Please fix negative final prices before saving.";

export const alertAddCompanyFirst = "Please select a company first.";

export const confirmResetMsg =
  "Are you sure you want to perform the Reset action?";

export const confirmDeleteCompany =
  "Are you sure you want to delete this company?";

export const confirmDeleteClient =
  "Are you sure you want to delete this client?";
