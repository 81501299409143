import React, { FC } from "react";
import ButtonHome from "../../components/Buttons/ButtonHome";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";

const NotFoundPage: FC = () => (
  <MainPageWrapper title="404" testid="not-found-page">
    <div className="row box-grid-container">
      <div className="error-page-container">
        <div className="error-page-title">
          Sorry, the page you are looking for could not be found
        </div>
        <div className="error-page-description">
          You can return to the home page by clicking the button below
        </div>
        <div className="error-page-button">
          <ButtonHome />
        </div>
      </div>
    </div>
  </MainPageWrapper>
);

export default NotFoundPage;
