import React, { FC } from "react";
import DropdownSelectInputChargers from "../../components/Inputs/DropdownSelectInputChargers";
import SimpleInput from "../../components/Inputs/SimpleInput";
import ButtonNext from "../../components/Buttons/ButtonNext";
import ButtonGreenSearch from "../../components/Buttons/ButtonGreenSearch";
import {
  ChargersOptionsDataType,
  inputDataOneType,
} from "../../types/chargers";

interface StepOnePropsTypes {
  inputDataStep: inputDataOneType;
  isLithiumText: string | null;
  dataChargersStep: ChargersOptionsDataType;
  activeStep: number;
  handleSubmit: () => void;
  changeStep: (_activeStep: 1 | 2 | 3) => void;
  errorsExist: boolean;
}

const chargercurrentTooltip = (
  <div className="tooltip-container tooltiptext">
    Fill this only if you wish to find chargers immediately based on the
    current.
  </div>
);

const StepOne: FC<StepOnePropsTypes> = ({
  inputDataStep,
  isLithiumText,
  dataChargersStep,
  changeStep,
  handleSubmit,
  errorsExist,
}) => {
  return (
    <>
      <div className="find-charger-step find-charger-step-1">
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.batteryChemistryArray}
          {...inputDataStep.chemistry}
        />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.batteryTypeArray}
          {...inputDataStep.type}
        />
        <SimpleInput {...inputDataStep.capacity} />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.batteryVoltageArray}
          {...inputDataStep.voltage}
        />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.countryArray}
          {...inputDataStep.country}
          // isCountrySelector={!!(inputDataStep?.country?.name === "country")}
        />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.mainsVoltageArray}
          {...inputDataStep.mainsVoltage}
          dependencyCountry={inputDataStep.country.value}
        />
        {isLithiumText === "Lithium-ion" && (
          <SimpleInput
            {...inputDataStep.chargercurrent}
            hasTooltip={chargercurrentTooltip}
          />
        )}
      </div>

      <div className="buttons-step-1">
        {isLithiumText !== "Lithium-ion" && (
          <div className="row box-grid-container">
            <div className="col-md-6 col-left-align" />
            <div className="col-md-6 col-right-align">
              {!errorsExist ? (
                <ButtonNext onClick={() => changeStep(2)} />
              ) : (
                <ButtonNext onClick={() => changeStep(2)} disabled />
              )}
            </div>
          </div>
        )}
        <div className="row box-grid-container">
          <div className="col-md-12">
            <div className="col-right-align">
              {!errorsExist ? (
                <ButtonGreenSearch onClick={handleSubmit} />
              ) : (
                <ButtonGreenSearch onClick={handleSubmit} disabled />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOne;
