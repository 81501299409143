import React, { FC } from "react";
import ButtonAddTransparent from "../../../Buttons/ButtonAddTransparent";
import DividerGrey from "../../../Dividers/DividerGrey";
import CurrentQuoteFooterDetails from "./CurrentQuoteFooterDetails";
import { CurrentQuoteTypes } from "../../../../types/currentQuoteTree";
import { availableRoutes } from "../../../../lib/routes";
import { useNavigation } from "../../../../hooks/useNavigation";

interface CurrentQuoteFooterPropsTypes {
  repNotes: string;
  setRepNotes: (_value: string) => void;
  currentQuote: CurrentQuoteTypes | null;
}

const CurrentQuoteFooter: FC<CurrentQuoteFooterPropsTypes> = ({
  repNotes,
  setRepNotes,
  currentQuote,
}) => {
  const { fullNavigateToUrl } = useNavigation();

  const navigateToForklift = () => {
    fullNavigateToUrl(availableRoutes.START_JOURNEY_BY_FORKLIFT);
  };

  const navigateToBattery = () => {
    fullNavigateToUrl(availableRoutes.START_JOURNEY_BY_BATTERY);
  };

  const navigateToCharger = () => {
    fullNavigateToUrl(availableRoutes.START_JOURNEY_BY_CHARGER);
  };

  return (
    <>
      <div data-html2canvas-ignore>
        <ButtonAddTransparent
          title="Forklift to quote"
          onClick={navigateToForklift}
        />
        <ButtonAddTransparent
          title="Battery to quote"
          onClick={navigateToBattery}
        />
        <ButtonAddTransparent
          title="Charger to quote"
          onClick={navigateToCharger}
        />
      </div>

      <DividerGrey />
      <CurrentQuoteFooterDetails
        repNotes={repNotes}
        setRepNotes={setRepNotes}
        currentQuote={currentQuote}
      />
    </>
  );
};

export default CurrentQuoteFooter;
