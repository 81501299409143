/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { FC } from "react";
import { useFormatPrice } from "../../../../../../hooks/UseFormatPrice";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../../helpers/formatMoney";
import { getCurrency } from "../../../../../../helpers/storage";
import ImageWithFallback from "../../../../../Media/ImageWithFallback";

interface CurrentQuotePopupItemPropsTypes {
  imageUrl: string;
  title: string;
  price: number;
}

const CurrentQuotePopupItem: FC<CurrentQuotePopupItemPropsTypes> = ({
  imageUrl,
  title,
  price,
}) => {
  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency: string = getCurrency();

  const itemPriceObj: FormattedMoneyTypes = formatMoney({
    amount: price,
    country: currency,
    separateDecimals: false,
    showMinusSign: true,
  });

  return (
    <div className="current-quote-item">
      <div className="current-quote-left">
        <ImageWithFallback type="charger" imageUrl={imageUrl} />
        <span>{title}</span>
      </div>
      <div className="current-quote-right">
        {getFormattedPriceFromObj(itemPriceObj)}
      </div>
    </div>
  );
};

export default CurrentQuotePopupItem;
