import { getCurrency } from "./storage";

const tempCurrency = getCurrency();

export const isCurrencyEUR: boolean = tempCurrency === "EUR";

// export const vatText: string = isCurrencyEUR ? "VAT" : "TAX";
export const vatText = "VAT";

export const currentGroupSeparator = tempCurrency === "EUR" ? "." : ",";
export const currentDecimalSeparator = tempCurrency === "EUR" ? "," : ".";

export const getCurrencyByRegion = (region: string | null): string => {
  if (!region) return "EUR";
  if (region === "USA") return "USA";
  return "EUR";
};

export const getCurrencySymbol = (): string => {
  const currency = getCurrency();

  if (currency === "EUR") return "€";
  if (currency === "USA") return "$";
  return "€";
};
