import React, { FC } from "react";
import { useNavigation } from "../../../hooks/useNavigation";
import { availableRoutes } from "../../../lib/routes";
import ButtonSkipClientFilter from "../../Buttons/ButtonSkipClientFilter";
import StartFromBeginButton from "../../Buttons/StartFromBeginButton";
import TakeMeBackButton from "../../Buttons/TakeMeBackButton";

interface Props {
  goBack?: boolean;
  backUrl?: string;
  startFromBeginning?: boolean;
  skipFilter?: boolean;
}

const TopBannerCustomBack: FC<Props> = ({
  backUrl,
  goBack = false,
  startFromBeginning = false,
  skipFilter = false,
}) => {
  const { fullNavigateToUrl, navigateTo } = useNavigation();

  const handleGoBack = () => {
    if (backUrl) fullNavigateToUrl(backUrl);
  };

  return (
    <div className="topbanner-wrapper">
      <div className="row box-grid-container topbanner-container">
        <div className="col-md-6 col-left-align">
          {goBack && (
            <>
              {backUrl ? (
                <div
                  className="white-btn underline-hover-btn"
                  onClick={handleGoBack}
                >
                  <div className="btn-icon">
                    <div className="icon symmetry-icon-arrow-left" />
                  </div>
                  <div className="btn-text">Back</div>
                </div>
              ) : (
                <TakeMeBackButton />
              )}
            </>
          )}
        </div>
        <div className="col-md-6 col-right-align">
          {startFromBeginning && <StartFromBeginButton />}
          {skipFilter && (
            <ButtonSkipClientFilter
              onClick={() => navigateTo(availableRoutes.CHOOSE_JOURNEY)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBannerCustomBack;
