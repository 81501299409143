import React, { FC } from "react";

interface PropsTypes {
  onClick: () => void;
}
const ButtonSkipClientFilter: FC<PropsTypes> = ({ onClick }) => {
  return (
    <div className="white-btn underline-hover-btn" onClick={onClick}>
      <div className="btn-icon">
        <div data-icon="" className="icon" />
      </div>
      <div className="btn-text">Skip Client Filter</div>
    </div>
  );
};

export default ButtonSkipClientFilter;
