/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SortType } from "react-bs-datatable";

import BatterySearchHeader from "../../components/Headers/BatterySearchHeader";
import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import BatterySearchForm from "../../components/Forms/BatterySearch/BatterySearchForm";
import {
  batteryDinOptions,
  measurementUnits,
} from "../../config/searchInputOptions";
import { InputDataTypes } from "../../types/batterySearch";
import {
  BatteryTypesNew,
  CoreFieldsTypes,
} from "../../types/basicProductsTypes";
import SelectedBatteryJourneyPopup from "../../components/Popups/SelectedBatteryJourneyPopup";
import {
  batteryCoreFieldsArrayMockData,
  chargerCoreFieldsArrayMockData,
} from "../../assets/mockData/coreFieldsArraysMockData";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import DataTableWithApi from "../../components/Tables/DataTableWithApi";
import NoResultsFoundBanner from "../../components/Banners/NoResultsFoundBanner";
import { getCurrency } from "../../helpers/storage";
import { regexEUR, regexUSD } from "../../config/currencyRegex";
import { checkIfObjectsHasEqualValues } from "../../helpers/functions";
import useDebounce from "../../hooks/useDebounce";
import { getCompanyDebounceTimeMs } from "../../settings/debounceLimits";

export interface OptionsTypes {
  chemistryAvailableOptions: string[];
  batteryTypeAvailableOptions: string[];
  batteryDinAvailableOptions: string[];
  batteryColorsAvailableOptions: string[];
}
interface InitialValuesTypes {
  batteryId: string;
  sapId: string;
  length: string;
  width: string;
  height: string;
  widthTolerance: string;
  heightTolerance: string;
  lengthTolerance: string;
  chemistry: string;
  batteryType: string;
  nominalSuggestedWeight: string;
  // nominalSuggestedWeightPercentageTolerance: string;
  minimumWeight: string;
  din: string;
  voltage: string;
  capacity: string;
  drawingNumber: string;
  color: string;
  leadAcidBatteryTrayCode: string;
}

export interface CoreFieldsArraysTypes {
  batteryCoreFieldsArray: CoreFieldsTypes[] | [];
  chargerCoreFieldsArray: CoreFieldsTypes[] | [];
}

const getPropname = (name: string): string => {
  if (name === "ID") return "ID";
  if (name === "sapId") return "SAP_ID";
  if (name === "chemistry") return "CHEMISTRY";
  if (name === "capacity") return "CAPACITY";
  if (name === "voltage") return "VOLTAGE";
  if (name === "capacity") return "CAPACITY";
  if (name === "din") return "DIN";
  if (name === "length") return "LENGTH";
  if (name === "width") return "WIDTH";
  if (name === "height") return "HEIGHT";
  if (name === "minimumWeight") return "MINIMUM_WEIGHT";
  if (name === "nominalSuggestedWeight") return "NOMINAL_SUGGESTED_WEIGHT";
  if (name === "drawingNumber") return "DRAWING_NUMBER";
  if (name === "color") return "COLOR";
  return "ID";
};

const DEFAULT_ROWS_PER_PAGE = 5;

const DEFAULT_PAGINATION_SETTINGS = {
  totalRecords: 0,
  page: 1,
  recordsPerPage: DEFAULT_ROWS_PER_PAGE,
  totalPages: 0,
};

const SearchBatteryPage: FC = () => {
  // const [measurementEU, setMeasurementEU] = useState<boolean>(true);
  const [displaySearchForm, setDisplaySearchForm] = useState<boolean>(true);

  const [selectedBattery, setSelectedBattery] =
    useState<BatteryTypesNew | null>(null);
  const [coreFieldsArrays, setCoreFieldsArrays] =
    useState<CoreFieldsArraysTypes>({
      chargerCoreFieldsArray: chargerCoreFieldsArrayMockData,
      batteryCoreFieldsArray: batteryCoreFieldsArrayMockData,
    });

  const [formikErrors, setFormikErrors] = useState<boolean>(false);

  const [availableOptions, setAvailableOptions] = useState<OptionsTypes>({
    chemistryAvailableOptions: [],
    batteryTypeAvailableOptions: [],
    batteryDinAvailableOptions: batteryDinOptions,
    batteryColorsAvailableOptions: [],
  });

  const currency = getCurrency();

  const measurementEU = useMemo(() => {
    return currency === "EUR";
  }, [currency]);

  const decimalsRegex = currency === "EUR" ? regexEUR : regexUSD;

  const validationSchema = Yup.object().shape({
    sapId: Yup.string()
      .matches(/^[0-9]*$/, "SAP Code should be a number")
      .nullable()
      .max(7, "SAP Code can be at most 7 characters"),
    batteryId: Yup.string()
      .matches(/^[0-9]*$/, "Battery ID should be a number")
      .nullable()
      .max(7, "Battery ID can be at most 7 characters"),
    length: Yup.string()
      .nullable()
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      )
      .max(6, "Length can be at most 6 characters"),
    lengthTolerance: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Length tollerance should be a number")
      .max(2, "Length tollerance can be at most 2 characters"),
    width: Yup.string()
      .nullable()
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      )
      .max(6, "Width can be at most 6 characters"),
    widthTolerance: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Width tollerance should be a number")
      .max(2, "Width tollerance can be at most 2 characters"),
    height: Yup.string()
      .nullable()
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      )
      .max(6, "Height can be at most 6 characters"),
    heightTolerance: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Height tollerance should be a number")
      .max(2, "Height tollerance can be at most 2 characters"),
    chemistry: Yup.string()
      .nullable()
      .oneOf(
        availableOptions.chemistryAvailableOptions,
        "Select one of the options"
      ),
    batteryType: Yup.string()
      .nullable()
      .oneOf(
        availableOptions.batteryTypeAvailableOptions,
        "Select one of the options"
      ),
    nominalSuggestedWeight: Yup.string()
      .nullable()
      // .matches(/^[0-9]*$/, "Nominal Weight should be a number")
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      )
      .max(7, "Nominal Weight can be at most 7 characters"),
    // nominalSuggestedWeightPercentageTolerance: Yup.string()
    //   .nullable()
    //   .matches(/^[0-9]*$/, "Minimum Weight should be a number")
    //   .max(2, "Nominal Suggested Weight Tolerance can be at most 99%"),
    minimumWeight: Yup.string()
      .nullable()
      // .matches(/^[0-9]*$/, "Minimum Weight should be a number")
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      )
      .max(7, "Minimum Weight can be at most 7 characters"),
    din: Yup.string()
      .nullable()
      .oneOf(
        availableOptions.batteryDinAvailableOptions,
        "Select one of the options"
      ),
    voltage: Yup.string()
      .nullable()
      .matches(
        decimalsRegex,
        "Please enter only digits or valid decimal format."
      ),
    capacity: Yup.string()
      .matches(/^[0-9]*$/, "Capacity should be a number")
      .nullable(),
    drawingNumber: Yup.string().nullable(),
    color: Yup.string()
      .nullable()
      .oneOf(
        availableOptions.batteryColorsAvailableOptions,
        "Select one of the options"
      ),
    leadAcidBatteryTrayCode: Yup.string().nullable(),
  });

  const initialValues: InitialValuesTypes = {
    batteryId: "",
    sapId: "",
    chemistry: "",
    batteryType: "",
    length: "",
    lengthTolerance: "",
    height: "",
    heightTolerance: "",
    width: "",
    leadAcidBatteryTrayCode: "",
    widthTolerance: "",
    nominalSuggestedWeight: "",
    // nominalSuggestedWeightPercentageTolerance: "",
    minimumWeight: "",
    din: "",
    voltage: "",
    capacity: "",
    drawingNumber: "",
    color: "",
  };

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const [fetchedData, setFetchedData] = useState<BatteryTypesNew[]>([]);

  const [paginationSettings, setPaginationSettings] = useState(
    DEFAULT_PAGINATION_SETTINGS
  );

  const [filter, setFilter] = useState("");

  const initialFilter: SortType = {
    prop: "ID",
    order: "asc",
  };
  const [sortState, setSortState] = useState<SortType>(initialFilter);

  const [currentPage, setCurrentPage] = useState(0);

  const handleStartSearch = async (data: InitialValuesTypes) => {
    const settings = {
      orderBy: filter ? "ID" : `${getPropname(sortState.prop)}`,
      orderDirection: `${sortState.order.toUpperCase()}`,

      region: measurementEU ? "EU" : "US",
      page: currentPage,
      pageSize: DEFAULT_ROWS_PER_PAGE,
    };

    const dataFormatted = {
      ...data,
      batteryId: data.batteryId ? parseInt(data.batteryId, 10) : null,

      sapId: filter || (data.sapId ?? null),
      chemistry:
        data.chemistry || data.chemistry !== "" ? data.chemistry : null,
      batteryType:
        data.batteryType || data.batteryType !== "" ? data.batteryType : null,

      // length: data.length ? parseFloat(data.length) : null,
      length: data.length ? parseFloat(data.length.replace(",", ".")) : null,
      lengthTolerance:
        data.length && data.lengthTolerance
          ? parseInt(data.lengthTolerance, 10)
          : 0,
      // height: data.height ? parseFloat(data.height) : null,
      height: data.height ? parseFloat(data.height.replace(",", ".")) : null,
      heightTolerance:
        data.height && data.heightTolerance
          ? parseInt(data.heightTolerance, 10)
          : 0,
      // width: data.width ? parseFloat(data.width) : null,
      width: data.width ? parseFloat(data.width.replace(",", ".")) : null,
      widthTolerance:
        data.width && data.widthTolerance
          ? parseInt(data.widthTolerance, 10)
          : 0,
      nominalSuggestedWeight: data.nominalSuggestedWeight
        ? parseFloat(data.nominalSuggestedWeight.replace(",", "."))
        : null,
      // nominalSuggestedWeightPercentageTolerance:
      //   data.nominalSuggestedWeightPercentageTolerance
      //     ? parseInt(data.nominalSuggestedWeightPercentageTolerance, 10)
      //     : null,
      leadAcidBatteryTrayCode:
        data.leadAcidBatteryTrayCode || data.leadAcidBatteryTrayCode !== ""
          ? data.leadAcidBatteryTrayCode
          : null,
      minimumWeight: data.minimumWeight
        ? parseFloat(data.minimumWeight.replace(",", "."))
        : null,
      voltage: data.voltage ? data.voltage.replace(",", ".") : null,
      capacity: data.capacity ? parseInt(data.capacity, 10) : null,
      din: data.din === "Yes" ? true : data.din === "No" ? false : null,

      drawingNumber: data.drawingNumber ? data.drawingNumber : null,

      color: data.color || data.color !== "" ? data.color : null,

      ...settings,
    };

    const config = {
      method: "post",
      url: "/v1/batteries/search",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataFormatted,
      isSearchRequest: true,
    };

    handleAuthApiCall(config);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (data: InitialValuesTypes) => {
      setFormikErrors(false);

      if (Object.keys(formik.errors).length > 0) {
        setFormikErrors(true);
        return;
      }

      handleStartSearch(data);
    },
  });

  const errors = Object.keys(formik.errors).length > 0;

  const updateFormikValue = (name: string, value: string) => {
    setFormikErrors(false);
    formik.setFieldValue(name, value);
  };

  const measureUnitSize: string = useMemo(() => {
    return measurementEU
      ? measurementUnits.EU.length
      : measurementUnits.USA.length;
  }, [measurementEU]);

  const measureUnitWeight: string = useMemo(() => {
    return measurementEU
      ? measurementUnits.EU.weight
      : measurementUnits.USA.weight;
  }, [measurementEU]);

  const getDropdownOptions = (name: string, value: string): void => {
    if (name === "chemistry") {
      updateFormikValue("chemistry", value);

      handleStartSearch({
        ...formik.values,
        chemistry: value,
      });
    } else if (name === "batteryType") {
      updateFormikValue("batteryType", value);

      handleStartSearch({
        ...formik.values,
        batteryType: value,
      });
    } else if (name === "color") {
      updateFormikValue("color", value);

      handleStartSearch({
        ...formik.values,
        color: value,
      });
    } else if (name === "din") {
      updateFormikValue("din", value);

      handleStartSearch({
        ...formik.values,
        din: value,
      });
    }
  };

  const inputData: InputDataTypes = {
    batteryId: {
      name: "batteryId",
      placeholder: "Battery ID",
      value: formik.values.batteryId,
      onChange: (value: string) => updateFormikValue("batteryId", value),
      errorMessage: formik.errors.batteryId ?? null,
    },
    sapId: {
      name: "sapId",
      placeholder: "SAP Code",
      value: formik.values.sapId,
      onChange: (value: string) => updateFormikValue("sapId", value),
      errorMessage: formik.errors.sapId ?? null,
    },
    leadAcidBatteryTrayCode: {
      name: "leadAcidBatteryTrayCode",
      placeholder: "Tray Code",
      value: formik.values.leadAcidBatteryTrayCode,
      onChange: (value: string) =>
        updateFormikValue("leadAcidBatteryTrayCode", value),
      errorMessage: formik.errors.leadAcidBatteryTrayCode ?? null,
    },
    length: {
      value: formik.values.length,
      name: "length",
      placeholder: "Length",
      errorMessage: formik.errors.length
        ? formik.errors.length
        : formik.errors.lengthTolerance ?? null,
      onChange: (value: string) => updateFormikValue("length", value),
      measurementUnit: measureUnitSize,

      subFormValue: formik.values.lengthTolerance,
      subFormName: "lengthTolerance",
      subFormMeasureUnit: measureUnitSize,
      subFormOnChange: (subFormValue: string) =>
        updateFormikValue("lengthTolerance", subFormValue),
    },
    width: {
      value: formik.values.width,
      name: "width",
      placeholder: "Width",
      errorMessage: formik.errors.width
        ? formik.errors.width
        : formik.errors.widthTolerance ?? null,
      onChange: (value: string) => updateFormikValue("width", value),
      measurementUnit: measureUnitSize,
      subFormValue: formik.values.widthTolerance,
      subFormName: "widthTolerance",
      subFormMeasureUnit: measureUnitSize,
      subFormOnChange: (subFormValue: string) =>
        updateFormikValue("widthTolerance", subFormValue),
    },
    height: {
      value: formik.values.height,
      name: "height",
      placeholder: "Height",
      errorMessage: formik.errors.height
        ? formik.errors.height
        : formik.errors.heightTolerance ?? null,
      onChange: (value: string) => updateFormikValue("height", value),
      measurementUnit: measureUnitSize,
      subFormValue: formik.values.heightTolerance,
      subFormName: "heightTolerance",
      subFormMeasureUnit: measureUnitSize,
      subFormOnChange: (subFormValue: string) =>
        updateFormikValue("heightTolerance", subFormValue),
    },
    chemistry: {
      name: "chemistry",
      placeholder: "Chemistry",
      value: formik.values.chemistry,
      onChange: (value: string) => getDropdownOptions("chemistry", value),
      errorMessage: formik.errors.chemistry ?? null,
    },
    batteryType: {
      name: "batteryType",
      placeholder: "Battery Type",
      value: formik.values.batteryType,
      onChange: (value: string) => getDropdownOptions("batteryType", value),
      errorMessage: formik.errors.batteryType ?? null,
    },
    nominalSuggestedWeight: {
      name: "nominalSuggestedWeight",
      placeholder: "Battery Weight",
      value: formik.values.nominalSuggestedWeight,
      onChange: (value: string) =>
        updateFormikValue("nominalSuggestedWeight", value),
      errorMessage: formik.errors.nominalSuggestedWeight ?? null,
      measurementUnit: measureUnitWeight,
    },
    minimumWeight: {
      name: "minimumWeight",
      placeholder: "Minimum Weight",
      value: formik.values.minimumWeight,
      onChange: (value: string) => updateFormikValue("minimumWeight", value),
      errorMessage: formik.errors.minimumWeight ?? null,
      measurementUnit: measureUnitWeight,
    },
    din: {
      name: "din",
      placeholder: "DIN",
      value: formik.values.din,
      onChange: (value: string) => getDropdownOptions("din", value),
      errorMessage: formik.errors.din ?? null,
    },
    voltage: {
      name: "voltage",
      placeholder: "Voltage",
      value: formik.values.voltage,
      onChange: (value: string) => updateFormikValue("voltage", value),
      errorMessage: formik.errors.voltage ?? null,
    },
    capacity: {
      name: "capacity",
      placeholder: "Capacity",
      value: formik.values.capacity,
      onChange: (value: string) => updateFormikValue("capacity", value),
      errorMessage: formik.errors.capacity ?? null,
    },
    drawingNumber: {
      name: "drawingNumber",
      placeholder: "Drawing Code",
      value: formik.values.drawingNumber,
      onChange: (value: string) => updateFormikValue("drawingNumber", value),
      errorMessage: formik.errors.drawingNumber ?? null,
    },
    color: {
      name: "color",
      placeholder: "Colour",
      value: formik.values.color,
      onChange: (value: string) => getDropdownOptions("color", value),
      errorMessage: formik.errors.color ?? null,
    },
  };

  const toggleDisplaySearchForm = () => {
    setDisplaySearchForm(!displaySearchForm);
  };

  const closePopup = () => {
    setSelectedBattery(null);
  };

  const [searchStarted, setSearchStarted] = useState(false);

  const handleSearch = () => {
    if (errors) setFormikErrors(true);
    else {
      setCurrentPage(0);
      setFilter("");
      formik.handleSubmit();
      setSearchStarted(true);
    }
  };

  const handleSelectBattery = (id: number) => {
    const chosenBattery: BatteryTypesNew | undefined = fetchedData.find(
      (battery) => battery.batteryId === id
    );

    setSelectedBattery(chosenBattery ?? null);
  };

  const [noResultsFound, setNoResultsFound] = useState(false);

  useEffect(() => {
    if (searchStarted) handleStartSearch(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (searchStarted) handleStartSearch(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState]);
  const debouncedFilterValue = useDebounce<string>(
    filter,
    getCompanyDebounceTimeMs
  );
  useEffect(() => {
    if (!checkIfObjectsHasEqualValues(initialFilter, sortState)) {
      setSortState(initialFilter);
    } else if (searchStarted) {
      handleStartSearch(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValue]);

  const parseDinArray = (dinArray: boolean[]): string[] => {
    const dinArrayParsed: string[] = [];

    if (!dinArray || dinArray.length === 0) return dinArrayParsed;

    dinArray.forEach((din) => {
      if (din) dinArrayParsed.push("Yes");
      else dinArrayParsed.push("No");
    });

    return dinArrayParsed;
  };

  useEffect(() => {
    if (apiData?.batteries) {
      if (apiData.batteries.length === 0) setNoResultsFound(true);
      else setNoResultsFound(false);

      setFetchedData(apiData.batteries);

      setPaginationSettings(
        apiData?.paginationSettings ?? DEFAULT_PAGINATION_SETTINGS
      );

      setAvailableOptions({
        ...availableOptions,
        chemistryAvailableOptions:
          apiData?.chemistryArray.length && apiData.batteries.length > 0
            ? apiData?.chemistryArray
            : availableOptions.chemistryAvailableOptions,
        batteryTypeAvailableOptions:
          apiData?.batteryTypeArray.length && apiData.batteries.length > 0
            ? apiData?.batteryTypeArray
            : availableOptions.batteryTypeAvailableOptions,
        batteryColorsAvailableOptions:
          apiData?.colorArray.length && apiData.batteries.length > 0
            ? apiData.colorArray
            : availableOptions.batteryColorsAvailableOptions,
        batteryDinAvailableOptions:
          apiData?.dinArray && apiData.batteries.length > 0
            ? parseDinArray(apiData.dinArray)
            : availableOptions.batteryDinAvailableOptions,
      });

      if (
        apiData?.batteryCoreFieldsArray &&
        apiData.batteryCoreFieldsArray.length > 0
      ) {
        setCoreFieldsArrays({
          ...coreFieldsArrays,
          batteryCoreFieldsArray: apiData.batteryCoreFieldsArray,
        });
      }

      if (
        apiData?.chargerCoreFieldsArray &&
        apiData.chargerCoreFieldsArray.length > 0
      ) {
        setCoreFieldsArrays({
          ...coreFieldsArrays,
          chargerCoreFieldsArray: apiData.chargerCoreFieldsArray,
        });
      }
    } else {
      setFetchedData([]);
      setPaginationSettings(DEFAULT_PAGINATION_SETTINGS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  useEffect(() => {
    if (formik.values.width === "" && formik.values.widthTolerance !== "") {
      formik.setFieldValue("widthTolerance", "");
    }
    if (formik.values.height === "" && formik.values.heightTolerance !== "") {
      formik.setFieldValue("heightTolerance", "");
    }
    if (formik.values.length === "" && formik.values.lengthTolerance !== "") {
      formik.setFieldValue("lengthTolerance", "");
    }

    if (checkIfObjectsHasEqualValues(formik.values, initialValues))
      handleStartSearch(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <MainPageWrapper title="Battery Search" testid="battery-search-page">
      <TopBanner goBack startFromBeginning />

      <BatterySearchHeader
        measurementEU={measurementEU}
        displaySearchForm={displaySearchForm}
        toggleDisplaySearchForm={toggleDisplaySearchForm}
      />

      {displaySearchForm && (
        <BatterySearchForm
          displayErrorBanner={formikErrors && errors}
          inputData={inputData}
          handleSearch={handleSearch}
          availableOptions={availableOptions}
        />
      )}

      {searchStarted &&
        (fetchedData.length > 0 ||
        (noResultsFound && filter !== "") ||
        filter === "" ? (
          <DataTableWithApi
            fetchedData={fetchedData}
            paginationSettings={paginationSettings}
            filter={filter}
            sortState={sortState}
            setFilter={setFilter}
            setSortState={setSortState}
            setCurrentPage={setCurrentPage}
            handleSelectBattery={handleSelectBattery}
            measureUnitWeight={measureUnitWeight}
            measureUnitSize={measureUnitSize}
          />
        ) : (
          <NoResultsFoundBanner />
        ))}

      {selectedBattery && (
        <SelectedBatteryJourneyPopup
          closePopup={closePopup}
          selectedBattery={selectedBattery}
          coreFieldsArrays={coreFieldsArrays}
        />
      )}

      <div className="pb-5" />
    </MainPageWrapper>
  );
};

export default SearchBatteryPage;
