import React, { FC } from "react";
import ButtonProductAddToQuote from "../../Buttons/ButtonProductAddToQuote";
import formatMoney from "../../../helpers/formatMoney";
import { useFormatPrice } from "../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../helpers/storage";
import { SelectedPopupTopTypes } from "../../../types/SelectedBatteryPopup";
import ImageWithFallback from "../../Media/ImageWithFallback";
import QuantityInput from "../../Inputs/QuantityInput";
import { ReplacementBatteriesTypes } from "../../../types/basicProductsTypes";
import ProductReplacesInfoButton from "../../Buttons/ProductReplacesInfoButton";

interface SelectedBatteryBannerTopProsTypes {
  batteryPopupTopValues: SelectedPopupTopTypes;
  addItemToQuote: () => void;
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
  replacementsArray?: ReplacementBatteriesTypes[];
  setQuantity: (_value: number) => void;
}

const SelectedBatteryBannerTop: FC<SelectedBatteryBannerTopProsTypes> = ({
  batteryPopupTopValues,
  addItemToQuote,
  quantity,
  onIncrement,
  onDecrement,
  replacementsArray,
  setQuantity,
}) => {
  const { title, imageUrl, price } = batteryPopupTopValues;

  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const priceObj = formatMoney({
    amount: price || 0,
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });

  return (
    <div className="product-details-top">
      <div className="product-details-top-left">
        <ImageWithFallback type="battery" imageUrl={imageUrl} />
      </div>
      <div className="product-details-top-right-popup">
        <div className="product-details-title">{title}</div>
        {replacementsArray && replacementsArray?.length > 0 && (
          <ProductReplacesInfoButton replacementsArray={replacementsArray} />
        )}
        <QuantityInput
          quantity={quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          handleManualQuantityChange={setQuantity}
        />
        <div className="product-details-price-outer">
          <div className="product-details-price">
            {getFormattedPriceFromObj(priceObj)}
          </div>
          <ButtonProductAddToQuote onClick={addItemToQuote} />
        </div>
      </div>
    </div>
  );
};

export default SelectedBatteryBannerTop;
