import React, { FC } from "react";
import { BannerRightTableTypes } from "../../../types/bannersTypes";

interface RightBannerTablePropsTypes {
  rightBannerTableValues: BannerRightTableTypes[];
}

const RightBannerTable: FC<RightBannerTablePropsTypes> = ({
  rightBannerTableValues,
}) => {
  return (
    <div className="product-details-table">
      {rightBannerTableValues.length > 0 && (
        <ul className="product-details">
          {rightBannerTableValues.map(({ id, title, description }) => (
            <li key={id} data-testid={`table-line-${title}`}>
              <div className="product-details-table-left me-3">{title}</div>
              <div className="product-details-table-right">{description}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RightBannerTable;
