import React, { FC } from "react";

interface ButtonNextGreenPropsTypes {
  title?: string;
  onClick?: () => void;
}

const ButtonNextGreen: FC<ButtonNextGreenPropsTypes> = ({
  title = "Next",
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div
      className="green-btn corner-btn"
      onClick={handleClick}
      data-testid="button-next-yellow"
    >
      <div className="btn-text">{title}</div>
      <div className="btn-icon">
        <div className="icon symmetry-icon-arrow-right" />
      </div>
    </div>
  );
};

export default ButtonNextGreen;
