import React, { FC } from "react";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";

const ButtonHome: FC = () => {
  const { fullNavigateToUrl } = useNavigation();
  return (
    <div
      className="yellow-btn corner-btn"
      onClick={() => fullNavigateToUrl(availableRoutes.HOME)}
    >
      <div className="btn-icon">
        <div className="icon symmetry-icon-home" />
      </div>
      <div className="btn-text">Home</div>
    </div>
  );
};

export default ButtonHome;
