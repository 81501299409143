/* eslint-disable react/jsx-filename-extension */
import React from "react";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

// import Button from "react-bootstrap/Button";
// import PageLayout from "../../components/Auth/PageLayout";
// import { loginRequest } from "../../authConfig";
// import { callMsGraph } from "../../graph";
// import ProfileData from "../../components/Auth/ProfileData";

import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import useAuth from "../../hooks/useAuth";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
  // const { instance, accounts } = useMsal();
  // const [graphData, setGraphData] = useState(null);
  const { accounts } = useMsal();

  // function RequestProfileData() {
  //   // Silently acquires an access token which is then attached to a request for MS Graph data
  //   instance
  //     .acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0],
  //     })
  //     .then((response) => {
  //       // eslint-disable-next-line no-shadow
  //       callMsGraph(response.accessToken).then((response) =>
  //         setGraphData(response)
  //       );
  //     });
  // }

  return (
    <>
      <h5 className="card-title">Welcome {accounts[0].name}</h5>
      <br />
      {/* {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        // eslint-disable-next-line react/jsx-no-bind
        <Button variant="secondary" onClick={RequestProfileData}>
          Request Profile Information
        </Button>
      )} */}
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  const { handleLogin } = useAuth();
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <div className="no-loggedin-user-outer">
          <div className="no-loggedin-user-inner">
            <ProfileContent />
          </div>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className="no-loggedin-user-outer">
          <div className="no-loggedin-user-inner">
            <h5>
              <center>Please sign-in to start using the app.</center>
            </h5>
            <div
              className="logout-user-btn no-logged-in-btn corner-btn"
              onClick={handleLogin}
            >
              <div>Sign in</div>
              <div className="icon symmetry-icon-user-locked-no" />
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

const LoginPage = () => {
  return (
    <MainPageWrapper title="Login" testid="login-page">
      <center>
        <MainContent />
      </center>
    </MainPageWrapper>
  );
};

export default LoginPage;
