import React, { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AvailableCompanyTypes,
  AvailableCountryTypes,
  FetchedCompanyTypes,
} from "../../types/companies";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import useAuth from "../../hooks/useAuth";
import DropdownSelectInput from "../Inputs/DropdownSelectInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import InputWithSubForm from "../Inputs/InputWithSubForm";
import { availableRoutes } from "../../lib/routes";
import { fullNavigateToUrl } from "../../helpers/functions";
// import { getCurrency } from "../../helpers/storage";
import { vatText } from "../../helpers/currencyFormat";

export interface CompanyDataEditTypes {
  companyName: string;
  countryId: number;
  companyTypeId: number;
  address: string;
  vat: string;
  sellerId: string;
  sellerName: string;
  sapId: string | null;
  city: string;
  postCode: string;
}

interface PropsTypes {
  selectedCompany: FetchedCompanyTypes | null;
  onClick: (_data: CompanyDataEditTypes) => void;
  closePopup: () => void;
}

const EditCompanyPopup: FC<PropsTypes> = ({
  onClick,
  selectedCompany,
  closePopup,
}) => {
  const { getAgentId, getAgentName } = useAuth();

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const [availableCompanies, setAvailableCompanies] = useState<
    AvailableCompanyTypes[]
  >([]);

  const [availableCountries, setAvailableCountries] = useState<
    AvailableCountryTypes[]
  >([]);

  const validationSchemaEditCompany = Yup.object().shape({
    companyName: Yup.string()
      .required("Required field")
      .max(100, "Company Name can be at most 100 characters"),
    companyTypeId: Yup.string()
      .required("Please select an option")
      .oneOf(
        availableCompanies.map((option) => option.companyType),
        "Invalid option selected"
      ),
    address: Yup.string()
      .required("Required field")
      .max(100, "Address can be at most 100 characters"),
    city: Yup.string()
      .required("Required field")
      .max(50, "City can be at most 50 characters"),
    postCode: Yup.string()
      .required("Required field")
      .max(10, "Post Code can be at most 10 characters"),
    countryId: Yup.string()
      .required("Please select an option")
      .oneOf(
        availableCountries.map((option) => option.countryName),
        "Invalid option selected"
      ),
    vat: Yup.string()
      .matches(/^[0-9]*$/, `Please enter a valid ${vatText} number`)
      .max(2, `${vatText} number can be at most 2 digits`),
    sapId: Yup.string().max(10, "SAP ID can be at most 10 characters"),
  });

  // const validationSchemaEditCompanyUSA = Yup.object().shape({
  //   companyName: Yup.string()
  //     .required("Required field")
  //     .max(100, "Company Name can be at most 100 characters"),
  //   city: Yup.string()
  //     .required("Required field")
  //     .max(50, "City can be at most 50 characters"),
  //   sapId: Yup.string()
  //     .max(10, "SAP ID can be at most 10 characters")
  //     .required("This field is required."),
  //   companyTypeId: Yup.string()
  //     .nullable()
  //     .oneOf(
  //       availableCompanies.map((option) => option.companyType),
  //       "Invalid option selected"
  //     ),
  //   address: Yup.string()
  //     .nullable()
  //     .max(100, "Address can be at most 100 characters"),
  //   postCode: Yup.string()
  //     .nullable()
  //     .max(10, "Post Code can be at most 10 characters"),
  //   countryId: Yup.string()
  //     .nullable()
  //     .oneOf(
  //       availableCountries.map((option) => option.countryName),
  //       "Invalid option selected"
  //     ),
  //   vat: Yup.string()
  //     .nullable()
  //     .matches(/^[0-9]*$/, "Please enter a valid VAT number")
  //     .max(2, "VAT number can be at most 2 digits"),
  // });

  // const currency: string = getCurrency();

  const formikEditCompany = useFormik({
    initialValues: {
      companyName: selectedCompany?.companyName ?? "",
      companyTypeId: selectedCompany?.companyTypeId?.companyType ?? "",
      address: selectedCompany?.address ?? "",
      city: selectedCompany?.city ?? "",
      postCode: selectedCompany?.postCode ?? "",
      countryId: selectedCompany?.countryId.countryName ?? "",
      vat: selectedCompany?.vat ?? "",
      sapId: selectedCompany?.sapId ?? "",
    },
    validateOnBlur: true,
    validationSchema: validationSchemaEditCompany,
    // validationSchema:
    //   currency === "EUR"
    //     ? validationSchemaEditCompany
    //     : validationSchemaEditCompanyUSA,
    onSubmit: () => {
      formikEditCompany.resetForm();
    },
  });
  const getAvailableCountryTypes = () => {
    handleAuthApiCall({
      method: "get",
      url: "/v1/countries",
    });
  };

  const getAvailableCompanies = () => {
    handleAuthApiCall({
      method: "get",
      url: "/v1/companyTypes",
    });

    setTimeout(() => {
      getAvailableCountryTypes();
    }, 100);
  };

  useEffect(() => {
    getAvailableCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiData?.companyTypes || apiData?.countries) {
      const companyTypesData: AvailableCompanyTypes[] | undefined =
        apiData?.companyTypes;
      if (companyTypesData && companyTypesData.length > 0) {
        setAvailableCompanies(companyTypesData);
      }

      const availableCountriesData: AvailableCountryTypes[] | undefined =
        apiData?.countries;
      if (availableCountriesData && availableCountriesData.length > 0) {
        setAvailableCountries(availableCountriesData);
      }
    } else {
      fullNavigateToUrl(availableRoutes.SELECT_CUSTOMER);
    }
  }, [apiData]);

  const companyAvailableOptions = availableCompanies.map((company) => {
    return company?.companyType ?? "";
  });

  const countryAvailableOptions = availableCountries.map((country) => {
    return country.countryName;
  });

  const countryId =
    availableCountries.find(
      (country) => country.countryName === formikEditCompany.values.countryId
    )?.countryId ?? "";

  const companyTypeId =
    availableCompanies.find(
      (company) =>
        company?.companyType === formikEditCompany.values.companyTypeId
    )?.companyTypeId ?? "";

  const dataForSubmit: CompanyDataEditTypes = {
    sellerId: getAgentId() ?? "",
    sellerName: getAgentName() ?? "",
    vat:
      formikEditCompany.values.vat !== "" ? formikEditCompany.values.vat : "0",
    city: formikEditCompany.values.city,
    address: formikEditCompany.values.address,
    postCode: formikEditCompany.values.postCode,
    companyName: formikEditCompany.values.companyName,
    countryId: parseInt(countryId, 10),
    companyTypeId: parseInt(companyTypeId, 10),
    sapId:
      formikEditCompany.values.sapId === ""
        ? null
        : formikEditCompany.values.sapId,
  };

  const updateFormikValue = (name: string, value: string) => {
    formikEditCompany.setFieldValue(name, value);
  };

  const errors = Object.keys(formikEditCompany.errors).length > 0;

  const inputData = {
    companyName: {
      name: "companyName",
      placeholder: "Company Name",
      value: formikEditCompany.values.companyName,
      onChange: (value: string) => updateFormikValue("companyName", value),
      errorMessage: formikEditCompany.errors.companyName ?? null,
    },
    companyTypeId: {
      name: "companyTypeId",
      placeholder: "Company Type",
      value: formikEditCompany.values.companyTypeId,
      onChange: (value: string) => updateFormikValue("companyTypeId", value),
      errorMessage: formikEditCompany.errors.companyTypeId ?? null,
    },
    address: {
      name: "address",
      placeholder: "Address",
      value: formikEditCompany.values.address,
      onChange: (value: string) => updateFormikValue("address", value),
      errorMessage: formikEditCompany.errors.address ?? null,
    },
    city: {
      name: "city",
      placeholder: "City",
      value: formikEditCompany.values.city,
      onChange: (value: string) => updateFormikValue("city", value),
      errorMessage: formikEditCompany.errors.city ?? null,
    },
    postCode: {
      name: "postCode",
      placeholder: "Post Code",
      value: formikEditCompany.values.postCode,
      onChange: (value: string) => updateFormikValue("postCode", value),
      errorMessage: formikEditCompany.errors.postCode ?? null,
    },
    countryId: {
      name: "countryId",
      placeholder: "Country",
      value: formikEditCompany.values.countryId,
      onChange: (value: string) => updateFormikValue("countryId", value),
      errorMessage: formikEditCompany.errors.countryId ?? null,
    },
    vat: {
      name: "vat",
      placeholder: vatText,
      value: formikEditCompany.values.vat,
      onChange: (value: string) => updateFormikValue("vat", value),
      errorMessage: formikEditCompany.errors.vat ?? null,
    },
    sapId: {
      name: "sapId",
      placeholder: "SAP ID",
      value: formikEditCompany.values.sapId,
      onChange: (value: string) => updateFormikValue("sapId", value),
      errorMessage: formikEditCompany.errors.sapId ?? null,
    },
  };

  const handleOnClick = () => {
    if (!errors) {
      formikEditCompany.handleSubmit();
      // pass data to onClick to call service to update company
      onClick(dataForSubmit);
    }
  };

  return (
    <div className="popup-outer open">
      <div className="popup-bg" onClick={closePopup} />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="banner-title">
            Let&apos;s update this company&apos;s info
          </div>
          <div className="row box-grid-container form-client">
            <div className="col-md-6">
              <InputWithSubForm {...inputData.companyName} />
            </div>
            <div className="col-md-6">
              <DropdownSelectInput
                initialOptions={companyAvailableOptions}
                {...inputData.companyTypeId}
              />
            </div>
            <div className="col-md-12">
              <InputWithSubForm {...inputData.address} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.city} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.postCode} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.sapId} />
            </div>
            <div className="col-md-6">
              <DropdownSelectInput
                initialOptions={countryAvailableOptions}
                {...inputData.countryId}
              />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.vat} />
            </div>
          </div>
          <div className="row box-grid-container">
            <div className="col-md-12 col-right-align">
              <ButtonSubmit onClick={handleOnClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompanyPopup;
