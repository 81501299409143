/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  BatteryTypesNew,
  ChargerTypes,
  CoreFieldsTypes,
  ForkliftTypesNew,
} from "../types/basicProductsTypes";
import { CurrentQuoteTypes } from "../types/currentQuoteTree";
import { getCurrentQuote } from "../helpers/storage";

interface AppContextTypes {
  loading: boolean;
  apiError: string | null;
  dataFetchedByForkliftId: ForkliftTypesNew[] | [];
  selectedBattery: BatteryTypesNew | null;
  setSelectedBattery: (_battery: BatteryTypesNew) => void;
  selectedCharger: ChargerTypes | null;
  setSelectedCharger: (_charger: ChargerTypes) => void;

  batteryCoreFieldsArray: CoreFieldsTypes[] | [];
  setApiError: (_error: string | null) => void;
  setLoading: (_loading: boolean) => void;
  setDataFetchedByForkliftId: (_data: ForkliftTypesNew[] | []) => void;
  setBatteryCoreFieldsArray: (_data: CoreFieldsTypes[] | []) => void;

  currentQuoteUpdated: boolean;
  currentQuoteUpdatedFlag: () => void;
  resetApiError: () => void;
  currentQuote: CurrentQuoteTypes | null;
  setCurrentQuote: (_quote: CurrentQuoteTypes | null) => void;
  activeForkliftDetailsInput: string | null;
  setActiveForkliftDetailsInput: (_activeForkliftDetailsInput: string) => void;
  chargersFoundData: ChargerTypes[] | [];
  setChargersFoundData: (_chargersFoundData: ChargerTypes[] | []) => void;
  eraseSelectedBattery: () => void;
  eraseSelectedCharger: () => void;
}

const CartContext = createContext<AppContextTypes>({
  loading: false,
  apiError: null,
  dataFetchedByForkliftId: [],
  selectedBattery: null,
  setSelectedBattery: (_battery: BatteryTypesNew) => {},
  selectedCharger: null,
  setSelectedCharger: (_charger: ChargerTypes) => {},

  batteryCoreFieldsArray: [],
  setApiError: (_error: string | null) => {},
  setLoading: (_loading: boolean) => {},
  setDataFetchedByForkliftId: (_data: ForkliftTypesNew[] | []) => {},
  setBatteryCoreFieldsArray: (_data: CoreFieldsTypes[] | []) => {},

  currentQuoteUpdated: false,
  currentQuoteUpdatedFlag: () => {},
  resetApiError: () => {},
  currentQuote: null,
  setCurrentQuote: (_quote: CurrentQuoteTypes | null) => {},
  activeForkliftDetailsInput: null,
  setActiveForkliftDetailsInput: (_activeForkliftDetailsInput: string) => {},

  chargersFoundData: [],
  setChargersFoundData: (_chargersFoundData: ChargerTypes[] | []) => {},
  eraseSelectedBattery: () => {},
  eraseSelectedCharger: () => {},
});

interface CartProviderProps {
  children: ReactNode;
}

const CartProvider: FC<CartProviderProps> = ({ children }) => {
  const [currentQuote, setCurrentQuote] = useState<CurrentQuoteTypes | null>(
    getCurrentQuote()
  );

  const [dataFetchedByForkliftId, setDataFetchedByForkliftId] = useState<
    ForkliftTypesNew[] | []
  >([]);
  const [chargersFoundData, setChargersFoundData] = useState<
    ChargerTypes[] | []
  >([]);
  const [batteryCoreFieldsArray, setBatteryCoreFieldsArray] = useState<
    CoreFieldsTypes[] | []
  >([]);

  const [selectedBattery, setSelectedBatteryByUser] =
    useState<BatteryTypesNew | null>(null);

  const [selectedCharger, setSelectedCharger] = useState<ChargerTypes | null>(
    null
  );

  const eraseSelectedBattery = useCallback(() => {
    setSelectedBatteryByUser(null);
  }, []);

  const eraseSelectedCharger = useCallback(() => {
    setSelectedCharger(null);
  }, []);

  const [apiError, setApiError] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [activeForkliftDetailsInput, setActiveForkliftDetailsInput] =
    useState<string>("manufacturer");

  const resetApiError = useCallback(() => {
    setApiError(null);
  }, []);

  const [currentQuoteUpdated, setCurrentQuoteUpdated] =
    useState<boolean>(false);

  const currentQuoteUpdatedFlag = useCallback(() => {
    setCurrentQuoteUpdated(!currentQuoteUpdated);
  }, [currentQuoteUpdated]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedBattery = (battery: BatteryTypesNew): void => {
    setSelectedBatteryByUser(battery);
    const availableCharger =
      battery?.chargers && battery.chargers?.length > 0
        ? battery.chargers[0]
        : null;
    setSelectedCharger(availableCharger);
  };

  const providerValue = useMemo(() => {
    return {
      loading,
      dataFetchedByForkliftId,
      apiError,
      selectedBattery,
      setSelectedBattery,
      selectedCharger,
      setSelectedCharger,

      batteryCoreFieldsArray,
      setApiError,
      setLoading,
      setDataFetchedByForkliftId,
      setBatteryCoreFieldsArray,

      currentQuoteUpdated,
      currentQuoteUpdatedFlag,
      resetApiError,
      currentQuote,
      setCurrentQuote,
      activeForkliftDetailsInput,
      setActiveForkliftDetailsInput,
      chargersFoundData,
      setChargersFoundData,
      eraseSelectedBattery,
      eraseSelectedCharger,
    };
  }, [
    loading,
    apiError,
    dataFetchedByForkliftId,
    selectedBattery,
    setSelectedBattery,
    batteryCoreFieldsArray,

    selectedCharger,
    setApiError,
    setLoading,
    setDataFetchedByForkliftId,
    setBatteryCoreFieldsArray,

    currentQuoteUpdated,
    currentQuoteUpdatedFlag,
    resetApiError,
    currentQuote,
    setCurrentQuote,

    setActiveForkliftDetailsInput,
    activeForkliftDetailsInput,
    chargersFoundData,
    setChargersFoundData,
  ]);

  return (
    <CartContext.Provider value={providerValue}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
