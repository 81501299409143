import React, { FC } from "react";
import DropdownSelectInputChargers from "../../components/Inputs/DropdownSelectInputChargers";
import ButtonPrevious from "../../components/Buttons/ButtonPrevious";
import ButtonGreenSearch from "../../components/Buttons/ButtonGreenSearch";
import {
  ChargersOptionsDataType,
  inputDataThreeType,
} from "../../types/chargers";

interface StepThreePropsTypes {
  inputDataStep: inputDataThreeType;
  dataChargersStep: ChargersOptionsDataType;
  isLithiumText: string | null;
  activeStep: number;
  changeStep: (_activeStep: 1 | 2 | 3) => void;
  handleSubmit: () => void;
  errorsExist: boolean;
}
const StepThree: FC<StepThreePropsTypes> = ({
  inputDataStep,
  dataChargersStep,
  isLithiumText,
  activeStep,
  changeStep,
  handleSubmit,
  errorsExist,
}) => {
  return (
    <>
      <div className="find-charger-step find-charger-step-3">
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.chargingCurveArray}
          {...inputDataStep.curve}
        />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.chargingProfileArray}
          {...inputDataStep.timeprofile}
        />
      </div>
      {isLithiumText !== "Lithium-ion" && activeStep === 3 && (
        <div className="buttons-step-3">
          <div className="row box-grid-container">
            <div className="col-md-6">
              <div className="col-left-align">
                {!errorsExist ? (
                  <ButtonPrevious
                    title="Previous"
                    onClick={() => changeStep(2)}
                  />
                ) : (
                  <ButtonPrevious
                    title="Previous"
                    onClick={() => changeStep(2)}
                    disabled
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-right-align">
                {!errorsExist ? (
                  <ButtonGreenSearch onClick={handleSubmit} />
                ) : (
                  <ButtonGreenSearch onClick={handleSubmit} disabled />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StepThree;
