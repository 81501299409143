interface AvailableRoutesTypes {
  HOME: string;
  CURRENT_QUOTE: string;
  ALL_QUOTES: string;
  QUOTE_ITEM_BY_ID: string;
  QUOTES_BY_FORKLIFT_ID: string;
  SEARCH_BATTERY: string;
  SELECT_BATTERY_BY_FORKLIFT: string;
  SELECT_CHARGER_BY_FORKLIFT: string;
  SELECT_CUSTOMER: string;
  CHOOSE_JOURNEY: string;
  START_JOURNEY_BY_FORKLIFT: string;
  ADD_NEW_FORKLIFT_TO_EXISTING_FLEET: string;
  START_JOURNEY_BY_BATTERY: string;
  START_JOURNEY_BY_CHARGER: string;
  ADD_NEW_COMPANY: string;
  CHARGERS_FOUND: string;

  LOGIN: string;
  REGISTER: string;
}

export const availableRoutes: AvailableRoutesTypes = {
  HOME: "/",

  CURRENT_QUOTE: "/currentQuote",

  ALL_QUOTES: "/allQuotes",
  QUOTE_ITEM_BY_ID: "/existingQuoteByQuoteId",
  QUOTES_BY_FORKLIFT_ID: "/quotesByForkliftId",

  SEARCH_BATTERY: "/searchBattery",
  SELECT_BATTERY_BY_FORKLIFT: "/selectBatteryByForklift",
  SELECT_CHARGER_BY_FORKLIFT: "/selectChargerByForklift",

  CHARGERS_FOUND: "/chargersFound",

  SELECT_CUSTOMER: "/selectCustomer",

  CHOOSE_JOURNEY: "/chooseJourney",
  START_JOURNEY_BY_FORKLIFT: "/startJourneyByForklift",
  START_JOURNEY_BY_BATTERY: "/startJourneyByBattery",
  START_JOURNEY_BY_CHARGER: "/startJourneyByCharger",
  ADD_NEW_FORKLIFT_TO_EXISTING_FLEET: "/addNewForkliftToExistingFleet",
  ADD_NEW_COMPANY: "/addNewCompany",

  LOGIN: "/login",
  REGISTER: "/register",
};
