import React, { FC } from "react";
import {
  BannerRightTableTypes,
  BannerRightTopTypes,
} from "../../../types/bannersTypes";
import { ReplacementBatteriesTypes } from "../../../types/basicProductsTypes";
import ProductReplacesInfoButton from "../../Buttons/ProductReplacesInfoButton";
import RightBannerTable from "./RightBannerTable";
import RightBannerTopChargers from "./RightBannerTopChargers";
import ButtonNextGreen from "../../Buttons/ButtonNextGreen";

interface RightBannerProps {
  rightBannerTopValues: BannerRightTopTypes | null;
  rightBannerTableValues: BannerRightTableTypes[];
  replacementsArray?: ReplacementBatteriesTypes[];
  addItemToQuote: () => void;
  navigateToNextStep: () => void;
}

const RightBannerChargers: FC<RightBannerProps> = ({
  replacementsArray,
  rightBannerTopValues,
  rightBannerTableValues,
  addItemToQuote,
  navigateToNextStep,
}) => {
  const onClickNext = () => {
    navigateToNextStep();
  };

  return (
    <div className="col-md-6" data-testid="right-banner">
      <div className="product-details-outer">
        {replacementsArray && replacementsArray?.length > 0 && (
          <ProductReplacesInfoButton replacementsArray={replacementsArray} />
        )}

        <div className="product-details-inner">
          {rightBannerTopValues && (
            <RightBannerTopChargers
              rightBannerTopValues={rightBannerTopValues}
              addItemToQuote={addItemToQuote}
            />
          )}
          {rightBannerTableValues?.length > 0 && (
            <RightBannerTable rightBannerTableValues={rightBannerTableValues} />
          )}
        </div>
      </div>

      <div className="row box-grid-container">
        <div className="col-md-12 pt-2 col-right-align mb-4">
          <ButtonNextGreen title="Finalize Quote" onClick={onClickNext} />
        </div>
      </div>
    </div>
  );
};

export default RightBannerChargers;
