/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC, useContext, useEffect } from "react";
import ButtonDelete from "../../../../Buttons/ButtonDelete";
import CurrentQuoteQuantityInput from "../QuoteItemComponents/CurrentQuoteQuantityInput";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../../hooks/UseFormatPrice";
import QuoteItemDescription from "../QuoteItemComponents/Description";
import InitialPrice from "../QuoteItemComponents/InitialPrice";
import DiscountPriceWithToggle from "../QuoteItemComponents/DiscountPriceWithToggle";
import FinalPriceWithToggle from "../QuoteItemComponents/FinalPriceWithToggle";
import { QuoteItemChargerTypes } from "../../../../../types/currentQuoteTree";

import { getCurrency, useQuoteUpdate } from "../../../../../helpers/storage";

import {
  alertHasAccessoriesMessage,
  confirmDeleteChargerMessage,
} from "../../../../../settings/confirmAlertMessages";
import { checkIfDiscountIsGreaterThanPrice } from "../../../../../helpers/functions";
import { ModalContext } from "../../../../../context/ModalContext";
// import AlertPopup from "../../../../Popups/AlertPopup";
// import ConfirmPopup from "../../../../Popups/ConfirmPopup";
import AlertPopupOpen from "../../../../Popups/AlertPopupOpen";
import ConfirmPopupOpen from "../../../../Popups/ConfirmPopupOpen";

interface QuoteItemChargerInBatteryPropsTypes {
  index: number;
  quoteItem: QuoteItemChargerTypes;
  finalPriceEnabled: boolean;
}

const QuoteItemChargerInBattery: FC<QuoteItemChargerInBatteryPropsTypes> = ({
  index,
  quoteItem,
  finalPriceEnabled,
}) => {
  const {
    openAlert,
    showAlert,
    alertMsg,
    openConfirm,
    showConfirm,
    confirmMsg,
  } = useContext(ModalContext);
  const { getFormattedPriceFromObj } = useFormatPrice();

  const {
    changeSelectedFinalPriceChargerInBattery,
    deleteChargerInBattery,
    updateChargerInBatteryQuantity,

    updateDiscountValueChangeChargerInBattery,
  } = useQuoteUpdate();

  const currency: string = getCurrency();

  const {
    name,
    imageUrl,
    itemPrice,
    totalPrice,
    quantity,
    manualAddedPrice,
    itemType,
    discountType,
    discountPercentage,
    discountPrice,
    discountIncrease,
    discountTotal,
  } = quoteItem;

  const [displayAlertCharger, setDisplayAlertCharger] =
    React.useState<boolean>(false);

  const [displayConfirmCharger, setDisplayConfirmChargers] =
    React.useState<boolean>(false);

  const initialPriceObj: FormattedMoneyTypes = formatMoney({
    amount: itemPrice,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  const handleChangeDiscountValue = (value: number) => {
    if (itemType === "charger") {
      updateDiscountValueChangeChargerInBattery({
        index,
        value,
      });
    }
  };

  const changeSelectedFinalPrice = (value: number) => {
    changeSelectedFinalPriceChargerInBattery({
      index,
      value,
    });
  };

  // helpers
  const handleChargerDelete = () => {
    if (quoteItem?.accessories?.length > 0) {
      setDisplayAlertCharger(true);
      openAlert(alertHasAccessoriesMessage);
      return;
    }
    setDisplayConfirmChargers(true);
    openConfirm(confirmDeleteChargerMessage);
  };

  const updateQuantity = (
    updateType: "increase" | "decrease",
    newQuantity = 1
  ) => {
    if (updateType === "decrease" && quantity - newQuantity < 1) return;

    if (finalPriceEnabled) return;

    updateChargerInBatteryQuantity({
      index,
      updateType,
      quantity: newQuantity,
    });
  };

  const handleManualQuantityChange = (manualQuantity: number): void => {
    if (finalPriceEnabled) return;

    updateChargerInBatteryQuantity({
      index,
      updateType: "manual",
      quantity: manualQuantity,
    });
  };

  const [discountErrorMessage, setDiscountErrorMessage] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    setDiscountErrorMessage(
      checkIfDiscountIsGreaterThanPrice({
        totalPrice,
      })
    );
  }, [discountTotal, totalPrice, discountIncrease]);

  const handleCancelConfirm = () => {
    setDisplayConfirmChargers(false);
  };

  const handleCloseAlert = () => {
    setDisplayAlertCharger(false);
  };

  const completeChargerDelete = () => {
    deleteChargerInBattery(index);
    setDisplayConfirmChargers(false);
  };

  return (
    <>
      <div
        className="cart-item-row cart-item-level-3"
        style={{ display: "block" }}
      >
        <div className="cart-item-row-inner row">
          <QuoteItemDescription productTitle={name} imageUrl={imageUrl} />

          <InitialPrice
            formattedPrice={getFormattedPriceFromObj(initialPriceObj)}
          />

          <CurrentQuoteQuantityInput
            quantity={quantity}
            onIncrement={() => updateQuantity("increase")}
            onDecrement={() => updateQuantity("decrease")}
            handleManualQuantityChange={handleManualQuantityChange}
          />

          <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-discount">
            {!finalPriceEnabled && (
              <DiscountPriceWithToggle
                itemType="chargerInBattery"
                index={index}
                discountType={discountType}
                discountIncrease={discountIncrease}
                discountPercentage={discountPercentage}
                discountPrice={discountPrice}
                handleChangeDiscountValue={handleChangeDiscountValue}
                errorMessage={discountErrorMessage}
              />
            )}
          </div>

          <FinalPriceWithToggle
            index={index}
            totalPrice={totalPrice}
            manualAddedPrice={manualAddedPrice}
            changeSelectedFinalPrice={changeSelectedFinalPrice}
          />

          <ButtonDelete onClick={handleChargerDelete} />
        </div>
        {/* {showAlert && alertMsg && alertMsg === alertHasAccessoriesMessage && (
          <AlertPopup title={alertMsg} />
        )} */}

        {/* {showConfirm &&
          confirmMsg &&
          confirmMsg === confirmDeleteChargerMessage && (
            <ConfirmPopup
              title={confirmMsg}
              onConfirm={() => deleteChargerInBattery(index)}
            />
          )} */}

        {showAlert &&
          displayAlertCharger &&
          alertMsg &&
          alertMsg === confirmDeleteChargerMessage && (
            <AlertPopupOpen
              title={alertMsg}
              onClose={handleCloseAlert}
              open={displayAlertCharger}
            />
          )}

        {showConfirm &&
          displayConfirmCharger &&
          confirmMsg &&
          confirmMsg === confirmDeleteChargerMessage && (
            <ConfirmPopupOpen
              title={confirmMsg}
              onConfirm={completeChargerDelete}
              open={displayConfirmCharger}
              onCancel={handleCancelConfirm}
            />
          )}
      </div>
    </>
  );
};

export default QuoteItemChargerInBattery;
//
