import React, { FC, useEffect, useState } from "react";
import { getCurrency } from "../../helpers/storage";

interface SelectInputChargersPropsTypes {
  initialOptions: string[];
  placeholder: string;
  onChange: (_option: string) => void;
  errorMessage: string | null;
  value: string | number | null;
  name: string;
  dependencyCountry?: string | number | null;
  isCountrySelector?: boolean;
}

const DropdownSelectInputChargers: FC<SelectInputChargersPropsTypes> = ({
  initialOptions,
  placeholder,
  onChange,
  errorMessage,
  value,
  name,
  dependencyCountry,
  isCountrySelector,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [depends, setDepends] = useState(false);
  const [availableOptions, setAvailableOptions] =
    useState<string[]>(initialOptions);

  const filterOptions = () => {
    if (typeof inputValue === "string" && inputValue !== null) {
      setAvailableOptions(
        initialOptions?.filter((option: string) =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputOpen(false);
    }, 200);
  };

  const handleFocus = () => {
    if (inputValue === "") {
      setAvailableOptions(initialOptions);
    }
    if (dependencyCountry === null || dependencyCountry === "") {
      setDepends(true);
    } else {
      setIsInputOpen(true);
      setDepends(false);
    }
  };

  const handleFocousOut = () => {
    setDepends(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (event.target.value === "") {
      onChange("");
    }
  };

  const [countryChanged, setCountryChanged] = useState(false);

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsInputOpen(false);
    setInputValue(option);

    if (!countryChanged) setCountryChanged(true);
  };

  useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (initialOptions !== undefined) setAvailableOptions(initialOptions);
  }, [initialOptions]);

  const currency = getCurrency();

  const checkIfCountryIsUSA = () => {
    if (
      currency === "USA" &&
      initialOptions?.length > 0 &&
      (value === "" || value === null)
    ) {
      const textUSA = "United States of America (USA)";
      if (initialOptions.includes(textUSA)) {
        handleOptionClick(textUSA);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setInputValue(value.toString());
    } else if (isCountrySelector && !countryChanged) checkIfCountryIsUSA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCountrySelector && !countryChanged) checkIfCountryIsUSA();
  }, [initialOptions]);

  return (
    <div
      className={`col-form-item form-dropdown-list ${
        isInputOpen ? "open" : ""
      }`}
      onBlur={handleBlur}
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleFocousOut}
        autoComplete="off"
      />
      <div className="icon symmetry-icon-arrow-up" />
      {isInputOpen && (
        <div
          className="form-dropdown-list-container"
          style={{ display: "block" }}
        >
          {availableOptions.length > 0 && (
            <ul>
              {availableOptions.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {depends && (
        <div className="country-required-field">
          The country input is empty. Fill this field first.
        </div>
      )}
      {errorMessage && !depends && (
        <div className="error-msg">{errorMessage}</div>
      )}
    </div>
  );
};

export default DropdownSelectInputChargers;
