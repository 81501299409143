import React, { FC, useEffect } from "react";

import { Button, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableColumnType,
  TableHeader,
} from "react-bs-datatable";

import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import TopBanner from "../../components/Banners/TopBanner";
import { storageVariables } from "../../lib/storageVariables";

import { TABLE_TYPES } from "../../types/quotesSchema";
import { fullNavigateToUrl } from "../../helpers/functions";
import { availableRoutes } from "../../lib/routes";
import formatMoney from "../../helpers/formatMoney";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import { getCurrency } from "../../helpers/storage";
import { formatDateToUSA } from "../../helpers/formatDate";
import { vatText } from "../../helpers/currencyFormat";

export type ArrayElementType = (typeof TABLE_TYPES)[number] & {
  button: any;
};

const getHeadersWithFormattedDate = (currency: string) => {
  const STORY_HEADERS: TableColumnType<ArrayElementType>[] = [
    {
      prop: "quoteId",
      title: "OFFER NUMBER",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "dateCreated",
      title: "DATE CREATED",
      isSortable: true,
      cell(row) {
        if (row.dateCreated) {
          if (currency === "EUR") {
            return <span>{row.dateCreated}</span>;
          }
          return <span>{formatDateToUSA(row.dateCreated)}</span>;
        }
        return <></>;
      },
    },
    {
      prop: "dateExpired",
      title: "DATE EXPIRED",
      isSortable: true,
      cell(row) {
        if (row.dateExpired) {
          if (currency === "EUR") {
            return <span>{row.dateExpired}</span>;
          }
          return <span>{formatDateToUSA(row.dateExpired)}</span>;
        }
        return <></>;
      },
    },
    {
      prop: "expired",
      title: "EXPIRED",
      isSortable: true,
      cell(row) {
        if (row.expired)
          return <span className="d-flex justify-content-center">Yes</span>;
        return <span className="d-flex justify-content-center">No</span>;
      },
    },
    {
      prop: "companyName",
      title: "COMPANY",
      isSortable: true,
    },
    {
      prop: "contactPersonFirstName",
      title: "FIRST NAME",
      isSortable: true,
    },
    {
      prop: "contactPersonLastName",
      title: "LAST NAME",
      isSortable: true,
    },
    {
      prop: "totalItemsPrice",
      title: `AMOUNT EXCL. ${vatText}`,
      cell(row) {
        return (
          <span className="d-flex justify-content-end">
            {row.totalItemsPrice}
          </span>
        );
      },
    },
    {
      prop: "quoteNotes",
      title: "SALES REP. NOTES",
    },
    {
      prop: "button",
      cell: (row) => (
        <Button
          role="button"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            localStorage.setItem(
              storageVariables.SELECTED_QUOTE_ID,
              JSON.stringify(row.quoteId)
            );

            fullNavigateToUrl(availableRoutes.QUOTE_ITEM_BY_ID);
          }}
        >
          Manage
          <div className="btn-icon">
            <div className="icon symmetry-icon-arrow-right" />
          </div>
        </Button>
      ),
    },
  ];

  return STORY_HEADERS;
};

const AllQuotesPage: FC = () => {
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const [fetchedData, setFetchedData] = React.useState<ArrayElementType[]>([]);

  const currency = getCurrency();

  const handleFetchedData = (data: ArrayElementType[]) => {
    const newData: ArrayElementType[] = data.map((item) => {
      const formattedPriceObj = formatMoney({
        amount: item.totalItemsPrice,
        country: currency,
        showMinusSign: true,
      });

      return {
        ...item,
        totalItemsPrice: formattedPriceObj.error
          ? "0.00"
          : `${formattedPriceObj.currencySymbol}${formattedPriceObj.money}`,
      };
    });

    setFetchedData(newData);
  };

  useEffect(() => {
    localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);

    handleAuthApiCall({
      method: "get",
      url: `/v1/quotes`,
      isSearchRequest: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiData?.quotes && apiData?.quotes.length > 0) {
      handleFetchedData(apiData.quotes as ArrayElementType[]);
    } else {
      setFetchedData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  return (
    <MainPageWrapper title="All Quotes" testid="all-Quotes">
      <style>{`body{background: #fff;}`}</style>
      <TopBanner goBack />

      {fetchedData.length > 0 ? (
        <>
          <div className="col-md-6 mobile-center">
            <div className="banner-title">Quote History</div>
          </div>
          <div className="overflow-x-scroll">
            <DatatableWrapper
              body={fetchedData}
              headers={getHeadersWithFormattedDate(currency)}
              sortProps={{
                initialState: {
                  order: "desc",
                  prop: "quoteId",
                },
              }}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: process.env
                    .REACT_APP_PAGINATION_ALL_QUOTES_MAX_VISIBLE_PAGES_NUMBER
                    ? parseInt(
                        process.env
                          .REACT_APP_PAGINATION_ALL_QUOTES_MAX_VISIBLE_PAGES_NUMBER,
                        10
                      )
                    : 10,
                  options: [5, 10],
                },
              }}
            >
              <style>{`body{background: #fff;}`}</style>
              <div className="row box-grid-container">
                {/* <div className="col-md-6 mobile-center">
                <div className="banner-title">Quote History</div>
              </div> */}
                <div className="datatable-search mobile-start">
                  <Filter placeholder="Quick filter by Offer Number" />
                </div>
              </div>
              <Table>
                <TableHeader />
                <TableBody />
              </Table>

              <Row className="mb-12 d-flex flex-col justify-content-center">
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-center"
                >
                  <Pagination
                    paginationRange={
                      process.env.REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER
                        ? parseInt(
                            process.env
                              .REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER,
                            10
                          )
                        : 5
                    }
                  />
                </Col>
              </Row>
            </DatatableWrapper>
          </div>
        </>
      ) : (
        <div className="row box-grid-container">
          <div className="col-md-12">
            <div className="banner-title">Quote History is empty</div>
          </div>
        </div>
      )}
    </MainPageWrapper>
  );
};

export default AllQuotesPage;
