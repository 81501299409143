import React, { FC } from "react";
import { numberValidationRegex } from "../../../../../config/validationRegex";

interface QuantityInputPropsTypes {
  quantity: number;
  level3?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
  handleManualQuantityChange?: (_value: number) => void;
}

const QuantityInput: FC<QuantityInputPropsTypes> = ({
  quantity,
  level3 = false,
  onIncrement,
  onDecrement,
  handleManualQuantityChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!handleManualQuantityChange) return;

    const value = parseInt(e.target.value, 10);

    if (!numberValidationRegex.test(String(value))) return;

    if (value > 0) {
      handleManualQuantityChange(value);
    }
  };

  return (
    <div className="col-md-1 col-sm-1 cart-item-col cart-item-quantity">
      <div className="cart-item-top">
        {!level3 && <div className="cart-item-quantity-label">QUANTITY</div>}
      </div>
      <div className="cart-item-bottom">
        <div className="cart-item-quantity-outer">
          <div className="cart-item-quantity-inner">
            <div
              className="cart-quantity-btn corner-btn-left cart-item-quantity-minus"
              onClick={onDecrement}
              data-html2canvas-ignore
            >
              <div className="icon symmetry-icon-arrow-down" />
            </div>
            <input
              className="cart-item-quantity-number-input"
              type="number"
              value={quantity}
              onChange={handleInputChange}
            />

            <div
              className="cart-quantity-btn corner-btn-right cart-item-quantity-plus"
              onClick={onIncrement}
              data-html2canvas-ignore
            >
              <div className="icon symmetry-icon-arrow-up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantityInput;
