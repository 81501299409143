import React, { createContext, useMemo, useState } from "react";

interface AppContextInterface {
  showAlert: boolean;
  showConfirm: boolean;
  alertMsg?: string;
  confirmMsg?: string;
  openAlert: (_msg: string) => void;
  closeAlert: () => void;
  openConfirm: (_msg: string) => void;
  closeConfirm: () => void;
}

interface PropsTypes {
  children: React.ReactNode;
}

const ModalContext = createContext<AppContextInterface>({
  showAlert: false,
  showConfirm: false,
  alertMsg: "",
  confirmMsg: "",
  openAlert: (_msg: string) => {},
  closeAlert: () => {},
  openConfirm: (_msg: string) => {},
  closeConfirm: () => {},
});

const ModalProvider: React.FC<PropsTypes> = ({ children }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [alertMsg, setAlertMsg] = useState<string>("");

  const [confirmMsg, setConfirmMsg] = useState<string>("");

  const openAlert = (_msg: string) => {
    setAlertMsg(_msg);
    setShowAlert(true);
  };
  const closeAlert = () => {
    setAlertMsg("");
    setShowAlert(false);
  };

  const openConfirm = (_msg: string) => {
    setConfirmMsg(_msg);
    setShowConfirm(true);
  };
  const closeConfirm = () => {
    setConfirmMsg("");
    setShowConfirm(false);
  };

  const providerValue = useMemo(() => {
    return {
      showAlert,
      showConfirm,
      alertMsg,
      confirmMsg,
      openAlert,
      closeAlert,
      openConfirm,
      closeConfirm,
    };
  }, [showAlert, showConfirm, alertMsg, confirmMsg]);

  return (
    <ModalContext.Provider value={providerValue}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
