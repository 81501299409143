export const regexEUR = /^\d*(,\d{0,2})?$/;
export const regexUSD = /^\d*\.?\d{0,2}$/;

interface CurrencyRegexTypes {
  EUR: RegExp;
  USD: RegExp;
}

export const currencyRegex: CurrencyRegexTypes = {
  EUR: regexEUR,
  USD: regexUSD,
};
