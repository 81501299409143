import React, { FC, useContext, useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { storageVariables } from "../../lib/storageVariables";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import TopBanner from "../../components/Banners/TopBanner";
import {
  CurrentQuoteTypes,
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../../types/currentQuoteTree";

import ExistingQuoteFooter from "../../components/Quote/ExistingQuotePageItems/ExistingQuoteFooter/ExistingQuoteFooter";
import ExistingQuoteHeader from "../../components/Quote/ExistingQuotePageItems/ExistingQuoteHeader";
import ExistingQuoteItemBlock from "../../components/Quote/ExistingQuotePageItems/ExistingQuoteItem/ExistingQuoteItemBlock";
import { getCurrentQuote, fixNumber } from "../../helpers/storage";
import {
  alertAddItemsBeforePdf,
  alertQuoteDuplicated,
  alertQuoteIsReadyForEdit,
  confirmDeleteQuoteMessage,
  confirmEditQuoteMessage,
} from "../../settings/confirmAlertMessages";
import { fullNavigateToUrl, getCurrentDate } from "../../helpers/functions";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import {
  FetchedCompanyTypes,
  FetchedContactPersonsTypes,
} from "../../types/companies";
import { availableRoutes } from "../../lib/routes";
import logopdf from "../../images/logo_pdf.jpg";
import { ModalContext } from "../../context/ModalContext";
import AlertPopup from "../../components/Popups/AlertPopup";
import ConfirmPopup from "../../components/Popups/ConfirmPopup";

interface CompanyDataTypes {
  companyId: number;
  contactPersonId: number;
}

const ExistingQuoteByIdPage: FC = () => {
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const {
    openAlert,
    showAlert,
    alertMsg,
    showConfirm,
    openConfirm,
    confirmMsg,
  } = useContext(ModalContext);

  const [fetchedQuote, setFetchedQuote] = useState<CurrentQuoteTypes | null>(
    null
  );

  const [companyData, setCompanyData] = useState<CompanyDataTypes | null>(null);

  const [apiReason, setApiReason] = useState<string | null>(null);
  const getCurrentDateFormat = (): string => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: number | string = today.getMonth() + 1; // Months start at 0!
    let dd: number | string = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}${mm}${yyyy}`;
  };

  const makePdf = async () => {
    const logo: any = document.getElementById("logo_pdf");
    logo.classList.add("showlogo");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF("p", "pt", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const pageStartBorder = 30;
    const spaceLeft = 80;
    const pdfContentDiv = document.getElementById("pdf_content");

    if (!pdfContentDiv) {
      openAlert(alertAddItemsBeforePdf);
      return;
    }

    const cartItemDivs: HTMLCollectionOf<Element> =
      pdfContentDiv.getElementsByClassName("cart-item-outer");

    if (cartItemDivs.length === 0) {
      openAlert(alertAddItemsBeforePdf);
      return;
    }

    let currentY = pageStartBorder;

    const widthL = 300;
    const heightL = 107;
    const canvasL = await html2canvas(logo);
    const imageDataL = canvasL.toDataURL("image/jpeg");

    // Add Logo to PDF
    pdf.addImage(
      imageDataL,
      "JPEG",
      spaceLeft / 2,
      currentY,
      (pdfWidth - spaceLeft) / 5,
      (((pdfWidth - spaceLeft) / 5) * heightL) / widthL
    );
    currentY =
      currentY + (((pdfWidth - spaceLeft) / 5) * heightL) / widthL + 10;

    const header: HTMLElement | null = document.getElementById("header_pdf");
    if (!header) return;

    const viewPort = document.querySelector("meta[name=viewport]");

    if (viewPort) {
      viewPort.setAttribute("content", "width=1220");
    }

    setTimeout(async () => {
      const { width: headerWidth, height: headerHeight } =
        header.getBoundingClientRect();

      // eslint-disable-next-line no-await-in-loop
      const canvasH = await html2canvas(header);
      const imageDataH = canvasH.toDataURL("image/jpeg");

      // Add Header to PDF
      pdf.addImage(
        imageDataH,
        "JPEG",
        spaceLeft / 2,
        currentY,
        pdfWidth - spaceLeft,
        ((pdfWidth - spaceLeft) * headerHeight) / headerWidth
      );
      currentY =
        currentY + ((pdfWidth - spaceLeft) * headerHeight) / headerWidth + 10;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < cartItemDivs.length; i++) {
        const cartItemDiv: Element = cartItemDivs[i];

        const { width, height } = cartItemDiv.getBoundingClientRect();

        if (
          currentY + ((pdfWidth - spaceLeft) * height) / width >
          pdfHeight - 20
        ) {
          pdf.addPage();
          currentY = pageStartBorder;
        }

        // eslint-disable-next-line no-await-in-loop
        const canvas = await html2canvas(cartItemDiv as HTMLCanvasElement);

        const imageData = canvas.toDataURL("image/jpeg");

        // Add Cart Block to PDF
        pdf.addImage(
          imageData,
          "JPEG",
          spaceLeft / 2,
          currentY,
          pdfWidth - spaceLeft,
          ((pdfWidth - spaceLeft) * height) / width
        );
        // currentY += height + 1;
        currentY = currentY + ((pdfWidth - spaceLeft) * height) / width + 5;
      }

      const footer: any = document.getElementById("footer_pdf");
      const { width, height } = footer.getBoundingClientRect();
      // eslint-disable-next-line no-await-in-loop
      const canvasF = await html2canvas(footer);
      const imageDataF = canvasF.toDataURL("image/jpeg");

      // If there is space to add footer to current page then add it else add new page
      if (
        currentY + ((pdfWidth - spaceLeft) * height) / width >
        pdfHeight - 20
      ) {
        pdf.addPage();
        currentY = pageStartBorder;
      }

      pdf.addImage(
        imageDataF,
        "JPEG",
        spaceLeft / 2,
        currentY,
        pdfWidth - spaceLeft,
        ((pdfWidth - spaceLeft) * height) / width
      );

      const totalPages = pdf.getNumberOfPages();

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= totalPages; i++) {
        // pdf.addPage();
        pdf.setFontSize(8);
        pdf.setPage(i);
        pdf.text(`Page ${i} of ${totalPages}`, pdfWidth - 80, pdfHeight - 20); // Add the page number at the desired position
        // Add your content for each page
      }
      const currentDate = getCurrentDateFormat();

      const pdfQuoteId = fetchedQuote?.quoteId;
      const sellerName = fetchedQuote?.salesPersonName.replace(" ", "");
      const contPersonFirstName =
        fetchedQuote?.customer?.contactPersonFirstName ?? "";
      const contPersonLastName =
        fetchedQuote?.customer?.contactPersonLastName ?? "";

      const documentName = `${contPersonFirstName}${contPersonLastName}_${pdfQuoteId}_${sellerName}_${currentDate}.pdf`;

      pdf.save(documentName);

      logo.classList.remove("showlogo");

      if (viewPort) {
        viewPort.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0"
        );
      }
    }, 1000);
  };

  const getContactpersonId = (contactPersons: FetchedContactPersonsTypes[]) => {
    // find the companyData.contactPersonId id from the contact persons array
    const activePerson = contactPersons.find(
      (person) => person.contactPersonId === companyData?.contactPersonId
    );

    return (
      activePerson?.contactPersonId ??
      contactPersons[contactPersons.length - 1].contactPersonId
    );
  };

  const finishQuoteDuplication = (selectedCompany: FetchedCompanyTypes) => {
    if (!fetchedQuote || !selectedCompany) return;

    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);

    setTimeout(() => {
      const newCompanyToSave: FetchedCompanyTypes = {
        ...selectedCompany,
        contactPersons: selectedCompany.contactPersons,
        activePersonId: getContactpersonId(selectedCompany.contactPersons),
      };

      localStorage.setItem(
        storageVariables.SELECTED_COMPANY,
        JSON.stringify(newCompanyToSave)
      );

      // createNewQuote(newCompanyToSave);
    }, 50);

    const tempVat = selectedCompany?.vat ? Number(selectedCompany?.vat) : 0;

    const newQuote: CurrentQuoteTypes = {
      ...fetchedQuote,
      dateModified: getCurrentDate(),
      dateCreated: getCurrentDate(),
      dateSubmitted: "",
      status: "S",
      vat: tempVat,
      totalPriceInclVat:
        fixNumber(
          fetchedQuote.totalPriceExclVat +
            (fetchedQuote.totalPriceExclVat * tempVat) / 100
        ) || 0,
    };

    localStorage.setItem(
      storageVariables.CURRENT_QUOTE,
      JSON.stringify(newQuote)
    );

    openAlert(alertQuoteDuplicated);
  };

  // DUPLICATE QUOTE
  const completeDuplicateQuote = () => {
    setApiReason("duplicateQuote");

    const quoteCompanyData: CompanyDataTypes = {
      companyId: fetchedQuote?.companyId ?? 0,
      contactPersonId: fetchedQuote?.contactPersonId ?? 0,
    };

    localStorage.setItem(storageVariables.DUPLICATE_QUOTE, "true");
    localStorage.removeItem(storageVariables.EDIT_QUOTE);

    setCompanyData(quoteCompanyData);
  };

  const duplicateQuote = () => {
    if (!fetchedQuote) return;

    const currentQuote: CurrentQuoteTypes | null = getCurrentQuote();

    if (
      currentQuote &&
      currentQuote?.quoteItems &&
      currentQuote.quoteItems.length > 0
    ) {
      openConfirm(confirmDeleteQuoteMessage);
      return;
    }
    completeDuplicateQuote();
  };

  // EDIT QUOTE
  const finishEditQuote = () => {
    const quoteCompanyData: CompanyDataTypes = {
      companyId: fetchedQuote?.companyId ?? 0,
      contactPersonId: fetchedQuote?.contactPersonId ?? 0,
    };

    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.DUPLICATE_QUOTE);

    setCompanyData(quoteCompanyData);
    setApiReason("editQuote");
  };

  const editQuote = () => {
    if (!fetchedQuote) return;

    const currentQuote: CurrentQuoteTypes | null = getCurrentQuote();

    if (currentQuote?.quoteItems && currentQuote.quoteItems.length > 0) {
      openConfirm(confirmEditQuoteMessage);
      return;
    }

    finishEditQuote();
  };

  const finalizeQuoteEdit = (selectedCompany: FetchedCompanyTypes) => {
    if (!fetchedQuote || !selectedCompany) return;

    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);

    setTimeout(() => {
      const newCompanyToSave: FetchedCompanyTypes = {
        ...selectedCompany,
        contactPersons: selectedCompany.contactPersons,
        activePersonId: fetchedQuote.contactPersonId,
      };

      localStorage.setItem(
        storageVariables.SELECTED_COMPANY,
        JSON.stringify(newCompanyToSave)
      );

      // createNewQuote(newCompanyToSave);
    }, 50);

    const tempVat = selectedCompany?.vat ? Number(selectedCompany?.vat) : 0;

    const editedQuote: CurrentQuoteTypes = {
      ...fetchedQuote,
      dateModified: getCurrentDate(),
      dateSubmitted: "",
      vat: tempVat,
      totalPriceInclVat:
        fixNumber(
          fetchedQuote.totalPriceExclVat +
            (fetchedQuote.totalPriceExclVat * tempVat) / 100
        ) || 0,
    };

    localStorage.setItem(
      storageVariables.CURRENT_QUOTE,
      JSON.stringify(editedQuote)
    );

    localStorage.setItem(storageVariables.EDIT_QUOTE, "true");

    openAlert(alertQuoteIsReadyForEdit);
  };

  useEffect(() => {
    if (!companyData) return;

    handleAuthApiCall({
      method: "get",
      url: `/v1/companies/${companyData?.companyId ?? 0}`,
    });

    setCompanyData(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useEffect(() => {
    const savedQuoteIdStr: string | null = localStorage.getItem(
      storageVariables.SELECTED_QUOTE_ID
    );

    if (!savedQuoteIdStr) return;

    handleAuthApiCall({
      method: "get",
      url: `/v2/quotes/${JSON.parse(savedQuoteIdStr)}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiReason === "duplicateQuote") {
      setApiReason(null);
      if (!apiData?.company) return;

      finishQuoteDuplication(apiData?.company);
      return;
    }

    if (apiReason === "editQuote") {
      setApiReason(null);
      if (!apiData?.company) return;

      finalizeQuoteEdit(apiData?.company);
      return;
    }

    setFetchedQuote(apiData?.quote ?? null);

    setTimeout(() => {
      async function fetchImages(imageUrls: any) {
        const dataUrls = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const url of imageUrls) {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(url);
          // eslint-disable-next-line no-await-in-loop
          const blob = await response.blob();
          // eslint-disable-next-line no-await-in-loop
          const dataUrl = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          dataUrls.push(dataUrl);
        }
        return dataUrls;
      }

      function convertImages() {
        const allImages = document.getElementsByClassName("image-class");
        const imageUrls = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < allImages.length; index++) {
          const tempSrc = allImages[index].getAttribute("src");
          imageUrls[index] = tempSrc;
        }

        // Add more image URLs as needed

        fetchImages(imageUrls)
          .then((dataUrls: any) => {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < dataUrls.length; index++) {
              document
                .getElementsByClassName("image-class")
                [index].setAttribute("src", dataUrls[index]);
            }
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          });
      }
      convertImages();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  return (
    <div>
      <style>{`body{background: #fff;}`}</style>
      <MainPageWrapper title="Current Quote" testid="current-quote-page">
        <TopBanner goBack />

        {fetchedQuote && (
          <div id="pdf_content">
            <div id="header_pdf">
              <ExistingQuoteHeader
                duplicateQuote={duplicateQuote}
                makePdf={makePdf}
                editQuote={editQuote}
                fetchedQuote={fetchedQuote}
                quoteNumber={
                  fetchedQuote?.quoteId ? fetchedQuote.quoteId.toString() : ""
                }
              />
            </div>
            <div className="cart-container">
              {fetchedQuote?.quoteItems?.length > 0 &&
                fetchedQuote.quoteItems.map(
                  (
                    quoteItem:
                      | QuoteItemForkliftTypes
                      | QuoteItemBatteryTypes
                      | QuoteItemChargerTypes,
                    index: number
                  ) => (
                    <ExistingQuoteItemBlock key={index} quoteItem={quoteItem} />
                  )
                )}
            </div>
            <div id="footer_pdf">
              <ExistingQuoteFooter
                repNotes={fetchedQuote.quoteNotes}
                currentQuote={fetchedQuote}
              />
              <div className="bg_pdf" id="bg_pdf" />
            </div>
            <div className="hidden_div">
              <div>
                <img
                  title="Sunlight logo pdf"
                  alt="Sunlight logo pdf"
                  src={logopdf}
                  className="logo-header-pdf image-class"
                  id="logo_pdf"
                />
              </div>
            </div>
          </div>
        )}
        <div className="pb-5" />
      </MainPageWrapper>

      {showAlert && alertMsg && alertMsg === alertQuoteIsReadyForEdit && (
        <AlertPopup
          title={alertMsg}
          additionalFn={() => fullNavigateToUrl(availableRoutes.CURRENT_QUOTE)}
        />
      )}

      {showAlert && alertMsg && alertMsg === alertQuoteDuplicated && (
        <AlertPopup
          title={alertMsg}
          additionalFn={() => window.location.reload()}
        />
      )}

      {showConfirm &&
        confirmMsg &&
        confirmMsg === confirmDeleteQuoteMessage && (
          <ConfirmPopup title={confirmMsg} onConfirm={completeDuplicateQuote} />
        )}
      {showConfirm && confirmMsg && confirmMsg === confirmEditQuoteMessage && (
        <ConfirmPopup title={confirmMsg} onConfirm={finishEditQuote} />
      )}
    </div>
  );
};

export default ExistingQuoteByIdPage;
