/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from "react";
import { CurrentQuoteTypes } from "../../../../types/currentQuoteTree";
import formatMoney from "../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../../helpers/storage";
import { vatText } from "../../../../helpers/currencyFormat";

interface FooterDetailsPropTypes {
  repNotes: string;
  setRepNotes: (_value: string) => void;

  currentQuote: CurrentQuoteTypes | null;
}
const CurrentQuoteFooterDetails: FC<FooterDetailsPropTypes> = ({
  repNotes,
  setRepNotes,
  currentQuote,
}) => {
  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const vat = currentQuote?.vat ?? 0;
  const totalPriceExclVat = currentQuote?.totalPriceExclVat ?? 0;
  const totalPriceInclVat = currentQuote?.totalPriceInclVat ?? 0;

  const totalPriceExclVatObj = formatMoney({
    amount: totalPriceExclVat || 0,
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });
  const totalPriceInclVatObj = formatMoney({
    amount: totalPriceInclVat.toString(),
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });

  const maxNotesLength = 500;

  return (
    <div className="row box-grid-container cart-bottom-container">
      <div className="col-md-7 notes-outer">
        <div className="notes-inner">
          <div className="d-flex box-grid-container justify-content-between ">
            <label htmlFor={currentQuote?.dateCreated || "currentQuote"}>
              SALES REP. NOTES
            </label>
            {repNotes.length > 0 && (
              <div className="cart-header-text p-0 m-0">
                {repNotes.length}/{maxNotesLength}
              </div>
            )}
          </div>
          <textarea
            id={currentQuote?.dateCreated || "currentQuote"}
            placeholder="Tell us all about it here..."
            value={repNotes}
            onChange={(e) => setRepNotes(e.target.value)}
            maxLength={maxNotesLength}
          />
        </div>
      </div>
      <div className="col-md-5">
        <div className="brief-cart-inner">
          <div className="brief-row brief-cart-price">
            <span className="brief-left">{`TOTAL EXCL. ${vatText}`}</span>

            <span className="brief-right cart-item-price-span">
              {getFormattedPriceFromObj(totalPriceExclVatObj)}
            </span>
          </div>
          <div className="brief-row brief-cart-vat">
            <span className="brief-left">{vatText}</span>
            <span className="brief-right vat">{vat}%</span>
          </div>
          <div className="brief-cart-divider" />
          <div className="brief-row brief-cart-total-price">
            <span className="brief-left" />

            <span className="brief-right cart-item-price-span">
              {getFormattedPriceFromObj(totalPriceInclVatObj)}
            </span>
          </div>
          <div className="brief-cart-divider" />
        </div>
      </div>
    </div>
  );
};

export default CurrentQuoteFooterDetails;
