import React, { FC } from "react";
import { BannerRightTopTypes } from "../../../types/bannersTypes";
import ButtonProductAddToQuote from "../../Buttons/ButtonProductAddToQuote";
import formatMoney from "../../../helpers/formatMoney";
import { useFormatPrice } from "../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../helpers/storage";
import ImageWithFallback from "../../Media/ImageWithFallback";
import QuantityInput from "../../Inputs/QuantityInput";

interface RightBannerTopPropsTypes {
  rightBannerTopValues: BannerRightTopTypes;
  addItemToQuote: () => void;
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
  setQuantity: (_value: number) => void;
}

const RightBannerTop: FC<RightBannerTopPropsTypes> = ({
  rightBannerTopValues,
  addItemToQuote,
  quantity,
  onIncrement,
  onDecrement,
  setQuantity,
}) => {
  const { title, imageUrl, price } = rightBannerTopValues;

  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const priceObj = formatMoney({
    amount: price || 0,
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });

  return (
    <div className="product-details-top">
      <div className="product-details-top-left">
        <ImageWithFallback type="charger" imageUrl={imageUrl} />
      </div>
      <div className="product-details-top-right">
        <div className="product-details-title">{title}</div>
        <QuantityInput
          quantity={quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          handleManualQuantityChange={setQuantity}
        />

        <div className="product-details-price-outer">
          <div className="product-details-price">
            {getFormattedPriceFromObj(priceObj)}
          </div>

          <ButtonProductAddToQuote onClick={addItemToQuote} />
        </div>
      </div>
    </div>
  );
};

export default RightBannerTop;
