import React, { FC, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { DiscountTypesEnum } from "../../../../../config/discountTypes";
import {
  currentDecimalSeparator,
  currentGroupSeparator,
  getCurrencySymbol,
} from "../../../../../helpers/currencyFormat";
import {
  getFloatFromString,
  getStringFromFloat,
} from "../../../../../helpers/parsers";
import { getCurrency } from "../../../../../helpers/storage";

interface PropsTypes {
  index: number;
  itemType: string;

  discountType: DiscountTypesEnum;

  discountIncrease: boolean;
  discountPercentage: number | null;
  discountPrice: number;
  errorMessage: string | null;

  toggleDiscountType?: () => void;
  toggleDiscountIncrease?: () => void;
  handleChangeDiscountValue: (_value: number) => void;
}

const DiscountPriceWithToggle: FC<PropsTypes> = ({
  index,
  itemType,
  discountType,
  discountIncrease,
  discountPercentage,
  discountPrice,
  errorMessage,

  toggleDiscountType,
  toggleDiscountIncrease,
  handleChangeDiscountValue,
}) => {
  const discountIsPercentage: boolean =
    discountType === DiscountTypesEnum.PERCENTAGE;

  const zeroValue = `0${currentDecimalSeparator}00`;

  const [currentValue, setValue] = React.useState<string>(zeroValue);

  const handleValueChange = (value: string) => {
    setValue(value);

    handleChangeDiscountValue(getFloatFromString(value));
  };

  const currency = getCurrency();
  const currencyStyle = currency === "EUR" ? "euro-format" : "usd-format";

  useEffect(() => {
    setValue(
      discountType === DiscountTypesEnum.PERCENTAGE
        ? discountPercentage?.toString() || zeroValue
        : getStringFromFloat(discountPrice)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(
      discountType === DiscountTypesEnum.PERCENTAGE
        ? discountPercentage?.toString() || zeroValue
        : getStringFromFloat(discountPrice)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountType, discountPrice, discountPercentage, discountIncrease]);

  return (
    <>
      {toggleDiscountType && toggleDiscountIncrease && (
        <div className="cart-item-top" data-html2canvas-ignore>
          <div className="cart-item-discount-label">
            <div className="custom-toggle-outer" onClick={toggleDiscountType}>
              <div
                className={`custom-toggle-inner active-${
                  !discountIsPercentage ? "left" : "right"
                }`}
              >
                <div className="custom-toggle custom-toggle-left">
                  {getCurrencySymbol()}&nbsp;
                </div>
                <div className="custom-toggle custom-toggle-center">
                  {!discountIncrease ? "DISCOUNT" : "INCREASE"}
                </div>
                <div className="custom-toggle custom-toggle-right">&nbsp;%</div>
                <div className="custom-toggle custom-toggle-ball" />
              </div>
            </div>

            <label
              htmlFor={`sliderInput${itemType}${index}`}
              className="switch"
            >
              <input
                id={`sliderInput${itemType}${index}`}
                onChange={toggleDiscountIncrease}
                type="checkbox"
                checked={discountIncrease}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      )}

      <div
        className={`cart-item-bottom ${
          !discountIsPercentage ? currencyStyle : "after-percent"
        } `}
        data-html2canvas-ignore
      >
        <div className="cart-item-discount-number">
          <CurrencyInput
            style={{ border: errorMessage ? "1px solid red" : "" }}
            allowNegativeValue={false}
            decimalsLimit={2}
            decimalScale={2}
            disableAbbreviations
            value={currentValue}
            decimalSeparator={currentDecimalSeparator}
            groupSeparator={currentGroupSeparator}
            onValueChange={(value) => handleValueChange(value || zeroValue)}
          />
        </div>
      </div>
      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </>
  );
};

export default DiscountPriceWithToggle;
