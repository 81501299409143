/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC } from "react";
import ImageWithFallback from "../../../../Media/ImageWithFallback";

interface ExistingDescriptionPropsTypes {
  imageUrl: string | null;
  productTitle: string;
  level3?: boolean;
}

const ExistingDescription: FC<ExistingDescriptionPropsTypes> = ({
  imageUrl,
  productTitle,
  level3 = false,
}) => {
  return (
    <div className="col-lg-5 col-sm-5 cart-item-col cart-item-description">
      {!level3 && (
        <div className="cart-item-top">
          <div className="cart-item-description-label">DESCRIPTION</div>
        </div>
      )}
      <div className="cart-item-bottom">
        <div className="cart-item-description-image">
          <ImageWithFallback type="charger" imageUrl={imageUrl} />
        </div>

        <div className="cart-item-description-title">{productTitle}</div>
      </div>
    </div>
  );
};

export default ExistingDescription;
