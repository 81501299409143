/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";

interface PropsTypes {
  displaySearchForm: boolean;
  onClick: () => void;
}

const ButtonShowHide: FC<PropsTypes> = ({ displaySearchForm, onClick }) => {
  return (
    <div
      role="button"
      className={`icon-near-title ${displaySearchForm ? "eye-closed" : ""}`}
      onClick={onClick}
    >
      <div className="icon symmetry-icon-eye-open" />
      <div className="icon symmetry-icon-eye-closed" />
    </div>
  );
};

export default ButtonShowHide;
