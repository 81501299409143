import React, { FC, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";

import CurrentQuoteHeader from "../../components/Quote/CurrentQuotePageItems/CurrentQuoteHeader";
import CurrentQuoteFooter from "../../components/Quote/CurrentQuotePageItems/CurrentQuoteFooter";
import CurrentQuoteItemBlock from "../../components/Quote/CurrentQuotePageItems/CurrentQuoteItem/CurrentQuoteItemBlock";
import { CartContext } from "../../context/CartContext";

import {
  CurrentQuoteTypes,
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../../types/currentQuoteTree";
import { storageVariables } from "../../lib/storageVariables";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";

import {
  currentQuoteHasNegativeFinalPrices,
  getCurrentDate,
} from "../../helpers/functions";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import {
  getCurrency,
  getCurrentQuote,
  useQuoteUpdate,
} from "../../helpers/storage";
import logopdf from "../../images/logo_pdf.jpg";
import {
  alertAddItemsBeforePdf,
  alertAddItemsBeforeSave,
  alertFinalPricesZero,
  alertHasChargerMessage,
} from "../../settings/confirmAlertMessages";
import AlertPopup from "../../components/Popups/AlertPopup";
import { ModalContext } from "../../context/ModalContext";
import {
  createDateObjectFromCustomDateString,
  getDateFromDateObject,
  getDateWithOffsetDays,
} from "../../helpers/dateParse";

const CurrentQuotePage: FC = () => {
  const { accounts } = useMsal();
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const { openAlert, showAlert, alertMsg } = useContext(ModalContext);

  const { currentQuote, apiError, setApiError, setCurrentQuote } =
    useContext(CartContext);

  const { updateQuoteExpirationDate } = useQuoteUpdate();
  const { fullNavigateToUrl } = useNavigation();

  const [repNotes, setRepNotes] = React.useState<string>(
    currentQuote?.quoteNotes || ""
  );

  const [expirationDate, setExpirationDate] = useState<Date>(
    getDateWithOffsetDays(30)
  );

  const makePdf = async () => {
    const logo: any = document.getElementById("logo_pdf");
    logo.classList.add("showlogo");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF("p", "pt", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const pageStartBorder = 30;
    const spaceLeft = 80;
    const pdfContentDiv = document.getElementById("pdf_content");

    if (!pdfContentDiv) {
      openAlert(alertAddItemsBeforePdf);
      return;
    }

    const cartItemDivs: HTMLCollectionOf<Element> =
      pdfContentDiv.getElementsByClassName("cart-item-outer");

    if (cartItemDivs.length === 0) {
      openAlert(alertAddItemsBeforePdf);
      return;
    }

    // Temp css to remove borders from inputs
    pdfContentDiv.classList.add("pdf_is_generating");

    let currentY = pageStartBorder;

    const viewPort = document.querySelector("meta[name=viewport]");

    if (viewPort) {
      viewPort.setAttribute("content", "width=1200");
    }

    setTimeout(async () => {
      const widthL = 300;
      const heightL = 107;
      const canvasL = await html2canvas(logo);

      const imageDataL = canvasL.toDataURL("image/jpeg");

      // Add Logo to PDF
      pdf.addImage(
        imageDataL,
        "JPEG",
        spaceLeft / 2,
        currentY,
        (pdfWidth - spaceLeft) / 5,
        (((pdfWidth - spaceLeft) / 5) * heightL) / widthL
      );
      currentY =
        currentY + (((pdfWidth - spaceLeft) / 5) * heightL) / widthL + 10;

      const header: HTMLElement | null = document.getElementById("header_pdf");

      if (!header) return;

      const { width: headerWidth, height: headerHeight } =
        header.getBoundingClientRect();

      // eslint-disable-next-line no-await-in-loop
      const canvasH = await html2canvas(header);
      const imageDataH = canvasH.toDataURL("image/jpeg");

      // Add Header to PDF
      pdf.addImage(
        imageDataH,
        "JPEG",
        spaceLeft / 2,
        currentY,
        pdfWidth - spaceLeft,
        ((pdfWidth - spaceLeft) * headerHeight) / headerWidth
      );
      currentY =
        currentY + ((pdfWidth - spaceLeft) * headerHeight) / headerWidth + 10;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < cartItemDivs.length; i++) {
        const cartItemDiv: Element = cartItemDivs[i];

        const { width, height } = cartItemDiv.getBoundingClientRect();

        if (
          currentY + ((pdfWidth - spaceLeft) * height) / width >
          pdfHeight - 20
        ) {
          pdf.addPage();
          currentY = pageStartBorder;
        }

        // eslint-disable-next-line no-await-in-loop
        const canvas = await html2canvas(cartItemDiv as HTMLCanvasElement);

        const imageData = canvas.toDataURL("image/jpeg");

        // Add Cart Block to PDF
        pdf.addImage(
          imageData,
          "JPEG",
          spaceLeft / 2,
          currentY,
          pdfWidth - spaceLeft,
          ((pdfWidth - spaceLeft) * height) / width
        );

        currentY = currentY + ((pdfWidth - spaceLeft) * height) / width + 5;
      }

      const footer: any = document.getElementById("footer_pdf");
      const { width, height } = footer.getBoundingClientRect();

      // eslint-disable-next-line no-await-in-loop
      const canvasF = await html2canvas(footer);
      const imageDataF = canvasF.toDataURL("image/jpeg");

      // If there is space to add footer to current page then add it else add new page
      if (
        currentY + ((pdfWidth - spaceLeft) * height) / width >
        pdfHeight - 20
      ) {
        pdf.addPage();
        currentY = pageStartBorder;
      }

      pdf.addImage(
        imageDataF,
        "JPEG",
        spaceLeft / 2,
        currentY,
        pdfWidth - spaceLeft,
        ((pdfWidth - spaceLeft) * height) / width
      );

      const totalPages = pdf.getNumberOfPages();

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= totalPages; i++) {
        pdf.setFontSize(8);
        pdf.setPage(i);
        pdf.text(`Page ${i} of ${totalPages}`, pdfWidth - 80, pdfHeight - 20); // Add the page number at the desired position
        // Add your content for each page
      }

      pdf.save("draft.pdf");

      pdfContentDiv.classList.remove("pdf_is_generating");
      logo.classList.remove("showlogo");

      if (viewPort) {
        viewPort.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0"
        );
      }
    }, 500);
  };

  const handleRepNotesChange = (value: string) => {
    setRepNotes(value);

    // update current quote in local storage
    if (currentQuote) {
      currentQuote.quoteNotes = value;
      localStorage.setItem(
        storageVariables.CURRENT_QUOTE,
        JSON.stringify(currentQuote)
      );
    }
  };

  const quoteSubmittedSuccessfully = () => {
    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.DUPLICATE_QUOTE);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_COMPANY);

    fullNavigateToUrl(availableRoutes.HOME);
  };

  const isQuoteFromEditJourney: string | null = localStorage.getItem(
    storageVariables.EDIT_QUOTE
  );

  const saveCurrentQuote = () => {
    if (apiError) setApiError(null);

    if (!currentQuote) return;

    if (currentQuote?.quoteItems?.length === 0) {
      openAlert(alertAddItemsBeforeSave);
      return;
    }

    if (currentQuoteHasNegativeFinalPrices(currentQuote)) {
      openAlert(alertFinalPricesZero);
      return;
    }

    const selectedCompanyStr: string | null = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );

    if (!selectedCompanyStr || !currentQuote) return;

    currentQuote.quoteNotes = repNotes;
    currentQuote.dateSubmitted = getCurrentDate();

    if (isQuoteFromEditJourney) {
      handleAuthApiCall({
        method: "put",
        url: `/v2/quotes/${currentQuote?.quoteId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: currentQuote,
      });

      localStorage.removeItem(storageVariables.EDIT_QUOTE);
    } else {
      currentQuote.createdBy = accounts[0]?.localAccountId || "";
      currentQuote.salesPersonName = accounts[0]?.name || "";
      localStorage.removeItem(storageVariables.DUPLICATE_QUOTE);

      handleAuthApiCall({
        method: "post",
        url: "/v2/quotes",
        headers: {
          "Content-Type": "application/json",
        },
        data: currentQuote,
      });
    }
  };

  useEffect(() => {
    const tempCurrentQuote: CurrentQuoteTypes | null = getCurrentQuote();
    if (tempCurrentQuote) {
      setCurrentQuote(tempCurrentQuote);

      const existingDateExpired = tempCurrentQuote.dateExpired;

      if (existingDateExpired && existingDateExpired.length > 0) {
        const rebuildDateObj: Date =
          createDateObjectFromCustomDateString(existingDateExpired);

        if (rebuildDateObj) {
          setExpirationDate(rebuildDateObj);
        }
      }
    }

    setTimeout(() => {
      async function fetchImages(imageUrls: any) {
        const dataUrls = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const url of imageUrls) {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(url);
          // eslint-disable-next-line no-await-in-loop
          const blob = await response.blob();
          // eslint-disable-next-line no-await-in-loop
          const dataUrl = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          dataUrls.push(dataUrl);
        }
        return dataUrls;
      }

      function convertImages() {
        const allImages = document.getElementsByClassName("image-class");
        const imageUrls = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < allImages.length; index++) {
          const tempSrc = allImages[index].getAttribute("src");
          imageUrls[index] = tempSrc;
        }

        // Add more image URLs as needed

        fetchImages(imageUrls)
          .then((dataUrls: any) => {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < dataUrls.length; index++) {
              document
                .getElementsByClassName("image-class")
                [index].setAttribute("src", dataUrls[index]);
            }
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          });
      }
      convertImages();
    }, 500);

    return () => {
      localStorage.removeItem(storageVariables.DUPLICATE_QUOTE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    quoteSubmittedSuccessfully();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  const currency = getCurrency();

  const currentDateFormat =
    currency === "USA" ? "MM/dd/yyyy, HH:mm" : "dd/MM/yyyy, HH:mm";

  const handleExpirationDateChange = (date: Date) => {
    // save only date without time
    const newDate = new Date(date);

    setExpirationDate(newDate);

    const expiresDateString: string = getDateFromDateObject(newDate);

    updateQuoteExpirationDate(expiresDateString);
  };

  return (
    <div>
      <style>{`body{background: #fff;}`}</style>
      <MainPageWrapper title="Current Quote" testid="current-quote-page">
        <TopBanner goBack />

        <div id="pdf_content">
          <div id="header_pdf">
            <CurrentQuoteHeader
              handleSave={saveCurrentQuote}
              handlePrintPDF={makePdf}
              quoteId={
                !!isQuoteFromEditJourney && currentQuote?.quoteId
                  ? currentQuote?.quoteId
                  : undefined
              }
            />
          </div>

          <div className="col-sm-12 col-right-align m-0 p-0">
            <div className="d-flex justify-content-end align-items-center mb-5">
              <span className="me-2 ">Expiration Date:</span>
              <div>
                <DatePicker
                  selected={expirationDate}
                  dateFormat={currentDateFormat}
                  minDate={getDateWithOffsetDays(1)}
                  timeInputLabel="Time:"
                  showTimeInput
                  onChange={(date: Date) => handleExpirationDateChange(date)}
                />
              </div>
            </div>
          </div>

          <div className="cart-container">
            {currentQuote &&
              currentQuote?.quoteItems?.length > 0 &&
              currentQuote.quoteItems.map(
                (
                  quoteItem:
                    | QuoteItemForkliftTypes
                    | QuoteItemBatteryTypes
                    | QuoteItemChargerTypes,
                  index: number
                ) => (
                  <CurrentQuoteItemBlock
                    key={index}
                    index={index}
                    quoteItem={quoteItem}
                  />
                )
              )}
          </div>

          <div id="footer_pdf">
            <CurrentQuoteFooter
              repNotes={repNotes}
              setRepNotes={handleRepNotesChange}
              currentQuote={currentQuote}
            />
            <div className="bg_pdf" id="bg_pdf" />
          </div>
          <div className="hidden_div">
            <div>
              <img
                title="Sunlight logo pdf"
                alt="Sunlight logo pdf"
                src={logopdf}
                className="logo-header-pdf image-class"
                id="logo_pdf"
              />
            </div>
          </div>
          <div className="pb-5" />
        </div>
      </MainPageWrapper>

      {showAlert && alertMsg && alertMsg === alertAddItemsBeforeSave && (
        <AlertPopup title={alertMsg} />
      )}
      {showAlert && alertMsg && alertMsg === alertFinalPricesZero && (
        <AlertPopup title={alertMsg} />
      )}
      {showAlert && alertMsg && alertMsg === alertAddItemsBeforePdf && (
        <AlertPopup title={alertMsg} />
      )}
      {showAlert && alertMsg && alertMsg === alertHasChargerMessage && (
        <AlertPopup title={alertMsg} />
      )}
    </div>
  );
};

export default CurrentQuotePage;
