import React, { FC } from "react";

const NoResultsFoundBanner: FC = () => {
  return (
    <div className="title-form-text bg-white text-center p-5 mt-2">
      <p className="p-3">Oops, we could not find what you were looking for.</p>
      <p className="p-3">Try Searching for something else.</p>
    </div>
  );
};

export default NoResultsFoundBanner;
