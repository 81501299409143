import React, { FC } from "react";
import ButtonNext from "../../../components/Buttons/ButtonNext";
import InputWithSubForm from "../../../components/Inputs/InputWithSubForm";
import DropdownSelectInput from "../../../components/Inputs/DropdownSelectInput";

import {
  AvailableCompanyTypes,
  AvailableCountryTypes,
  inputNewClientDataTypes,
} from "../../../types/companies";
// import { getCurrency } from "../../../helpers/storage";

type CompanyFormPropsTypes = {
  inputData: inputNewClientDataTypes;
  availableCountries: AvailableCountryTypes[];
  availableCompanies: AvailableCompanyTypes[];
  onClick: () => void;
};

export const CompanyForm: FC<CompanyFormPropsTypes> = ({
  inputData,
  availableCountries,
  availableCompanies,
  onClick,
}) => {
  const companyAvailableOptions = availableCompanies.map((company) => {
    return company.companyType;
  });

  const countryAvailableOptions = availableCountries.map((country) => {
    return country.countryName;
  });

  // const currency: string = getCurrency();

  return (
    <div className="row box-grid-container form-client">
      <div className="col-md-6">
        <InputWithSubForm {...inputData.companyName} />
      </div>
      <div className="col-md-6">
        <DropdownSelectInput
          initialOptions={companyAvailableOptions}
          {...inputData.companyTypeId}
        />
      </div>
      <div className="col-md-12">
        <InputWithSubForm {...inputData.address} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.city} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.postCode} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.sapId} />
      </div>
      <div className="col-md-6">
        <DropdownSelectInput
          initialOptions={countryAvailableOptions}
          {...inputData.countryId}
          // countrySelector={currency === "USA"}
        />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.vat} />
      </div>
      <div className="col-md-12">
        <div className="col-right-align">
          <ButtonNext onClick={onClick} />
        </div>
      </div>
    </div>
  );
};
