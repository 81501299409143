import React, { FC } from "react";

interface ButtonHeaderAllQuotesPropsTypes {
  title: string;
  onClick?: () => void;
  icon?: string;
}

const ButtonHeaderAllQuotes: FC<ButtonHeaderAllQuotesPropsTypes> = ({
  onClick,
  title,
  icon,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div
      className="white-btn underline-hover-btn"
      onClick={handleClick}
      data-html2canvas-ignore
    >
      <div className="btn-text me-2">{title}</div>
      <div className="btn-icon">
        {icon === "step-up-down" && (
          <div className="icon symmetry-icon-step-up-down" />
        )}
        {icon === "bill" && <div className="icon symmetry-icon-bill" />}
        {icon === "document-edit" && (
          <div className="icon symmetry-icon-document-edit" />
        )}
      </div>
    </div>
  );
};

export default ButtonHeaderAllQuotes;
