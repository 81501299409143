import React, { FC } from "react";
import InputWithSubForm from "../../Inputs/InputWithSubForm";
import DropdownSelectInputWithoutState from "../../Inputs/DropdownSelectInputWithoutState";
import ButtonSearch from "../../Buttons/ButtonSearch";
import { InputDataTypes } from "../../../types/batterySearch";
import { OptionsTypes } from "../../../pages/Battery/SearchBatteryPage";

interface PropsTypes {
  inputData: InputDataTypes;
  displayErrorBanner: boolean;
  handleSearch: () => void;
  availableOptions: OptionsTypes;
}

const BatterySearchForm: FC<PropsTypes> = ({
  inputData,
  displayErrorBanner,
  handleSearch,
  availableOptions,
}) => {
  const {
    chemistryAvailableOptions,
    batteryTypeAvailableOptions,
    batteryDinAvailableOptions,
    batteryColorsAvailableOptions,
  } = availableOptions;

  return (
    <div className="col-form-outer">
      {displayErrorBanner && (
        <div className="header-form-error">
          Please take another look something is not quite right
        </div>
      )}
      <div className="col-form-inner">
        <InputWithSubForm {...inputData.batteryId} />
        <InputWithSubForm {...inputData.sapId} />

        <DropdownSelectInputWithoutState
          initialOptions={chemistryAvailableOptions}
          {...inputData.chemistry}
        />

        <DropdownSelectInputWithoutState
          initialOptions={batteryTypeAvailableOptions}
          {...inputData.batteryType}
        />

        <InputWithSubForm {...inputData.voltage} />
        <InputWithSubForm {...inputData.capacity} />

        <InputWithSubForm {...inputData.length} />
        <InputWithSubForm {...inputData.width} />
        <InputWithSubForm {...inputData.height} />

        <DropdownSelectInputWithoutState
          initialOptions={batteryDinAvailableOptions}
          {...inputData.din}
        />
        <InputWithSubForm {...inputData.drawingNumber} />
        <InputWithSubForm {...inputData.leadAcidBatteryTrayCode} />

        <DropdownSelectInputWithoutState
          initialOptions={batteryColorsAvailableOptions}
          {...inputData.color}
        />

        <InputWithSubForm {...inputData.nominalSuggestedWeight} />
        <InputWithSubForm {...inputData.minimumWeight} />
      </div>
      <div className="col-form-inner justify-content-end">
        <ButtonSearch onClick={handleSearch} />
      </div>
    </div>
  );
};

export default BatterySearchForm;
