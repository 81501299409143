//  const isLocalDev = process.env.REACT_APP_ENV === "development";

/**
 *
 * enable for local DEVELOPMENT
 *
 */
// const isLocalDev = true;
// const isUat = false;
// const isProd = false;

/**
 *
 * enable for UAT
 *
 */
// const isLocalDev = false;
// const isUat = true;
// const isProd = false;

/**
 *
 * enable for PRODUCTION
 *
 */
const isLocalDev = false;
const isUat = false;
const isProd = true;

export interface EnvironmentTypes {
  isLocalDev: boolean;
  isUat: boolean;
  isProd: boolean;
}

export const getCurrentEnv = () => {
  const currentEnvironment: EnvironmentTypes = {
    isLocalDev,
    isUat,
    isProd,
  };

  return currentEnvironment;
};
