import React, { FC, useState } from "react";

interface SelectInputPropsTypes {
  initialOptions: string[];
  placeholder: string;
  onChange: (_option: string) => void;
  errorMessage: string | null;
  value: string;
  name: string;
}

const DropdownSelectInputWithoutState: FC<SelectInputPropsTypes> = ({
  initialOptions,
  placeholder,
  onChange,
  errorMessage,
  value,
  name,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    value !== "" ? value : ""
  );

  const [isInputOpen, setIsInputOpen] = useState(false);

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputOpen(false);
    }, 200);
  };

  const handleFocus = () => {
    setIsInputOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValueB = event.target.value;
    onChange(inputValueB);

    setInputValue(inputValueB);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsInputOpen(false);
    setInputValue(option);
  };

  return (
    <div
      className={`col-form-item form-dropdown-list ${
        isInputOpen ? "open" : ""
      }`}
      onBlur={handleBlur}
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={value ?? inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        className="py-3"
      />
      <div className="icon symmetry-icon-arrow-up" />
      {isInputOpen && (
        <div
          className="form-dropdown-list-container"
          style={{ display: "block" }}
        >
          {initialOptions !== undefined && initialOptions.length > 0 && (
            <ul>
              {initialOptions.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </div>
  );
};

export default DropdownSelectInputWithoutState;
