import React, { FC, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import LeftBannerBodyChargers from "../../components/Banners/LeftBanner/LeftBannerChargers/LeftBannerBodyChargers";

import LeftBannerTopChargers from "../../components/Banners/LeftBanner/LeftBannerChargers/LeftBannerTopChargers";
import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import { CartContext } from "../../context/CartContext";

import {
  getBannerRightTableValuesForChargers,
  getLeftBannerTopValuesForChargers,
  getRightBannerTopValuesForChargers,
} from "../../helpers/parsers";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import {
  LeftBannerTopValuesForChargersTypes,
  BannerRightTableTypes,
  BannerRightTopTypes,
} from "../../types/bannersTypes";
import {
  BatteryTypesNew,
  ChargerTypes,
  ForkliftTypesNew,
} from "../../types/basicProductsTypes";

import {
  getForkliftFetchedData,
  getSelectedBattery,
  getSelectedCharger,
  hasAvailableBatteriesForSelect,
  useQuoteUpdate,
} from "../../helpers/storage";
import { storageVariables } from "../../lib/storageVariables";
import RightBannerChargersByForklift from "../../components/Banners/RightBanner/RightBannerChargersByForklift";

const SelectChargerByForkliftPage: FC = () => {
  const { addChargerToQuoteByForklift } = useQuoteUpdate();
  const { apiError, selectedCharger, setSelectedCharger } =
    useContext(CartContext);

  const { navigateTo } = useNavigation();

  const navigateToNextStep = () => {
    navigateTo(availableRoutes.CURRENT_QUOTE);
  };

  const [dataFetchedByForkliftId, setDataFetchedByForkliftId] = useState<
    ForkliftTypesNew[]
  >([]);
  const [selectedBattery, setSelectedBattery] =
    useState<BatteryTypesNew | null>(null);

  const [savedForkliftData] = useLocalStorage<ForkliftTypesNew[] | null>(
    storageVariables.SELECTED_FORKLIFT_FETCHED_DATA,
    null
  );

  const [savedBattery] = useLocalStorage<BatteryTypesNew | null>(
    storageVariables.SELECTED_BATTERY,
    null
  );
  const [savedCharger] = useLocalStorage<ChargerTypes | null>(
    storageVariables.SELECTED_CHARGER,
    null
  );

  const hasAvailableBatteries: boolean = hasAvailableBatteriesForSelect();

  // Right Banner Values
  const rightBannerTopValues: BannerRightTopTypes =
    getRightBannerTopValuesForChargers(selectedCharger);

  const chargerCoreFieldsArrayStr = localStorage.getItem(
    storageVariables.CORE_FIELDS_ARRAY_CHARGER
  );
  const chargerCoreFieldsArray = chargerCoreFieldsArrayStr
    ? JSON.parse(chargerCoreFieldsArrayStr)
    : [];

  const rightBannerTableValues: BannerRightTableTypes[] =
    getBannerRightTableValuesForChargers(
      chargerCoreFieldsArray,
      selectedCharger
    );

  // Left Banner TOP Values
  const leftBannerTopValuesForChargers: LeftBannerTopValuesForChargersTypes | null =
    getLeftBannerTopValuesForChargers(dataFetchedByForkliftId, selectedBattery);

  //  Left Banner Bottom Values

  const [quantity, setQuantity] = useState<number>(1);

  const onIncrement = () => {
    setQuantity(quantity + 1);
  };

  const onDecrement = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleAddChargerToQuote = () => {
    if (dataFetchedByForkliftId?.length > 0 && selectedCharger) {
      addChargerToQuoteByForklift(selectedCharger, quantity);
      setQuantity(1);
    }
  };

  useEffect(() => {
    const forkliftFetchedData: ForkliftTypesNew[] | null =
      getForkliftFetchedData();

    if (forkliftFetchedData) {
      setDataFetchedByForkliftId(forkliftFetchedData);

      const battery: BatteryTypesNew | null = getSelectedBattery();
      if (battery) {
        setSelectedBattery(battery);

        const charger: ChargerTypes | null = getSelectedCharger();

        if (charger) {
          setSelectedCharger(charger);
        }
      }
    }
  }, []);

  useEffect(() => {
    setSelectedBattery(savedBattery);
  }, [savedBattery]);

  useEffect(() => {
    if (savedForkliftData) {
      setDataFetchedByForkliftId(savedForkliftData);
    }
  }, [savedForkliftData]);

  useEffect(() => {
    if (savedCharger) {
      setSelectedCharger(savedCharger);
    }
  }, [savedCharger]);

  return (
    <MainPageWrapper title="Charger Select" testid="battery-journey-page">
      <TopBanner goBack startFromBeginning />

      {!apiError &&
        (hasAvailableBatteries ? (
          <>
            <div className="">
              <div className="row box-grid-container">
                <div className="col-md-6" data-testid="left-banner">
                  <LeftBannerTopChargers
                    leftBannerTopValuesForChargers={
                      leftBannerTopValuesForChargers
                    }
                  />
                  <LeftBannerBodyChargers
                    availableChargersForPurchase={
                      selectedBattery?.chargers || []
                    }
                  />
                </div>

                {selectedBattery &&
                  selectedBattery?.chargers &&
                  selectedBattery?.chargers.length > 0 && (
                    <RightBannerChargersByForklift
                      rightBannerTopValues={rightBannerTopValues}
                      rightBannerTableValues={rightBannerTableValues}
                      addItemToQuote={handleAddChargerToQuote}
                      navigateToNextStep={navigateToNextStep}
                      onIncrement={onIncrement}
                      onDecrement={onDecrement}
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  )}
              </div>
            </div>
          </>
        ) : (
          <div className="">
            <div className="row box-grid-container">
              <div className="banner-left-outer">
                <div className="banner-left-inner">
                  <div className="banner-title">
                    No compatible chargers found
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </MainPageWrapper>
  );
};
export default SelectChargerByForkliftPage;
