import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import TopBannerCustomBack from "../../components/Banners/TopBanner/TopBannerCustomBack";
import { availableRoutes } from "../../lib/routes";

import {
  AvailableCompanyTypes,
  AvailableCountryTypes,
  CreatedContactPersonsTypes,
  FetchedCompanyTypes,
  FetchedContactPersonsTypes,
} from "../../types/companies";
import AddNewCompanyStepper from "../../components/Speppers/AddNewCompanyStepper";
import { CompanyForm } from "../../components/Forms/AddNewCompany/CompanyForm";
import AddContactsForm from "../../components/Forms/AddNewCompany/ContactsForm";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ContactsAdded from "../../components/Forms/AddNewCompany/ContactsAdded";
import { useNavigation } from "../../hooks/useNavigation";
import useAuth from "../../hooks/useAuth";
import TopBannerStepperBack from "../../components/Banners/TopBanner/TopBannerStepperBack";
import useAuthApiCall from "../../hooks/useAuthApiCall";

import {
  emailValidationRegex,
  phoneCountryCodeValidationRegex,
} from "../../config/validationRegex";
import { storageVariables } from "../../lib/storageVariables";
import {
  createNewQuote,
  // getCurrency
} from "../../helpers/storage";
import { vatText } from "../../helpers/currencyFormat";

interface NewCompanyTypesData {
  companyName: string;
  countryId: number;
  companyTypeId: number;
  address: string;
  vat: string;
  sellerId: string;
  sellerName: string;
  sapId: string | null;
  city: string;
  postCode: string;
  contactPersons: CreatedContactPersonsTypes[];
}

const AddNewCompanyPage: FC = () => {
  const { fullNavigateToUrl } = useNavigation();

  const [availableCompanies, setAvailableCompanies] = useState<
    AvailableCompanyTypes[]
  >([]);

  const [availableCountries, setAvailableCountries] = useState<
    AvailableCountryTypes[]
  >([]);

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const { getAgentId, getAgentName } = useAuth();

  const [contactPersons, setContactPersons] = useState<
    CreatedContactPersonsTypes[]
  >([]);

  const [step, setStep] = useState<1 | 2>(1);

  const validationSchemaStepOne = Yup.object().shape({
    companyName: Yup.string()
      .required("Required field")
      .max(100, "Company Name can be at most 100 characters"),
    companyTypeId: Yup.string()
      .required("Please select an option")
      .oneOf(
        availableCompanies.map((option) => option.companyType),
        "Invalid option selected"
      ),
    address: Yup.string()
      .required("Required field")
      .max(100, "Address can be at most 100 characters"),
    city: Yup.string()
      .required("Required field")
      .max(50, "City can be at most 50 characters"),
    postCode: Yup.string()
      .required("Required field")
      .max(10, "Post Code can be at most 10 characters"),
    countryId: Yup.string()
      .required("Please select an option")
      .oneOf(
        availableCountries.map((option) => option.countryName),
        "Invalid option selected"
      ),
    vat: Yup.string()
      .matches(/^[0-9]*$/, `Please enter a valid ${vatText} number`)
      .max(2, `${vatText} number can be at most 2 digits`),
    sapId: Yup.string().max(10, "SAP ID can be at most 10 characters"),
  });

  // const validationSchemaStepOneUSA = Yup.object().shape({
  //   companyName: Yup.string()
  //     .required("Required field")
  //     .max(100, "Company Name can be at most 100 characters"),
  //   city: Yup.string()
  //     .required("Required field")
  //     .max(50, "City can be at most 50 characters"),
  //   sapId: Yup.string()
  //     .max(10, "SAP ID can be at most 10 characters")
  //     .required("This field is required."),
  //   companyTypeId: Yup.string()
  //     .nullable()
  //     .oneOf(
  //       availableCompanies.map((option) => option.companyType),
  //       "Invalid option selected"
  //     ),
  //   address: Yup.string()
  //     .nullable()
  //     .max(100, "Address can be at most 100 characters"),
  //   postCode: Yup.string()
  //     .nullable()
  //     .max(10, "Post Code can be at most 10 characters"),
  //   countryId: Yup.string()
  //     .nullable()
  //     .oneOf(
  //       availableCountries.map((option) => option.countryName),
  //       "Invalid option selected"
  //     ),
  //   vat: Yup.string().nullable().max(2, "VAT number can be at most 2 digits"),
  // });

  const validationSchemaStepTwo = Yup.object().shape({
    firstName: Yup.string()
      .required("This is a required field")
      .max(40, "First Name can be at most 40 characters"),
    lastName: Yup.string()
      .required("This is a required field")
      .max(40, "Last Name can be at most 40 characters"),
    email: Yup.string()
      .required("This is a required field")
      .matches(emailValidationRegex, "Please enter a valid email")
      .max(100, "Email can be at most 100 characters"),
    phoneCountryCode: Yup.string()
      .required("This is a required field")
      .matches(
        phoneCountryCodeValidationRegex,
        "Please enter a valid phone country code"
      ),
    phoneNumber: Yup.string()
      .required("This is a required field")
      .matches(/^[0-9-]*$/, "Please enter a valid phone number")
      .max(20, "Phone Number can be at most 20 characters"),
    title: Yup.string()
      .required("This is a required field")
      .max(40, "Job Title can be at most 40 characters"),
  });

  // const validationSchemaStepTwoUSA = Yup.object().shape({
  //   firstName: Yup.string()
  //     .required("This is a required field")
  //     .max(40, "First Name can be at most 40 characters"),
  //   lastName: Yup.string()
  //     .required("This is a required field")
  //     .max(40, "Last Name can be at most 40 characters"),
  //   email: Yup.string()
  //     .required("This is a required field")
  //     .matches(emailValidationRegex, "Please enter a valid email"),
  //   phoneCountryCode: Yup.string()
  //     .nullable()
  //     .matches(
  //       phoneCountryCodeValidationRegex,
  //       "Please enter a valid phone country code"
  //     ),
  //   phoneNumber: Yup.string()
  //     .nullable()
  //     .matches(/^[0-9-]*$/, "Please enter a valid phone number")
  //     .max(20, "Phone Number can be at most 20 characters"),
  //   title: Yup.string()
  //     .nullable()
  //     .max(40, "Job Title can be at most 40 characters"),
  // });

  const initialValuesStepOne = {
    companyName: "",
    companyTypeId: "",
    address: "",
    city: "",
    postCode: "",
    countryId: "",
    countryName: "",
    vat: "",
    sapId: "",
  };

  // const currency: string = getCurrency();

  const formikStepOne = useFormik({
    initialValues: initialValuesStepOne,
    validationSchema: validationSchemaStepOne,
    // validationSchema:
    //   currency === "EUR" ? validationSchemaStepOne : validationSchemaStepOneUSA,
    onSubmit: () => {
      setStep(2);
    },
  });

  const initialValuesStepTwo: CreatedContactPersonsTypes = {
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneNumber: "",
    title: "",
  };

  const formikStepTwo = useFormik({
    initialValues: initialValuesStepTwo,
    validationSchema: validationSchemaStepTwo,
    // validationSchema:
    //   currency === "EUR" ? validationSchemaStepTwo : validationSchemaStepTwoUSA,
    onSubmit: () => {
      setContactPersons([...contactPersons, formikStepTwo.values]);
      formikStepTwo.resetForm();
    },
  });

  const updateFormikValue = (name: string, value: string) => {
    if (step === 1) formikStepOne.setFieldValue(name, value);
    else formikStepTwo.setFieldValue(name, value);
  };

  const inputDataStepOne = {
    companyName: {
      name: "companyName",
      placeholder: "Company Name",
      value: formikStepOne.values.companyName,
      onChange: (value: string) => updateFormikValue("companyName", value),
      errorMessage: formikStepOne.errors.companyName ?? null,
    },
    companyTypeId: {
      name: "companyTypeId",
      placeholder: "Company Type",
      value: formikStepOne.values.companyTypeId,
      onChange: (value: string) => updateFormikValue("companyTypeId", value),
      errorMessage: formikStepOne.errors.companyTypeId ?? null,
    },
    address: {
      name: "address",
      placeholder: "Address",
      value: formikStepOne.values.address,
      onChange: (value: string) => updateFormikValue("address", value),
      errorMessage: formikStepOne.errors.address ?? null,
    },
    city: {
      name: "city",
      placeholder: "City",
      value: formikStepOne.values.city,
      onChange: (value: string) => updateFormikValue("city", value),
      errorMessage: formikStepOne.errors.city ?? null,
    },
    postCode: {
      name: "postCode",
      placeholder: "Post Code",
      value: formikStepOne.values.postCode,
      onChange: (value: string) => updateFormikValue("postCode", value),
      errorMessage: formikStepOne.errors.postCode ?? null,
    },
    countryId: {
      name: "countryId",
      placeholder: "Country",
      value: formikStepOne.values.countryId,
      onChange: (value: string) => {
        updateFormikValue("countryId", value);
      },
      errorMessage: formikStepOne.errors.countryId ?? null,
    },
    vat: {
      name: "vat",
      placeholder: vatText,
      value: formikStepOne.values.vat,
      onChange: (value: string) => updateFormikValue("vat", value),
      errorMessage: formikStepOne.errors.vat ?? null,
    },
    sapId: {
      name: "sapId",
      placeholder: "SAP ID",
      value: formikStepOne.values.sapId,
      onChange: (value: string) => updateFormikValue("sapId", value),
      errorMessage: formikStepOne.errors.sapId ?? null,
    },
  };

  const inputDataStepTwo = {
    firstName: {
      name: "firstName",
      placeholder: "First Name",
      value: formikStepTwo.values.firstName,
      onChange: (value: string) => updateFormikValue("firstName", value),
      errorMessage: formikStepTwo.errors.firstName ?? null,
    },
    lastName: {
      name: "lastName",
      placeholder: "Last Name",
      value: formikStepTwo.values.lastName,
      onChange: (value: string) => updateFormikValue("lastName", value),
      errorMessage: formikStepTwo.errors.lastName ?? null,
    },
    email: {
      name: "email",
      placeholder: "Email",
      value: formikStepTwo.values.email,
      onChange: (value: string) => updateFormikValue("email", value),
      errorMessage: formikStepTwo.errors.email ?? null,
    },
    phoneCountryCode: {
      name: "phoneCountryCode",
      placeholder: "Phone Country Code",
      value: formikStepTwo.values.phoneCountryCode,
      onChange: (value: string) => updateFormikValue("phoneCountryCode", value),
      errorMessage: formikStepTwo.errors.phoneCountryCode ?? null,
    },
    phoneNumber: {
      name: "phoneNumber",
      placeholder: "Phone Number",
      value: formikStepTwo.values.phoneNumber,
      onChange: (value: string) => updateFormikValue("phoneNumber", value),
      errorMessage: formikStepTwo.errors.phoneNumber ?? null,
    },
    title: {
      name: "title",
      placeholder: "Job Title",
      value: formikStepTwo.values.title,
      onChange: (value: string) => updateFormikValue("title", value),
      errorMessage: formikStepTwo.errors.title ?? null,
    },
  };

  const errorsStepOne = Object.keys(formikStepOne.errors).length > 0;
  const errorsStepTwo = Object.keys(formikStepTwo.errors).length > 0;

  const handleFirstStepSubmit = () => {
    if (!errorsStepOne) {
      formikStepOne.handleSubmit();
    }
  };

  const handleAddContact = () => {
    if (!errorsStepTwo) {
      formikStepTwo.handleSubmit();
    }
  };

  const [apiReason, setApiReason] = useState<string>("");

  const handleSubmitForm = () => {
    // find countryId  by countryName
    const countryId =
      availableCountries.find(
        (country) => country.countryName === formikStepOne.values.countryId
      )?.countryId || "";

    // find companyTypeId by companyTypeName
    const companyTypeId =
      availableCompanies.find(
        (company) => company.companyType === formikStepOne.values.companyTypeId
      )?.companyTypeId || "";

    const dataForSubmit: NewCompanyTypesData = {
      sellerId: getAgentId() || "",
      sellerName: getAgentName() || "",
      contactPersons,
      vat: formikStepOne.values.vat !== "" ? formikStepOne.values.vat : "0",
      city: formikStepOne.values.city,
      address: formikStepOne.values.address,
      postCode: formikStepOne.values.postCode,
      companyName: formikStepOne.values.companyName,
      countryId: parseInt(countryId, 10),
      companyTypeId: parseInt(companyTypeId, 10),
      sapId:
        formikStepOne.values.sapId === "" ? null : formikStepOne.values.sapId,
    };

    setApiReason("createCompany");

    setTimeout(() => {
      handleAuthApiCall({
        method: "post",
        data: dataForSubmit,
        url: `/v1/companies/createCompany`,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }, 100);
  };

  const handleDeleteContactItem = (id: number) => {
    setContactPersons((prevState) => {
      return prevState.filter((_, i) => i !== id);
    });
  };

  const getAvailableCountryTypes = () => {
    handleAuthApiCall({
      method: "get",
      url: "/v1/countries",
    });
  };

  const getAvailableCompanies = () => {
    handleAuthApiCall({
      method: "get",
      url: "/v1/companyTypes",
    });

    getAvailableCountryTypes();
  };

  const handleChooseJourney = (
    selectedCompany: FetchedCompanyTypes | null = null,
    fetchedContactPersons: FetchedContactPersonsTypes[] = [],
    activePersonId: number | null = null
  ) => {
    if (!selectedCompany) return;

    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.EDIT_QUOTE);

    setTimeout(() => {
      const newCompanyToSave: FetchedCompanyTypes = {
        ...selectedCompany,
        contactPersons: fetchedContactPersons,
        activePersonId,
      };

      localStorage.setItem(
        storageVariables.SELECTED_COMPANY,
        JSON.stringify(newCompanyToSave)
      );

      createNewQuote(newCompanyToSave);
    }, 50);

    setTimeout(() => {
      fullNavigateToUrl(availableRoutes.CHOOSE_JOURNEY);
    }, 100);
  };

  useEffect(() => {
    getAvailableCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiReason === "createCompany" && apiData?.companyId) {
      handleAuthApiCall({
        method: "get",
        url: `/v1/companies/${apiData?.companyId}`,
      });

      setApiReason("companyCreated");
    } else if (apiReason === "companyCreated") {
      const contactPersonsArray: FetchedContactPersonsTypes[] =
        apiData?.company?.contactPersons ?? [];

      setContactPersons(contactPersonsArray);

      // set active person id to the last person in the list
      if (contactPersonsArray.length > 0) {
        const lastPersonId =
          contactPersonsArray[contactPersonsArray.length - 1]?.contactPersonId;

        setApiReason("");
        handleChooseJourney(
          apiData?.company,
          contactPersonsArray,
          lastPersonId
        );
      }
    } else if (apiData?.companyTypes || apiData?.countries) {
      const companyTypesData: AvailableCompanyTypes[] | undefined =
        apiData?.companyTypes;
      if (companyTypesData && companyTypesData.length > 0) {
        setAvailableCompanies(companyTypesData);
      }

      const availableCountriesData: AvailableCountryTypes[] | undefined =
        apiData?.countries;
      if (availableCountriesData && availableCountriesData.length > 0) {
        setAvailableCountries(availableCountriesData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  return (
    <MainPageWrapper title="Add New Customer" testid="not-found-page">
      {step === 1 ? (
        <TopBannerCustomBack
          startFromBeginning
          goBack
          backUrl={step === 1 ? availableRoutes.SELECT_CUSTOMER : undefined}
        />
      ) : (
        <TopBannerStepperBack
          clickGoBack={() => setStep(1)}
          startFromBeginning
        />
      )}

      <div>
        <div className="row box-grid-container add-client-container">
          <div className="col-md-6">
            <div className="title-form-text">
              Let&apos;s add a shiny new client
            </div>
            <AddNewCompanyStepper step={step} />

            {step === 1 && (
              <CompanyForm
                inputData={inputDataStepOne}
                onClick={handleFirstStepSubmit}
                availableCountries={availableCountries}
                availableCompanies={availableCompanies}
              />
            )}

            {step === 2 && (
              <AddContactsForm
                inputData={inputDataStepTwo}
                onClick={handleAddContact}
              />
            )}
          </div>

          {contactPersons.length > 0 && (
            <ContactsAdded
              onClick={handleDeleteContactItem}
              contactPersons={contactPersons}
            />
          )}
          <div className="row box-grid-container">
            <div className="col-md-12 col-right-align">
              {step === 2 && (
                <ButtonSubmit
                  disabled={contactPersons.length === 0}
                  onClick={handleSubmitForm}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </MainPageWrapper>
  );
};

export default AddNewCompanyPage;
