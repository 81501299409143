import React, { FC, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

interface PropsTypes {
  title: string | undefined;
  description?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  open?: boolean;
}

const ConfirmPopupOpen: FC<PropsTypes> = ({
  title,
  description,
  onConfirm,
  open,
  onCancel,
}) => {
  const { closeConfirm } = useContext(ModalContext);

  const handleConfirm = () => {
    onConfirm();
    closeConfirm();
  };

  const handleCancel = () => {
    closeConfirm();
    if (onCancel) onCancel();
  };

  return (
    <div className={`popup-outer ${open ? "open" : ""}`}>
      <div className="popup-bg" />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="popup-title">{title}</div>
          <div className="popup-message">{description}</div>
          <div className="row box-grid-container alert-row popup-buttons">
            <div className="col-sm-4 col-left-align" onClick={handleCancel}>
              <div className="white-btn underline-hover-btn">
                <div className="btn-text">Cancel</div>
              </div>
            </div>
            <div className="col-sm-4 col-right-align" onClick={handleConfirm}>
              <div className="yellow-btn corner-btn">
                <div className="btn-text">OK</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopupOpen;
