/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC, useContext, useEffect } from "react";
import ButtonDelete from "../../../../Buttons/ButtonDelete";
import CurrentQuoteQuantityInput from "../QuoteItemComponents/CurrentQuoteQuantityInput";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../../hooks/UseFormatPrice";
import QuoteItemDescription from "../QuoteItemComponents/Description";
import InitialPrice from "../QuoteItemComponents/InitialPrice";
import DiscountPriceWithToggle from "../QuoteItemComponents/DiscountPriceWithToggle";
import FinalPriceWithToggle from "../QuoteItemComponents/FinalPriceWithToggle";
import { QuoteItemChargerTypes } from "../../../../../types/currentQuoteTree";

import { getCurrency, useQuoteUpdate } from "../../../../../helpers/storage";

import {
  alertHasAccessoriesMessage,
  confirmDeleteQuoteItem,
} from "../../../../../settings/confirmAlertMessages";
import { checkIfDiscountIsGreaterThanPrice } from "../../../../../helpers/functions";
import { ModalContext } from "../../../../../context/ModalContext";
import ConfirmPopupOpen from "../../../../Popups/ConfirmPopupOpen";
import AlertPopupOpen from "../../../../Popups/AlertPopupOpen";

interface QuoteItemChargerPropsTypes {
  index: number;
  quoteItem: QuoteItemChargerTypes;
  finalPriceEnabled: boolean;
  toggleFinalPriceEnabled: () => void;
}

const QuoteItemCharger: FC<QuoteItemChargerPropsTypes> = ({
  index,
  quoteItem,
  finalPriceEnabled,
  toggleFinalPriceEnabled,
}) => {
  const {
    openAlert,
    showAlert,
    alertMsg,
    openConfirm,
    confirmMsg,
    showConfirm,
  } = useContext(ModalContext);

  const { getFormattedPriceFromObj } = useFormatPrice();

  const {
    updateChargerQuantity,
    deleteQuoteBlockByIndex,
    updateDiscountValueChangeCharger,
    changeSelectedFinalPriceCharger,

    toggleDiscountTypeCharger,

    toggleDiscountIncreaseCharger,
  } = useQuoteUpdate();

  const currency: string = getCurrency();

  const {
    name,
    imageUrl,
    itemPrice,
    totalPrice,
    quantity,
    manualAddedPrice,
    itemType,
    discountType,
    discountPercentage,
    discountPrice,
    discountIncrease,
    discountTotal,
  } = quoteItem;

  const [displayAlert, setDisplayAlert] = React.useState<boolean>(false);

  const [displayConfirm, setDisplayConfirm] = React.useState<boolean>(false);

  const initialPriceObj: FormattedMoneyTypes = formatMoney({
    amount: itemPrice,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  const handleChangeDiscountValue = (value: number) => {
    updateDiscountValueChangeCharger({
      index,
      value,
    });
  };

  const changeSelectedFinalPriceChargerValue = (value: number) => {
    changeSelectedFinalPriceCharger({
      index,
      value,
    });
  };

  // helpers
  const handleQuoteItemDelete = () => {
    if (quoteItem?.accessories?.length > 0) {
      setDisplayAlert(true);
      openAlert(alertHasAccessoriesMessage);
      return;
    }

    setDisplayConfirm(true);
    openConfirm(confirmDeleteQuoteItem);
  };

  const updateQuantity = (
    updateType: "increase" | "decrease",
    newQuantity = 1
  ) => {
    if (updateType === "decrease" && quantity - newQuantity < 1) return;

    if (finalPriceEnabled) return;

    updateChargerQuantity({
      index,
      updateType,
      quantity: newQuantity,
    });
  };

  const handleManualQuantityChange = (manualQuantity: number): void => {
    if (finalPriceEnabled) return;

    updateChargerQuantity({
      index,
      updateType: "manual",
      quantity: manualQuantity,
    });
  };

  const [discountErrorMessage, setDiscountErrorMessage] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    setDiscountErrorMessage(
      checkIfDiscountIsGreaterThanPrice({
        totalPrice,
      })
    );
  }, [discountTotal, totalPrice, discountIncrease]);

  const completeBatteryDelete = () => {
    deleteQuoteBlockByIndex(index);
    setDisplayConfirm(false);
  };

  const handleCancelConfirm = () => {
    setDisplayConfirm(false);
  };

  const handleCloseAlert = () => {
    setDisplayAlert(false);
  };

  return (
    <>
      <div
        className="cart-item-row cart-item-level-2"
        style={{ display: "block" }}
      >
        {showConfirm &&
          displayConfirm &&
          confirmMsg &&
          confirmMsg === confirmDeleteQuoteItem && (
            <ConfirmPopupOpen
              title={confirmMsg}
              onConfirm={completeBatteryDelete}
              open={displayConfirm}
              onCancel={handleCancelConfirm}
            />
          )}

        {showAlert &&
          displayAlert &&
          alertMsg &&
          alertMsg === alertHasAccessoriesMessage && (
            <AlertPopupOpen
              title={alertMsg}
              onClose={handleCloseAlert}
              open={displayAlert}
            />
          )}

        <div className="cart-item-row-inner row">
          <QuoteItemDescription productTitle={name} imageUrl={imageUrl} />

          <InitialPrice
            formattedPrice={getFormattedPriceFromObj(initialPriceObj)}
          />

          <CurrentQuoteQuantityInput
            quantity={quantity}
            onIncrement={() => updateQuantity("increase")}
            onDecrement={() => updateQuantity("decrease")}
            handleManualQuantityChange={handleManualQuantityChange}
          />

          <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-discount">
            {!finalPriceEnabled && (
              <DiscountPriceWithToggle
                itemType={itemType}
                index={index}
                discountType={discountType}
                discountIncrease={discountIncrease}
                discountPercentage={discountPercentage}
                discountPrice={discountPrice}
                toggleDiscountType={() => toggleDiscountTypeCharger(index)}
                toggleDiscountIncrease={() =>
                  toggleDiscountIncreaseCharger(index)
                }
                handleChangeDiscountValue={handleChangeDiscountValue}
                errorMessage={discountErrorMessage}
              />
            )}
          </div>

          <FinalPriceWithToggle
            index={index}
            totalPrice={totalPrice}
            manualAddedPrice={manualAddedPrice}
            toggleSelectedFinalPrice={toggleFinalPriceEnabled}
            changeSelectedFinalPrice={changeSelectedFinalPriceChargerValue}
          />

          <ButtonDelete onClick={handleQuoteItemDelete} />
        </div>
      </div>
    </>
  );
};

export default QuoteItemCharger;
