import { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { storageVariables } from "../lib/storageVariables";
import {
  BatteryTypesNew,
  ChargerTypes,
  ForkliftTypesNew,
} from "../types/basicProductsTypes";
import {
  FetchedCompanyTypes,
  FetchedContactPersonsTypes,
} from "../types/companies";
import {
  CurrentQuoteTypes,
  QuoteCustomerTypes,
  QuoteItemForkliftTypes,
  QuoteItemBatteryTypes,
  BasicQuoteItemTypes,
  QuoteItemChargerTypes,
  CurrentQuoteFooterDataTypes,
} from "../types/currentQuoteTree";
import { FetchedForkliftFleetTypesNew } from "../types/forkliftFleetTypes";
import { DiscountTypesEnum } from "../config/discountTypes";
import {
  fallbackBatteryImg,
  fallbackChargerImg,
} from "../assets/mockData/fallbackImages";
import { getCurrentDate } from "./functions";
import { CurrentRegionSettingsTypes } from "../types/agent";
import { getDateFromDateObject, getDateWithOffsetDays } from "./dateParse";

interface DiscountUpdateTypes {
  discountType: DiscountTypesEnum.PERCENTAGE | DiscountTypesEnum.AMOUNT_OFF;
  discountValue: number;
  unitPrice: number;
  quantity: number;
}

export const getCurrency = (): string => {
  const currencyStr: string | null = localStorage.getItem(
    storageVariables.CURRENCY
  );

  if (!currencyStr) return "EUR";

  const currencySettings: CurrentRegionSettingsTypes = JSON.parse(currencyStr);

  return currencySettings.currency || "EUR";
};

// INITIAL VALUES
const initialQuoteItem: BasicQuoteItemTypes = {
  id: 0,
  name: "",
  model: "",
  voltage: 0,
  battery: null,
  charger: null,
  itemPrice: 0,
  quantity: 0,
  totalPrice: 0,
  discountType: DiscountTypesEnum.AMOUNT_OFF,
  discountPercentage: 0,
  discountTotal: 0,
  discountIncrease: false,
  manualAddedPrice: null,
  imageUrl: "",
  discountPrice: 0,
};

export const initialCurrentQuoteFooterData: CurrentQuoteFooterDataTypes = {
  totalPriceExclVat: 0,
  vat: 0,
  totalPriceInclVat: 0,
};

export const initialCharger: QuoteItemChargerTypes = {
  itemType: "charger",
  ...initialQuoteItem,
  accessories: [],
};

export const initialBattery: QuoteItemBatteryTypes = {
  itemType: "battery",
  sapId: "",
  drawingNumber: "",
  replacementBatteries: null,
  liMaxCapacity: null,
  replacementPercentage: null,
  typeModuleFinalChoice: null,
  moduleWeight: null,
  sumModuleWeight: null,
  trayWeight: null,
  ballastWeight: null,
  trayThickness: null,
  status: null,
  ...initialQuoteItem,
};
export const initialForklift: QuoteItemForkliftTypes = {
  itemType: "forklift",
  manufacturer: "",
  model: "",
  battery: null,
};

export const initialCustomer: QuoteCustomerTypes = {
  sapId: "",
  companyId: 0,
  companyName: "",
  contactPerson: 0,
  contactPersonFirstName: "",
  contactPersonLastName: "",

  address: "",
  city: "",
  state: "",
  zip: "",
  countryName: "",
  countryId: 0,

  // Sales person
  phone: "",
  email: "",
  salesPersonId: "",

  vat: "",
  currency: "",
};

const initialQuote: CurrentQuoteTypes = {
  // Current quote page
  customer: initialCustomer,

  quoteItems: [],

  totalPriceExclVat: 0,
  vat: 0,
  totalPriceInclVat: 0,
  totalDiscount: 0,

  quoteNotes: "",

  // Quote history data
  approvedBy: "0",
  createdBy: "0",
  salesPersonName: "",
  dateCreated: "",
  dateExpired: "",
  dateModified: "",
  dateSubmitted: "",
  orderId: 0,
  quoteId: 0,
  status: "S",

  companyId: 0,
  contactPersonId: 0,
  currency: getCurrency(),
  totalItemsPrice: 0,
};

//

export const fixNumber = (number: number): number =>
  parseFloat(number.toFixed(2));

/**
 * GETTERS AND SETTERS
 */

export const getForkliftFetchedData = (): ForkliftTypesNew[] | null => {
  const forkliftsDataStr = localStorage.getItem(
    storageVariables.SELECTED_FORKLIFT_FETCHED_DATA
  );

  if (!forkliftsDataStr) return null;

  return JSON.parse(forkliftsDataStr);
};

export const getSelectedCharger = (): ChargerTypes | null => {
  const charger = localStorage.getItem(storageVariables.SELECTED_CHARGER);

  if (!charger) return null;

  return JSON.parse(charger);
};

export const saveSelectedBattery = (battery: BatteryTypesNew) => {
  localStorage.setItem(
    storageVariables.SELECTED_BATTERY,
    JSON.stringify(battery)
  );

  const availableCharger =
    battery?.chargers && battery.chargers?.length > 0
      ? battery.chargers[0]
      : null;

  if (availableCharger) {
    localStorage.setItem(
      storageVariables.SELECTED_CHARGER,
      JSON.stringify(availableCharger)
    );
  }
};
export const getSelectedBattery = (): BatteryTypesNew | null => {
  const battery = localStorage.getItem(storageVariables.SELECTED_BATTERY);

  if (!battery) return null;

  return JSON.parse(battery);
};

export const createNewQuote = (
  selectedCompany: FetchedCompanyTypes
): boolean => {
  localStorage.removeItem(storageVariables.SELECTED_BATTERY);
  localStorage.removeItem(storageVariables.SELECTED_CHARGER);
  localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);

  const newQuote: CurrentQuoteTypes = { ...initialQuote };

  // customer
  newQuote.customer.sapId = selectedCompany?.sapId ?? null;
  newQuote.customer.companyId =
    selectedCompany?.companyTypeId?.companyTypeId ?? 0;
  newQuote.customer.companyName = selectedCompany?.companyName ?? "";
  newQuote.customer.contactPerson = selectedCompany.activePersonId ?? 0;

  const selectedEmployee: FetchedContactPersonsTypes | undefined =
    selectedCompany.contactPersons.find(
      (person) => person.contactPersonId === selectedCompany.activePersonId
    );

  newQuote.customer.contactPersonFirstName = selectedEmployee?.firstName ?? "";
  newQuote.customer.contactPersonLastName = selectedEmployee?.lastName ?? "";

  newQuote.customer.address = selectedCompany.address ?? "";
  newQuote.customer.city = selectedCompany.city ?? "";
  newQuote.customer.state = selectedCompany.state ?? "";
  newQuote.customer.zip = selectedCompany.zip ?? "";
  newQuote.customer.countryId = selectedCompany?.countryId?.countryId ?? 0;

  newQuote.customer.phone = selectedCompany?.phoneNumber ?? "";
  newQuote.customer.email = selectedCompany?.email ?? "";
  newQuote.customer.countryName = selectedCompany?.countryId?.countryName ?? "";
  newQuote.customer.salesPersonId = selectedCompany?.sellerId ?? "";

  // Current Quote
  newQuote.vat = selectedCompany?.vat ? Number(selectedCompany.vat) : 0;
  newQuote.dateCreated = getCurrentDate();
  newQuote.dateModified = getCurrentDate();
  newQuote.dateExpired = getDateFromDateObject(getDateWithOffsetDays(30));

  newQuote.companyId = selectedCompany?.companyId ?? 0;
  newQuote.contactPersonId = selectedCompany?.activePersonId ?? 0;

  localStorage.setItem(
    storageVariables.CURRENT_QUOTE,
    JSON.stringify(newQuote)
  );

  return true;
};

export const getCurrentQuote = (): CurrentQuoteTypes | null => {
  return localStorage.getItem(storageVariables.CURRENT_QUOTE)
    ? JSON.parse(localStorage.getItem(storageVariables.CURRENT_QUOTE) || "")
    : null;
};

export const quoteItemsNotEmpty = (): boolean => {
  const currentQuote = getCurrentQuote();

  if (!currentQuote) return false;

  return currentQuote.quoteItems.length > 0;
};

export const saveNewCompany = ({
  company,
  selectedEmployee,
}: {
  company: FetchedCompanyTypes;
  selectedEmployee: string;
}): boolean => {
  localStorage.removeItem(storageVariables.SELECTED_COMPANY);
  localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
  localStorage.removeItem(storageVariables.CURRENT_QUOTE);
  localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
  localStorage.removeItem(storageVariables.SELECTED_BATTERY);
  localStorage.removeItem(storageVariables.SELECTED_CHARGER);

  localStorage.setItem(
    storageVariables.SELECTED_COMPANY,
    JSON.stringify({ company, selectedEmployee })
  );
  return true;
};

export const hasAvailableBatteriesForSelect = (): boolean => {
  const forkliftsDataStr: string | null = localStorage.getItem(
    storageVariables.SELECTED_FORKLIFT_FETCHED_DATA
  );

  if (!forkliftsDataStr) return false;

  const forkliftsData: ForkliftTypesNew[] = JSON.parse(forkliftsDataStr);

  if (forkliftsData?.length === 0) return false;

  return !!forkliftsData[0]?.batteries?.length;
};

export const getLastQuoteItem = ():
  | QuoteItemForkliftTypes
  | QuoteItemBatteryTypes
  | QuoteItemChargerTypes
  | null => {
  // const currentQuote = JSON.parse(
  //   localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
  // );
  const currentQuoteStr = localStorage.getItem(storageVariables.CURRENT_QUOTE);

  if (!currentQuoteStr) return null;

  const currentQuote: CurrentQuoteTypes = JSON.parse(currentQuoteStr);

  if (currentQuote?.quoteItems?.length === 0) return null;

  return currentQuote?.quoteItems[currentQuote.quoteItems.length - 1];
};

export const getIsSelectedBatteryInLastQuoteItem = (): boolean => {
  const lastQuoteItem:
    | QuoteItemForkliftTypes
    | QuoteItemBatteryTypes
    | QuoteItemChargerTypes
    | null = getLastQuoteItem();

  if (!lastQuoteItem) return false;

  const selectedBatteryStr: string | null = localStorage.getItem(
    storageVariables.SELECTED_BATTERY
  );

  if (!selectedBatteryStr) {
    const currentQuote: CurrentQuoteTypes | null = getCurrentQuote();
    if (currentQuote?.quoteItems?.length === 0) return false;

    if (lastQuoteItem?.itemType === "forklift") {
      localStorage.setItem(
        storageVariables.SELECTED_BATTERY,
        JSON.stringify(lastQuoteItem?.battery)
      );
      return false;
    }

    if (lastQuoteItem?.itemType === "battery") {
      localStorage.setItem(
        storageVariables.SELECTED_BATTERY,
        JSON.stringify(lastQuoteItem)
      );
      return false;
    }
    return false;
  }

  const selectedBattery: BatteryTypesNew = JSON.parse(selectedBatteryStr);

  if (lastQuoteItem?.itemType === "forklift") {
    return lastQuoteItem?.battery?.id === selectedBattery?.batteryId;
  }

  if (lastQuoteItem?.itemType === "battery") {
    return lastQuoteItem?.id === selectedBattery?.batteryId;
  }

  return false;
};

export const getIsSelectedChargerInLastQuoteItem = (): boolean => {
  const lastQuoteItem:
    | QuoteItemForkliftTypes
    | QuoteItemBatteryTypes
    | QuoteItemChargerTypes
    | null = getLastQuoteItem();

  if (!lastQuoteItem) return false;

  const selectedChargerStr: string | null = localStorage.getItem(
    storageVariables.SELECTED_CHARGER
  );

  if (!selectedChargerStr) {
    const currentQuote: CurrentQuoteTypes | null = getCurrentQuote();
    if (currentQuote?.quoteItems?.length === 0) return false;

    if (lastQuoteItem?.itemType === "forklift") {
      localStorage.setItem(
        storageVariables.SELECTED_CHARGER,
        JSON.stringify(lastQuoteItem?.battery?.charger)
      );
    }

    if (lastQuoteItem?.itemType === "battery") {
      localStorage.setItem(
        storageVariables.SELECTED_CHARGER,
        JSON.stringify(lastQuoteItem?.charger)
      );
    }
    if (lastQuoteItem?.itemType === "charger") {
      localStorage.setItem(
        storageVariables.SELECTED_CHARGER,
        JSON.stringify(lastQuoteItem)
      );
    }
    return false;
  }

  const selectedCharger: ChargerTypes | null = JSON.parse(
    selectedChargerStr || ""
  );

  // if (!selectedCharger) return false;

  if (lastQuoteItem?.itemType === "forklift") {
    return lastQuoteItem?.battery?.charger?.id === selectedCharger?.chargerId;
  }

  if (lastQuoteItem?.itemType === "battery") {
    return lastQuoteItem?.charger?.id === selectedCharger?.chargerId;
  }

  if (lastQuoteItem?.itemType === "charger") {
    return lastQuoteItem?.id === selectedCharger?.chargerId;
  }

  return false;
};

export const useQuoteUpdate = () => {
  const { setCurrentQuote } = useContext(CartContext);

  const updateQuoteExpirationDate = (date: string): void => {
    const currentQuote: CurrentQuoteTypes | null = getCurrentQuote();
    if (!currentQuote) return;

    currentQuote.dateExpired = date;

    localStorage.setItem(
      storageVariables.CURRENT_QUOTE,
      JSON.stringify(currentQuote)
    );

    setCurrentQuote(currentQuote);
  };

  const calculateDiscountTotal = ({
    discountType,
    discountValue,
    unitPrice,
    quantity,
  }: DiscountUpdateTypes) => {
    if (discountType === DiscountTypesEnum.PERCENTAGE) {
      return fixNumber((unitPrice * quantity * discountValue) / 100);
    }

    if (discountType === DiscountTypesEnum.AMOUNT_OFF) {
      return discountValue;
    }

    return 0;
  };

  const updateCurrentQuoteTotalPrice = (
    newCurrentQuote: CurrentQuoteTypes
  ): void => {
    const currentQuote = { ...newCurrentQuote };
    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    let newTotalPriceExclVat = 0;

    currentQuoteItems.forEach((quoteItem) => {
      if (quoteItem.itemType === "forklift" && quoteItem?.battery) {
        newTotalPriceExclVat += quoteItem.battery.totalPrice;
        if (quoteItem.battery?.charger) {
          newTotalPriceExclVat += quoteItem.battery.charger.totalPrice;
        }
      } else if (quoteItem.itemType === "battery") {
        newTotalPriceExclVat += quoteItem.totalPrice;
        if (quoteItem?.charger) {
          newTotalPriceExclVat += quoteItem.charger.totalPrice;
        }
      } else if (quoteItem.itemType === "charger") {
        newTotalPriceExclVat += quoteItem.totalPrice;
      }
    });

    currentQuote.totalPriceExclVat = fixNumber(newTotalPriceExclVat);
    currentQuote.totalPriceInclVat = fixNumber(
      newTotalPriceExclVat + (newTotalPriceExclVat * currentQuote.vat) / 100 ||
        0
    );

    currentQuote.dateModified = getCurrentDate();

    localStorage.setItem(
      storageVariables.CURRENT_QUOTE,
      JSON.stringify(currentQuote)
    );

    setCurrentQuote(currentQuote);
  };

  const addBatteryToQuoteByForklift = (
    battery: BatteryTypesNew,
    quantity = 1
  ): boolean => {
    if (!battery) return false;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return false;

    const batteryToAdd: QuoteItemBatteryTypes = initialBattery;

    batteryToAdd.id = battery?.batteryId;
    batteryToAdd.name = battery?.batteryInfo;
    batteryToAdd.voltage = battery?.voltage;
    batteryToAdd.imageUrl = battery?.imageUrl || fallbackBatteryImg;
    batteryToAdd.sapId = battery.sapId;
    batteryToAdd.drawingNumber = battery?.drawingNumber
      ? battery.drawingNumber
      : "";

    // TODO: Fix model WHEN IS KNOWN
    batteryToAdd.model = battery?.batteryInfo;

    batteryToAdd.quantity = quantity;
    batteryToAdd.itemPrice = battery?.price?.price || 0;
    batteryToAdd.totalPrice = fixNumber(
      batteryToAdd.itemPrice * batteryToAdd.quantity
    );

    const selectedForklift: FetchedForkliftFleetTypesNew | null = JSON.parse(
      localStorage.getItem(storageVariables.SELECTED_FORKLIFT) || ""
    );

    if (!selectedForklift) return false;

    const newForkliftToAdd: QuoteItemForkliftTypes = initialForklift;

    newForkliftToAdd.manufacturer = selectedForklift.manufacturer;
    newForkliftToAdd.model = selectedForklift.model;

    newForkliftToAdd.battery = batteryToAdd;

    // save selected battery and charger
    localStorage.setItem(
      storageVariables.SELECTED_BATTERY,
      JSON.stringify(battery)
    );

    const availableChargers =
      battery?.chargers && battery.chargers?.length > 0
        ? battery.chargers[0]
        : null;

    if (availableChargers) {
      localStorage.setItem(
        storageVariables.SELECTED_CHARGER,
        JSON.stringify(availableChargers)
      );
    }

    updateCurrentQuoteTotalPrice({
      ...currentQuote,
      quoteItems: [...currentQuote.quoteItems, newForkliftToAdd],
    });

    return true;
  };

  const addChargerToQuoteByChargerFlow = (
    charger: ChargerTypes,
    quantity = 1
  ): void => {
    if (!charger) return;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;

    const chargerToAdd: QuoteItemChargerTypes = initialCharger;

    chargerToAdd.id = charger?.chargerId;
    chargerToAdd.name = charger?.model;
    chargerToAdd.voltage = charger?.batteryVoltage;
    chargerToAdd.imageUrl = charger?.imageUrl || fallbackBatteryImg;

    // TODO: Fix model WHEN IS KNOWN
    chargerToAdd.model = charger.model;

    chargerToAdd.quantity = quantity;
    chargerToAdd.itemPrice = charger?.price?.price || 0;
    chargerToAdd.totalPrice = fixNumber(
      chargerToAdd.itemPrice * chargerToAdd.quantity
    );

    updateCurrentQuoteTotalPrice({
      ...currentQuote,
      quoteItems: [...currentQuote.quoteItems, chargerToAdd],
    });
  };

  const addBatteryToQuoteByBatteryFlow = (
    battery: BatteryTypesNew,
    quantity = 1
  ): boolean => {
    if (!battery) return false;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return false;

    const batteryToAdd: QuoteItemBatteryTypes = initialBattery;

    batteryToAdd.id = battery?.batteryId;
    batteryToAdd.name = battery?.batteryInfo;
    batteryToAdd.voltage = battery?.voltage;
    batteryToAdd.imageUrl = battery?.imageUrl || fallbackBatteryImg;
    batteryToAdd.sapId = battery.sapId;
    batteryToAdd.drawingNumber = battery?.drawingNumber
      ? battery.drawingNumber
      : "";

    // TODO: Fix model WHEN IS KNOWN
    batteryToAdd.model = battery?.batteryInfo;

    batteryToAdd.quantity = quantity;
    batteryToAdd.itemPrice = battery?.price?.price || 0;
    batteryToAdd.totalPrice = fixNumber(
      batteryToAdd.itemPrice * batteryToAdd.quantity
    );

    updateCurrentQuoteTotalPrice({
      ...currentQuote,
      quoteItems: [...currentQuote.quoteItems, batteryToAdd],
    });

    return true;
  };

  const addChargerUnderBattery = (
    charger: ChargerTypes,
    quantity = 1
  ): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

    if (updatedQuoteItem.itemType === "charger") return;

    const batteryToAdd: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem.battery
        : updatedQuoteItem;

    if (!batteryToAdd) return;

    batteryToAdd.charger = initialCharger;

    batteryToAdd.charger.id = charger.chargerId;
    batteryToAdd.charger.name = charger.model;
    batteryToAdd.charger.voltage = charger.batteryVoltage;
    batteryToAdd.charger.imageUrl = charger.imageUrl || fallbackChargerImg;

    batteryToAdd.charger.quantity = quantity;
    batteryToAdd.charger.itemPrice = charger.price?.price || 0;

    batteryToAdd.charger.totalPrice = fixNumber(
      batteryToAdd.charger.itemPrice * batteryToAdd.charger.quantity
    );

    if (batteryToAdd.manualAddedPrice) {
      batteryToAdd.charger.discountPercentage = null;
      batteryToAdd.charger.discountTotal = null;
      batteryToAdd.charger.discountIncrease = true;
      batteryToAdd.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
      batteryToAdd.charger.manualAddedPrice = batteryToAdd.charger.totalPrice;
      batteryToAdd.charger.discountPrice = 0;
    } else {
      batteryToAdd.charger.discountIncrease = batteryToAdd.discountIncrease;
      batteryToAdd.charger.discountType = batteryToAdd.discountType;
    }

    // TODO: Fix model WHEN IS KNOWN
    batteryToAdd.charger.model = charger.model;

    const newUpdatedQuoteItem:
      | QuoteItemBatteryTypes
      | QuoteItemForkliftTypes
      | QuoteItemChargerTypes
      | null =
      updatedQuoteItem.itemType === "forklift"
        ? currentQuote.quoteItems[currentQuoteItems.length - 1]
        : currentQuote.quoteItems[currentQuoteItems.length - 1].battery;

    if (!newUpdatedQuoteItem) return;

    currentQuote.quoteItems[currentQuoteItems.length - 1] = newUpdatedQuoteItem;

    // save selected charger
    localStorage.setItem(
      storageVariables.SELECTED_CHARGER,
      JSON.stringify(charger)
    );

    updateCurrentQuoteTotalPrice(currentQuote);
  };

  const addChargerUnderBatteryBatteryFlow = (
    charger: ChargerTypes,
    quantity = 1
  ): void => {
    if (!charger) return;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

    if (updatedQuoteItem.itemType !== "battery") return;

    const batteryToAdd: QuoteItemBatteryTypes | null = updatedQuoteItem;

    if (!batteryToAdd) return;

    batteryToAdd.charger = initialCharger;

    batteryToAdd.charger.id = charger.chargerId;
    batteryToAdd.charger.name = charger.model;
    batteryToAdd.charger.voltage = charger.batteryVoltage;
    batteryToAdd.charger.imageUrl = charger.imageUrl || fallbackChargerImg;

    batteryToAdd.charger.quantity = quantity;
    batteryToAdd.charger.itemPrice = charger.price?.price || 0;

    batteryToAdd.charger.totalPrice = fixNumber(
      batteryToAdd.charger.itemPrice * batteryToAdd.charger.quantity
    );

    if (batteryToAdd.manualAddedPrice) {
      batteryToAdd.charger.discountPercentage = null;
      batteryToAdd.charger.discountTotal = null;
      batteryToAdd.charger.discountIncrease = true;
      batteryToAdd.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
      batteryToAdd.charger.manualAddedPrice = batteryToAdd.charger.totalPrice;
      batteryToAdd.charger.discountPrice = 0;
    } else {
      batteryToAdd.charger.discountIncrease = batteryToAdd.discountIncrease;
      batteryToAdd.charger.discountType = batteryToAdd.discountType;
    }

    // TODO: Fix model WHEN IS KNOWN
    batteryToAdd.charger.model = charger.model;

    currentQuote.quoteItems[currentQuoteItems.length - 1] = batteryToAdd;

    // save selected charger
    localStorage.setItem(
      storageVariables.SELECTED_CHARGER,
      JSON.stringify(charger)
    );

    updateCurrentQuoteTotalPrice(currentQuote);
  };

  const addChargerWithBattery = (
    quoteItem: QuoteItemForkliftTypes | QuoteItemBatteryTypes,
    _charger: ChargerTypes,
    quantity = 1
  ): void => {
    if (!quoteItem || !_charger) return;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;

    const currentQuoteItems: (
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes
    )[] = currentQuote?.quoteItems || [];

    const tempBattery =
      quoteItem.itemType === "forklift" ? quoteItem.battery : quoteItem;

    if (!tempBattery) return;

    const batteryToAdd: QuoteItemBatteryTypes = initialBattery;

    batteryToAdd.id = tempBattery?.id;
    batteryToAdd.name = tempBattery?.name;
    batteryToAdd.voltage = tempBattery?.voltage;
    batteryToAdd.quantity = 1;
    batteryToAdd.imageUrl = tempBattery?.imageUrl || fallbackBatteryImg;
    batteryToAdd.sapId = tempBattery.sapId;
    batteryToAdd.drawingNumber = tempBattery?.drawingNumber
      ? tempBattery.drawingNumber
      : "";

    batteryToAdd.model = tempBattery?.model;

    batteryToAdd.discountPercentage = 0;
    batteryToAdd.discountTotal = 0;
    batteryToAdd.discountIncrease = false;
    batteryToAdd.manualAddedPrice = null;
    batteryToAdd.discountPrice = 0;

    batteryToAdd.itemPrice = tempBattery?.itemPrice || 0;
    batteryToAdd.totalPrice = fixNumber(
      batteryToAdd.itemPrice * batteryToAdd.quantity
    );

    // add charger to battery
    batteryToAdd.charger = initialCharger;

    batteryToAdd.charger.id = _charger.chargerId;
    batteryToAdd.charger.name = _charger.model;
    batteryToAdd.charger.voltage = _charger.batteryVoltage;
    batteryToAdd.charger.imageUrl = _charger.imageUrl || fallbackChargerImg;

    batteryToAdd.charger.discountPercentage = 0;
    batteryToAdd.charger.discountTotal = 0;
    batteryToAdd.charger.discountIncrease = false;
    batteryToAdd.charger.manualAddedPrice = null;
    batteryToAdd.charger.discountPrice = 0;

    batteryToAdd.charger.quantity = quantity;
    batteryToAdd.charger.itemPrice = _charger.price?.price || 0;

    batteryToAdd.charger.totalPrice = fixNumber(
      batteryToAdd.charger.itemPrice * quantity
    );

    let newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes = {
      ...quoteItem,
    };

    if (quoteItem.itemType === "forklift") {
      newUpdatedQuoteItem.battery = batteryToAdd;
    } else if (quoteItem.itemType === "battery") {
      newUpdatedQuoteItem = batteryToAdd;
    }

    currentQuoteItems.push(newUpdatedQuoteItem);

    updateCurrentQuoteTotalPrice(currentQuote);
  };

  const addChargerToQuoteByForklift = (
    charger: ChargerTypes,
    quantity = 1
  ): void => {
    if (!charger) return;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

    if (updatedQuoteItem.itemType === "charger") return;

    const batteryToAdd: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem.battery
        : updatedQuoteItem;

    if (!batteryToAdd) return;

    if (!batteryToAdd.charger) {
      addChargerUnderBattery(charger, quantity);
    } else {
      addChargerWithBattery(updatedQuoteItem, charger, quantity);
    }
  };

  // const addChargerToQuoteByForklift = (
  //   charger: ChargerTypes,
  //   quantity = 1
  // ): void => {
  //   if (!charger) return;

  //   const currentQuote: CurrentQuoteTypes | null = JSON.parse(
  //     localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
  //   );

  //   const currentQuoteItems = currentQuote?.quoteItems || [];

  //   if (!currentQuote || currentQuoteItems.length === 0) return;

  //   const updatedQuoteItem:
  //     | QuoteItemForkliftTypes
  //     | QuoteItemBatteryTypes
  //     | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

  //   if (updatedQuoteItem.itemType === "charger") return;

  //   const batteryToAdd: QuoteItemBatteryTypes | null =
  //     updatedQuoteItem.itemType === "forklift"
  //       ? updatedQuoteItem.battery
  //       : updatedQuoteItem;

  //   if (!batteryToAdd) return;

  //   batteryToAdd.charger = initialCharger;

  //   batteryToAdd.charger.id = charger.chargerId;
  //   batteryToAdd.charger.name = charger.model;
  //   batteryToAdd.charger.voltage = charger.batteryVoltage;
  //   batteryToAdd.charger.imageUrl = charger.imageUrl || fallbackChargerImg;

  //   batteryToAdd.charger.quantity = quantity;
  //   batteryToAdd.charger.itemPrice = charger.price?.price || 0;

  //   batteryToAdd.charger.totalPrice = fixNumber(
  //     batteryToAdd.charger.itemPrice * batteryToAdd.charger.quantity
  //   );

  //   if (batteryToAdd.manualAddedPrice) {
  //     batteryToAdd.charger.discountPercentage = null;
  //     batteryToAdd.charger.discountTotal = null;
  //     batteryToAdd.charger.discountIncrease = true;
  //     batteryToAdd.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
  //     batteryToAdd.charger.manualAddedPrice = batteryToAdd.charger.totalPrice;
  //     batteryToAdd.charger.discountPrice = 0;
  //   } else {
  //     batteryToAdd.charger.discountIncrease = batteryToAdd.discountIncrease;
  //     batteryToAdd.charger.discountType = batteryToAdd.discountType;
  //   }

  //   // TODO: Fix model WHEN IS KNOWN
  //   batteryToAdd.charger.model = charger.model;

  //   const newUpdatedQuoteItem:
  //     | QuoteItemBatteryTypes
  //     | QuoteItemForkliftTypes
  //     | QuoteItemChargerTypes
  //     | null =
  //     updatedQuoteItem.itemType === "forklift"
  //       ? currentQuote.quoteItems[currentQuoteItems.length - 1]
  //       : currentQuote.quoteItems[currentQuoteItems.length - 1].battery;

  //   if (!newUpdatedQuoteItem) return;

  //   currentQuote.quoteItems[currentQuoteItems.length - 1] = newUpdatedQuoteItem;

  //   // save selected charger
  //   localStorage.setItem(
  //     storageVariables.SELECTED_CHARGER,
  //     JSON.stringify(charger)
  //   );

  //   updateCurrentQuoteTotalPrice(currentQuote);
  // };

  const addChargerToQuoteByBatteryFlow = (
    charger: ChargerTypes,
    quantity = 1
  ): void => {
    // if (!charger) return;

    // const currentQuote: CurrentQuoteTypes | null = JSON.parse(
    //   localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    // );

    // const currentQuoteItems = currentQuote?.quoteItems || [];

    // if (!currentQuote || currentQuoteItems.length === 0) return;

    // const updatedQuoteItem:
    //   | QuoteItemForkliftTypes
    //   | QuoteItemBatteryTypes
    //   | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

    // if (updatedQuoteItem.itemType !== "battery") return;

    // const batteryToAdd: QuoteItemBatteryTypes | null = updatedQuoteItem;

    // if (!batteryToAdd) return;

    // batteryToAdd.charger = initialCharger;

    // batteryToAdd.charger.id = charger.chargerId;
    // batteryToAdd.charger.name = charger.model;
    // batteryToAdd.charger.voltage = charger.batteryVoltage;
    // batteryToAdd.charger.imageUrl = charger.imageUrl || fallbackChargerImg;

    // batteryToAdd.charger.quantity = quantity;
    // batteryToAdd.charger.itemPrice = charger.price?.price || 0;

    // batteryToAdd.charger.totalPrice = fixNumber(
    //   batteryToAdd.charger.itemPrice * batteryToAdd.charger.quantity
    // );

    // // TODO: Fix model WHEN IS KNOWN
    // batteryToAdd.charger.model = charger.model;

    // currentQuote.quoteItems[currentQuoteItems.length - 1] = batteryToAdd;

    // updateCurrentQuoteTotalPrice(currentQuote);

    if (!charger) return;

    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[currentQuoteItems.length - 1];

    if (updatedQuoteItem.itemType !== "battery") return;

    const batteryToAdd: QuoteItemBatteryTypes | null = updatedQuoteItem;

    if (!batteryToAdd) return;

    if (!batteryToAdd.charger) {
      addChargerUnderBatteryBatteryFlow(charger, quantity);
    } else {
      addChargerWithBattery(updatedQuoteItem, charger, quantity);
    }
  };

  const updateCurrentQuoteTotalDiscount = (
    newCurrentQuote: CurrentQuoteTypes
  ): void => {
    const currentQuote: CurrentQuoteTypes = { ...newCurrentQuote };

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    let totalDiscount = 0;

    currentQuoteItems.forEach((quoteItem) => {
      if (quoteItem.itemType === "forklift" && quoteItem?.battery) {
        totalDiscount += quoteItem.battery.discountTotal || 0;
        if (quoteItem.battery?.charger) {
          totalDiscount += quoteItem.battery.charger.discountTotal || 0;
        }
      } else if (quoteItem.itemType === "battery") {
        totalDiscount += quoteItem.discountTotal || 0;
        if (quoteItem?.charger) {
          totalDiscount += quoteItem.charger.discountTotal || 0;
        }
      } else if (quoteItem.itemType === "charger") {
        totalDiscount += quoteItem.discountTotal || 0;
      }
    });

    currentQuote.totalDiscount = fixNumber(totalDiscount);

    updateCurrentQuoteTotalPrice(currentQuote);
  };

  const deleteChargerByForkliftFlow = (index: number): boolean => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return false;

    const updatedForkliftQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (
      updatedForkliftQuoteItem.itemType !== "forklift" ||
      !updatedForkliftQuoteItem?.battery
    ) {
      return false;
    }

    updatedForkliftQuoteItem.battery.charger = null;

    currentQuoteItems[index] = updatedForkliftQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);

    return true;
  };
  const deleteChargerByBatteryFlow = (index: number): boolean => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return false;

    const updatedForkliftQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (
      updatedForkliftQuoteItem.itemType !== "forklift" ||
      !updatedForkliftQuoteItem?.battery
    ) {
      return false;
    }

    updatedForkliftQuoteItem.battery.charger = null;

    currentQuoteItems[index] = updatedForkliftQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);

    return true;
  };
  const deleteChargerInBattery = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedForkliftQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (updatedForkliftQuoteItem.itemType === "charger") {
      return;
    }

    const batteryForForUpdate: QuoteItemBatteryTypes | null =
      updatedForkliftQuoteItem.itemType === "forklift"
        ? updatedForkliftQuoteItem.battery
        : updatedForkliftQuoteItem;

    if (!batteryForForUpdate) return;

    batteryForForUpdate.charger = null;

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedForkliftQuoteItem.itemType === "forklift"
        ? { ...updatedForkliftQuoteItem, battery: batteryForForUpdate }
        : batteryForForUpdate;

    currentQuote.quoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const deleteQuoteBlockByIndex = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote || currentQuote?.quoteItems?.length === 0) return;

    // remove the quote item by index
    if (currentQuote?.quoteItems.length === 1) {
      currentQuote.quoteItems = [];

      currentQuote.totalPriceExclVat = 0;
      currentQuote.totalPriceInclVat = 0;
      currentQuote.totalDiscount = 0;

      localStorage.setItem(
        storageVariables.CURRENT_QUOTE,
        JSON.stringify(currentQuote)
      );

      setCurrentQuote(currentQuote);
    } else {
      currentQuote?.quoteItems.splice(index, 1);
      updateCurrentQuoteTotalDiscount(currentQuote);
    }
    window.location.reload();
  };

  const updateBatteryQuantity = ({
    index,
    updateType,
    quantity = 1,
  }: {
    index: number;
    updateType: "increase" | "decrease" | "manual";
    quantity?: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    // If the item is a forklift, take the battery and update it
    // If the item is a battery, update it
    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery) return;

    if (updateType === "manual") {
      tempBattery.quantity = quantity;
    } else if (updateType === "increase") {
      tempBattery.quantity += quantity;
    } else {
      tempBattery.quantity -= quantity;
    }

    if (tempBattery.manualAddedPrice === null) {
      // If the discount type is a amount off
      if (tempBattery.discountType === DiscountTypesEnum.AMOUNT_OFF) {
        const tempDiscountTotal = tempBattery.discountIncrease
          ? tempBattery.discountTotal || 0
          : (tempBattery.discountTotal || 0) * -1;

        tempBattery.totalPrice = fixNumber(
          tempBattery.itemPrice * tempBattery.quantity + tempDiscountTotal
        );
      } else {
        tempBattery.discountTotal = fixNumber(
          tempBattery.itemPrice *
            tempBattery.quantity *
            ((tempBattery.discountPercentage || 0) / 100)
        );
        if (tempBattery.discountIncrease) {
          tempBattery.totalPrice = fixNumber(
            tempBattery.itemPrice * tempBattery.quantity +
              tempBattery.discountTotal
          );
        } else {
          tempBattery.totalPrice = fixNumber(
            tempBattery.itemPrice * tempBattery.quantity -
              tempBattery.discountTotal
          );
        }
      }
    }

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuote.quoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };
  const updateChargerQuantity = ({
    index,
    updateType,
    quantity = 1,
  }: {
    index: number;
    updateType: "increase" | "decrease" | "manual";
    quantity?: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType !== "charger") return;

    const tempCharger: QuoteItemChargerTypes | null = updatedQuoteItem;

    if (!tempCharger) return;

    if (updateType === "manual") {
      tempCharger.quantity = quantity;
    } else if (updateType === "increase") {
      tempCharger.quantity += quantity;
    } else {
      tempCharger.quantity -= quantity;
    }

    if (tempCharger.manualAddedPrice === null) {
      // If the discount type is a amount off
      if (tempCharger.discountType === DiscountTypesEnum.AMOUNT_OFF) {
        const tempDiscountTotal = tempCharger.discountIncrease
          ? tempCharger.discountTotal || 0
          : (tempCharger.discountTotal || 0) * -1;

        tempCharger.totalPrice = fixNumber(
          tempCharger.itemPrice * tempCharger.quantity + tempDiscountTotal
        );
      } else {
        tempCharger.discountTotal = fixNumber(
          tempCharger.itemPrice *
            tempCharger.quantity *
            ((tempCharger.discountPercentage || 0) / 100)
        );
        if (tempCharger.discountIncrease) {
          tempCharger.totalPrice = fixNumber(
            tempCharger.itemPrice * tempCharger.quantity +
              tempCharger.discountTotal
          );
        } else {
          tempCharger.totalPrice = fixNumber(
            tempCharger.itemPrice * tempCharger.quantity -
              tempCharger.discountTotal
          );
        }
      }
    }
    // else {
    //   tempCharger.manualAddedPrice = fixNumber(
    //     tempCharger.itemPrice * tempCharger.quantity
    //   );
    //   tempCharger.totalPrice = tempCharger.manualAddedPrice;
    // }

    currentQuote.quoteItems[index] = tempCharger;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const updateChargerInBatteryQuantity = ({
    index,
    updateType,
    quantity = 1,
  }: {
    index: number;
    updateType: "increase" | "decrease" | "manual";
    quantity?: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    // If the item is a forklift, take the battery and update it
    // If the item is a battery, update it
    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery || !tempBattery?.charger) return;

    if (updateType === "manual") {
      tempBattery.charger.quantity = quantity;
    } else if (updateType === "increase") {
      tempBattery.charger.quantity += quantity;
    } else {
      tempBattery.charger.quantity -= quantity;
    }

    if (tempBattery.manualAddedPrice === null) {
      // If the discount type is a amount off
      if (tempBattery.discountType === DiscountTypesEnum.AMOUNT_OFF) {
        const tempDiscountTotal = tempBattery.charger.discountIncrease
          ? tempBattery.charger.discountTotal || 0
          : (tempBattery.charger.discountTotal || 0) * -1;

        tempBattery.charger.totalPrice = fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity +
            tempDiscountTotal
        );
      } else {
        tempBattery.charger.discountTotal = fixNumber(
          tempBattery.charger.itemPrice *
            tempBattery.charger.quantity *
            ((tempBattery.charger.discountPercentage || 0) / 100)
        );
        if (tempBattery.charger.discountIncrease) {
          tempBattery.charger.totalPrice = fixNumber(
            tempBattery.charger.itemPrice * tempBattery.charger.quantity +
              tempBattery.charger.discountTotal
          );
        } else {
          tempBattery.charger.totalPrice = fixNumber(
            tempBattery.charger.itemPrice * tempBattery.charger.quantity -
              tempBattery.charger.discountTotal
          );
        }
      }
    }

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuote.quoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const toggleManualAddedPriceForBattery = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    // If the item is a forklift, take the battery and update it
    // If the item is a battery, update it
    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery) return;

    // Enable the manual price
    if (tempBattery.manualAddedPrice === null) {
      tempBattery.discountTotal = null;
      tempBattery.discountIncrease = false;
      tempBattery.discountPercentage = null;
      tempBattery.discountPrice = 0;
      tempBattery.discountType = DiscountTypesEnum.AMOUNT_OFF;
      tempBattery.totalPrice = fixNumber(
        tempBattery.itemPrice * tempBattery.quantity
      );
      tempBattery.manualAddedPrice = tempBattery.totalPrice;

      if (tempBattery?.charger) {
        tempBattery.charger.discountTotal = null;
        tempBattery.charger.discountIncrease = false;
        tempBattery.charger.discountPercentage = null;
        tempBattery.charger.discountPrice = 0;
        tempBattery.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
        tempBattery.charger.totalPrice = fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity
        );
        tempBattery.charger.manualAddedPrice = tempBattery.charger.totalPrice;
      }

      // put the updated quote item back into the updatedQuoteItem
      const newUpdatedQuoteItem:
        | QuoteItemBatteryTypes
        | QuoteItemForkliftTypes =
        updatedQuoteItem.itemType === "forklift"
          ? { ...updatedQuoteItem, battery: tempBattery }
          : tempBattery;

      currentQuote.quoteItems[index] = newUpdatedQuoteItem;
    } else {
      // Disable the manual price
      tempBattery.manualAddedPrice = null;
      tempBattery.discountTotal = 0;
      tempBattery.discountPercentage = 0;
      tempBattery.discountPrice = 0;
      tempBattery.discountType = DiscountTypesEnum.AMOUNT_OFF;
      tempBattery.discountIncrease = false;

      tempBattery.totalPrice = fixNumber(
        tempBattery.itemPrice * tempBattery.quantity
      );

      if (tempBattery?.charger) {
        tempBattery.charger.manualAddedPrice = null;
        tempBattery.charger.discountTotal = 0;
        tempBattery.charger.discountPrice = 0;
        tempBattery.charger.discountPercentage = 0;
        tempBattery.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
        tempBattery.charger.discountIncrease = false;

        tempBattery.charger.totalPrice = fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity
        );
      }

      // put the updated quote item back into the updatedQuoteItem
      const newUpdatedQuoteItem:
        | QuoteItemBatteryTypes
        | QuoteItemForkliftTypes =
        updatedQuoteItem.itemType === "forklift"
          ? { ...updatedQuoteItem, battery: tempBattery }
          : tempBattery;

      currentQuote.quoteItems[index] = newUpdatedQuoteItem;
    }

    updateCurrentQuoteTotalDiscount(currentQuote);
  };
  const toggleManualAddedPriceForCharger = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType !== "charger") return;

    const tempCharger: QuoteItemChargerTypes | null = updatedQuoteItem;

    if (!tempCharger) return;

    // Enable the manual price
    if (tempCharger.manualAddedPrice === null) {
      tempCharger.discountTotal = null;
      tempCharger.discountIncrease = false;
      tempCharger.discountPercentage = null;
      tempCharger.discountPrice = 0;
      tempCharger.discountType = DiscountTypesEnum.AMOUNT_OFF;
      tempCharger.totalPrice = fixNumber(
        tempCharger.itemPrice * tempCharger.quantity
      );
      tempCharger.manualAddedPrice = tempCharger.totalPrice;

      currentQuote.quoteItems[index] = tempCharger;
    } else {
      // Disable the manual price
      tempCharger.manualAddedPrice = null;
      tempCharger.discountTotal = 0;
      tempCharger.discountPercentage = 0;
      tempCharger.discountPrice = 0;
      tempCharger.discountType = DiscountTypesEnum.AMOUNT_OFF;
      tempCharger.discountIncrease = false;

      tempCharger.totalPrice = fixNumber(
        tempCharger.itemPrice * tempCharger.quantity
      );

      currentQuote.quoteItems[index] = tempCharger;
    }

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const changeSelectedFinalPriceBattery = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const tempBattery:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!tempBattery || tempBattery.itemType === "charger") return;

    if (tempBattery.itemType === "forklift") {
      if (!tempBattery?.battery) return;

      tempBattery.battery.manualAddedPrice = fixNumber(value);
      tempBattery.battery.totalPrice = fixNumber(value);
    }

    if (tempBattery.itemType === "battery") {
      tempBattery.manualAddedPrice = fixNumber(value);
      tempBattery.totalPrice = fixNumber(value);
    }

    currentQuoteItems[index] = tempBattery;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };
  const changeSelectedFinalPriceCharger = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const tempCharger:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!tempCharger || tempCharger.itemType !== "charger") return;

    tempCharger.manualAddedPrice = fixNumber(value);
    tempCharger.totalPrice = fixNumber(value);

    currentQuoteItems[index] = tempCharger;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const changeSelectedFinalPriceChargerInBattery = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return;

    const tempBattery:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!tempBattery || tempBattery.itemType === "charger") return;

    if (tempBattery.itemType === "forklift") {
      if (!tempBattery?.battery || !tempBattery?.battery?.charger) return;

      tempBattery.battery.charger.manualAddedPrice = fixNumber(value);
      tempBattery.battery.charger.totalPrice = fixNumber(value);
    }

    if (tempBattery.itemType === "battery") {
      if (!tempBattery?.charger) return;

      tempBattery.charger.manualAddedPrice = fixNumber(value);
      tempBattery.charger.totalPrice = fixNumber(value);
    }

    currentQuoteItems[index] = tempBattery;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const toggleDiscountTypeBattery = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery) return;

    if (tempBattery.discountType === DiscountTypesEnum.PERCENTAGE) {
      tempBattery.discountType = DiscountTypesEnum.AMOUNT_OFF;
      if (tempBattery?.charger) {
        tempBattery.charger.discountType = DiscountTypesEnum.AMOUNT_OFF;
      }
    } else {
      tempBattery.discountType = DiscountTypesEnum.PERCENTAGE;

      if (tempBattery?.charger) {
        tempBattery.charger.discountType = DiscountTypesEnum.PERCENTAGE;
      }
    }

    tempBattery.discountPercentage = 0;
    tempBattery.discountTotal = 0;
    tempBattery.discountPrice = 0;
    tempBattery.totalPrice = fixNumber(
      tempBattery.itemPrice * tempBattery.quantity
    );

    if (tempBattery?.charger) {
      tempBattery.charger.discountPercentage = 0;
      tempBattery.charger.discountTotal = 0;
      tempBattery.charger.discountPrice = 0;
      tempBattery.charger.totalPrice = fixNumber(
        tempBattery.charger.itemPrice * tempBattery.charger.quantity
      );
    }

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };
  const toggleDiscountTypeCharger = (index: number): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType !== "charger") return;

    const tempCharger: QuoteItemChargerTypes | null = updatedQuoteItem;

    if (!tempCharger) return;

    if (tempCharger.discountType === DiscountTypesEnum.PERCENTAGE) {
      tempCharger.discountType = DiscountTypesEnum.AMOUNT_OFF;
    } else {
      tempCharger.discountType = DiscountTypesEnum.PERCENTAGE;
    }

    tempCharger.discountPercentage = 0;
    tempCharger.discountTotal = 0;
    tempCharger.discountPrice = 0;
    tempCharger.totalPrice = fixNumber(
      tempCharger.itemPrice * tempCharger.quantity
    );

    currentQuoteItems[index] = tempCharger;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const toggleDiscountIncreaseBattery = (index: number): boolean => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    if (!currentQuote) return false;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return false;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger")
      return false;

    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery) return false;

    tempBattery.discountTotal = 0;
    tempBattery.discountPrice = 0;
    tempBattery.discountPercentage = 0;

    if (tempBattery?.charger) {
      tempBattery.charger.discountPrice = 0;
      tempBattery.charger.discountTotal = 0;
      tempBattery.charger.discountPercentage = 0;
    }

    if (tempBattery.discountIncrease === true) {
      tempBattery.discountIncrease = false;

      tempBattery.totalPrice = fixNumber(
        tempBattery.itemPrice * tempBattery.quantity -
          (tempBattery?.discountTotal ?? 0)
      );

      if (tempBattery?.charger) {
        tempBattery.charger.discountIncrease = false;
        tempBattery.charger.totalPrice = fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity -
            (tempBattery.charger?.discountTotal ?? 0)
        );
      }
    } else {
      tempBattery.discountIncrease = true;
      tempBattery.totalPrice = fixNumber(
        tempBattery.itemPrice * tempBattery.quantity +
          (tempBattery?.discountTotal ?? 0)
      );

      if (tempBattery?.charger) {
        tempBattery.charger.discountIncrease = true;
        tempBattery.charger.totalPrice = fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity +
            (tempBattery.charger?.discountTotal ?? 0)
        );
      }
    }

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);

    return true;
  };
  const toggleDiscountIncreaseCharger = (index: number): boolean => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) ?? ""
    );

    if (!currentQuote) return false;
    const currentQuoteItems = currentQuote?.quoteItems || [];
    if (!currentQuote || currentQuoteItems.length === 0) return false;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType !== "charger")
      return false;

    const tempCharger: QuoteItemChargerTypes | null = updatedQuoteItem;

    if (!tempCharger) return false;

    tempCharger.discountTotal = 0;
    tempCharger.discountPrice = 0;
    tempCharger.discountPercentage = 0;

    if (tempCharger.discountIncrease === true) {
      tempCharger.discountIncrease = false;
      tempCharger.totalPrice = fixNumber(
        tempCharger.itemPrice * tempCharger.quantity -
          (tempCharger?.discountTotal || 0)
      );
    } else {
      tempCharger.discountIncrease = true;
      tempCharger.totalPrice = fixNumber(
        tempCharger.itemPrice * tempCharger.quantity +
          (tempCharger?.discountTotal || 0)
      );
    }

    currentQuoteItems[index] = tempCharger;

    updateCurrentQuoteTotalDiscount(currentQuote);

    return true;
  };

  const updateDiscountValueChangeBattery = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery) return;

    tempBattery.discountPercentage =
      tempBattery.discountType === DiscountTypesEnum.PERCENTAGE ? value : 0;
    tempBattery.discountPrice =
      tempBattery.discountType === DiscountTypesEnum.AMOUNT_OFF ? value : 0;

    tempBattery.discountTotal = calculateDiscountTotal({
      discountType: tempBattery.discountType,
      discountValue: value,
      unitPrice: tempBattery.itemPrice,
      quantity: tempBattery.quantity,
    });

    tempBattery.totalPrice = tempBattery.discountIncrease
      ? fixNumber(
          tempBattery.itemPrice * tempBattery.quantity +
            tempBattery.discountTotal
        )
      : fixNumber(
          tempBattery.itemPrice * tempBattery.quantity -
            tempBattery.discountTotal
        );

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };
  const updateDiscountValueChangeCharger = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType !== "charger") return;

    const tempCharger: QuoteItemChargerTypes | null = updatedQuoteItem;

    if (!tempCharger) return;

    tempCharger.discountPercentage =
      tempCharger.discountType === DiscountTypesEnum.PERCENTAGE ? value : 0;
    tempCharger.discountPrice =
      tempCharger.discountType === DiscountTypesEnum.AMOUNT_OFF ? value : 0;

    tempCharger.discountTotal = calculateDiscountTotal({
      discountType: tempCharger.discountType,
      discountValue: value,
      unitPrice: tempCharger.itemPrice,
      quantity: tempCharger.quantity,
    });

    tempCharger.totalPrice = tempCharger.discountIncrease
      ? fixNumber(
          tempCharger.itemPrice * tempCharger.quantity +
            tempCharger.discountTotal
        )
      : fixNumber(
          tempCharger.itemPrice * tempCharger.quantity -
            tempCharger.discountTotal
        );

    currentQuoteItems[index] = tempCharger;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  const updateDiscountValueChangeChargerInBattery = ({
    index,
    value,
  }: {
    index: number;
    value: number;
  }): void => {
    const currentQuote: CurrentQuoteTypes | null = JSON.parse(
      localStorage.getItem(storageVariables.CURRENT_QUOTE) || ""
    );

    const currentQuoteItems = currentQuote?.quoteItems || [];

    if (!currentQuote || currentQuoteItems.length === 0) return;

    const updatedQuoteItem:
      | QuoteItemForkliftTypes
      | QuoteItemBatteryTypes
      | QuoteItemChargerTypes = currentQuoteItems[index];

    if (!updatedQuoteItem || updatedQuoteItem.itemType === "charger") return;

    const tempBattery: QuoteItemBatteryTypes | null =
      updatedQuoteItem.itemType === "forklift"
        ? updatedQuoteItem?.battery
        : updatedQuoteItem;

    if (!tempBattery || !tempBattery?.charger) return;

    tempBattery.charger.discountPercentage =
      tempBattery.charger.discountType === DiscountTypesEnum.PERCENTAGE
        ? value
        : 0;
    tempBattery.charger.discountPrice =
      tempBattery.charger.discountType === DiscountTypesEnum.AMOUNT_OFF
        ? value
        : 0;

    tempBattery.charger.discountTotal = calculateDiscountTotal({
      discountType: tempBattery.charger.discountType,
      discountValue: value,
      unitPrice: tempBattery.charger.itemPrice,
      quantity: tempBattery.charger.quantity,
    });

    tempBattery.charger.totalPrice = tempBattery.charger.discountIncrease
      ? fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity +
            tempBattery.charger.discountTotal
        )
      : fixNumber(
          tempBattery.charger.itemPrice * tempBattery.charger.quantity -
            tempBattery.charger.discountTotal
        );

    const newUpdatedQuoteItem: QuoteItemBatteryTypes | QuoteItemForkliftTypes =
      updatedQuoteItem.itemType === "forklift"
        ? { ...updatedQuoteItem, battery: tempBattery }
        : tempBattery;

    currentQuoteItems[index] = newUpdatedQuoteItem;

    updateCurrentQuoteTotalDiscount(currentQuote);
  };

  return {
    // Toggle
    toggleManualAddedPriceForBattery,
    toggleManualAddedPriceForCharger,

    toggleDiscountIncreaseBattery,
    toggleDiscountIncreaseCharger,

    toggleDiscountTypeBattery,
    toggleDiscountTypeCharger,

    // Add
    addBatteryToQuoteByForklift,
    addChargerToQuoteByForklift,
    addBatteryToQuoteByBatteryFlow,
    addChargerToQuoteByBatteryFlow,
    addChargerToQuoteByChargerFlow,

    // Delete
    deleteQuoteBlockByIndex,
    deleteChargerByForkliftFlow,
    deleteChargerByBatteryFlow,
    deleteChargerInBattery,

    // Quantity Update
    updateBatteryQuantity,
    updateChargerInBatteryQuantity,
    updateChargerQuantity,

    // Discount Value
    updateDiscountValueChangeBattery,
    updateDiscountValueChangeChargerInBattery,
    updateDiscountValueChangeCharger,

    // Final Price
    changeSelectedFinalPriceBattery,
    changeSelectedFinalPriceChargerInBattery,
    changeSelectedFinalPriceCharger,

    // Update
    updateCurrentQuoteTotalDiscount,

    // Others
    updateQuoteExpirationDate,
  };
};
