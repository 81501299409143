/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC, useEffect, useState } from "react";
import {
  fallbackBatteryImg,
  fallbackChargerImg,
  fallbackTruckImg,
} from "../../assets/mockData/fallbackImages";

interface ImageWithFallbackPropsTypes {
  type: "forklift" | "battery" | "charger";
  imageUrl: string | null | undefined;
}

const ImageWithFallback: FC<ImageWithFallbackPropsTypes> = ({
  type,
  imageUrl,
}) => {
  const [image, setImage] = useState<string | null | undefined>(imageUrl);

  const getFallbackImage = (): string => {
    if (type === "forklift") return fallbackTruckImg;
    if (type === "battery") return fallbackBatteryImg;
    return fallbackChargerImg;
  };

  const handleImageError = () => {
    setImage(getFallbackImage());
  };

  useEffect(() => {
    if (!imageUrl || imageUrl === "") {
      handleImageError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (imageUrl && imageUrl !== "") setImage(imageUrl);
  }, [imageUrl]);

  return (
    <>
      {type === "forklift" ? (
        <img
          src={image ?? getFallbackImage()}
          onError={handleImageError}
          alt={`${type} image`}
          title={`${type} image`}
          className="image-class"
          data-html2canvas-ignore
        />
      ) : (
        <img
          src={image ?? getFallbackImage()}
          onError={handleImageError}
          alt={`${type} image`}
          title={`${type} image`}
          className="image-class"
        />
      )}
    </>
  );
};

export default ImageWithFallback;
