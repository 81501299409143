import React, { FC } from "react";

interface PropsTypes {
  title: string;
  onClick: () => void;
}

const ButtonChooseJourney: FC<PropsTypes> = ({ title, onClick }) => {
  return (
    <div className="choose-journey-box underline-hover-btn" onClick={onClick}>
      <div className="choose-journey-title btn-text">{title}</div>
    </div>
  );
};

export default ButtonChooseJourney;
