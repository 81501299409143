import React, { FC } from "react";
import { BannerRightTopTypes } from "../../../types/bannersTypes";
import ButtonProductAddToQuote from "../../Buttons/ButtonProductAddToQuote";
import formatMoney from "../../../helpers/formatMoney";
import { useFormatPrice } from "../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../helpers/storage";
import ImageWithFallback from "../../Media/ImageWithFallback";

interface RightBannerTopPropsTypes {
  rightBannerTopValues: BannerRightTopTypes;
  addItemToQuote: () => void;
}

const RightBannerTopChargers: FC<RightBannerTopPropsTypes> = ({
  rightBannerTopValues,
  addItemToQuote,
}) => {
  const { title, imageUrl, price } = rightBannerTopValues;

  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const priceObj = formatMoney({
    amount: price || 0,
    country: currency,
    showMinusSign: true,
    separateDecimals: true,
  });

  return (
    <div className="product-details-top">
      <div className="product-details-top-left">
        <ImageWithFallback type="charger" imageUrl={imageUrl} />
      </div>
      <div className="product-details-top-right">
        <div className="product-details-title">{title}</div>
        <div className="product-details-price-outer">
          <div className="product-details-price">
            {getFormattedPriceFromObj(priceObj)}
          </div>

          <ButtonProductAddToQuote onClick={addItemToQuote} />
        </div>
      </div>
    </div>
  );
};

export default RightBannerTopChargers;
