/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";

const ChargerSearchHeader: FC = () => {
  return (
    <div className="">
      <div className="row box-grid-container">
        <div className="col-md-6 col-left-align">
          <div className="title-form">
            <div className="title-form-text">Let&apos;s find a charger</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargerSearchHeader;
