import React, { FC, useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/CartContext";
import { FetchedDataContext } from "../../context/FetchedDataContext";
import { AuthContext } from "../../context/AuthContext";

const ErrorPopup: FC = () => {
  const { apiError, setApiError } = useContext(CartContext);
  const { fetchDataApiError, setFetchDataApiError } =
    useContext(FetchedDataContext);
  const { authApiError, setAuthApiError } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const closePopup = () => {
    setShowPopup(false);
    if (apiError) setApiError(null);
    if (fetchDataApiError) setFetchDataApiError(null);
    if (authApiError) setAuthApiError(null);
  };

  const defaultMessage =
    "Oops, something went wrong on the server, please refresh the page to try again.";

  useEffect(() => {
    if (apiError || fetchDataApiError || authApiError) {
      setShowPopup(true);
    }
  }, [apiError, fetchDataApiError, authApiError]);

  return (
    <div className={`popup-outer ${showPopup ? "open" : ""}`}>
      <div className="popup-bg" />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="popup-title">
            {apiError || fetchDataApiError || authApiError || defaultMessage}
          </div>
          <div className="popup-message">
            If the problem persists, please contact the IT Department.
          </div>
          <div className="popup-poupup-btn" onClick={closePopup}>
            <div className="yellow-btn corner-btn">
              <div className="btn-text">Close</div>
              <div className="btn-icon">
                <div className="icon symmetry-icon-cancel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
