import React, { FC } from "react";
import ClientContactItem from "../../ListItems/ClientContactItem";

interface ContactsTypes {
  firstName: string;
  email: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  title: string;
}
interface ContactsAddedPropsTypes {
  contactPersons: ContactsTypes[];
  // eslint-disable-next-line no-unused-vars
  onClick: (id: number) => void;
}
const ContactsAdded: FC<ContactsAddedPropsTypes> = ({
  contactPersons,
  onClick,
}) => {
  const chargercurrentTooltip = (
    <div className="tooltip-container tooltiptext">Delete Contact</div>
  );
  return (
    <div className="col-md-6">
      <div className="table-title">Contacts Added</div>
      <div className="clients-table-outer">
        <div className="clients-table-inner">
          {contactPersons &&
            contactPersons.map.length > 0 &&
            contactPersons.map((contactData: ContactsTypes, idx: number) => (
              <ClientContactItem
                key={idx}
                {...contactData}
                onClick={() => onClick(idx)}
                hasTooltip={chargercurrentTooltip}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ContactsAdded;
