/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";

interface PropsTypes {
  onClick: () => void;
}

const ButtonDelete: FC<PropsTypes> = ({ onClick }) => {
  return (
    <div
      className="col-lg-1 col-md-1 col-sm-1 cart-item-col cart-item-remove"
      data-html2canvas-ignore
    >
      <div className="cart-item-top">
        <div className="cart-item-remove-label" />
      </div>
      <div className="cart-item-bottom">
        <div className="cart-item-remove" onClick={onClick} role="button">
          <div className="cart-item-remove-box corner-btn-right">
            <div data-icon="$" className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonDelete;
