import React, { FC, useEffect } from "react";
import ErrorPopup from "../Popups/ErrorPopup";

interface MainPageWrapperPropsTypes {
  children: React.ReactNode;
  testid?: string;
  title?: string;
}

const MainPageWrapper: FC<MainPageWrapperPropsTypes> = ({
  testid,
  title,
  children,
}) => {
  useEffect(() => {
    if (!title) document.title = "Sunlight";
    else document.title = `${title} | Sunlight`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="main-page-wrapper container"
        data-testid={`main-page-wrapper-${testid ?? ""}`}
      >
        {children}
      </div>
      <ErrorPopup />
    </>
  );
};

export default MainPageWrapper;
