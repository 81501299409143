import React, { FC } from "react";

export interface SimpleInputTypes {
  value: string | number | null;
  name: string;
  placeholder?: string;
  errorMessage: string | null;
  onChange: (_value: string) => void;
  hasTooltip?: React.ReactNode;
}

const SimpleInput: FC<SimpleInputTypes> = ({
  value,
  name,
  placeholder,
  errorMessage,
  onChange,
  hasTooltip,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  // eslint-disable-next-line no-nested-ternary
  let valueProp = value ?? "";

  if (typeof value === "number") {
    valueProp = value.toString();
  }

  return (
    <div
      className={`col-form-item ${
        hasTooltip !== undefined ? "tooltip_outer" : ""
      }`}
    >
      <input
        name={name}
        value={valueProp}
        type="text"
        onChange={handleInputChange}
        placeholder={placeholder ?? ""}
        autoComplete="off"
      />
      {hasTooltip !== undefined && hasTooltip}
      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </div>
  );
};

export default SimpleInput;
