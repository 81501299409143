import React, { FC } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import SunlightLogo from "./SunlightLogo";
import NavbarLinks from "./NavbarLinks";
import UserNavLinks from "./UserNavLink";

const NavBarDesktop: FC = () => {
  return (
    <nav className="navbar fixed-top bg-body-tertiary">
      <div className="container container-fluid max-width-1600">
        <SunlightLogo />
        <AuthenticatedTemplate>
          <NavbarLinks />
        </AuthenticatedTemplate>
        <UserNavLinks />
      </div>
    </nav>
  );
};

export default NavBarDesktop;
