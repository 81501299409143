/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, FC, useEffect, useContext } from "react";

import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";

import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import ButtonNext from "../../components/Buttons/ButtonNext";
import ButtonAdd from "../../components/Buttons/ButtonAdd";

import { storageVariables } from "../../lib/storageVariables";
import { FetchedCompanyTypes } from "../../types/companies";
import { FetchedForkliftFleetTypesNew } from "../../types/forkliftFleetTypes";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import { getForkliftFullName } from "../../helpers/functions";
import ReactSelectInput from "../../components/Inputs/ReactSelectInput";
import { ReactSelectOptionsTypes } from "../../types/inputs";
import { sortForkliftsByName } from "../../helpers/sorting";
import { CartContext } from "../../context/CartContext";
import { ModalContext } from "../../context/ModalContext";
import { alertAddCompanyFirst } from "../../settings/confirmAlertMessages";
import AlertPopup from "../../components/Popups/AlertPopup";

const SelectForkliftPage: FC = () => {
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const { openAlert, showAlert, alertMsg } = useContext(ModalContext);

  const { eraseSelectedBattery, eraseSelectedCharger } =
    useContext(CartContext);

  const { navigateTo, fullNavigateToUrl } = useNavigation();

  const [inputValue, setInputValue] = useState<string | null>(null);

  const placeholder = "Select from existing fleet";
  const [selectedFleet, setSelectedFleet] = useState<string>(placeholder);

  const selectedFleetBool = selectedFleet !== placeholder;
  const [notes, setNotes] = useState<string | null>(null);
  const [fleets, setFleets] = useState<FetchedForkliftFleetTypesNew[]>([]);
  const [selectedCompany, setSelectedCompany] =
    useState<FetchedCompanyTypes | null>(null);

  // save the forkliftId in the local storage
  const handleAddNewForklift = () => {
    const selectedCompanyStr: string | null = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );

    if (!selectedCompanyStr) {
      openAlert(alertAddCompanyFirst);
      return;
    }

    navigateTo(availableRoutes.ADD_NEW_FORKLIFT_TO_EXISTING_FLEET);
  };

  const startNewOrder = () => {
    if (!inputValue) return;

    navigateTo(availableRoutes.SELECT_BATTERY_BY_FORKLIFT);
  };

  const navigateToQuoteHistory = () => {
    fullNavigateToUrl(availableRoutes.QUOTES_BY_FORKLIFT_ID);
  };

  const changeSelectedFleet = (fleet: FetchedForkliftFleetTypesNew) => {
    localStorage.setItem(
      storageVariables.SELECTED_FORKLIFT,
      JSON.stringify(fleet)
    );

    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    eraseSelectedBattery();
    eraseSelectedCharger();

    setSelectedFleet(getForkliftFullName(fleet));
  };

  useEffect(() => {
    // delete the selected forklift from the local storage
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);

    const company: string | null = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );
    if (company) {
      const parsedCompany: FetchedCompanyTypes = JSON.parse(company);
      setSelectedCompany(parsedCompany);
    } else {
      setSelectedCompany(null);
      setInputValue(null);
    }
  }, []);

  useEffect(() => {
    if (!inputValue) return;

    handleAuthApiCall({
      method: "get",
      url: `/v2/fleets/companies/${inputValue}`,
      isSearchRequest: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const [availableFleets, setAvailableFleets] = useState<
    ReactSelectOptionsTypes[]
  >([]);

  const [selectedOption, setSelectedOption] =
    useState<ReactSelectOptionsTypes | null>(null);

  const handleSelectChange = (option: ReactSelectOptionsTypes | null) => {
    setSelectedOption(option);
    if (!option) {
      localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
      setSelectedFleet(placeholder);

      const selectedForklift: FetchedForkliftFleetTypesNew = JSON.parse(
        localStorage.getItem(storageVariables.SELECTED_FORKLIFT) || "{}"
      );
      setNotes(selectedForklift?.notes);
    } else {
      const tempFleet: FetchedForkliftFleetTypesNew = JSON.parse(option.value);

      changeSelectedFleet(tempFleet);
      const selectedForklift: FetchedForkliftFleetTypesNew = JSON.parse(
        localStorage.getItem(storageVariables.SELECTED_FORKLIFT) || "{}"
      );
      setNotes(selectedForklift?.notes);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      setInputValue(JSON.stringify(selectedCompany.companyId));
    } else {
      setInputValue(null);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!apiData || !apiData?.success || !apiData?.companyFleet) return;

    setFleets(apiData.companyFleet);

    const newForkliftAdded = localStorage.getItem(
      storageVariables.NEW_FORKLIFT_ADDED
    );

    if (newForkliftAdded) {
      localStorage.removeItem(storageVariables.NEW_FORKLIFT_ADDED);
      localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);

      const fleet: FetchedForkliftFleetTypesNew[] = apiData.companyFleet;

      if (fleet.length === 0) return;

      const lastForklift: FetchedForkliftFleetTypesNew =
        fleet[fleet.length - 1];

      const lastForliftOption: ReactSelectOptionsTypes = {
        value: JSON.stringify(lastForklift),
        label: getForkliftFullName(lastForklift),
      };

      handleSelectChange(lastForliftOption);
    }
    if (apiData?.companyFleet)
      setFleets(sortForkliftsByName(apiData?.companyFleet));
  }, [apiData]);

  useEffect(() => {
    if (fleets.length === 0) return;

    const tempAvailableFleets: ReactSelectOptionsTypes[] = fleets.map(
      (fleet) => {
        return {
          value: JSON.stringify(fleet),
          label: getForkliftFullName(fleet),
        };
      }
    );

    setAvailableFleets(tempAvailableFleets);
  }, [fleets]);

  return (
    <MainPageWrapper title="Forklift Search" testid="home-page">
      {showAlert && alertMsg && alertMsg === alertAddCompanyFirst && (
        <AlertPopup title={alertMsg} />
      )}
      <TopBanner goBack startFromBeginning />
      <div className="row box-grid-container row-with-notes">
        <div className="col-md-6">
          <div className="banner-left-outer">
            <div className="banner-left-inner">
              <div className="banner-title">
                Select from {selectedCompany?.companyName ?? ""} existing fleet
              </div>

              <ReactSelectInput
                placeholder="Select from existing fleet"
                availableOptions={availableFleets}
                onChange={handleSelectChange}
                selectedOption={selectedOption}
              />

              {selectedFleetBool && (
                <div className="row box-grid-container">
                  <div className="col-md-6 col-left-align">
                    <div
                      className="white-btn underline-hover-btn"
                      onClick={navigateToQuoteHistory}
                    >
                      <div className="btn-icon">
                        <div data-icon="" className="icon" />
                      </div>
                      <div className="btn-text">Forklift Quote History</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row box-grid-container">
                <div className="col-md-6 col-left-align">
                  <ButtonAdd
                    title="New forklift to Fleet"
                    onClick={handleAddNewForklift}
                  />
                </div>
                <div className="col-md-6 col-right-align">
                  <ButtonNext
                    onClick={startNewOrder}
                    // disabled={!selectedFleetBool || !inputValue}
                    disabled={!selectedOption}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedFleet !== placeholder && (
          <div className="col-md-6 notes-outer">
            <div className="notes-inner">
              <div className="d-flex box-grid-container justify-content-between ">
                <span className="textarea-notes">NOTES</span>
              </div>
              {notes?.length && notes?.length > 0 ? (
                <div className="textarea-notes-block">{notes}</div>
              ) : (
                <div className="no-notes">No notes for this.</div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainPageWrapper>
  );
};

export default SelectForkliftPage;
