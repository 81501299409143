import React from "react";
import { FormattedMoneyTypes } from "../helpers/formatMoney";

export const useFormatPrice = () => {
  const getFormattedPriceFromObj = (
    formattedPriceObj: FormattedMoneyTypes,
    hideCurrencySymbol = false,
    finalPrice = false
  ): React.ReactNode => {
    if (formattedPriceObj.error) return <></>;

    const currencySymbol = hideCurrencySymbol
      ? ""
      : formattedPriceObj.currencySymbol;

    if (formattedPriceObj.decimalPart) {
      if (!finalPrice)
        return (
          <>
            {`${currencySymbol}${formattedPriceObj.money}`}
            <span>{formattedPriceObj.decimalPart}</span>
          </>
        );
      return (
        <>
          {`${currencySymbol}${formattedPriceObj.money}${formattedPriceObj.decimalPart}`}
        </>
      );
    }

    return <>{`${currencySymbol}${formattedPriceObj.money}`}</>;
  };

  return { getFormattedPriceFromObj };
};
