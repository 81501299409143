import React, { createContext, FC, ReactNode, useMemo, useState } from "react";

interface AppContextTypes {
  authApiError: string | null;
  authLoading: boolean;
  setAuthLoading: (_loading: boolean) => void;
  setAuthApiError: (_error: string | null) => void;
}

const AuthContext = createContext<AppContextTypes>({
  authApiError: null,
  authLoading: false,
  setAuthLoading: (_loading: boolean) => {},
  setAuthApiError: (_error: string | null) => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authApiError, setAuthApiError] = useState<string | null>(null);
  const [authLoading, setAuthLoading] = useState<boolean>(false);

  const providerValue = useMemo(() => {
    return {
      authLoading,
      authApiError,
      setAuthApiError,
      setAuthLoading,
    };
  }, [authLoading, authApiError, setAuthApiError, setAuthLoading]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
