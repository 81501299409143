import React, { FC } from "react";

interface AddNewCompanyStepperTypes {
  step: number;
}

const AddNewCompanyStepper: FC<AddNewCompanyStepperTypes> = ({ step }) => {
  return (
    <div className="step-outer">
      <div className="steps-inner">
        <div className={`step ${step === 1 ? " active" : "done"}`}>
          <div className="step-number">1</div>
          <div className="step-text">Company</div>
        </div>
        <div className={`step ${step === 2 ? " active" : ""}`}>
          <div className="step-number">2</div>
          <div className="step-text">Contacts</div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCompanyStepper;
