import React, { FC } from "react";

interface ExistingQuantityInputPropsTypes {
  quantity: number;
  level3?: boolean;
}

const ExistingQuantityInput: FC<ExistingQuantityInputPropsTypes> = ({
  quantity,
  level3 = false,
}) => {
  return (
    <div className="col-md-1 col-sm-1 cart-item-col cart-item-quantity center-align">
      <div className="cart-item-top">
        {!level3 && <div className="cart-item-quantity-label">QUANTITY</div>}
      </div>
      <div className="cart-item-bottom center-align">
        <div className="cart-item-quantity-outer">
          <div className="cart-item-quantity-inner">
            <div className="cart-item-price">{quantity}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingQuantityInput;
