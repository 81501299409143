import React, { FC } from "react";

interface Props {
  title: string;
  date: string;
}

const HeaderDateInfos: FC<Props> = ({ title, date }) => {
  return (
    <div className="cart-item-top mx-3">
      <div className="cart-item-price-label mb-1">{title}</div>
      <div className="cart-item-price-label">
        <span className="zona-bold one-rem">
          <strong>{date}</strong>
        </span>
      </div>
    </div>
  );
};

export default HeaderDateInfos;
