import React, { FC } from "react";
import InputWithSubForm from "../../../components/Inputs/InputWithSubForm";
import ButtonContact from "../../Buttons/ButtonContact";
import { InputNewClientContactPersonDataTypes } from "../../../types/companies";
// import { getCurrency } from "../../../helpers/storage";

interface ContactsFormPropsTypes {
  onClick: () => void;
  inputData: InputNewClientContactPersonDataTypes;
}

const AddContactsForm: FC<ContactsFormPropsTypes> = ({
  onClick,
  inputData,
}) => {
  // const currency: string = getCurrency();

  return (
    <div className="row box-grid-container form-client">
      <div className="col-md-6">
        <InputWithSubForm {...inputData.firstName} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.lastName} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.email} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm
          {...inputData.phoneCountryCode}
          // phoneCountryCodeUSA={currency === "USA"}
        />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.phoneNumber} />
      </div>
      <div className="col-md-6">
        <InputWithSubForm {...inputData.title} />
      </div>
      <div className="col-md-12">
        <div className="col-right-align">
          <ButtonContact onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default AddContactsForm;
