import { useCallback, useContext, useState } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { somethingWentWrongMessage } from "../settings/errorMessages";
import useAuth from "./useAuth";
import { FetchedDataContext } from "../context/FetchedDataContext";
import { getCurrentEnv } from "../config/environment";
import { baseUrlUat, baseUrlProd } from "../API_SETTINGS/requestSettings";

interface PropsTypes {
  method: string;
  url: string;
  data?: any;
  headers?: {
    "Content-Type"?: string;
  };
  isSearchRequest?: boolean;
}

const useAuthApiCall = () => {
  const { setFetchDataApiError } = useContext(FetchedDataContext);
  const { handleLogout } = useAuth();
  const { instance, inProgress, accounts } = useMsal();

  const [apiData, setApiData] = useState<any | null>(null);

  const handleResponse = ({
    responseData,
    errorMessage,
    isSearchRequest = false,
  }: {
    responseData: any;
    errorMessage?: string;
    isSearchRequest?: boolean;
  }): void => {
    if (errorMessage) {
      setFetchDataApiError(errorMessage || somethingWentWrongMessage);
    } else if (!responseData.success) {
      setApiData(null);
      if (!isSearchRequest) {
        setFetchDataApiError(
          responseData?.errorMessage || somethingWentWrongMessage
        );
      }
    } else {
      setApiData(responseData);
    }
  };

  const handleAuthApiCall = useCallback(
    ({ method, url, data, headers, isSearchRequest = false }: PropsTypes) => {
      setFetchDataApiError(null);

      const { isProd, isUat } = getCurrentEnv();

      let baseUrl = url;

      if (isProd) {
        baseUrl = `${baseUrlProd}${url}`;
      } else if (isUat) {
        baseUrl = `${baseUrlUat}${url}`;
      }

      if (inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            const { accessToken } = accessTokenResponse;

            // Call your API with token
            const config = {
              method,
              url: baseUrl,
              data,
              headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
              },
            };

            axios(config)
              .then((response) => {
                handleResponse({
                  responseData: response.data,
                  isSearchRequest,
                });
              })
              .catch((error) => {
                handleResponse({
                  responseData: null,
                  errorMessage: error?.message || somethingWentWrongMessage,
                });
              });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then((accessTokenResponse) => {
                  // Acquire token interactive success
                  const { accessToken } = accessTokenResponse;

                  const config = {
                    method,
                    url: baseUrl,
                    data,
                    headers: {
                      ...headers,
                      Authorization: `Bearer ${accessToken}`,
                    },
                  };

                  axios(config)
                    .then((response) => {
                      handleResponse({
                        responseData: response.data,
                        isSearchRequest,
                      });
                    })
                    // eslint-disable-next-line no-shadow
                    .catch((error) => {
                      handleResponse({
                        responseData: null,
                        errorMessage:
                          error?.message || somethingWentWrongMessage,
                      });
                    });
                })
                // eslint-disable-next-line no-shadow
                .catch((error) => {
                  // Acquire token interactive failure
                  setApiData({
                    data: null,
                    error: error?.message || somethingWentWrongMessage,
                  });
                  handleLogout(true);
                });
            }
            setApiData({
              data: null,
              error: error?.message || somethingWentWrongMessage,
            });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instance, accounts, inProgress, apiData, handleLogout]
  );

  return { apiData, handleAuthApiCall };
};

export default useAuthApiCall;
