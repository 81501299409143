import React, { FC } from "react";

interface ButtonSearchPropsTypes {
  onClick: () => void;
}

const ButtonSearch: FC<ButtonSearchPropsTypes> = ({ onClick }) => {
  return (
    <div className="col-form-item">
      <div className="col-right-align">
        <div className="yellow-btn corner-btn" onClick={onClick}>
          <div className="btn-text">Search</div>
          <div className="btn-icon">
            <div className="icon symmetry-icon-search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonSearch;
