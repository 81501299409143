import React, { FC, useContext, useEffect, useState } from "react";
import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import RadioButton from "../../components/Buttons/RadioButton";
import ButtonAdd from "../../components/Buttons/ButtonAdd";
import {
  FetchedCompanyTypes,
  FetchedContactPersonsTypes,
  CreatedContactPersonsTypes,
} from "../../types/companies";
import SelectCustomerInputAsync from "../../components/Inputs/SelectCustomerInputAsync";
import { availableRoutes } from "../../lib/routes";
import { getCurrentQuote } from "../../helpers/quotes";
import { QuoteTypes } from "../../types/quote";
import ButtonNext from "../../components/Buttons/ButtonNext";
import ClientListItem from "../../components/ListItems/ClientListItem";
import { storageVariables } from "../../lib/storageVariables";
import { createNewQuote } from "../../helpers/storage";

import { useNavigation } from "../../hooks/useNavigation";
import { confirmDeleteQuoteMessage } from "../../settings/confirmAlertMessages";
import EditContactPopup, {
  ClientDataEditTypes,
} from "../../components/Popups/EditContactPopup";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import EditCompanyPopup, {
  CompanyDataEditTypes,
} from "../../components/Popups/EditCompanyPopup";
import AddContactsPopup from "../../components/Popups/AddContactsPopup";
import { ModalContext } from "../../context/ModalContext";
import ConfirmPopup from "../../components/Popups/ConfirmPopup";
import useRegion from "../../hooks/useRegion";

const SelectCustomerPage: FC = () => {
  const { showConfirm, openConfirm, confirmMsg } = useContext(ModalContext);

  const { fullNavigateToUrl, navigateTo } = useNavigation();

  const [selectedOption, setSelectedOption] = useState<number>(1);

  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const [showPopupEditCompany, setShowPopupEditCompany] = useState(false);

  const [showPopupAddClients, setShowPopupAddClients] = useState(false);

  const [selectedCompany, setSelectedCompany] =
    useState<FetchedCompanyTypes | null>(null);

  const [contactPersons, setContactPersons] = useState<
    FetchedContactPersonsTypes[]
  >([]);

  const [showPopupEditClient, setShowPopupEditClient] =
    React.useState<boolean>(false);

  const [selectedClient, setSelectedClient] =
    React.useState<FetchedContactPersonsTypes | null>(null);

  const [activePersonId, setActivePersonId] = useState<number | null>(null);
  const handleOptionChange = (option: number) => {
    setSelectedOption(option);
    setSelectedCompany(null);
  };

  const [contactPersonsId, setContactPersonsId] = useState<number>(0);
  const [apiReason, setApiReason] = useState<string>("");
  const [editData, setEditData] = useState<ClientDataEditTypes | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const handleAddNewClient = () => {
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_COMPANY);
    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    navigateTo(availableRoutes.ADD_NEW_COMPANY);
  };

  const completeHandleJourney = () => {
    if (!selectedCompany) return;

    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.EDIT_QUOTE);

    setTimeout(() => {
      const newCompanyToSave: FetchedCompanyTypes = {
        ...selectedCompany,
        contactPersons,
        activePersonId,
      };

      localStorage.setItem(
        storageVariables.SELECTED_COMPANY,
        JSON.stringify(newCompanyToSave)
      );

      createNewQuote(newCompanyToSave);
    }, 50);

    setTimeout(() => {
      fullNavigateToUrl(availableRoutes.CHOOSE_JOURNEY);
    }, 100);
  };

  const completeHandleJourneyOnConfirm = () => {
    localStorage.removeItem(storageVariables.CURRENT_QUOTE);

    completeHandleJourney();
  };

  const handleChooseJourney = () => {
    if (!selectedCompany) return;

    const currentQuote: QuoteTypes | null = getCurrentQuote();

    if (currentQuote?.quoteItems && currentQuote.quoteItems.length > 0) {
      openConfirm(confirmDeleteQuoteMessage);
      return;

      localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    }

    completeHandleJourney();
  };

  const handlePreselectedChooseJourney = (
    // eslint-disable-next-line no-shadow
    selectedCompany: FetchedCompanyTypes | null = null,
    fetchedContactPersons: FetchedContactPersonsTypes[] = [],
    // eslint-disable-next-line no-shadow
    activePersonId: number | null = null
  ) => {
    if (!selectedCompany) return;

    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.EDIT_QUOTE);

    setTimeout(() => {
      const newCompanyToSave: FetchedCompanyTypes = {
        ...selectedCompany,
        contactPersons: fetchedContactPersons,
        activePersonId,
      };

      localStorage.setItem(
        storageVariables.SELECTED_COMPANY,
        JSON.stringify(newCompanyToSave)
      );

      createNewQuote(newCompanyToSave);
    }, 50);

    // setLoading(false);
    setTimeout(() => {
      fullNavigateToUrl(availableRoutes.CHOOSE_JOURNEY);
    }, 100);
  };

  const onEdit = (id: number) => {
    setActivePersonId(id);

    const selectedPerson: FetchedContactPersonsTypes | undefined =
      contactPersons.find((item) => item.contactPersonId === id);

    setSelectedClient(selectedPerson ?? null);

    setShowPopupEditClient(true);
  };

  const onDelete = (personsId: number) => {
    if (!selectedCompany) return;

    setContactPersonsId(personsId);
    setApiReason("onDelete");

    handleAuthApiCall({
      method: "delete",
      url: `/v1/contactPersons/${personsId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        companyId: selectedCompany?.companyId ?? 0,
      },
    });
  };

  const handleSubmitPopupEdit = (data: ClientDataEditTypes) => {
    setApiReason("onEdit");
    setEditData(data);

    const contactPersonId = selectedClient?.contactPersonId;

    handleAuthApiCall({
      method: "patch",
      url: `/v1/contactPersons/${contactPersonId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ...data,
        companyId: selectedCompany?.companyId ?? 0,
      },
    });
  };

  const cleanUp = () => {
    setApiReason("");
    localStorage.removeItem(storageVariables.CURRENT_QUOTE);
    localStorage.removeItem(storageVariables.SELECTED_BATTERY);
    localStorage.removeItem(storageVariables.SELECTED_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_COMPANY);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
    localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);
    localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
    localStorage.removeItem(storageVariables.SELECTED_FORKLIFT_FETCHED_DATA);
    fullNavigateToUrl(availableRoutes.SELECT_CUSTOMER);
  };

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    switch (apiReason) {
      case "onDelete": {
        const reducedContactPersons: FetchedContactPersonsTypes[] | null =
          contactPersons?.filter((item) => {
            return item.contactPersonId !== contactPersonsId;
          });
        setContactPersons(reducedContactPersons ?? []);
        setActivePersonId(reducedContactPersons[0]?.contactPersonId);
        setApiReason("");
        break;
      }

      case "onEdit": {
        setShowPopupEditClient(false);

        // update contact persons list with new data
        const updatedContactPersons: FetchedContactPersonsTypes[] =
          contactPersons.map((item) => {
            if (item.contactPersonId === selectedClient?.contactPersonId) {
              return {
                ...item,
                ...(editData ?? {}),
              };
            }
            return item;
          });

        setContactPersons(updatedContactPersons);
        setEditData(null);
        setApiReason("");
        break;
      }

      case "onEditCompany": {
        setShowPopupEditCompany(false);
        cleanUp();
        break;
      }

      case "onDeleteCompany": {
        setShowPopupEditCompany(false);
        cleanUp();
        break;
      }

      case "onAddContacts": {
        setShowPopupAddClients(false);

        if (!selectedCompany) break;

        setLoading(true);

        handleAuthApiCall({
          method: "get",
          url: `/v1/companies/${selectedCompany?.companyId}`,
        });

        setApiReason("newContactCreated");
        break;
      }

      case "newContactCreated": {
        const contactPersonsArray: FetchedContactPersonsTypes[] =
          apiData?.company?.contactPersons ?? [];

        setContactPersons(contactPersonsArray);

        setApiReason("");
        if (contactPersonsArray.length > 0) {
          const lastPersonId =
            contactPersonsArray[contactPersonsArray.length - 1]
              ?.contactPersonId;

          handlePreselectedChooseJourney(
            apiData?.company,
            contactPersonsArray,
            lastPersonId
          );
        }
        break;
      }

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  const handleSubmitPopupEditCompany = (data: CompanyDataEditTypes) => {
    setApiReason("onEditCompany");

    const companyId = selectedCompany?.companyId ?? 0;

    handleAuthApiCall({
      method: "put",
      data,
      url: `/v1/companies/${companyId}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const handleSubmitPopupAddContacts = (data: CreatedContactPersonsTypes) => {
    setApiReason("onAddContacts");

    const companyId = selectedCompany?.companyId ?? 0;
    const postData = {
      companyId,
      contactPersons: [data],
    };

    handleAuthApiCall({
      method: "post",
      url: "/v1/contactPersons",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    });
  };
  const onDeleteCompany = () => {
    setApiReason("onDeleteCompany");

    const companyId = selectedCompany?.companyId ?? 0;

    handleAuthApiCall({
      method: "delete",
      url: `/v1/companies/${companyId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        companyId,
      },
    });
  };

  const { handleGetRegion } = useRegion();

  useEffect(() => {
    handleGetRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showConfirm &&
        confirmMsg &&
        confirmMsg === confirmDeleteQuoteMessage && (
          <ConfirmPopup
            title={confirmMsg}
            onConfirm={completeHandleJourneyOnConfirm}
          />
        )}
      <MainPageWrapper title="Customer Select" testid="not-found-page">
        <TopBanner skipFilter />

        {selectedClient && showPopupEditClient && (
          <EditContactPopup
            onClick={handleSubmitPopupEdit}
            closePopup={() => setShowPopupEditClient(false)}
            selectedClient={selectedClient}
          />
        )}

        {selectedCompany && showPopupEditCompany && (
          <EditCompanyPopup
            onClick={(data) => handleSubmitPopupEditCompany(data)}
            closePopup={() => setShowPopupEditCompany(false)}
            selectedCompany={selectedCompany}
          />
        )}
        {selectedCompany && showPopupAddClients && (
          <AddContactsPopup
            onClick={(data) => handleSubmitPopupAddContacts(data)}
            closePopup={() => setShowPopupAddClients(false)}
          />
        )}
        {!loading && (
          <div className="">
            <div className="row box-grid-container">
              <div className="col-md-6">
                <div className="banner-left-outer">
                  <div className="banner-left-inner">
                    <div className="banner-title">
                      Let&apos;s get started with the client
                    </div>
                    <div className="banner-input-container">
                      <div className="client-radio-container custom-radio-outer mobile-column">
                        <RadioButton
                          title="by Company Name"
                          active={selectedOption === 1}
                          onClick={() => handleOptionChange(1)}
                        />
                        <RadioButton
                          title="by the Client's SAP ID"
                          active={selectedOption === 2}
                          onClick={() => handleOptionChange(2)}
                        />
                      </div>

                      <div className="client-input-container">
                        <SelectCustomerInputAsync
                          setContactPersons={setContactPersons}
                          setSelectedCompany={setSelectedCompany}
                          setActivePersonId={setActivePersonId}
                          selectedOption={selectedOption}
                          setShowPopupEditCompany={setShowPopupEditCompany}
                          setShowPopupAddContacts={setShowPopupAddClients}
                          onDeleteCompany={onDeleteCompany}
                        />
                      </div>
                    </div>

                    {contactPersons &&
                      contactPersons.length > 0 &&
                      contactPersons?.map(
                        (person: FetchedContactPersonsTypes, index: number) => (
                          <div key={index} className="list-results-outer">
                            <div className="list-results-inner">
                              <ClientListItem
                                {...person}
                                onClick={setActivePersonId}
                                isActiveId={
                                  activePersonId ??
                                  contactPersons[0].contactPersonId
                                }
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                            </div>
                          </div>
                        )
                      )}

                    <div className="row box-grid-container">
                      <div className="col-md-6 col-left-align">
                        <ButtonAdd
                          title="New Client"
                          onClick={handleAddNewClient}
                        />
                      </div>
                      <div className="col-md-6 col-right-align">
                        <ButtonNext
                          onClick={handleChooseJourney}
                          disabled={!selectedCompany || !activePersonId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6" />
            </div>
          </div>
        )}
      </MainPageWrapper>
    </>
  );
};

export default SelectCustomerPage;
