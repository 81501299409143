import React, { FC, useEffect, useState } from "react";

import { Button, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableColumnType,
  TableHeader,
} from "react-bs-datatable";

import TABLE_TYPES from "../../types/quotesByForkliftIdSchema.json";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import TopBanner from "../../components/Banners/TopBanner";
import { storageVariables } from "../../lib/storageVariables";
import { FetchedCompanyTypes } from "../../types/companies.d";
import { FetchedForkliftFleetTypesNew } from "../../types/forkliftFleetTypes";
import {
  fullNavigateToUrl,
  getForkliftFullName,
} from "../../helpers/functions";
import { availableRoutes } from "../../lib/routes";
import formatMoney from "../../helpers/formatMoney";
import useAuthApiCall from "../../hooks/useAuthApiCall";
import { getCurrency } from "../../helpers/storage";
import { formatDateToUSA } from "../../helpers/formatDate";

type ArrayElementType = (typeof TABLE_TYPES)[number] & {
  button: any;
};

const getHeadersWithFormattedDate = (currency: string) => {
  const STORY_HEADERS: TableColumnType<ArrayElementType>[] = [
    {
      prop: "quoteId",
      title: "OFFER NUMBER",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "dateCreated",
      title: "DATE CREATED",
      isSortable: true,
      cell(row) {
        if (row.dateCreated) {
          if (currency === "EUR") {
            return <span>{row.dateCreated}</span>;
          }
          return <span>{formatDateToUSA(row.dateCreated)}</span>;
        }
        return <></>;
      },
    },
    {
      prop: "companyName",
      title: "COMPANY",
      isSortable: true,
    },
    {
      prop: "contactPersonFirstName",
      title: "FIRST NAME",
      isSortable: true,
    },
    {
      prop: "contactPersonLastName",
      title: "LAST NAME",
      isSortable: true,
    },
    {
      prop: "totalItemsPrice",
      title: "AMOUNT",
    },
    {
      prop: "quoteNotes",
      title: "SALES REP. NOTES",
    },
    {
      prop: "button",
      cell: (row) => (
        <Button
          role="button"
          variant="outline-primary"
          size="sm"
          onClick={() => {
            // Save quoteId to localStorage
            localStorage.setItem(
              storageVariables.SELECTED_QUOTE_ID,
              JSON.stringify(row.quoteId)
            );
            // Navigate to quote details page
            fullNavigateToUrl(availableRoutes.QUOTE_ITEM_BY_ID);
          }}
        >
          Manage
          <div className="btn-icon">
            <div className="icon symmetry-icon-arrow-right" />
          </div>
        </Button>
      ),
    },
  ];

  return STORY_HEADERS;
};

const QuotesByForkliftPage: FC = () => {
  const { apiData, handleAuthApiCall } = useAuthApiCall();

  const [fetchedData, setFetchedData] = useState<ArrayElementType[]>([]);
  const [companyName, setCompanyName] = useState<string>("");

  const selectedCompany: FetchedCompanyTypes = JSON.parse(
    localStorage.getItem(storageVariables.SELECTED_COMPANY) || "{}"
  );
  const selectedForklift: FetchedForkliftFleetTypesNew = JSON.parse(
    localStorage.getItem(storageVariables.SELECTED_FORKLIFT) || "{}"
  );

  const currency = getCurrency();

  const handleFetchedData = (data: ArrayElementType[]) => {
    const newData: ArrayElementType[] = data.map((item) => {
      const formattedPriceObj = formatMoney({
        amount: item.totalItemsPrice,
        country: currency,
        showMinusSign: true,
      });

      return {
        ...item,
        totalItemsPrice: formattedPriceObj.error
          ? "0.00"
          : `${formattedPriceObj.currencySymbol}${formattedPriceObj.money}`,
      };
    });

    setFetchedData(newData);
  };

  useEffect(() => {
    localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);

    if (!selectedCompany?.companyId) {
      setCompanyName("");
      return;
    }

    const companyId = selectedCompany?.companyId;
    setCompanyName(selectedCompany?.companyName);

    // if (!selectedForklift?.forkliftId) return;
    // const forkliftId = selectedForklift?.forkliftId;

    if (!selectedCompany?.activePersonId) return;
    const contactId = selectedCompany?.activePersonId;

    handleAuthApiCall({
      method: "post",
      data: {
        manufacturer: selectedForklift?.manufacturer,
        model: selectedForklift?.model,
      },
      url: `/v2/quotes/companies/${companyId}/contactPersons/${contactId}`,
      isSearchRequest: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedForkliftName = getForkliftFullName(selectedForklift);

  useEffect(() => {
    if (!apiData || !apiData?.success) return;

    if (apiData?.quotes && apiData?.quotes.length > 0) {
      handleFetchedData(apiData.quotes as ArrayElementType[]);
    } else {
      setFetchedData([]);
    }
  }, [apiData]);

  if (companyName === "")
    return (
      <MainPageWrapper title="All Quotes" testid="all-Quotes">
        <style>{`body{background: #fff;}`}</style>
        <TopBanner goBack />
        <div className="row box-grid-container">
          <div className="col-md-12">
            <div className="banner-title">
              Please select a company and a Forklift from the fleet{" "}
            </div>
          </div>
        </div>
      </MainPageWrapper>
    );

  return (
    <MainPageWrapper title="Quotes by Forklift" testid="Quotes-by-Forklift">
      <TopBanner goBack />

      {fetchedData.length > 0 && (
        <>
          <div className="col-md-6 mobile-center">
            <div className="banner-title">
              Forklift Quote History for {selectedForkliftName}
            </div>
          </div>
          <div className="overflow-x-scroll">
            <DatatableWrapper
              body={fetchedData}
              headers={getHeadersWithFormattedDate(currency)}
              sortProps={{
                initialState: {
                  order: "desc",
                  prop: "quoteId",
                },
              }}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: process.env
                    .REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER
                    ? parseInt(
                        process.env
                          .REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER,
                        10
                      )
                    : 5,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <style>{`body{background: #fff;}`}</style>
              <div className="row box-grid-container">
                <div className="col-md-6 datatable-search mobile-start">
                  <Filter placeholder="Quick filter by Offer Number" />
                </div>
              </div>
              <Table>
                <TableHeader />
                <TableBody />
              </Table>

              <Row className="mb-12 d-flex flex-col justify-content-center">
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-center"
                >
                  <Pagination
                    paginationRange={
                      process.env.REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER
                        ? parseInt(
                            process.env
                              .REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER,
                            10
                          )
                        : 5
                    }
                  />
                </Col>
              </Row>
            </DatatableWrapper>
          </div>
        </>
      )}
      {fetchedData.length < 1 && (
        <div className="row box-grid-container">
          <div className="col-md-12">
            <div className="banner-title">
              Forklift Quote History for {selectedForkliftName} is empty
            </div>
          </div>
        </div>
      )}
    </MainPageWrapper>
  );
};

export default QuotesByForkliftPage;
