import React, { FC } from "react";
import { LeftBannerTopValuesForChargersTypes } from "../../../../types/bannersTypes";
import ImageWithFallback from "../../../Media/ImageWithFallback";

interface LeftBannerTopChargersPropsTypes {
  leftBannerTopValuesForChargers: LeftBannerTopValuesForChargersTypes | null;
}

const LeftBannerTopChargers: FC<LeftBannerTopChargersPropsTypes> = ({
  leftBannerTopValuesForChargers,
}) => {
  if (!leftBannerTopValuesForChargers) return null;

  const {
    imageUrl,
    manufacturer,
    model,
    selectedBatteryImageUrl,
    selectedBatteryName,
  } = leftBannerTopValuesForChargers;

  return (
    <div className="banner-left-top-outer" data-testid="banner-left-top">
      <div className="banner-left-top-inner">
        <div className="banner-left-top-img">
          <ImageWithFallback type="forklift" imageUrl={imageUrl} />
        </div>

        {leftBannerTopValuesForChargers && (
          <>
            <div className="banner-left-top-texts">
              <ul className="banner-left-top-title">
                {manufacturer && <li>Manufacturer</li>}
                {model && <li>Model</li>}
              </ul>
              <ul className="banner-left-top-desc">
                {manufacturer && <li>{manufacturer}</li>}
                {model && <li>{model}</li>}
              </ul>
            </div>
            <div className="banner-left-top-extra">
              <div className="banner-left-top-extra-img">
                <ImageWithFallback
                  type="battery"
                  imageUrl={selectedBatteryImageUrl}
                />
              </div>
              <div className="banner-left-top-extra-title">
                {selectedBatteryName}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeftBannerTopChargers;
