import React, { FC } from "react";
import formatMoney, {
  FormattedMoneyTypes,
} from "../../../../../helpers/formatMoney";
import { useFormatPrice } from "../../../../../hooks/UseFormatPrice";
import { getCurrency } from "../../../../../helpers/storage";

interface PropsTypes {
  totalPrice: number;
  manualAddedPrice: number | null;
}

const ExistingFinalPriceWithToggle: FC<PropsTypes> = ({
  totalPrice,
  manualAddedPrice,
}) => {
  const { getFormattedPriceFromObj } = useFormatPrice();

  const currency = getCurrency();

  const manualAddedPriceObj: FormattedMoneyTypes = formatMoney({
    amount: manualAddedPrice ?? totalPrice,
    showMinusSign: true,
    separateDecimals: true,
    country: currency,
  });

  return (
    <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-final-price">
      <div className="cart-item-top right-align">
        <div className="cart-item-final-price-label">
          <div>FINAL PRICE</div>
        </div>
      </div>

      <div className="cart-item-bottom right-align">
        <div className="cart-item-final-price">
          {getFormattedPriceFromObj(manualAddedPriceObj)}
        </div>
      </div>
    </div>
  );
};

export default ExistingFinalPriceWithToggle;
