/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ConfirmPopup from "../Popups/ConfirmPopup";
import { confirmDeleteClient } from "../../settings/confirmAlertMessages";

export interface ClientListItemPropsTypes {
  contactPersonId?: number;
  firstName: string;
  lastName: string;

  title: string;
  isActiveId: number | null;
  onClick: (_contactPersonId: number) => void;
  onEdit: (_contactPersonId: number) => void;
  onDelete: (_id: number) => void;
}

const ClientListItem: FC<ClientListItemPropsTypes> = ({
  isActiveId,
  onClick,
  onEdit,
  onDelete,
  contactPersonId,
  firstName,
  lastName,
  title,
}) => {
  const { showConfirm, openConfirm, confirmMsg } = useContext(ModalContext);

  const [deleteClient, setDeleteClient] = React.useState(false);

  const clientTitle = firstName.concat(" ", lastName, ", ", title);

  const completeDelete = () => {
    if (contactPersonId) onDelete(contactPersonId);
  };
  const handleDelete = () => {
    if (!contactPersonId) return;
    setDeleteClient(true);

    openConfirm(confirmDeleteClient);
  };

  const handleNameClick = () => {
    if (contactPersonId) onClick(contactPersonId);
  };

  const handleEdit = () => {
    if (contactPersonId) onEdit(contactPersonId);
  };

  return (
    <>
      <div
        className={`list-results-item ${
          isActiveId === contactPersonId ? "active" : ""
        }`}
      >
        <div
          onClick={handleNameClick}
          role="button"
          className="list-results-left"
        >
          <div className="list-results-item-icon">
            <div className="list-results-bullet" />
          </div>
          <div className="list-results-item-title">{clientTitle}</div>
        </div>
        <div className="list-results-item-choices">
          <div className="list-results-item-edit">
            <span className="mobile-hide">Edit</span>
            <div className="icon-edit" onClick={handleEdit} role="button">
              <div className="icon symmetry-icon-edit" />
            </div>
          </div>
          <div className="list-results-item-delete">
            <span className="mobile-hide">Delete</span>
            <div className="icon-delete" onClick={handleDelete} role="button">
              <div className="icon symmetry-icon-bin" />
            </div>
          </div>
        </div>
      </div>
      {showConfirm &&
        deleteClient &&
        confirmMsg &&
        confirmMsg === confirmDeleteClient && (
          <ConfirmPopup title={confirmMsg} onConfirm={completeDelete} />
        )}
    </>
  );
};

export default ClientListItem;
