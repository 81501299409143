import React, { FC } from "react";

interface ButtonContactPropsTypes {
  onClick?: () => void;
}

const ButtonContact: FC<ButtonContactPropsTypes> = ({ onClick }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div onClick={handleClick} className="blue-btn corner-btn">
      <div className="btn-icon">
        <div className="icon symmetry-icon-add" />
      </div>
      <div className="btn-text">Contact</div>
    </div>
  );
};

export default ButtonContact;
