import React, { FC } from "react";
import { ReplacementBatteriesTypes } from "../../../types/basicProductsTypes";

import {
  SelectedPopupTableTypes,
  SelectedPopupTopTypes,
} from "../../../types/SelectedBatteryPopup";
import RightBannerTable from "../RightBanner/RightBannerTable";
import SelectedBatteryBannerTop from "./SelectedBatteryBannerTop";

interface PropsTypes {
  batteryPopupTopValues: SelectedPopupTopTypes;
  batteryPopupTableValues: SelectedPopupTableTypes[];
  replacementsArray?: ReplacementBatteriesTypes[];
  addItemToQuote: () => void;
  onIncrement: () => void;
  onDecrement: () => void;
  quantity: number;
  setQuantity: (_value: number) => void;
}

const SelectBatteryBanner: FC<PropsTypes> = ({
  replacementsArray,
  batteryPopupTopValues,
  batteryPopupTableValues,
  addItemToQuote,
  onIncrement,
  onDecrement,
  quantity,
  setQuantity,
}) => {
  return (
    <div data-testid="selected-battery-popup">
      <div className="product-details-inner">
        <SelectedBatteryBannerTop
          quantity={quantity}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          batteryPopupTopValues={batteryPopupTopValues}
          addItemToQuote={addItemToQuote}
          replacementsArray={replacementsArray}
          setQuantity={setQuantity}
        />
        {batteryPopupTableValues?.length > 0 && (
          <RightBannerTable rightBannerTableValues={batteryPopupTableValues} />
        )}
      </div>
    </div>
  );
};

export default SelectBatteryBanner;
