import React, { FC, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

interface PropsTypes {
  title: string | undefined;

  description?: string;
  additionalFn?: () => void;
}

const AlertPopup: FC<PropsTypes> = ({
  title,
  description,

  additionalFn,
}) => {
  const { closeAlert } = useContext(ModalContext);

  const handleConfirm = () => {
    if (additionalFn) {
      additionalFn();
    }
    closeAlert();
  };

  return (
    <div className="popup-outer open">
      <div className="popup-bg" />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="popup-title">{title ?? ""}</div>
          <div className="popup-message">{description}</div>
          <div
            className="row box-grid-container alert-row"
            onClick={handleConfirm}
          >
            <div className="center-align">
              <div className="yellow-btn corner-btn">
                <div className="btn-text">OK</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
