import React, { FC } from "react";

interface ButtonNextPropsTypes {
  disabled?: boolean;
  onClick?: () => void;
  hasTooltip?: React.ReactNode;
}

const ButtonNext: FC<ButtonNextPropsTypes> = ({
  disabled = false,
  onClick,
  hasTooltip,
}) => {
  const handleClick = () => {
    if (onClick && !disabled) onClick();
  };

  return (
    <div
      className={`col-form-item ${
        hasTooltip !== undefined && disabled ? "tooltip_outer" : ""
      }`}
    >
      <div
        className={`${disabled ? "disable" : "yellow"}-btn corner-btn }`}
        onClick={handleClick}
        data-testid="button-next-yellow"
      >
        <div className="btn-text">Next</div>
        <div className="btn-icon">
          <div className="icon symmetry-icon-arrow-right" />
        </div>
      </div>{" "}
      {hasTooltip !== undefined && disabled && hasTooltip}
    </div>
  );
};

export default ButtonNext;
