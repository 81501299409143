import React, { FC, useContext, useEffect } from "react";
import LeftBannerBodyChargers from "../../components/Banners/LeftBanner/LeftBannerChargers/LeftBannerBodyChargers";

import TopBanner from "../../components/Banners/TopBanner";
import MainPageWrapper from "../../components/Containers/MainPageWrapper";
import { CartContext } from "../../context/CartContext";

import {
  getBannerRightTableValuesForChargers,
  getRightBannerTopValuesForChargers,
} from "../../helpers/parsers";
import { useNavigation } from "../../hooks/useNavigation";
import { availableRoutes } from "../../lib/routes";
import {
  BannerRightTableTypes,
  BannerRightTopTypes,
} from "../../types/bannersTypes";

import { useQuoteUpdate } from "../../helpers/storage";
import { storageVariables } from "../../lib/storageVariables";
import RightBannerChargers from "../../components/Banners/RightBanner/RightBannerChargers";
import { alertAddCompanyFirst } from "../../settings/confirmAlertMessages";
import AlertPopup from "../../components/Popups/AlertPopup";
import { ModalContext } from "../../context/ModalContext";

const tempChargerCoreFieldsArray = [
  {
    key: "chargerId",
    label: "ID of charger",
  },
  {
    key: "batteryType",
    label: "BATTERY TYPE",
  },
  {
    key: "batteryVoltage",
    label: "BATTERY VOLTAGE",
  },
  {
    key: "countryNetwork",
    label: "COUNTRY NETWORK",
  },
  {
    key: "chargerPhaseType",
    label: "CHARGER PHASE TYPE",
  },
  {
    key: "chargerFrequency",
    label: "CHARGER FREQUENCY",
  },
  {
    key: "chargingCurve",
    label: "CHARGING CURVE",
  },
  {
    key: "chargingTime",
    label: "CHARGING TIME",
  },
  {
    key: "chargerConnectors",
    label: "CHARGER CONNECTORS",
  },
  {
    key: "accessories",
    label: "ACCESSORIES",
  },
];

const ChargersFound: FC = () => {
  const { openAlert, showAlert, alertMsg } = useContext(ModalContext);
  const { addChargerToQuoteByChargerFlow } = useQuoteUpdate();

  const { apiError, chargersFoundData, selectedCharger, setSelectedCharger } =
    useContext(CartContext);

  const { navigateTo } = useNavigation();

  const navigateToNextStep = () => {
    navigateTo(availableRoutes.CURRENT_QUOTE);
  };

  // Right Banner Values
  const rightBannerTopValues: BannerRightTopTypes =
    getRightBannerTopValuesForChargers(selectedCharger);

  const chargerCoreFieldsArrayStr = localStorage.getItem(
    storageVariables.CORE_FIELDS_ARRAY_CHARGER
  );
  const chargerCoreFieldsArray = chargerCoreFieldsArrayStr
    ? JSON.parse(chargerCoreFieldsArrayStr)
    : tempChargerCoreFieldsArray;

  const rightBannerTableValues: BannerRightTableTypes[] =
    getBannerRightTableValuesForChargers(
      chargerCoreFieldsArray,
      selectedCharger
    );

  //  Left Banner Bottom Values
  const handleAddChargerToQuote = () => {
    const selectdCompanyStr: string | null = localStorage.getItem(
      storageVariables.SELECTED_COMPANY
    );

    if (!selectdCompanyStr) {
      openAlert(alertAddCompanyFirst);
      return;
    }

    if (selectedCharger) {
      addChargerToQuoteByChargerFlow(selectedCharger);
    }
  };

  useEffect(() => {
    if (chargersFoundData && chargersFoundData.length > 0) {
      setSelectedCharger(chargersFoundData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainPageWrapper title="Charger Select" testid="battery-journey-page">
      {showAlert && alertMsg && alertMsg === alertAddCompanyFirst && (
        <AlertPopup title={alertMsg} />
      )}
      <TopBanner goBack startFromBeginning />

      {!apiError ? (
        <>
          <div className="">
            <div className="row box-grid-container">
              <div className="col-md-6" data-testid="left-banner">
                <LeftBannerBodyChargers
                  availableChargersForPurchase={chargersFoundData || []}
                />
              </div>

              {chargersFoundData && chargersFoundData.length > 0 && (
                <RightBannerChargers
                  rightBannerTopValues={rightBannerTopValues}
                  rightBannerTableValues={rightBannerTableValues}
                  addItemToQuote={handleAddChargerToQuote}
                  navigateToNextStep={navigateToNextStep}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="">
          <div className="row box-grid-container">
            <div className="banner-left-outer">
              <div className="banner-left-inner">
                <div className="banner-title">No compatible chargers found</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainPageWrapper>
  );
};
export default ChargersFound;
