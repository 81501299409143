import { getCurrency } from "../helpers/storage";
import { QuoteItemTypes, QuoteTypes } from "../types/quote";

export const quoteItemInitialValues: QuoteItemTypes = {
  status: "",
  itemType: "",
  leadTime: "",
  quoteItemId: 0,
  productType: "",
  productName: "",

  forkliftId: 0,
  forkliftName: "",

  batteryId: 0,
  batteryName: "",
  batteryPrice: 0,
  batteryTotalPrice: 0,
  batteryQuantity: 0,
  batteryDiscount: 0,
  batteryImageUrl: "",

  chargerId: 0,
  chargerName: "",
  chargerQuantity: 0,
  chargerDiscount: 0,
  chargerPrice: 0,
  chargerTotalPrice: 0,
  chargerImageUrl: "",

  currency: getCurrency(),
  totalPrice: 0,
  discountType: "money",
  discountTotal: 0,
  quoteItemNotes: "",
};

export const initialQuote: QuoteTypes = {
  orderId: 0,
  companyId: 0,
  contactPersonId: 0,
  country: "",
  createdBy: 0,
  approvedBy: 0,
  dateSubmitted: "",
  dateExpired: "",
  status: "",
  shipTo: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  quoteNotes: "",
  totalItemsPrice: 0,
  currency: getCurrency(),
  totalDiscount: 0,
  quoteItems: [],
};
