import React, { FC } from "react";

interface PropsTypes {
  title: string;
  active: boolean;
  onClick: () => void;
}

const RadioButton: FC<PropsTypes> = ({ title, onClick, active }) => {
  return (
    <div className={`custom-radio ${active ? "active" : ""}`} onClick={onClick}>
      <div data-icon="" className="icon" />
      <div>{title}</div>
    </div>
  );
};

export default RadioButton;
