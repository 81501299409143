/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from "react";
import {
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../../../../../types/currentQuoteTree";
import { fallbackTruckImg } from "../../../../../assets/mockData/fallbackImages";
import ImageWithFallback from "../../../../Media/ImageWithFallback";

interface ExistingQuoteItemHeaderPropsTypes {
  quoteItem:
    | QuoteItemForkliftTypes
    | QuoteItemBatteryTypes
    | QuoteItemChargerTypes;
  toggleShowQuoteItems: () => void;
}

const ExistingQuoteItemHeader: FC<ExistingQuoteItemHeaderPropsTypes> = ({
  quoteItem,
  toggleShowQuoteItems,
}) => {
  let title = "";
  let imageUrl;

  if (quoteItem.itemType === "forklift") {
    title = `${quoteItem.manufacturer}, ${quoteItem.model}`;
    imageUrl = fallbackTruckImg;
  } else if (quoteItem.itemType === "battery") {
    title = "Battery Flow";
    imageUrl = undefined;
  } else if (quoteItem.itemType === "charger") {
    title = "Charger Flow";
    imageUrl = undefined;
  }
  return (
    <div className="cart-item-row cart-item-level-1 ">
      <div className="cart-item-row-inner row">
        <div
          className="cart-item-title col-sm-12"
          role="button"
          onClick={toggleShowQuoteItems}
        >
          <div className="icon symmetry-icon-arrow-up" />
          <div className="cart-item-image">
            {imageUrl && (
              <ImageWithFallback type="forklift" imageUrl={imageUrl} />
            )}
          </div>
          <div className="cart-item-text">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default ExistingQuoteItemHeader;
