interface StorageVariablesTypes {
  CURRENT_QUOTE: string;
  SELECTED_FORKLIFT: string;
  SELECTED_FORKLIFT_FETCHED_DATA: string;
  SELECTED_COMPANY: string;
  SELECTED_BATTERY: string;
  SELECTED_CHARGER: string;
  CORE_FIELDS_ARRAY_CHARGER: string;
  SELECTED_QUOTE_ID: string;
  CURRENCY: string;
  NEW_FORKLIFT_ADDED: string;
  EDIT_QUOTE: string;
  DUPLICATE_QUOTE: string;
}

export const storageVariables: StorageVariablesTypes = {
  CURRENT_QUOTE: "sl_currentQuote",
  SELECTED_FORKLIFT: "sl_selectedForklift",
  SELECTED_FORKLIFT_FETCHED_DATA: "sl_forkliftFetchedData",
  SELECTED_COMPANY: "sl_selectedCompany",
  SELECTED_BATTERY: "sl_selectedBattery",
  SELECTED_CHARGER: "sl_selectedCharger",
  CORE_FIELDS_ARRAY_CHARGER: "sl_coreFieldsArrayCharger",
  SELECTED_QUOTE_ID: "sl_selectedQuoteId",
  CURRENCY: "sl_currency",
  NEW_FORKLIFT_ADDED: "sl_newForkliftAdded",
  EDIT_QUOTE: "sl_editQuote",
  DUPLICATE_QUOTE: "sl_duplicateQuote",
};
