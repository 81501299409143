import React, { FC } from "react";
import { InputDataPropsTypes } from "../../types/batterySearch";

const InputWithSubForm: FC<InputDataPropsTypes> = ({
  value,
  name,
  placeholder,
  errorMessage,
  onChange,
  measurementUnit,
  subFormName,
  subFormValue,
  subFormMeasureUnit,
  subFormOnChange,
  // phoneCountryCodeUSA,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleSubFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (subFormOnChange) subFormOnChange(event.target.value);
  };

  // useEffect(
  //   () => {
  //     if (phoneCountryCodeUSA && value === "") {
  //       console.log("phoneCountryCodeUSA", phoneCountryCodeUSA);
  //       onChange("+1");
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  return (
    <div className="col-form-item align-items-center">
      <input
        type="text"
        placeholder={placeholder ?? ""}
        value={value}
        name={name}
        onChange={handleInputChange}
        className="py-3"
      />
      {measurementUnit && (
        <div className="form-input-desc">{measurementUnit}</div>
      )}

      {subFormName && (
        <div className="sub-form-input">
          <span>tolerance:</span>
          <input
            type="text"
            name={subFormName}
            value={subFormValue}
            onChange={handleSubFormInputChange}
          />
          <span>{subFormMeasureUnit}</span>
        </div>
      )}

      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </div>
  );
};

export default InputWithSubForm;
