import React, { FC, useState } from "react";
// import { textUSA } from "../../settings/commonTexts";

interface SelectInputPropsTypes {
  initialOptions: string[];
  placeholder: string;
  onChange: (_option: string) => void;
  errorMessage: string | null;
  value: string;
  name: string;
  // countrySelector?: boolean;
}

const DropdownSelectInput: FC<SelectInputPropsTypes> = ({
  initialOptions,
  placeholder,
  onChange,
  errorMessage,
  value,
  name,
  // countrySelector,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    value !== "" ? value : ""
  );

  const [isInputOpen, setIsInputOpen] = useState(false);
  const [availableOptions, setAvailableOptions] =
    useState<string[]>(initialOptions);

  const filterOptions = (element: string) => {
    const filteredOptions = Object.values(initialOptions).filter((option) =>
      option.toLowerCase().includes(element.toLowerCase())
    );
    return filteredOptions;
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputOpen(false);
    }, 200);
  };

  const handleFocus = () => {
    if (inputValue.length < 1) {
      setAvailableOptions(initialOptions);
    }
    setIsInputOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValueB = event.target.value;
    onChange(inputValueB);
    const filteredOptions = filterOptions(inputValueB);

    setAvailableOptions(filteredOptions);
    setInputValue(inputValueB);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsInputOpen(false);
    setInputValue(option);
  };

  // const [countryAdded, setCountryAdded] = useState(false);

  // const checkIfCountryIsUSA = () => {
  //   if (initialOptions?.length > 0 && value === "") {
  //     if (!countryAdded && initialOptions.includes(textUSA)) {
  //       onChange(textUSA);
  //       setInputValue(textUSA);
  //       setCountryAdded(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (countrySelector) checkIfCountryIsUSA();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (countrySelector) checkIfCountryIsUSA();
  // }, [initialOptions]);

  return (
    <div
      className={`col-form-item form-dropdown-list ${
        isInputOpen ? "open" : ""
      }`}
      onBlur={handleBlur}
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={value ?? inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        className="py-3"
      />
      <div className="icon symmetry-icon-arrow-up" />
      {isInputOpen && (
        <div
          className="form-dropdown-list-container"
          style={{ display: "block" }}
        >
          {availableOptions !== undefined && availableOptions.length > 0 && (
            <ul>
              {availableOptions.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </div>
  );
};

export default DropdownSelectInput;
