import React, { FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import InputWithSubForm from "../Inputs/InputWithSubForm";
import { CreatedContactPersonsTypes } from "../../types/companies";
import {
  emailValidationRegex,
  phoneCountryCodeValidationRegex,
} from "../../config/validationRegex";
import { getCurrency } from "../../helpers/storage";

interface PropsTypes {
  onClick: (_data: CreatedContactPersonsTypes) => void;
  closePopup: () => void;
}

const AddContactsPopup: FC<PropsTypes> = ({ onClick, closePopup }) => {
  const validationSchemaAddContact = Yup.object().shape({
    firstName: Yup.string()
      .required("This is a required field")
      .max(40, "First Name can be at most 40 characters"),
    lastName: Yup.string()
      .required("This is a required field")
      .max(40, "Last Name can be at most 40 characters"),
    email: Yup.string()
      .required("This is a required field")
      .matches(emailValidationRegex, "Please enter a valid email")
      .max(100, "Email can be at most 100 characters"),
    phoneCountryCode: Yup.string()
      .required("This is a required field")
      .matches(
        phoneCountryCodeValidationRegex,
        "Please enter a valid phone country code"
      ),
    phoneNumber: Yup.string()
      .required("This is a required field")
      .matches(/^[0-9-]*$/, "Please enter a valid phone number")
      .max(20, "Phone Number can be at most 20 characters"),
    title: Yup.string()
      .required("This is a required field")
      .max(40, "Job Title can be at most 40 characters"),
  });
  const validationSchemaAddContactUSA = Yup.object().shape({
    firstName: Yup.string()
      .required("This is a required field")
      .max(40, "First Name can be at most 40 characters"),
    lastName: Yup.string()
      .required("This is a required field")
      .max(40, "Last Name can be at most 40 characters"),
    email: Yup.string()
      .required("This is a required field")
      .matches(emailValidationRegex, "Please enter a valid email"),
    phoneCountryCode: Yup.string()
      .nullable()
      .matches(
        phoneCountryCodeValidationRegex,
        "Please enter a valid phone country code"
      ),
    phoneNumber: Yup.string()
      .nullable()
      .matches(/^[0-9-]*$/, "Please enter a valid phone number")
      .max(20, "Phone Number can be at most 20 characters"),
    title: Yup.string()
      .nullable()
      .max(40, "Job Title can be at most 40 characters"),
  });

  const currency: string = getCurrency();

  const formikAddContact = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      title: "",
    },
    validationSchema:
      currency === "EUR"
        ? validationSchemaAddContact
        : validationSchemaAddContactUSA,
    onSubmit: (data) => {
      onClick(data);
      formikAddContact.resetForm();
    },
  });
  const inputData = {
    firstName: {
      name: "firstName",
      placeholder: "First Name",
      value: formikAddContact.values.firstName,
      onChange: (value: string) => updateFormikValue("firstName", value),
      errorMessage: formikAddContact.errors.firstName ?? null,
    },
    lastName: {
      name: "lastName",
      placeholder: "Last Name",
      value: formikAddContact.values.lastName,
      onChange: (value: string) => updateFormikValue("lastName", value),
      errorMessage: formikAddContact.errors.lastName ?? null,
    },
    email: {
      name: "email",
      placeholder: "Email",
      value: formikAddContact.values.email,
      onChange: (value: string) => updateFormikValue("email", value),
      errorMessage: formikAddContact.errors.email ?? null,
    },
    phoneCountryCode: {
      name: "phoneCountryCode",
      placeholder: "Phone Country Code",
      value: formikAddContact.values.phoneCountryCode,
      onChange: (value: string) => updateFormikValue("phoneCountryCode", value),
      errorMessage: formikAddContact.errors.phoneCountryCode ?? null,
    },
    phoneNumber: {
      name: "phoneNumber",
      placeholder: "Phone Number",
      value: formikAddContact.values.phoneNumber,
      onChange: (value: string) => updateFormikValue("phoneNumber", value),
      errorMessage: formikAddContact.errors.phoneNumber ?? null,
    },
    title: {
      name: "title",
      placeholder: "Job Title",
      value: formikAddContact.values.title,
      onChange: (value: string) => updateFormikValue("title", value),
      errorMessage: formikAddContact.errors.title ?? null,
    },
  };

  const updateFormikValue = (name: string, value: string) => {
    formikAddContact.setFieldValue(name, value);
  };

  const errors = Object.keys(formikAddContact.errors).length > 0;

  const handleOnClick = () => {
    if (!errors) {
      formikAddContact.handleSubmit();
    }
  };
  return (
    <div className="popup-outer open">
      <div className="popup-bg" onClick={closePopup} />
      <div className="popup-inner">
        <div className="popup-box">
          <div className="banner-title">Let&apos;s create a new contact</div>
          <div className="row box-grid-container form-client">
            <div className="col-md-6">
              <InputWithSubForm {...inputData.firstName} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.lastName} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.email} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.phoneCountryCode} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.phoneNumber} />
            </div>
            <div className="col-md-6">
              <InputWithSubForm {...inputData.title} />
            </div>
          </div>
          <div className="row box-grid-container">
            <div className="col-md-12 col-right-align">
              <ButtonSubmit onClick={handleOnClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactsPopup;
