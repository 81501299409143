import React, { FC } from "react";

const ButtonFinalizeQuote: FC = () => {
  return (
    <div className="current-quote-btn corner-btn">
      <div className="current-quote-btn-text">Finalize Quote</div>
      <div className="current-quote-btn-icon">
        <div className="icon symmetry-icon-inbox-query" />
      </div>
    </div>
  );
};

export default ButtonFinalizeQuote;
