import React, { FC } from "react";

interface ButtonSavePropsTypes {
  onClick: () => void;
  hasTooltip?: React.ReactNode;
}

const ButtonSave: FC<ButtonSavePropsTypes> = ({ onClick, hasTooltip }) => {
  return (
    <div
      className={`col-form-item save-button-tooltip ${
        hasTooltip !== undefined ? "tooltip_outer" : ""
      }`}
    >
      <div
        className="yellow-btn corner-btn"
        onClick={onClick}
        data-html2canvas-ignore
      >
        <div className="btn-text">Save</div>
        <div className="btn-icon">
          <div className="icon symmetry-icon-save" />
        </div>
      </div>
      {hasTooltip !== undefined && hasTooltip}
    </div>
  );
};

export default ButtonSave;
