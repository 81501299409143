import React, { FC, useContext, useEffect, useState } from "react";
import { CartContext } from "../../../../context/CartContext";
import { ChargerTypes } from "../../../../types/basicProductsTypes";
import AllCompatiblesButton from "../../../Buttons/AllCompatiblesButton";
import { storageVariables } from "../../../../lib/storageVariables";
import BannerListItem from "../../../ListItems/BannerListItem";
import { getIsSelectedChargerInLastQuoteItem } from "../../../../helpers/storage";

interface LeftBannerBodyChargersPropsTypes {
  availableChargersForPurchase: ChargerTypes[] | [];
  isChargersFound?: boolean;
}

const LeftBannerBodyChargers: FC<LeftBannerBodyChargersPropsTypes> = ({
  availableChargersForPurchase,
  isChargersFound,
}) => {
  const { selectedCharger, setSelectedCharger } = useContext(CartContext);

  const [showAllCompatibles, setShowAllCompatibles] = useState<boolean>(false);

  const handleSelectedCharger = (charger: ChargerTypes) => {
    setSelectedCharger(charger);
    localStorage.setItem(
      storageVariables.SELECTED_CHARGER,
      JSON.stringify(charger)
    );
  };

  useEffect(() => {
    setShowAllCompatibles(false);
    if (availableChargersForPurchase?.length === 0) return;

    if (selectedCharger) {
      const isSelectedChargerInLastQuoteItem: boolean =
        getIsSelectedChargerInLastQuoteItem();

      if (isSelectedChargerInLastQuoteItem) {
        // check if the selected charger id is in the first 3 available batteries using availableBatteriesForPurchase.slice(0, 3)
        const first3Chargers = availableChargersForPurchase.slice(0, 3);

        const selectedChargerIn3First = first3Chargers.find(
          (charger) => charger.chargerId === selectedCharger.chargerId
        );

        if (!selectedChargerIn3First) {
          setShowAllCompatibles(true);
        }
      } else {
        handleSelectedCharger(availableChargersForPurchase[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableChargersForPurchase]);

  return (
    <div className="banner-left-outer">
      <div className="banner-left-inner">
        <div className="banner-title">
          {isChargersFound || availableChargersForPurchase?.length > 0
            ? "Compatible Chargers Found"
            : "Compatible Chargers not found"}
        </div>
        <div className="all-list-items-container">
          {availableChargersForPurchase?.length > 0 && !isChargersFound && (
            <React.Fragment>
              {!showAllCompatibles ? (
                <React.Fragment>
                  {availableChargersForPurchase
                    .slice(0, 3)
                    .map((charger: ChargerTypes) => (
                      <BannerListItem
                        key={charger.chargerId}
                        title={charger.model}
                        onClick={() => handleSelectedCharger(charger)}
                        active={
                          charger.chargerId === selectedCharger?.chargerId
                        }
                      />
                    ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {availableChargersForPurchase.map((charger: ChargerTypes) => (
                    <BannerListItem
                      key={charger.chargerId}
                      title={charger.model}
                      onClick={() => handleSelectedCharger(charger)}
                      active={charger.chargerId === selectedCharger?.chargerId}
                    />
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        {availableChargersForPurchase?.length > 0 && isChargersFound && (
          <React.Fragment>
            <React.Fragment>
              {availableChargersForPurchase.map((charger) => {
                const divStyle =
                  charger.chargerId === selectedCharger?.chargerId
                    ? "banner-list-item active"
                    : "banner-list-item";

                return (
                  <div
                    key={charger.chargerId}
                    onClick={() => setSelectedCharger(charger)}
                    className={divStyle}
                  >
                    {charger.model}
                  </div>
                );
              })}
            </React.Fragment>
          </React.Fragment>
        )}

        {!isChargersFound && (
          <div className="row box-grid-container all-compatible-button-container">
            <div className="col-md-6 col-left-align">
              {availableChargersForPurchase?.length > 3 &&
                !showAllCompatibles && (
                  <AllCompatiblesButton
                    title="All Compatible Chargers"
                    onClick={() => setShowAllCompatibles(true)}
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftBannerBodyChargers;
