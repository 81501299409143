import React, { FC } from "react";
import DropdownSelectInputChargers from "../../components/Inputs/DropdownSelectInputChargers";
import ButtonPrevious from "../../components/Buttons/ButtonPrevious";
import ButtonNext from "../../components/Buttons/ButtonNext";
import {
  ChargersOptionsDataType,
  inputDataTwoType,
} from "../../types/chargers";

interface StepTwoPropsTypes {
  inputDataStep: inputDataTwoType;
  dataChargersStep: ChargersOptionsDataType;
  isLithiumText: string | null;
  activeStep: number;
  changeStep: (_activeStep: 1 | 2 | 3) => void;
  errorsExist: boolean;
  dependencyCountryFromOne?: string | null;
}
const StepTwo: FC<StepTwoPropsTypes> = ({
  inputDataStep,
  dataChargersStep,
  isLithiumText,
  activeStep,
  changeStep,
  errorsExist,
  dependencyCountryFromOne,
}) => {
  return (
    <>
      <div className="find-charger-step find-charger-step-2">
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.chargerPhaseTypeArray}
          {...inputDataStep.phasetype}
          dependencyCountry={dependencyCountryFromOne}
        />
        <DropdownSelectInputChargers
          initialOptions={dataChargersStep?.chargerFrequencyArray}
          {...inputDataStep.frequency}
        />
      </div>
      {isLithiumText !== "Lithium-ion" && activeStep === 2 && (
        <div className="buttons-step-2">
          <div className="row box-grid-container">
            <div className="col-md-6">
              <div className="col-left-align">
                {!errorsExist ? (
                  <ButtonPrevious
                    title="Previous"
                    onClick={() => changeStep(1)}
                  />
                ) : (
                  <ButtonPrevious
                    title="Previous"
                    onClick={() => changeStep(1)}
                    disabled
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-right-align">
                {!errorsExist ? (
                  <ButtonNext onClick={() => changeStep(3)} />
                ) : (
                  <ButtonNext onClick={() => changeStep(3)} disabled />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StepTwo;
