/* eslint-disable no-plusplus */
import {
  fallbackBatteryImg,
  fallbackChargerImg,
  fallbackTruckImg,
} from "../assets/mockData/fallbackImages";
import {
  LeftBannerTopValuesForBatteriesTypes,
  LeftBannerTopValuesForChargersTypes,
  BannerRightTableTypes,
  BannerRightTopTypes,
} from "../types/bannersTypes";

import {
  BatteryTypesNew,
  BenefitsTypes,
  ParsedBenefitsTypes,
  ReplacementBatteriesTypes,
  ForkliftTypesNew,
  ChargerTypes,
  CoreFieldsTypes,
  BatteryForDataTableTypes,
} from "../types/basicProductsTypes";
import { FetchedCompanyTypes } from "../types/companies";
import {
  CurrentQuoteTypes,
  QuoteItemBatteryTypes,
  QuoteItemChargerTypes,
  QuoteItemForkliftTypes,
} from "../types/currentQuoteTree";
import {
  currentDecimalSeparator,
  currentGroupSeparator,
} from "./currencyFormat";
import { fixNumber, getCurrency } from "./storage";

interface SelectedProductTypes {
  [key: string]: string | number | boolean;
}

const isObjectEmpty = (obj: { [key: string]: any }) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const getCoreFieldsData = (
  fieldsArray: CoreFieldsTypes[],
  selectedProduct: SelectedProductTypes
): BannerRightTableTypes[] => {
  const productDetails: BannerRightTableTypes[] = [];

  if (isObjectEmpty(selectedProduct) || fieldsArray?.length === 0)
    return productDetails;

  for (let index = 0; index < fieldsArray.length; index++) {
    const coreFieldKey = fieldsArray[index].key;
    const coreFieldLabel = fieldsArray[index].label;

    if (coreFieldKey in selectedProduct) {
      const coreFieldValue = selectedProduct[coreFieldKey];

      if (coreFieldValue !== null) {
        let possibleBooleanField = coreFieldValue;
        if (typeof coreFieldValue === "boolean") {
          possibleBooleanField = coreFieldValue ? "Yes" : "No";
        }

        productDetails.push({
          id: index,
          title: coreFieldLabel,
          description: possibleBooleanField.toString(),
        });
      }
    }
  }

  return productDetails;
};

export const getBenefitsForBattery = (
  battery: BatteryTypesNew | null
): ParsedBenefitsTypes[] => {
  if (!battery) return [];
  const benefitsArray: BenefitsTypes[] = battery?.benefits || [];

  if (benefitsArray.length === 0) return [];

  const parsedBenefitsArray: ParsedBenefitsTypes[] = [];

  benefitsArray.forEach((benefit) => {
    const parsedBenefit: ParsedBenefitsTypes = {
      id: benefit?.batteryBenefitId,
      title: benefit?.batteryBenefitTitle || "",
      description: benefit?.batteryBenefitDescription || "",
    };

    if (parsedBenefit.id && parsedBenefit.title) {
      parsedBenefitsArray.push(parsedBenefit);
    }
  });

  return parsedBenefitsArray;
};

export const getBatteryReplacements = (
  battery: BatteryTypesNew | null
): ReplacementBatteriesTypes[] => {
  const replacementBatteriesArray: BatteryTypesNew[] =
    battery?.replacementBatteries || [];

  if (replacementBatteriesArray.length === 0) return [];

  const parsedReplacementBatteriesArray: ReplacementBatteriesTypes[] = [];

  replacementBatteriesArray.forEach((replacementBattery) => {
    const parsedReplacementBattery: ReplacementBatteriesTypes = {
      id: replacementBattery?.batteryId,
      title: replacementBattery?.batteryInfo,
    };

    if (parsedReplacementBattery.id && parsedReplacementBattery.title) {
      parsedReplacementBatteriesArray.push(parsedReplacementBattery);
    }
  });

  return parsedReplacementBatteriesArray;
};

export const getRightBannerTopValuesForBatteries = (
  battery: BatteryTypesNew | null
): BannerRightTopTypes => {
  const price = battery?.price?.price;
  const formattedPrice = price ? JSON.stringify(price) : "";

  // TODO: Add Money formatter in PRICE

  const tempTopValues: BannerRightTopTypes = {
    id: battery?.batteryId || 0,
    title: battery?.batteryInfo || "",
    price: formattedPrice,
    imageUrl: battery?.imageUrl || fallbackBatteryImg,
  };

  return tempTopValues;
};

// export const getLeftBannerTopValuesForBatteries = (
//   dataFetchedByForkliftId: ForkliftTypesNew[]
// ): LeftBannerTopValuesForBatteriesTypes | null => {
//   if (!dataFetchedByForkliftId || dataFetchedByForkliftId?.length === 0)
//     return null;

//   const forkliftElement: ForkliftTypesNew = dataFetchedByForkliftId[0];

//   const parsedBannerLeftTopValues: LeftBannerTopValuesForBatteriesTypes = {
//     id: forkliftElement?.forkliftId || 0,
//     manufacturer: forkliftElement?.manufacturer || "",
//     voltage: forkliftElement?.batteryVoltage || 0,
//     model: forkliftElement?.model || "",
//     imageUrl: forkliftElement?.imageUrl || fallbackTruckImg,
//   };
//   return parsedBannerLeftTopValues;
// };

export const getLeftBannerTopValuesForBatteries = (
  dataFetchedByForkliftId: ForkliftTypesNew[]
): LeftBannerTopValuesForBatteriesTypes | null => {
  if (!dataFetchedByForkliftId || dataFetchedByForkliftId?.length === 0)
    return null;

  const forkliftElement: ForkliftTypesNew = dataFetchedByForkliftId[0];

  const parsedBannerLeftTopValues: LeftBannerTopValuesForBatteriesTypes = {
    manufacturer: forkliftElement?.manufacturer || "",
    model: forkliftElement?.model || "",
    imageUrl: fallbackTruckImg,
  };
  return parsedBannerLeftTopValues;
};

export const getAvailableBatteriesForPurchase = (
  dataFetchedByForkliftId: ForkliftTypesNew[]
): BatteryTypesNew[] | [] => {
  if (
    dataFetchedByForkliftId &&
    dataFetchedByForkliftId?.length > 0 &&
    dataFetchedByForkliftId[0]?.batteries
  ) {
    return dataFetchedByForkliftId[0]?.batteries;
  }

  return [];
};

export const getBannerRightTableValuesForBatteries = (
  coreFieldsArray: CoreFieldsTypes[],
  selectedBattery: BatteryTypesNew | null
): BannerRightTableTypes[] => {
  if (!selectedBattery || coreFieldsArray?.length === 0) return [];

  return getCoreFieldsData(
    coreFieldsArray,
    selectedBattery as unknown as SelectedProductTypes
  );
};

/**
 * CHARGERS PARSERS
 */

// export const getLeftBannerTopValuesForChargers = (
//   dataFetchedByForkliftId: ForkliftTypesNew[],
//   selectedBattery: BatteryTypesNew | null
// ): LeftBannerTopValuesForChargersTypes | null => {
//   if (
//     !dataFetchedByForkliftId ||
//     dataFetchedByForkliftId?.length === 0 ||
//     !selectedBattery
//   )
//     return null;

//   const forkliftElement = dataFetchedByForkliftId[0];

//   const parsedBannerLeftTopValues: LeftBannerTopValuesForChargersTypes = {
//     id: forkliftElement?.forkliftId || 0,
//     manufacturer: forkliftElement?.manufacturer || "",
//     voltage: forkliftElement?.batteryVoltage || 0,
//     model: forkliftElement?.model || "",
//     imageUrl: forkliftElement?.imageUrl || fallbackTruckImg,

//     selectedBatteryId: selectedBattery?.batteryId || null,
//     selectedBatteryName: selectedBattery?.batteryInfo || null,
//     selectedBatteryImageUrl: selectedBattery?.imageUrl || fallbackBatteryImg,
//   };
//   return parsedBannerLeftTopValues;
// };

export const getLeftBannerTopValuesForChargers = (
  dataFetchedByForkliftId: ForkliftTypesNew[],
  selectedBattery: BatteryTypesNew | null
): LeftBannerTopValuesForChargersTypes | null => {
  if (
    !dataFetchedByForkliftId ||
    dataFetchedByForkliftId?.length === 0 ||
    !selectedBattery
  )
    return null;

  const forkliftElement = dataFetchedByForkliftId[0];

  const parsedBannerLeftTopValues: LeftBannerTopValuesForChargersTypes = {
    manufacturer: forkliftElement?.manufacturer || "",
    model: forkliftElement?.model || "",
    imageUrl: fallbackTruckImg,

    selectedBatteryId: selectedBattery?.batteryId || null,
    selectedBatteryName: selectedBattery?.batteryInfo || null,
    selectedBatteryImageUrl: selectedBattery?.imageUrl || fallbackBatteryImg,
  };
  return parsedBannerLeftTopValues;
};

export const getRightBannerTopValuesForChargers = (
  charger: ChargerTypes | null
): BannerRightTopTypes => {
  const price = charger?.price?.price;

  const formattedPrice = price ? JSON.stringify(price) : "";

  const tempTopValues: BannerRightTopTypes = {
    id: charger?.chargerId || 0,
    title: charger?.model || "",
    price: formattedPrice,
    imageUrl: charger?.imageUrl || fallbackBatteryImg,
  };

  return tempTopValues;
};

export const getBannerRightTableValuesForChargers = (
  chargerCoreFields: CoreFieldsTypes[],
  selectedCharger: ChargerTypes | null
): BannerRightTableTypes[] => {
  if (!selectedCharger) return [];

  return getCoreFieldsData(
    chargerCoreFields,
    selectedCharger as unknown as SelectedProductTypes
  );
};

export const parseFetchedCompanies = (
  companies: FetchedCompanyTypes[]
): string[] => {
  if (!companies || companies.length === 0) return [];

  const parsedCompanies: string[] = [];

  companies.forEach((company) => {
    parsedCompanies.push(company.companyName);
  });

  return parsedCompanies;
};

export interface ItemsDataTypes {
  imageUrl: string;
  title: string;
  price: number;
}

export const getQuotePopupItemsValues = (
  currentQuote: CurrentQuoteTypes | null
): ItemsDataTypes[] => {
  const quoteItemsPopupValues: ItemsDataTypes[] = [];

  if (!currentQuote || currentQuote.quoteItems.length === 0)
    return quoteItemsPopupValues;

  currentQuote.quoteItems.forEach(
    (
      item:
        | QuoteItemForkliftTypes
        | QuoteItemBatteryTypes
        | QuoteItemChargerTypes
    ) => {
      if (item.itemType === "charger") {
        const parsedItemCharger: ItemsDataTypes = {
          imageUrl: item.imageUrl || fallbackChargerImg,
          title: item.name || "",
          price: item?.manualAddedPrice ?? item?.totalPrice,
        };

        quoteItemsPopupValues.push(parsedItemCharger);
      } else if (item.itemType === "forklift" || item.itemType === "battery") {
        const battery: QuoteItemBatteryTypes =
          item.itemType === "forklift"
            ? (item.battery as QuoteItemBatteryTypes)
            : (item as QuoteItemBatteryTypes);

        if (!battery) return;

        const parsedItemBattery: ItemsDataTypes = {
          imageUrl: battery?.imageUrl || fallbackBatteryImg,
          title: battery?.name || "",
          price: battery?.manualAddedPrice ?? (battery?.totalPrice || 0),
        };

        quoteItemsPopupValues.push(parsedItemBattery);

        if (battery?.charger) {
          const parsedItemChargerInBattery: ItemsDataTypes = {
            imageUrl: battery?.charger?.imageUrl || fallbackChargerImg,
            title: battery?.charger?.name || "",
            price:
              battery?.charger?.manualAddedPrice ??
              (battery?.charger?.totalPrice || 0),
          };

          quoteItemsPopupValues.push(parsedItemChargerInBattery);
        }
      }
    }
  );

  return quoteItemsPopupValues;
};

export const parseFetchedBatteries = ({
  batteries,
  sizeUnit,
  weightUnit,
}: {
  batteries: BatteryTypesNew[];
  sizeUnit: string;
  weightUnit: string;
}): BatteryForDataTableTypes[] => {
  if (batteries.length === 0) return [];

  const tempBatteriesArray: BatteryForDataTableTypes[] = [];

  batteries.forEach((battery) => {
    const tempBattery: BatteryForDataTableTypes = {
      batteryId: battery.batteryId,
      sapId: `${battery.sapId}`,
      chemistry: battery.chemistry,
      batteryType: battery.batteryType,
      voltage: `${battery.voltage} V`,
      capacity: `${battery.capacity} Ah`,
      din: battery.din ? "Yes" : "No",
      length: `${battery.length} ${sizeUnit}`,
      height: `${battery.height} ${sizeUnit}`,
      width: `${battery.width} ${sizeUnit}`,
      nominalSuggestedWeight: `${battery.nominalSuggestedWeight} ${weightUnit}`,
      minimumWeight: `${battery.minimumWeight} ${weightUnit}`,
      drawingNumber: `${battery.drawingNumber}`,
      color: battery?.color || "",
    };

    tempBatteriesArray.push(tempBattery);
  });

  return tempBatteriesArray;
};

export const getFloatFromString = (value: string): number => {
  const currency = getCurrency();

  let tempValue = value.replace(currentGroupSeparator, "");

  if (currency === "EUR") {
    tempValue = value
      .replace(currentGroupSeparator, "")
      .replace(currentDecimalSeparator, ".");
  }

  const numericValue = parseFloat(tempValue);

  return fixNumber(numericValue);
};

export const getStringFromFloat = (value: number): string => {
  const tempValue = value.toString().replace(".", currentDecimalSeparator);

  return tempValue;
};

export const getFloatFromEvent = (
  event: React.ChangeEvent<HTMLInputElement>
): number => {
  const { value: rawValue } = event.target;

  // Remove non-numeric characters and restrict to two decimal places
  const numericValue = parseFloat(
    rawValue.replace(/[^0-9.]/g, "").replace(/(\..{0,2})\..*/g, "$1")
  );

  return numericValue;
};
