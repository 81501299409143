import { useContext } from "react";

import { useMsal } from "@azure/msal-react";

import { somethingWentWrongMessage } from "../settings/errorMessages";
import { storageVariables } from "../lib/storageVariables";
import { useNavigation } from "./useNavigation";
import { loginRequest } from "../authConfig";
import { availableRoutes } from "../lib/routes";
import { confirmLogoutMessage } from "../settings/confirmAlertMessages";
import { AuthContext } from "../context/AuthContext";

const useAuth = () => {
  const { instance, accounts } = useMsal();

  const getAgentName = (): string | undefined => {
    return accounts && accounts?.length > 0 ? accounts[0]?.name : "";
  };

  const getAgentId = (): string | undefined => {
    return accounts && accounts?.length > 0
      ? accounts[0]?.idTokenClaims?.aud
      : "";
  };

  const { fullNavigateToUrl } = useNavigation();

  const { setAuthApiError } = useContext(AuthContext);

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .catch((e) => {
        setAuthApiError(e?.message || somethingWentWrongMessage);
      })
      .finally(() => {
        localStorage.removeItem(storageVariables.CURRENCY);
        localStorage.removeItem(storageVariables.CURRENT_QUOTE);
        localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
        localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);
        localStorage.removeItem(storageVariables.SELECTED_BATTERY);
        localStorage.removeItem(storageVariables.SELECTED_CHARGER);
        localStorage.removeItem(storageVariables.SELECTED_COMPANY);
        localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
        localStorage.removeItem(
          storageVariables.SELECTED_FORKLIFT_FETCHED_DATA
        );
        fullNavigateToUrl(availableRoutes.HOME);
      });
  };

  const handleLogout = (force = false): void => {
    if (!force) {
      // eslint-disable-next-line no-alert
      const deleteCurrentQuoteResult = window.confirm(confirmLogoutMessage);
      if (!deleteCurrentQuoteResult) {
        return;
      }
    }

    instance
      .logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      })
      .then(() => {})
      .catch((e) => {
        setAuthApiError(e?.message || somethingWentWrongMessage);
      })
      .finally(() => {
        localStorage.removeItem(storageVariables.CURRENCY);
        localStorage.removeItem(storageVariables.CURRENT_QUOTE);
        localStorage.removeItem(storageVariables.SELECTED_FORKLIFT);
        localStorage.removeItem(storageVariables.SELECTED_QUOTE_ID);
        localStorage.removeItem(storageVariables.SELECTED_BATTERY);
        localStorage.removeItem(storageVariables.SELECTED_CHARGER);
        localStorage.removeItem(storageVariables.SELECTED_COMPANY);
        localStorage.removeItem(storageVariables.CORE_FIELDS_ARRAY_CHARGER);
        localStorage.removeItem(
          storageVariables.SELECTED_FORKLIFT_FETCHED_DATA
        );
        fullNavigateToUrl(availableRoutes.LOGIN);
      });
  };

  return {
    handleLogout,
    handleLogin,

    getAgentName,
    getAgentId,
  };
};

export default useAuth;
