import React, { FC } from "react";

interface PropsTypes {
  title: string;
  active?: boolean;
  onClick: () => void;
}

const BannerListItem: FC<PropsTypes> = ({ title, onClick, active = false }) => {
  return (
    <div
      onClick={onClick}
      className={`banner-list-item ${active ? "active" : ""}`}
    >
      {title}
    </div>
  );
};

export default BannerListItem;
