/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useCallback } from "react";

import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  SortType,
  TableBody,
  TableColumnType,
  TableHeader,
} from "react-bs-datatable";
import TABLE_TYPES from "../../types/batteryTableSchema.json";
import { storageVariables } from "../../lib/storageVariables";

export interface FetchParams {
  filter: string;
  sortState: SortType;
  currentPage: number;
  // rowsPerPage: number;
}

export interface FetchResponse<T> {
  data: T[];
  maxPage: number;
  filteredDataLength: number;
}

export type TableRowType<T = any> = Record<string, T>;

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20];

interface PropTypes {
  fetchedData: any[];
  filter: string;
  sortState: SortType;
  measureUnitSize: string;
  measureUnitWeight: string;
  setFilter: (_data: string) => void;
  setSortState: (_data: SortType) => void;
  setCurrentPage: (_data: number) => void;
  handleSelectBattery: (_id: number) => void;
  paginationSettings: {
    totalRecords: number;
    page: number;
    recordsPerPage: number;
    totalPages: number;
  };
}

const DataTableWithApi: FC<PropTypes> = ({
  filter,
  sortState,
  fetchedData,
  paginationSettings,
  measureUnitSize,
  measureUnitWeight,
  setFilter,
  setSortState,
  setCurrentPage,
  handleSelectBattery,
}) => {
  type ArrayElementType = (typeof TABLE_TYPES)[number] & {
    button: any;
  };

  let STORY_HEADERS: TableColumnType<ArrayElementType>[] = [
    {
      prop: "batteryId",
      title: "BATTERY ID",
      isFilterable: true,
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.batteryId ?? ""}
          </div>
        );
      },
    },
    {
      prop: "sapId",
      title: "SAP CODE",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">{row.sapId ?? ""}</div>
        );
      },
    },
    {
      prop: "chemistry",
      title: "CHEMISTRY",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.chemistry ?? ""}
          </div>
        );
      },
    },
    {
      prop: "batteryType",
      title: "TYPE",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.batteryType ?? ""}
          </div>
        );
      },
    },
    {
      prop: "voltage",
      title: "VOLTAGE",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.voltage ? `${row.voltage} V` : ""}
          </div>
        );
      },
    },
    {
      prop: "capacity",
      title: "CAPACITY",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.capacity ? `${row.capacity} Ah` : ""}
          </div>
        );
      },
    },
    {
      prop: "din",
      title: "DIN",
      isSortable: true,
      cell(row) {
        if (row.din === null)
          return <div className="d-flex justify-content-center"> - </div>;
        if (!row.din)
          return (
            <span
              className="d-flex justify-content-center"
              style={{ color: "#D20000" }}
            >
              &#10006;
            </span>
          );
        return (
          <span
            className="d-flex justify-content-center"
            style={{ color: "#06b300" }}
          >
            &#10004;
          </span>
        );
      },
    },
    {
      prop: "length",
      title: "LENGTH",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.length ? `${row.length} ${measureUnitSize}` : ""}
          </div>
        );
      },
    },
    {
      prop: "width",
      title: "WIDTH",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.width ? `${row.width} ${measureUnitSize}` : ""}
          </div>
        );
      },
    },
    {
      prop: "height",
      title: "HEIGHT",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.height ? `${row.height} ${measureUnitSize}` : ""}
          </div>
        );
      },
    },
    {
      prop: "nominalSuggestedWeight",
      title: "BATTERY WEIGHT",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end">
            {row.nominalSuggestedWeight
              ? `${row.nominalSuggestedWeight} ${measureUnitWeight}`
              : ""}
          </div>
        );
      },
    },
    // {
    //   prop: "minimumWeight",
    //   title: "MIN. WEIGHT",
    //   isSortable: true,
    //   cell(row) {
    //     return (
    //       <div className="d-flex justify-content-end ">
    //         {row.minimumWeight
    //           ? `${row.minimumWeight} ${measureUnitWeight}`
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      prop: "leadAcidBatteryTrayCode",
      title: "TRAY CODE",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end">
            {row.leadAcidBatteryTrayCode ?? ""}
          </div>
        );
      },
    },
    {
      prop: "drawingNumber",
      title: "DRAWING CODE",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">
            {row.drawingNumber ?? ""}
          </div>
        );
      },
    },
    {
      prop: "color",
      title: "COLOUR",
      isSortable: true,
      cell(row) {
        return (
          <div className="d-flex justify-content-end ">{row.color ?? ""}</div>
        );
      },
    },
    {
      prop: "button",
      cell: (row: { batteryId: number | string }) => (
        <div
          className="cyan-btn corner-btn table-btn"
          onClick={() => {
            const id: number =
              typeof row.batteryId === "string"
                ? parseInt(row.batteryId, 10)
                : row.batteryId;
            handleSelectBattery(id);
          }}
        >
          <div className="btn-text">&#x2b;</div>
        </div>
      ),
    },
  ];
  const currentQuoteStr = localStorage.getItem(storageVariables.CURRENCY);
  if (currentQuoteStr !== null) {
    if (JSON.parse(currentQuoteStr).currency === "USA") {
      STORY_HEADERS = STORY_HEADERS.filter((obj) => obj.prop !== "color");
    }
  }

  const onFilterChange = useCallback((text: string) => {
    setFilter(text);
    setCurrentPage(0);
  }, []);

  const onSortChange = useCallback((nextProp: SortType) => {
    setSortState(nextProp);
    setCurrentPage(0);
  }, []);

  const onPaginationChange = useCallback((nextPage: number) => {
    setCurrentPage(nextPage - 1);
  }, []);

  return (
    <div className="pt-2 overflow-x-scroll">
      <DatatableWrapper
        body={fetchedData}
        headers={STORY_HEADERS}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: process.env
              .REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER
              ? parseInt(
                  process.env.REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER,
                  10
                )
              : 5,
            options: DEFAULT_ROWS_PER_PAGE_OPTIONS,
          },
        }}
      >
        <div className="row box-grid-container mt-4">
          <div className="col-md-6">
            <div className="banner-title mobile-center">Batteries Found</div>
          </div>
          <div className="col-md-6 datatable-search">
            <Filter
              controlledProps={{ filter, onFilterChange }}
              placeholder="Quick filter by Sap Code"
            />
          </div>
        </div>
        <Table>
          <TableHeader
            controlledProps={{
              sortState,
              filteredDataLength: paginationSettings.totalRecords,
              onSortChange,
            }}
          />
          <TableBody
            rowProps={{
              className: "table table-hover",
            }}
            controlledProps={{
              filteredDataLength: paginationSettings.totalRecords,
            }}
          />
        </Table>
        <Row className="mb-12 d-flex flex-col justify-content-center">
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-center"
          >
            <Pagination
              paginationRange={
                process.env.REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER
                  ? parseInt(
                      process.env.REACT_APP_PAGINATION_MAX_VISIBLE_PAGES_NUMBER,
                      10
                    )
                  : 5
              }
              controlledProps={{
                currentPage: paginationSettings.page + 1,
                // currentPage: currentPage + 1,
                maxPage: paginationSettings.totalPages,
                onPaginationChange,
              }}
            />
          </Col>
        </Row>
      </DatatableWrapper>
    </div>
  );
};

export default DataTableWithApi;
