import React, { FC } from "react";
import { SelectedPopupTableTypes } from "../../../../types/SelectedBatteryPopup";
import ChargerItemTop from "./ChargerItemTop";
import RightBannerTable from "../../RightBanner/RightBannerTable";
// import { BannerRightTopTypes } from "../../../../types/bannersTypes";
import { ChargerTypes } from "../../../../types/basicProductsTypes";
import { BannerRightTopTypes } from "../../../../types/bannersTypes";
import { getRightBannerTopValuesForChargers } from "../../../../helpers/parsers";

interface ProsTypes {
  addItemToQuote: () => void;
  charger: ChargerTypes;
  chargerPopupTableValues: SelectedPopupTableTypes[];
  isSelected: boolean;
  onClick: (_id: number) => void;
  onIncrement: () => void;
  onDecrement: () => void;
  quantity: number;
  chargerId: number;
  setQuantity: (_value: number) => void;
}

const ChargerItem: FC<ProsTypes> = ({
  addItemToQuote,
  charger,
  chargerPopupTableValues,
  isSelected,
  onClick,
  onIncrement,
  onDecrement,
  quantity,
  chargerId,
  setQuantity,
}) => {
  const chargerItemTopValues: BannerRightTopTypes =
    getRightBannerTopValuesForChargers(charger);

  const { title } = chargerItemTopValues;

  return (
    <div data-testid="selected-charger-popup">
      <div
        className={`banner-list-item ${isSelected ? "active" : ""} `}
        onClick={() => onClick(chargerId)}
      >
        {title}
      </div>
      {isSelected && (
        <div className="product-details-outer p-0">
          <div className="product-details-inner">
            <ChargerItemTop
              quantity={quantity}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              addItemToQuote={addItemToQuote}
              chargerPopupTopValues={chargerItemTopValues}
              setQuantity={setQuantity}
            />
            {chargerPopupTableValues?.length > 0 && (
              <RightBannerTable
                rightBannerTableValues={chargerPopupTableValues}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChargerItem;
