import { useNavigate } from "react-router-dom";

export const useNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string): void => {
    navigate(path);
  };

  const goBack = (depth = -1): void => {
    navigate(depth);
  };

  function fullNavigateToUrl(url: string) {
    window.location.href = url;
  }

  return { navigateTo, goBack, fullNavigateToUrl };
};
