// This is not working as expected anymore.
export const getdateFromTimestampOld = (timestamp: string) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getdateFromTimestamp = (timestamp: string): string => {
  // get index of 2nd / and return everything from the beginning to the 4th digit after it
  const secondSlashIndex = timestamp.indexOf("/", timestamp.indexOf("/") + 1);

  if (secondSlashIndex === -1) return timestamp;

  return timestamp.slice(0, secondSlashIndex + 5);
};

export const getDateFromDateObject = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}/${month}/${year}, ${hour}:${
    minutes > 9 ? minutes : `0${minutes}`
  }:${seconds > 9 ? seconds : `0${seconds}`}`;
};

export const getDateWithOffsetDays = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const createDateObjectFromCustomDateString = (
  dateString: string
): Date => {
  const [day, month, year] = dateString
    .split("/")
    .map((datePart) => parseInt(datePart, 10));

  // remove the date part from the dateString
  const tempDateString = dateString.slice(dateString.indexOf(",") + 1);

  // get hour minute and second from the incoming dateString
  const [hour, minutes] = tempDateString
    .split(":")
    .map((datePart) => parseInt(datePart, 10));

  const seconds = 0;

  return new Date(year, month - 1, day, hour, minutes, seconds);
};
