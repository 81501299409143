import React, { FC } from "react";
import { numberValidationRegex } from "../../config/validationRegex";

interface PropsTypes {
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
  handleManualQuantityChange?: (_value: number) => void;
}

const QuantityInput: FC<PropsTypes> = ({
  quantity,
  onIncrement,
  onDecrement,
  handleManualQuantityChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!handleManualQuantityChange) return;

    const value = parseInt(e.target.value, 10);

    if (!numberValidationRegex.test(String(value))) return;

    if (value > 0) {
      handleManualQuantityChange(value);
    }
  };

  return (
    <div className="quantity-inner-popup">
      <div className="quantity-btn corner-btn-left" onClick={onDecrement}>
        <div className="icon symmetry-icon-arrow-down" />
      </div>

      <input
        className="cart-item-quantity-number-input"
        type="number"
        value={quantity}
        onChange={handleInputChange}
      />
      <div className="quantity-btn corner-btn-right " onClick={onIncrement}>
        <div className="icon symmetry-icon-arrow-up" />
      </div>
    </div>
  );
};

export default QuantityInput;
