import { FetchedForkliftFleetTypesNew } from "../types/forkliftFleetTypes";
import { getForkliftFullName } from "./functions";

export const sortForkliftsByName = (
  forklifts: FetchedForkliftFleetTypesNew[]
): FetchedForkliftFleetTypesNew[] => {
  if (!forklifts) return [];

  if (forklifts?.length < 2) return forklifts;

  return forklifts.sort((a, b) => {
    const nameA = getForkliftFullName(a);
    const nameB = getForkliftFullName(b);
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
