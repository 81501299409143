import React, { FC } from "react";

interface ExistingInitialPricePropsTypes {
  formattedPrice: React.ReactNode | string;
}

const ExistingInitialPrice: FC<ExistingInitialPricePropsTypes> = ({
  formattedPrice,
}) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-2 cart-item-col cart-item-price">
      <div className="cart-item-top right-align">
        <div className="cart-item-price-label">PRICE</div>
      </div>
      <div className="cart-item-bottom col-right-align right-align">
        <div className="cart-item-price">{formattedPrice}</div>
      </div>
    </div>
  );
};

export default ExistingInitialPrice;
