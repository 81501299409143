import React, { lazy, Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import NotFoundPage from "./pages/NotFound/NotFoundPage";

import NavBarDesktop from "./components/NavBarDesktop";
import CurrentQuotePage from "./pages/Quotes/CurrentQuotePage";
import LoginPage from "./pages/Auth/LoginPage";
import AllQuotesPage from "./pages/Quotes/AllQuotesPage";
import { availableRoutes } from "./lib/routes";
import SelectChargerByForkliftPage from "./pages/Charger/SelectChargerByForkliftPage";
import SearchBatteryPage from "./pages/Battery/SearchBatteryPage";
import EmptyPage from "./pages/EmptyPage";
import SelectJourneyPage from "./pages/SelectJourney/SelectJourneyPage";
import SelectCustomerPage from "./pages/Customer/SelectCustomerPage";
import SelectForkliftPage from "./pages/Forklift/SelectForkliftPage";
import HomePage from "./pages/Home/HomePage";
import SearchChargerPage from "./pages/Charger/SearchChargerPage";
import QuotesByForkliftPage from "./pages/Quotes/QuotesByForkliftPage";
import AddNewForklictToExistingFleet from "./pages/Forklift/AddNewForklictToExistingFleet";
import ExistingQuoteByIdPage from "./pages/Quotes/ExistingQuoteByIdPage";
import AddNewCompanyPage from "./pages/Customer/AddNewCompanyPage";
import ChargersFound from "./pages/Charger/ChargersFound";

const SelectBatteryByForkliftPage = lazy(
  () => import("./pages/Battery/SelectBatteryByForkliftPage")
);

function Layout() {
  return (
    <>
      <NavBarDesktop />
      <Outlet />
    </>
  );
}

export default function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path={availableRoutes.HOME} element={<Layout />}>
            <Route
              index
              element={
                <Suspense fallback={<EmptyPage />}>
                  <HomePage />
                </Suspense>
              }
            />

            <Route
              path={availableRoutes.CURRENT_QUOTE}
              element={<CurrentQuotePage />}
            />

            <Route
              path={availableRoutes.ADD_NEW_COMPANY}
              element={<AddNewCompanyPage />}
            />

            <Route
              path={availableRoutes.ALL_QUOTES}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <AllQuotesPage />
                </Suspense>
              }
            />

            <Route
              path={availableRoutes.QUOTE_ITEM_BY_ID}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <ExistingQuoteByIdPage />
                </Suspense>
              }
            />
            <Route
              path={availableRoutes.QUOTES_BY_FORKLIFT_ID}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <QuotesByForkliftPage />
                </Suspense>
              }
            />

            <Route
              path={availableRoutes.QUOTES_BY_FORKLIFT_ID}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <QuotesByForkliftPage />
                </Suspense>
              }
            />

            <Route
              path={availableRoutes.START_JOURNEY_BY_FORKLIFT}
              element={<SelectForkliftPage />}
            />
            <Route
              path={availableRoutes.ADD_NEW_FORKLIFT_TO_EXISTING_FLEET}
              element={<AddNewForklictToExistingFleet />}
            />
            <Route
              path={availableRoutes.START_JOURNEY_BY_BATTERY}
              element={<SearchBatteryPage />}
            />

            <Route
              path={availableRoutes.START_JOURNEY_BY_CHARGER}
              element={<SearchChargerPage />}
            />

            <Route
              path={availableRoutes.SELECT_BATTERY_BY_FORKLIFT}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <SelectBatteryByForkliftPage />
                </Suspense>
              }
            />
            <Route
              path={availableRoutes.SELECT_CHARGER_BY_FORKLIFT}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <SelectChargerByForkliftPage />
                </Suspense>
              }
            />
            <Route
              path={availableRoutes.CHARGERS_FOUND}
              element={
                <Suspense fallback={<EmptyPage />}>
                  <ChargersFound />
                </Suspense>
              }
            />

            <Route
              path={availableRoutes.CHOOSE_JOURNEY}
              element={<SelectJourneyPage />}
            />

            <Route
              path={availableRoutes.SELECT_CUSTOMER}
              element={<SelectCustomerPage />}
            />

            <Route path={availableRoutes.LOGIN} element={<LoginPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Routes>
          <Route path={availableRoutes.HOME} element={<Layout />}>
            {/* <Route index element={<Navigate to={availableRoutes.LOGIN} />} /> */}
            <Route index element={<LoginPage />} />
            <Route path={availableRoutes.LOGIN} element={<LoginPage />} />
            {/* <Route path="*" element={<LoginPage />} /> */}
            <Route path="*" element={<Navigate to={availableRoutes.LOGIN} />} />
          </Route>
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
}
