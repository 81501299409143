import React, { FC, useEffect, useState } from "react";
import ButtonHeaderAllQuotes from "../../Buttons/ButtonHeaderAllQuotes";
import CustomerDetails, {
  CustomerDetailsPropsTypes,
} from "../CurrentQuotePageItems/CurrentQuoteHeader/CustomerDetails";
import { CurrentQuoteTypes } from "../../../types/currentQuoteTree";
import HeaderDateInfos from "./ExistingQuoteItem/ExistingQuoteItemComponents/HeaderDateInfos";
import { useFormatDate } from "../../../hooks/useFormatDate";

interface HeaderPropsTypes {
  code: string;
  title: string;
  name: string;
  email: string;
  tel: string;
  quoteId: string | null;
}

const initialHeaderValues: HeaderPropsTypes = {
  code: "",
  title: "",
  name: "",
  email: "",
  tel: "",
  quoteId: "",
};

interface ExistingQuoteHeaderPropsTypes {
  fetchedQuote: CurrentQuoteTypes;
  makePdf?: () => void;
  duplicateQuote?: () => void;
  editQuote?: () => void;
  quoteNumber: string | null;
}

const ExistingQuoteHeader: FC<ExistingQuoteHeaderPropsTypes> = ({
  fetchedQuote,
  makePdf,
  duplicateQuote,
  editQuote,
  quoteNumber,
}) => {
  const { formatDateByCurrency } = useFormatDate();
  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetailsPropsTypes>(initialHeaderValues);

  const pdfQuote = () => {
    if (makePdf) makePdf();
  };

  const duplicate = () => {
    if (duplicateQuote) duplicateQuote();
  };

  useEffect(() => {
    const {
      sapId,
      companyName,
      address,
      city,
      countryName,
      phone,
      email,
      contactPersonFirstName,
      contactPersonLastName,
      contactPersonTitle,
    } = fetchedQuote.customer;

    const contactPersonFullName = `${contactPersonFirstName ?? ""} ${
      contactPersonLastName ?? ""
    } ${contactPersonTitle ? `- ${contactPersonTitle}` : ""}`;

    const tempCustomerDetails: CustomerDetailsPropsTypes = {
      code: sapId ?? "",
      title: `${companyName ?? ""} - ${address ?? ""}, ${city ?? ""}, ${
        countryName || ""
      }`,
      name: contactPersonFullName,
      email: email ?? "",
      tel: phone ?? "",
      quoteId: quoteNumber ?? "",
    };

    setCustomerDetails(tempCustomerDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="row box-grid-container cart-header-container">
      <div className="col-md-4 col-left-align">
        <CustomerDetails {...customerDetails} />
      </div>

      <div className="col-md-6 col-right-align header-cart-right">
        <div className="header-cart-right-top">
          <ButtonHeaderAllQuotes
            onClick={editQuote}
            title="Edit Quote"
            icon="document-edit"
          />
          <ButtonHeaderAllQuotes
            onClick={duplicate}
            title="Duplicate Quote"
            icon="step-up-down"
          />
          <ButtonHeaderAllQuotes
            onClick={pdfQuote}
            title="Make PDF"
            icon="bill"
          />
        </div>

        <div className="container header-cart-right-bottom">
          <div className="header-cart-right-bottom-inner">
            <HeaderDateInfos
              title="DATE CREATED"
              date={
                fetchedQuote?.dateCreated
                  ? formatDateByCurrency(fetchedQuote?.dateCreated)
                  : ""
              }
            />
            <HeaderDateInfos
              title="DATE SUBMITTED"
              date={
                fetchedQuote?.dateSubmitted
                  ? formatDateByCurrency(fetchedQuote?.dateSubmitted)
                  : ""
              }
            />
            <HeaderDateInfos
              title="EXPIRES ON"
              date={
                fetchedQuote?.dateExpired
                  ? formatDateByCurrency(fetchedQuote?.dateExpired)
                  : ""
              }
            />
            <HeaderDateInfos
              title="DATE MODIFIED"
              date={
                fetchedQuote?.dateModified
                  ? formatDateByCurrency(fetchedQuote?.dateModified)
                  : ""
              }
            />
            <div className="cart-item-top ms-4">
              <div className="cart-item-price-label mb-1">SALES REP.</div>
              <div className="cart-item-price-label">
                <span className="zona-bold one-rem">
                  <strong>{fetchedQuote.salesPersonName}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingQuoteHeader;
