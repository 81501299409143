import React, { FC } from "react";

interface ButtonAddPropsTypes {
  title: string;
  onClick: () => void;
}

const ButtonAddTransparent: FC<ButtonAddPropsTypes> = ({ onClick, title }) => {
  return (
    <div className="transparent-btn underline-hover-btn" onClick={onClick}>
      <div className="btn-icon">
        <div className="icon symmetry-icon-add" />
      </div>
      <div className="btn-text">{title}</div>
    </div>
  );
};

export default ButtonAddTransparent;
